//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum SectionBlockTypeDto { 
	SectionTextBlock = "SectionTextBlock", 
	SectionImageBlock = "SectionImageBlock", 
	SectionQuoteBlock = "SectionQuoteBlock", 
	SectionMultipleQuotesBlock = "SectionMultipleQuotesBlock", 
	SectionIconListBlock = "SectionIconListBlock", 
	SectionArticleListBlock = "SectionArticleListBlock"
}
