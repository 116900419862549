import { observer } from "mobx-react";
import React from "react";
import { TaskView } from "../../../../shared/components/tasks/TaskView";
import { TestTaskInfo } from "../../test/testTaskInfo/TestTaskInfo";
import { TestTask } from "./TestTask";

interface TestTaskProps {
  testTask: TestTask;
  shouldTaskTitlesBeDisplayed: boolean;
}

export const TestTaskView = observer((props: TestTaskProps) => {
  const { testTask, shouldTaskTitlesBeDisplayed } = props;
  const { task } = testTask;

  return (
    <div className="TestTask">
      <TestTaskInfo
        title={task.model.title}
        description={task.model.description}
        shouldTaskTitlesBeDisplayed={shouldTaskTitlesBeDisplayed}
      />
      <div className="TestTask__taskContent">
        <TaskView task={task} />
      </div>
    </div>
  );
});
