import uniqueId from "lodash/uniqueId";

export class HttpError extends Error {
  public id = uniqueId();

  constructor(public code: number, public message: string) {
    super(message);
  }
}

export class PageNotFoundError extends HttpError {
  constructor(message: string) {
    super(404, message);
  }
}

export const isHttpError = (error: unknown): error is HttpError => error instanceof HttpError;
