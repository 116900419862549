import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../shared/components/button/Button";
import { EmptySearch } from "../../shared/components/emptySearch/EmptySearch";
import { Image, SanityImage } from "../../shared/components/image/Image";
import { Label } from "../../shared/components/label/Label";
import { List } from "../../shared/components/list/List";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { SectionHeading } from "../../shared/components/sectionHeading/SectionHeading";
import { Select } from "../../shared/components/select/Select";
import { SvgIcon } from "../../shared/components/svgIcon/SvgIcon";
import { HighlightedThumbnail } from "../../shared/components/thumbnails/highlightedThumbnail/HighlightedThumbnail";
import { Title } from "../../shared/components/title/Title";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { useUrl } from "../../useRouter";
import { PreviousExamsViewModel } from "./PreviousExamsViewModel";
import { ExamThemeCard } from "./components/examThemeCard/ExamThemeCard";

interface PreviousExamsViewProps {
  model: PreviousExamsViewModel;
}

export const PreviousExamsView = observer((props: PreviousExamsViewProps) => {
  const { model } = props;
  const {
    previousExamsStructure,
    courseParams,
    examsByYear,
    examsYears,
    yearsSelect,
    typesSelect,
    areFiltersApplied,
    resetFilters,
    filteredItems,
  } = model;
  const { title, description, image, articles } = previousExamsStructure;
  const translations = useTranslations();
  const url = useUrl();

  return (
    <PageTitle title={translations.previousExams}>
      <article className="PreviousExams">
        <div className="PreviousExams__inner">
          <section className="PreviousExams__hero">
            <div className="PreviousExams__content">
              <Title level={1} className="PreviousExams__title">
                {title}
              </Title>
              {description && <p className="PreviousExams__description">{description}</p>}
            </div>
            <Image.Container aspectRatio="3/2" className="PreviousExams__imageContainer">
              {image && <SanityImage {...image} />}
            </Image.Container>
          </section>
          <form className="PreviousExams__filters" noValidate>
            <div className="PreviousExams__filter">
              <Label>{translations.year}</Label>
              <Select
                isTrainingGroundTask={false}
                isMulti
                options={yearsSelect.availableItems}
                onChange={val => yearsSelect.setValue(val)}
                value={yearsSelect.value}
                label={translations.year}
                placeholder={yearsSelect.placeholder}
              />
            </div>
            {typesSelect.areAnyItemsInSelect && (
              <div className="PreviousExams__filter">
                <Label>{translations.type}</Label>
                <Select
                  isTrainingGroundTask={false}
                  isMulti
                  options={typesSelect.availableItems}
                  onChange={val => typesSelect.setValue(val)}
                  value={typesSelect.value}
                  label={translations.type}
                  placeholder={typesSelect.placeholder}
                />
              </div>
            )}
          </form>
          {areFiltersApplied && (
            <div className="PreviousExams__filtersListWrapper">
              <ul className="PreviousExams__filtersList">
                {yearsSelect.valueWithLabels.map(item => (
                  <li key={item.value} className="PreviousExams__filtersListItem">
                    <Button
                      onClick={() => yearsSelect.deselectItem(item.value)}
                      type="outline"
                      variant="secondary"
                      size="xsmall"
                    >
                      {item.label}
                      <SvgIcon iconName="close" />
                    </Button>
                  </li>
                ))}
                {typesSelect.valueWithLabels.map(item => (
                  <li key={item.value} className="PreviousExams__filtersListItem">
                    <Button
                      onClick={() => typesSelect.deselectItem(item.value)}
                      type="outline"
                      variant="secondary"
                      size="xsmall"
                    >
                      {item.label}
                      <SvgIcon iconName="close" />
                    </Button>
                  </li>
                ))}
              </ul>
              <Button type="clear" size="small" className="PreviousExams__clearFiltersButton" onClick={resetFilters}>
                <SvgIcon iconName="close" className="PreviousExams__clearFiltersIcon" />
                <span>{translations.clearSelection}</span>
              </Button>
            </div>
          )}
          <section className="PreviousExams__exams">
            {areFiltersApplied ? (
              <>
                <SectionHeading title={translations.searchResults} level={2} />
                <List
                  items={filteredItems}
                  renderItem={examTheme => <ExamThemeCard examTheme={examTheme} />}
                  empty={
                    <div className="PreviousExams__emptyExamsList">
                      <EmptySearch
                        emptySearchMessage={translations.sorryNoSearchResults}
                        linkUrl={url.toDashboardPage(courseParams)}
                        linkText={translations.goToDashboard}
                      />
                    </div>
                  }
                  className="PreviousExams__examsList"
                />
              </>
            ) : (
              <ul className="PreviousExams__yearsList">
                {examsYears.map(examYear => (
                  <li key={examYear} className="PreviousExams__yearsListItem">
                    <SectionHeading title={examYear} level={2} />
                    <List
                      items={examsByYear[examYear]}
                      renderItem={examTheme => <ExamThemeCard examTheme={examTheme} />}
                      className="PreviousExams__examsList"
                    />
                  </li>
                ))}
              </ul>
            )}
          </section>
          {articles.length > 0 && (
            <section className="PreviousExams__articlesSection">
              <SectionHeading level={2} title={translations.additionalContentHeading} />
              <List
                items={articles}
                renderItem={article => (
                  <HighlightedThumbnail
                    title={{
                      text: article.title,
                      level: 3,
                    }}
                    description={article.description}
                    image={article.image}
                    category={article.category}
                    href={url.toCourseArticlePage(courseParams, article)}
                    linkGoBackText={translations.goToPreviousExams}
                    type="Article"
                  />
                )}
                className="PreviousExams__articlesList"
                singleItemClassName="PreviousExams__articlesListItem"
              />
            </section>
          )}
        </div>
      </article>
    </PageTitle>
  );
});
