import { assertUnreachable, Brand, Flavor } from "../../typeUtils";
import { TaskViewModel, ActionableTask } from "./TaskView";

export type TaskId = Flavor<string, "taskId">;
export type QuestionId = Flavor<string, "questionId">;
export type AnswerId = Flavor<string, "answerId">;

export type InputValue = Brand<string, "inputValue">;

export function isActionableTask(task: TaskViewModel): task is ActionableTask {
  const { _type } = task;
  switch (_type) {
    case "ChoiceListTask":
    case "ChoiceTask":
    case "GapTask":
    case "InputTask":
    case "MaskTask":
    case "MatchTask":
      return true;
    case "TextTask":
      return false;
    default:
      return assertUnreachable(_type);
  }
}

export type SingleAnswerQuestion = { questionId: QuestionId } & Brand<{ value: AnswerId }, "singleAnswerQuestion">;
export type MultipleAnswerQuestion = { questionId: QuestionId } & Brand<
  { value: AnswerId[] },
  "multipleAnswerQuestion"
>;
export type InputAnswerQuestion = { questionId: QuestionId } & Brand<{ value: InputValue }, "inputAnswerQuestion">;

export type AnswerQuestions = (SingleAnswerQuestion | MultipleAnswerQuestion | InputAnswerQuestion)[];
