import * as React from "react";
import { CourseColorDto } from "./types/courses/dto/CourseColorDto";

interface ActiveCourseContextVariables {
  courseColor: string;
  courseHoverColor: string;
  courseTextColor: string;
}

const activeCourseContextVariables: ActiveCourseContextVariables = {
  courseColor: "--courseColor",
  courseHoverColor: "--courseHoverColor",
  courseTextColor: "--courseTextColor",
};

export const useCourseColor = (context?: CourseColorDto) => {
  React.useEffect(() => {
    if (!context) return;
    document.body.style.setProperty(activeCourseContextVariables.courseColor, context.backgroundColor);
    document.body.style.setProperty(activeCourseContextVariables.courseHoverColor, context.hoverColor);
    document.body.style.setProperty(activeCourseContextVariables.courseTextColor, context.textColor);

    return () => {
      document.body.style.removeProperty(activeCourseContextVariables.courseColor);
      document.body.style.removeProperty(activeCourseContextVariables.courseHoverColor);
      document.body.style.removeProperty(activeCourseContextVariables.courseTextColor);
    };
  }, [context]);
};
