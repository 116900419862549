import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "../../../shared/components/link/Link";
import { SearchDomainThumbnail } from "../../../shared/components/thumbnails/domainThumbnail/SearchDomainThumbnail";
import { Title } from "../../../shared/components/title/Title";
import { assertUnreachable } from "../../../shared/typeUtils";
import { useUrl } from "../../../useRouter";
import { SearchItemViewModel } from "./SearchItemViewModel";

export const SearchItemView = observer(({ model }: { model: SearchItemViewModel }) => {
  const url = useUrl();

  const { thumbnail, searchResultItemDto, paymentSidePanelViewModel, activeCourseStructure } = model;
  const { id, type, title, description, category } = searchResultItemDto;

  const navigationUrl = () => {
    if (
      type === "TextTask" ||
      type === "ChoiceTask" ||
      type === "ChoiceListTask" ||
      type === "TaskGroup" ||
      type === "MaskTask" ||
      type === "Video" ||
      type === "TextBlock" ||
      type === "InputTask" ||
      type === "GapTask" ||
      type === "MatchTask"
    )
      return url.toLessonPage(activeCourseStructure.urlParams, searchResultItemDto);
    if (type === "Article") return url.toArticlePage(searchResultItemDto);
    if (type === "Theme") return url.toThemePage(activeCourseStructure.urlParams, searchResultItemDto.slug);
    if (type === "Test") {
      const testStructure = activeCourseStructure.getTest(id);

      if (!testStructure) {
        throw new Error("Cannot find test in the course");
      }

      return url.toThemeTestPage(
        activeCourseStructure.urlParams,
        testStructure.themeStructure.slug,
        searchResultItemDto.slug
      );
    }

    return assertUnreachable(type);
  };

  return (
    <div className="SearchItem">
      <div className="SearchItem__thumbnailContainer">
        <SearchDomainThumbnail
          thumbnail={thumbnail}
          paymentSidePanelViewModel={paymentSidePanelViewModel}
          courseStructure={activeCourseStructure}
          thumbnailType="rectangle"
          href={navigationUrl()}
        />
      </div>
      <div className="SearchItem__info">
        {!!category && <span className="SearchItem__category">{category}</span>}
        <Title className="SearchItem__title" level={2}>
          {title}
        </Title>
        <p className="SearchItem__description">{description}</p>
      </div>
      {thumbnail.hasAccess && (
        <Link className="SearchItem__linkOverlay" type="clear" to={navigationUrl()} tabIndex={-1}>
          <span className="visuallyHidden">{title}</span>
        </Link>
      )}
    </div>
  );
});
