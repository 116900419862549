import flatten from "lodash/flatten";
import { computed } from "mobx";
import { ThemeStructureDto } from "../../types/courses/dto/ThemeStructureDto";
import { HighLevelStepType, stepTypeDtoToStepType } from "../stepTypeUtils";
import { CourseStructure } from "./CourseStructure";
import { LessonStructure } from "./LessonStructure";
import { StepStructure } from "./StepStructure";
import { TestStructure } from "./TestStructure";

export class ThemeStructure {
  @computed get lessonStructures(): LessonStructure[] {
    return this.dto.lessons.map(lesson => new LessonStructure(this, lesson));
  }

  @computed get articles() {
    return this.dto.articles;
  }

  @computed get testStructures() {
    return this.dto.tests?.map(test => new TestStructure(this, test));
  }

  @computed get relatedContentStepStructures() {
    if (this.dto.relatedContentIds === undefined) {
      return [];
    }

    return this.dto.relatedContentIds
      .map(id => this.courseStructure.allSteps.find(step => step.id === id))
      .filter((stepStructure): stepStructure is StepStructure => !!stepStructure);
  }

  @computed get relatedTrainingGround() {
    const { trainingGroundInfos } = this.courseStructure;

    if (!trainingGroundInfos || trainingGroundInfos.length === 0) {
      return;
    }

    return trainingGroundInfos.find(trainingGroundInfo => trainingGroundInfo.themeIds.includes(this.id));
  }

  @computed get steps() {
    return flatten(this.lessonStructures.map(lessonStructure => lessonStructure.stepStructures));
  }

  @computed get stepsByType() {
    const stepsByType: { [key in HighLevelStepType]: StepStructure[] } = {
      Video: this.steps.filter(step => stepTypeDtoToStepType[step.type] === "Video"),
      Task: this.steps.filter(step => stepTypeDtoToStepType[step.type] === "Task"),
      TextBlock: this.steps.filter(step => stepTypeDtoToStepType[step.type] === "TextBlock"),
      Sound: this.steps.filter(step => stepTypeDtoToStepType[step.type] === "Sound"),
    };

    return stepsByType;
  }

  get id() {
    return this.dto.id;
  }

  get title() {
    return this.dto.title;
  }

  get slug() {
    return this.dto.slug;
  }

  get description() {
    return this.dto.description;
  }

  get image() {
    return this.dto.image;
  }

  get subtitle() {
    return this.dto.subtitle;
  }

  get themeColor() {
    return this.dto.themeColor;
  }

  get orderNumber() {
    return this.dto.orderNumber;
  }

  get isUnderDevelopment() {
    return this.dto.isUnderDevelopment;
  }

  get hasStepsWithAccess() {
    return this.steps.some(step => step.hasAccess);
  }

  constructor(
    public courseStructure: CourseStructure,
    private dto: ThemeStructureDto,
    public source: "course" | "previousExams"
  ) {}

  getTest(testSlug: string) {
    const testStructure = this.testStructures?.find(test => test.slug === testSlug);

    if (!testStructure) {
      throw new TypeError("Invalid test slug");
    }

    return testStructure;
  }
}
