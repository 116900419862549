import classNames from "classnames";
import React from "react";
import { Status } from "../../Status";

interface StatusTagProps {
  status: Status;
  size?: "small" | "default";
  children: React.ReactNode;
}

export const StatusTag: React.FC<StatusTagProps> = props => {
  const { status, size = "default", children } = props;
  return (
    <div
      className={classNames("StatusTag", {
        "StatusTag--error": status === "error",
        "StatusTag--warning": status === "warning",
        "StatusTag--success": status === "success",
        "StatusTag--small": size === "small",
      })}
    >
      {children}
    </div>
  );
};
