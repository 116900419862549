import { computed } from "mobx";
import { LessonStructure } from "../contentStructure/LessonStructure";
import { IProgressStore } from "../stores/ProgressStore";

export class LessonProgress {
  @computed get steps() {
    return this.lessonStructure.stepStructures.map(step => ({
      id: step.id,
      type: step.type,
      isCompleted: this.progressStore.isCompleted(step.id),
    }));
  }

  @computed get actionableStepsCount() {
    return this.steps.length;
  }

  @computed get completedStepsCount() {
    return this.steps.filter(step => step.isCompleted).length;
  }

  @computed get progress() {
    if (this.actionableStepsCount === 0) {
      return 0;
    }
    return this.completedStepsCount / this.actionableStepsCount;
  }

  @computed get isCompleted() {
    return this.actionableStepsCount === this.completedStepsCount;
  }

  @computed get id() {
    return this.lessonStructure.id;
  }

  constructor(private progressStore: IProgressStore, private lessonStructure: LessonStructure) {}

  getIsStepCompletedById(id: string) {
    return this.steps.find(s => s.id === id)?.isCompleted;
  }
}
