import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

export const useSubtitle = (type: ThumbnailTypes) => {
  const translations = useTranslations();

  const subtitles: { [key in ThumbnailTypes]: string } = {
    ChoiceListTask: translations.choiceTask,
    ChoiceTask: translations.choiceTask,
    MaskTask: translations.task,
    TaskGroup: translations.task,
    TextTask: translations.task,
    TextBlock: translations.text,
    Video: translations.video,
    Article: translations.article,
    Theme: translations.theme,
    InputTask: translations.task,
    Test: translations.quiz,
    TrainingGround: translations.trainingGround,
    GapTask: translations.task,
    MatchTask: translations.task,
    PreviousExams: translations.previousExams,
    Author: translations.author,
    Sound: translations.sound,
  };

  return subtitles[type];
};
