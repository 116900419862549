import classNames from "classnames";
import React, { forwardRef } from "react";

type InputVariant = "default" | "clear";

export interface InputProps {
  value: string;
  label: string;
  describedBy?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (v: string) => void;
  onBlur?: () => void;
  onEnter?: () => void;
  htmlType?: "text" | "email";
  variant?: InputVariant;
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    value,
    label,
    describedBy,
    placeholder,
    disabled,
    required,
    onChange,
    onBlur,
    onEnter,
    htmlType = "text",
    variant = "default",
    className,
  } = props;

  return (
    <div
      className={classNames(
        "Input",
        { "Input--default": variant === "default" },
        { "Input--clear": variant === "clear" },
        { "Input--disabled": disabled },
        className
      )}
    >
      <input
        ref={ref}
        className="Input__field"
        value={value}
        aria-label={label}
        aria-describedby={describedBy}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        type={htmlType}
        onChange={e => onChange(e.target.value)}
        onBlur={onBlur}
        onKeyDown={e => {
          if (e.key.toLowerCase() === "enter") {
            if (onEnter) {
              onEnter();
              e.preventDefault();
            }
          }
        }}
      />
      <div className="Input__focusIndicator" />
    </div>
  );
});
