import { observable } from "mobx";
import { ISearchService, IVideoService } from "../../../shared/api/BackendApi";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { Search } from "../../../shared/models/Search";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { UserStore } from "../../../shared/stores/UserStore";
import { SearchContentTypeDto } from "../../../types/search/dto/SearchContentTypeDto";
import { GtmBasePage } from "../../GtmBasePage";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { SearchItemViewModel } from "../searchItem/SearchItemViewModel";

export type FiltersType = "video" | "tasks" | "theme" | "otherContent";

interface SiteSearchFilters {
  phrase: string;
  filterNames: string[];
}

export class SiteSearchViewModel extends GtmBasePage {
  private readonly defaultFilters: SiteSearchFilters = {
    phrase: "",
    filterNames: [],
  };

  @observable public totalCount?: number;

  @observable public search: Search<SearchItemViewModel, SiteSearchFilters>;

  @observable public isLoading = false;

  constructor(
    public activeCourseStructure: CourseStructure,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public progressStore: IProgressStore,
    public searchService: ISearchService,
    public videoService: IVideoService,
    private paymentSidePanelViewModel: PaymentSidePanelViewModel,
    private bookmarkStore: BookmarkStore,
    private userStore: UserStore,
    gtm: IGtmService,
    siteSearchFilter: Partial<SiteSearchFilters>
  ) {
    super(languageService, gtm, errorService);
    this.search = new Search(options => this.load(options), { ...this.defaultFilters, ...siteSearchFilter });
  }

  private async load(options: {
    skip: number;
    take: number;
    filters: SiteSearchFilters;
  }): Promise<ReadonlyArray<SearchItemViewModel>> {
    const { skip, take, filters } = options;

    if (filters.phrase.trim() === "") {
      this.totalCount = undefined;
      return [];
    }

    this.isLoading = true;

    const searchFilters: SearchContentTypeDto[] = [];
    if (filters.filterNames.includes("video")) {
      searchFilters.push(SearchContentTypeDto.Video);
    }

    if (filters.filterNames.includes("tasks")) {
      searchFilters.push(SearchContentTypeDto.TextTask);
      searchFilters.push(SearchContentTypeDto.ChoiceTask);
      searchFilters.push(SearchContentTypeDto.ChoiceListTask);
      searchFilters.push(SearchContentTypeDto.MaskTask);
      searchFilters.push(SearchContentTypeDto.TaskGroup);
      searchFilters.push(SearchContentTypeDto.Test);
    }

    if (filters.filterNames.includes("theme")) {
      searchFilters.push(SearchContentTypeDto.Theme);
    }

    if (filters.filterNames.includes("otherContent")) {
      searchFilters.push(SearchContentTypeDto.Article, SearchContentTypeDto.TextBlock);
    }

    const searchResults = await this.searchService.searchContent({
      skip,
      take,
      courseId: this.activeCourseStructure.id,
      phrase: filters.phrase,
      searchFilters,
    });

    this.totalCount = searchResults.totalCount;
    this.isLoading = false;
    return searchResults.searchResultItems.map(
      searchResult =>
        new SearchItemViewModel(
          this.activeCourseStructure,
          searchResult,
          this.progressStore,
          this.videoService,
          this.paymentSidePanelViewModel,
          this.bookmarkStore,
          this.userStore
        )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected async loadData() {
    this.isLoading = false;
  }
}
