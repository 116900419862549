import { observer } from "mobx-react";
import React from "react";
import { bindTo, property } from "react-mvvm";
import { assertUnreachable } from "../../typeUtils";
import { ChoiceListTask } from "./choiceListTask/ChoiceListTaskView";
import { ChoiceListTaskViewModel } from "./choiceListTask/ChoiceListTaskViewModel";
import { ChoiceTask } from "./choiceTask/ChoiceTaskView";
import { ChoiceTaskViewModel } from "./choiceTask/ChoiceTaskViewModel";
import { GapTask } from "./gapTask/GapTaskView";
import { GapTaskViewModel } from "./gapTask/GapTaskViewModel";
import { InputTask } from "./inputTask/InputTaskView";
import { InputTaskViewModel } from "./inputTask/InputTaskViewModel";
import { MaskTaskViewModel } from "./maskTask/MaskTaskViewModel";
import { MaskTaskContent } from "./maskTask/maskTaskContent/MaskTaskContent";
import { MaskTaskFeedback } from "./maskTask/maskTaskFeedback/MaskTaskFeedback";
import { MaskTaskStage } from "./maskTask/maskTaskStage/MaskTaskStage";
import { MatchTaskViewModel } from "./matchTask/MatchTaskViewModel";
import { MatchTaskResult } from "./matchTask/result/MatchTaskResult";
import { MatchTask } from "./matchTask/task/MatchTask";
import { TextTask, TextTaskModel } from "./textTask/TextTask";

export type TextTaskType = { _type: "TextTask"; model: TextTaskModel };

export type ActionableTask =
  | {
      _type: "ChoiceListTask";
      model: ChoiceListTaskViewModel;
    }
  | { _type: "ChoiceTask"; model: ChoiceTaskViewModel }
  | { _type: "MaskTask"; model: MaskTaskViewModel }
  | { _type: "InputTask"; model: InputTaskViewModel }
  | { _type: "GapTask"; model: GapTaskViewModel }
  | { _type: "MatchTask"; model: MatchTaskViewModel };

export type TaskViewModel = TextTaskType | ActionableTask;

export const TaskView = observer(({ task }: { task: TaskViewModel }) => {
  const { _type } = task;

  switch (_type) {
    case "ChoiceTask":
      return <ChoiceTask model={task.model} />;
    case "ChoiceListTask":
      return <ChoiceListTask model={task.model} />;
    case "MaskTask": {
      const { state, content } = task.model;
      return (
        <>
          <MaskTaskContent content={content} />
          <MaskTaskStage model={task.model} />
          <MaskTaskFeedback state={state} />
        </>
      );
    }
    case "InputTask": {
      const { model } = task;
      return (
        <InputTask
          answersState={model.answersState}
          {...bindTo(property(model, "value"))}
          isSubmitted={model.isSubmitted}
          isDisabled={model.isDisabled}
          content={model.content}
          onEnter={model.submit}
          info={model.info}
          useMathInput={model.useMathInput}
        />
      );
    }
    case "GapTask":
      return <GapTask model={task.model} />;
    case "TextTask":
      return <TextTask model={task.model} />;
    case "MatchTask": {
      const { state, answers, questions, content, isMultipleChoice } = task.model;
      return state._type === "task" ? (
        <MatchTask
          answers={answers}
          questions={questions}
          content={content}
          isMultipleChoice={isMultipleChoice}
          {...bindTo(property(state, "value"))}
        />
      ) : (
        <MatchTaskResult
          answers={answers}
          content={content}
          questions={questions}
          correctAnswers={state.correctAnswers}
          userAnswers={state.value}
          isMultipleChoice={isMultipleChoice}
        />
      );
    }
    default:
      throw assertUnreachable(_type);
  }
});
