import Konva from "konva";
import { observer } from "mobx-react";
import React from "react";
import { Image } from "react-konva";
import { getFilters, RGB } from "../MaskFilters";
import { MaskViewModel } from "../MaskViewModel";
import { useKonvaImageCash } from "../useKonvaImageCash";

const opacity = 0.6;

export interface MaskProps {
  model: MaskViewModel;
  pointerTouchStart: () => void;
  pointerTouchEnd: () => void;
}

export const Mask: React.FC<MaskProps> = observer(props => {
  const {
    model: { state, highlight },
    pointerTouchStart,
    pointerTouchEnd,
  } = props;

  const filters = React.useMemo(getFilters, []);
  const currentFilter = filters[state.type];
  const prevFilter = React.useRef<RGB>();
  const imageRef = React.useRef<Konva.Image>(null);
  useKonvaImageCash(imageRef);

  const maskOpacity = state.type === "notSelected" ? 0 : opacity;

  const filter = currentFilter || prevFilter.current;

  React.useEffect(() => {
    if (currentFilter) {
      prevFilter.current = currentFilter;
    }

    if (imageRef.current) {
      imageRef.current.to({
        opacity: maskOpacity,
      });
    }
  }, [state.type]);

  return state.type !== "loading" && state.type !== "error" ? (
    <Image
      opacity={0}
      ref={imageRef}
      {...filter}
      image={state.image}
      filters={[Konva.Filters.RGB]}
      onMouseDown={pointerTouchStart}
      onTouchStart={pointerTouchStart}
      onClick={pointerTouchEnd}
      onMouseEnter={highlight}
      onMouseLeave={() => state.type === "highlighted" && state.removeHighlight()}
      perfectDrawEnabled={false}
      preventDefault={false}
    />
  ) : null;
});
