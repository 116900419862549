import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { AddBookmarkDialog } from "../../../../pages/lessons/lesson/bookmarkDialog/addBookmarkDialog/AddBookmarkDialog";
import { RemoveBookmarkDialog } from "../../../../pages/lessons/lesson/bookmarkDialog/removeBookmarkDialog/RemoveBookmarkDialog";
import { useUrl } from "../../../../useRouter";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { CommandButton } from "../../button/Button";
import { Link } from "../../link/Link";
import { SvgIcon } from "../../svgIcon/SvgIcon";
import { HintRelatedStepViewModel } from "./HintRelatedStepViewModel";

interface HintRelatedStepProps {
  model: HintRelatedStepViewModel;
}

export const HintRelatedStep = observer((props: HintRelatedStepProps) => {
  const { model } = props;
  const {
    relatedStep,
    activeCourseParams,
    showBookmarkDialog,
    isRelatedStepBookmarked,
    removeBookmarkDialogViewModel,
    addBookmarkDialogViewModel,
  } = model;
  const translations = useTranslations();
  const url = useUrl();
  const bookmarkRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <div className="HintRelatedStep">
      <div>
        <p className="HintRelatedStep__linkDescription">{translations.youCanFindMoreInformationHere}</p>
        <Link target="_blank" to={url.toLessonPage(activeCourseParams, relatedStep)} className="HintRelatedStep__link">
          {relatedStep.title}
        </Link>
      </div>
      <div className="HintRelatedStep__buttonWrapper">
        <CommandButton ref={bookmarkRef} command={showBookmarkDialog} variant="tertiary" size="small">
          <SvgIcon
            className={classNames("HintRelatedStep__icon", {
              "HintRelatedStep__icon--bookmarked": isRelatedStepBookmarked,
            })}
            iconName="bookmark-outlined"
          />
          {isRelatedStepBookmarked ? translations.bookmarked : translations.bookmarkForLater}
        </CommandButton>
      </div>
      <RemoveBookmarkDialog
        getReferenceElementToAttach={() => bookmarkRef.current}
        modal={removeBookmarkDialogViewModel}
      />
      <AddBookmarkDialog getReferenceElementToAttach={() => bookmarkRef.current} modal={addBookmarkDialogViewModel} />
    </div>
  );
});
