import range from "lodash/range";
import { observer } from "mobx-react";
import * as React from "react";
import { PaginatedList } from "react-mvvm";
import { EmptySearch } from "../../../shared/components/emptySearch/EmptySearch";
import { LoadMoreList } from "../../../shared/components/list/LoadMoreList";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { useUrl } from "../../../useRouter";
import { SearchItemSkeletonView } from "../searchItem/SearchItemSkeletonView";
import { SearchItemView } from "../searchItem/SearchItemView";
import { SearchItemViewModel } from "../searchItem/SearchItemViewModel";

interface ISearchListViewProps {
  courseStructure: CourseStructure;
  list: PaginatedList<SearchItemViewModel>;
  totalCount?: number;
  emptySearchMessage: string;
}

export const SearchListView: React.FC<ISearchListViewProps> = observer(props => {
  const { list, totalCount, emptySearchMessage, courseStructure } = props;
  const translations = useTranslations();
  const url = useUrl();
  const isEmpty = totalCount !== undefined && totalCount === 0;

  const renderContent = () => {
    if (isEmpty && !list.isLoading) {
      return (
        <EmptySearch
          emptySearchMessage={emptySearchMessage}
          linkUrl={url.toThemesPage(courseStructure.urlParams)}
          linkText={translations.seeAllThemes}
        />
      );
    }

    if (!!totalCount && !list.isLoadingFirstPage) {
      return (
        <LoadMoreList list={list} totalCount={totalCount} renderItem={result => <SearchItemView model={result} />} />
      );
    }

    if (list.isLoading) {
      return range(list.pageSize).map(item => <SearchItemSkeletonView key={`SearchItemSkeleton__${item}`} />);
    }
    return null;
  };

  return <div className="SearchList">{renderContent()}</div>;
});
