import { observer } from "mobx-react";
import React from "react";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { useGapTaskContext } from "../../../contexts/gapTask/GapTaskContext";
import { ChoiceGapTest } from "../../portableTextContent/PortableTextTypes";
import { shuffledOptions } from "../../portableTextContent/inlineChoiceGapTest/utils";
import { Select } from "../../select/Select";
import { ChoiceListOptionStatus } from "../choiceListTask/choiceListOptionStatus/ChoiceListOptionStatus";
import { GapTest } from "./GapTestsViewModel";

interface GapTaskGapProps {
  id: string;
  choiceGapTest: ChoiceGapTest;
}

export const GapTaskGap: React.FC<GapTaskGapProps> = observer(props => {
  const { id, choiceGapTest } = props;
  const { choices, additionalChoices } = choiceGapTest;

  const {
    gapTaskViewModel: { gapTestsViewModel, shouldDisplayCorrectAnswer, showCorrectAnswer, isCompleted, isDisabled },
  } = useGapTaskContext();
  const currentGapTest = gapTestsViewModel.gapTests.get(id);
  const translations = useTranslations();

  const onChange = (newValue: string) => {
    if (currentGapTest) {
      currentGapTest.setValue(newValue);
    }
  };

  const shouldAnswerBeDisplayed =
    shouldDisplayCorrectAnswer && gapTestsViewModel.gapTests.get(id)?.state === "incorrect";

  React.useEffect(() => {
    gapTestsViewModel.registerGapTest(id, new GapTest(choices, additionalChoices));
  }, []);

  React.useEffect(() => {
    if (isCompleted) {
      showCorrectAnswer();
    }
  }, [isCompleted]);

  return (
    <div className="GapTaskGap">
      <ChoiceListOptionStatus
        status={gapTestsViewModel.gapTests.get(id)?.state || "default"}
        className="GapTaskGap__choiceGapTestOptionStatus"
        variant="full-width"
      >
        <Select<string>
          value={gapTestsViewModel.gapTests.get(id)?.value}
          options={shuffledOptions(choices, additionalChoices)}
          variant="square"
          className="GapTaskGap__choiceGapTestSelect"
          onChange={onChange}
          placeholder={translations.select}
          disabled={isDisabled}
        />
      </ChoiceListOptionStatus>
      {shouldAnswerBeDisplayed && (
        <p className="GapTaskGap__choiceGapTestCorrectAnswer">{choices.map(choice => choice.content.nb).join(", ")}</p>
      )}
    </div>
  );
});
