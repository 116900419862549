import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import { formatProgress } from "../../formatProgress";

export type ProgressBarVariant = "light" | "dark";
export type ProgressBarType = "error" | "warning" | "success" | "info";
type ProgressBarSize = "small" | "medium" | "large";

export interface ProgressBarProps {
  progress?: number;
  variant?: ProgressBarVariant;
  size?: ProgressBarSize;
  type?: ProgressBarType;
  rounded?: boolean;
}

export const ProgressBar = observer((props: ProgressBarProps) => {
  const { progress = 0, variant = "light", size = "medium", type = "warning", rounded = true } = props;
  const { progressValue, isCompleted } = formatProgress(progress);

  const progressBarStyle = useSpring({ to: { width: `${progressValue}%` }, from: { width: "0%" } });

  return (
    <div
      className={classNames("ProgressBar", `ProgressBar--${size}`, `ProgressBar--${variant}`, {
        "ProgressBar--rounded": rounded,
      })}
    >
      <animated.div
        className={classNames("ProgressBar__filler", `ProgressBar__filler--${type}`, {
          "ProgressBar__filler--completed": isCompleted,
        })}
        style={progressBarStyle}
      />
    </div>
  );
});
