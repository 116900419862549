import classNames from "classnames";
import React from "react";
import { List } from "../../../../shared/components/list/List";
import { SectionHeading } from "../../../../shared/components/sectionHeading/SectionHeading";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { RelatedArticleDto } from "../../../../types/courses/dto/RelatedArticleDto";
import { ThemeArticleItem } from "./ThemeArticleItem";

export interface ThemeArticleSectionProps {
  articles: RelatedArticleDto[];
  activeCourseParams: ActiveCourseParams;
  color?: string;
  themeTitle: string;
}

export const ThemeArticleSection: React.FC<ThemeArticleSectionProps> = props => {
  const { articles, activeCourseParams, color, themeTitle } = props;
  const translations = useTranslations();

  const isListLengthOdd = articles.length % 2 !== 0;

  return (
    <div className="ThemeArticleSection">
      <SectionHeading title={translations.readAlso} level={2} />
      <List
        items={articles}
        className={classNames("ThemeArticleSection__articleList", {
          "ThemeArticleSection__articleList--odd": isListLengthOdd,
        })}
        renderItem={(article: RelatedArticleDto) => {
          return (
            <ThemeArticleItem
              key={article.id}
              contentRef={article}
              description={article.description}
              title={article.title}
              image={article.image}
              category={article.category}
              color={color}
              activeCourseParams={activeCourseParams}
              themeTitle={themeTitle}
            />
          );
        }}
      />
    </div>
  );
};
