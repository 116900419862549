import noop from "lodash/noop";
import { computed, observable, onBecomeObserved } from "mobx";
import { Deferred } from "react-mvvm";
import { IVideoService } from "../../../shared/api/BackendApi";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { GtmBasePage } from "../../GtmBasePage";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { BookmarkListStore } from "../BookmarkListStore";
import { BookmarkViewModel } from "./bookmark/BookmarkViewModel";

class Loaded {
  @computed get bookmarks() {
    const bookmarks = this.bookmarkListStore.all;
    return bookmarks.map(
      bookmark =>
        new BookmarkViewModel(
          bookmark,
          this.paymentSidePanelViewModel,
          this.activeCourseStructure,
          this.progressStore,
          this.videoService
        )
    );
  }

  constructor(
    private activeCourseStructure: CourseStructure,
    private bookmarkListStore: BookmarkListStore,
    private progressStore: IProgressStore,
    private videoService: IVideoService,
    private paymentSidePanelViewModel: PaymentSidePanelViewModel
  ) {}
}

export class BookmarkListViewModel extends GtmBasePage {
  @observable state: Deferred<Loaded> = "Loading";

  constructor(
    activeCourseStructure: CourseStructure,
    languageService: ILanguageService,
    errorService: IErrorService,
    bookmarkListStore: BookmarkListStore,
    progressStore: IProgressStore,
    videoService: IVideoService,
    paymentSidePanelViewModel: PaymentSidePanelViewModel,
    gtm: IGtmService
  ) {
    super(languageService, gtm, errorService);

    onBecomeObserved(this, "state", async () => {
      await bookmarkListStore.loadByCourseId(activeCourseStructure.id);

      this.state = new Loaded(
        activeCourseStructure,
        bookmarkListStore,
        progressStore,
        videoService,
        paymentSidePanelViewModel
      );
      this.isLoading = false;
    });
  }

  protected async loadData() {
    noop();
  }
}
