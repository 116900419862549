import React from "react";
import { DetailedProgressBar } from "../../../../../shared/components/detailedProgressBar/DetailedProgressBar";
import { Image, SanityImage } from "../../../../../shared/components/image/Image";
import { Link } from "../../../../../shared/components/link/Link";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../../shared/models/Url";
import { ThemeInfoDto } from "../../../../../types/trainingground/dto/ThemeInfoDto";
import { useUrl } from "../../../../../useRouter";

interface ThemeResultItemProps {
  themeInfo: ThemeInfoDto;
  totalStepsCount: number;
  completedStepsCount: number;
  isThemeAvailable: boolean;
  activeCourseParams: ActiveCourseParams;
}

export const ThemeResultItem = (props: ThemeResultItemProps) => {
  const { themeInfo, totalStepsCount, completedStepsCount, isThemeAvailable, activeCourseParams } = props;
  const { title, image, slug } = themeInfo;
  const translations = useTranslations();
  const url = useUrl();

  return (
    <div className="ThemeResultItem">
      <div className="ThemeResultItem__themeInfo">
        {image && (
          <Image.Container aspectRatio="1/1" className="ThemeResultItem__imageContainer">
            <SanityImage {...image} />
          </Image.Container>
        )}
        <p className="ThemeResultItem__title">{title}</p>
        {isThemeAvailable && (
          <Link to={url.toThemePage(activeCourseParams, slug)} type="clear" className="ThemeResultItem__actionOverlay">
            <span className="visuallyHidden">{title}</span>
          </Link>
        )}
      </div>
      <div className="ThemeResultItem__progressInfo">
        <DetailedProgressBar
          totalStepsCount={totalStepsCount}
          completedStepsCount={completedStepsCount}
          progressInfo={translations.correctAnswers}
          type="warning"
        />
      </div>
    </div>
  );
};
