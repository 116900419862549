import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { ProductViewModel } from "../product/ProductViewModel";
import { ProductParams } from "../product/addProductRouting";
import { AuthorsForProductViewModel } from "./AuthorsForProductViewModel";

export interface AuthorsForProductQueryParams {
  author?: string;
}

export const addAuthorsForProductRouting = (productRoute: IRoute<ProductViewModel, unknown, ProductParams>) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const productAuthorsRouting = productRoute.addRoute<AuthorsForProductViewModel, {}, AuthorsForProductQueryParams>(
    RouteConfiguration.AuthorsForProductPage,
    AuthorsForProductViewModel,
    (productViewModel, _, queryParams) => {
      const authorsForProduct = new AuthorsForProductViewModel(
        productViewModel.languageService,
        productViewModel.errorService,
        productViewModel.navigation,
        productViewModel.gtm,
        productViewModel.userStore,
        productViewModel.product.productParams,
        productViewModel.backendApi,
        productViewModel.product.productAuthorsHero,
        { author: queryParams.author }
      );
      return productViewModel.showChildPage(authorsForProduct);
    }
  );
  return productAuthorsRouting;
};
