import { useSpring } from "react-spring";
import { useDelayedDisplay } from "../../hooks/useDelayedDisplay";

export const usePopup = (isOpen: boolean) => {
  const [isDisplayed, hide] = useDelayedDisplay(isOpen);

  const backgroundStyles = useSpring({
    opacity: isOpen ? 1 : 0,
    onResolve: () => hide(),
  });

  return { isDisplayed, backgroundStyles };
};
