import classNames from "classnames";
import React from "react";
import { Status } from "../../../../Status";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../../typeUtils";

export interface StatusLayerProps {
  variant: "small" | "default";
  status: Status;
}

export const StatusLayer: React.FC<StatusLayerProps> = ({ variant, status }) => {
  const translations = useTranslations();

  const getStatusText = () => {
    switch (status) {
      case "success":
        return translations.finished;
      case "warning":
        return translations.failed;
      case "error":
        return translations.error;
      default:
        return assertUnreachable(status);
    }
  };

  return (
    <div className={classNames("StatusLayer", `StatusLayer--${variant}`)}>
      <div className={classNames("StatusLayer__wrapper", `StatusLayer__wrapper--${status}`)}>
        <span className="StatusLayer__text">{getStatusText()}</span>
      </div>
    </div>
  );
};
