import { action, observable } from "mobx";
import { ITibetClient } from "../../shared/api/TibetClient";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { HttpError } from "../../shared/services/errors/HttpError";
import { UserStore } from "../../shared/stores/UserStore";
import { GtmBasePage } from "../GtmBasePage";

export class BuyRedirectViewModel extends GtmBasePage {
  @observable courseId = "";

  @observable selectedPriceOptionId = "";

  @observable successUrl = "";

  @observable cancelUrl = "";

  @observable failUrl = "";

  @observable isPurchasing = true;

  constructor(
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public gtm: IGtmService,
    private tibetClient: ITibetClient,
    private userStore: UserStore
  ) {
    super(languageService, gtm, errorService);
  }

  @action.bound
  public async setAndInitiateBuying(
    courseId: string,
    selectedPriceOptionId: string,
    successUrl: string,
    cancelUrl: string,
    failUrl: string
  ) {
    this.courseId = courseId;
    this.selectedPriceOptionId = selectedPriceOptionId;
    this.successUrl = successUrl;
    this.cancelUrl = cancelUrl;
    this.failUrl = failUrl;

    if (this.userStore.user?.accessibleCourseIds.some(id => id === courseId)) {
      this.isPurchasing = false;
      window.location.href = cancelUrl;
    }

    try {
      await this.tibetClient.initiateBuying(courseId, selectedPriceOptionId, successUrl, cancelUrl, failUrl);
    } catch (e) {
      if (e instanceof Response) {
        const message = await e.text();
        this.errorService.showErrorSplash(new HttpError(e.status, message));
        throw message;
      }
      throw e;
    }
  }

  protected async loadData() {
    this.isLoading = false;
  }
}
