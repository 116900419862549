import React from "react";
import { BottomSheet } from "../../../../../shared/components/bottomSheet/BottomSheet";
import { Button } from "../../../../../shared/components/button/Button";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { HintPanelContent } from "./hintPanelContent/HintPanelContent";
import { HintToggleImage } from "./hintToggleImage/HintToggleImage";

interface HintPanelProps {
  hint: TaskHintViewModel;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onResolve: () => void;
}

export const HintPanel: React.FC<HintPanelProps> = props => {
  const { hint, isOpen, setIsOpen, onResolve } = props;
  const { content, relatedStep } = hint;
  const translations = useTranslations();

  return (
    <div className="HintPanel">
      <Button onClick={() => setIsOpen(true)} className="HintPanel__toggle" disabled={isOpen} type="clear">
        <span className="visuallyHidden">{translations.seeHintButton}</span>
        <HintToggleImage isHintOpen={isOpen} />
      </Button>
      <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen} onResolve={onResolve} variant="blue">
        <HintPanelContent content={content} relatedStep={relatedStep} />
      </BottomSheet>
    </div>
  );
};
