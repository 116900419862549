import noop from "lodash/noop";
import { UserBookmarkDto } from "../../../types/bookmarks/dto/UserBookmarkDto";
import { IBookmarkService } from "../../api/BackendApi";
import { Bookmark } from "../Bookmark";
import { IBookmarkStore } from "../BookmarkStore";

class BookmarkServiceMock implements IBookmarkService {
  async createUserBookmark() {
    noop();
  }

  async getUserBookmarks() {
    return [] as UserBookmarkDto[];
  }

  async deleteUserBookmark() {
    noop();
  }

  async updateUserBookmark() {
    noop();
  }
}

class BookmarkStoreMock implements IBookmarkStore {
  bookmarkService = new BookmarkServiceMock();

  bookmarks: Bookmark[] = [];

  isBookmarked() {
    return false;
  }

  removeBookmark() {
    noop();
  }

  createBookmark(stepId: string, id?: string | undefined): Bookmark {
    const bookmark = new Bookmark(this, stepId, "activeCourseId", id || "fallbackID");
    return bookmark;
  }

  getBookmark() {
    return undefined;
  }
}

export const bookmarkStoreMock = new BookmarkStoreMock();
