import flatten from "lodash/flatten";
import { computed } from "mobx";
import { CourseStructureDto } from "../../types/courses/dto/CourseStructureDto";
import { ActiveCourseParams } from "../models/Url";
import { Product } from "../stores/Product";
import { PreviousExamsStructure } from "./PreviousExamsStructure";
import { ThemeStructure } from "./ThemeStructure";

export type CourseId = string;
type CourseStructureProduct = Pick<Product, "slug" | "courses" | "title">;

export class CourseStructure {
  previousExamsStructure?: PreviousExamsStructure;

  @computed get themeStructures(): ThemeStructure[] {
    return this.dto.themes.map(theme => new ThemeStructure(this, theme, "course"));
  }

  @computed private get previousExamsThemeStructures() {
    return this.previousExamsStructure?.examThemes || [];
  }

  @computed get buyCourseSection() {
    return this.dto.buyCourseSection;
  }

  @computed get freeContentSection() {
    return this.dto.freeContentSection;
  }

  @computed get priceOptions() {
    return this.dto.priceOptions;
  }

  @computed get isLicensed() {
    return this.dto.isLicensed;
  }

  @computed get allSteps() {
    const allThemeLessons = flatten(this.themeStructures.map(theme => theme.lessonStructures));
    const allPreviousExamsLessons = flatten(
      this.previousExamsThemeStructures.map(examTheme => examTheme.theme.lessonStructures)
    );
    const allLessons = allThemeLessons.concat(allPreviousExamsLessons);
    return flatten(allLessons.map(lesson => lesson.stepStructures));
  }

  @computed get urlParams(): ActiveCourseParams {
    return {
      productSlug: this.product.slug,
      courseSlug: this.slug,
    };
  }

  @computed get coursePreviousExamsThemes() {
    return this.previousExamsStructure?.examThemes.map(examTheme => examTheme.theme);
  }

  get id(): CourseId {
    return this.dto.id;
  }

  get title() {
    return this.dto.title;
  }

  get slug() {
    return this.dto.slug;
  }

  get color() {
    return this.dto.color;
  }

  get description() {
    return this.dto.description;
  }

  get image() {
    return this.dto.image;
  }

  get levels() {
    return this.dto.levels;
  }

  get isInfoBannerDisplayed() {
    return this.dto.isInfoBannerDisplayed;
  }

  get infoBannerTitle() {
    return this.dto.infoBannerTitle;
  }

  get infoBannerDescription() {
    return this.dto.infoBannerDescription;
  }

  get infoBannerLink() {
    return this.dto.infoBannerLink;
  }

  get trainingGroundInfos() {
    return this.dto.trainingGroundInfosDto;
  }

  get hasFullAccess() {
    return this.dto.isCourseFreeOrAccessible;
  }

  get trainingGroundHeaderInfo() {
    return {
      trainingGroundHeaderText: this.dto.trainingGroundHeaderText,
      trainingGroundInfos: this.trainingGroundInfos,
    };
  }

  get feedbackButtonLink() {
    return this.dto.feedbackButtonLink;
  }

  get otherCourses() {
    return this.product.courses.filter(c => c.id !== this.id);
  }

  get productTitle() {
    return this.product.title;
  }

  constructor(private product: CourseStructureProduct, private dto: CourseStructureDto) {
    if (this.dto.previousExamsStructure) {
      this.previousExamsStructure = new PreviousExamsStructure(this, this.dto.previousExamsStructure);
    }
  }

  getLessonByStepSlug(stepSlug: string) {
    const lessonSteps = flatten(this.themeStructures.map(theme => theme.steps));
    const previousExamsLessons = flatten(
      this.previousExamsThemeStructures.map(examTheme => examTheme.theme.lessonStructures)
    );
    const previousExamsSteps = flatten(previousExamsLessons.map(lesson => lesson.stepStructures));

    const allSteps = lessonSteps.concat(previousExamsSteps);
    const lessonStructure = allSteps.find(step => step.slug === stepSlug)?.lessonStructure;

    if (!lessonStructure) {
      throw new TypeError(`Invalid step slug. Can not find lesson for step slug ${stepSlug}`);
    }

    return lessonStructure;
  }

  getTest(testId: string) {
    return flatten(this.themeStructures.map(theme => theme.testStructures)).find(test => test.id === testId);
  }

  tryGetThemeBySlug(themeSlug: string) {
    return (
      this.themeStructures.find(t => t.slug === themeSlug) ||
      this.previousExamsThemeStructures?.find(examTheme => examTheme.theme.slug === themeSlug)?.theme
    );
  }

  getThemeBySlug(themeSlug: string) {
    const theme = this.tryGetThemeBySlug(themeSlug);

    if (!theme) {
      throw new TypeError(`Invalid theme slug. Can not find theme for slug ${themeSlug}`);
    }

    return theme;
  }
}
