import * as React from "react";

interface ScrollLockProps {
  isLocked: boolean;
}

export const ScrollLock = (props: ScrollLockProps) => {
  const { isLocked } = props;

  const scrollPosition = React.useRef<number>();

  const setScrollPosition = (scroll?: number) => {
    scrollPosition.current = scroll;
  };

  const getScrollPosition = () => {
    return scrollPosition.current;
  };

  const unlockScroll = () => {
    const scrollPositionValue = getScrollPosition();
    if (scrollPositionValue !== undefined) {
      const { body } = document;
      body.classList.remove("scrollLocked");
      body.style.top = "";
      window.scrollTo(0, scrollPositionValue);
      setScrollPosition(undefined);
    }
  };

  React.useEffect(() => {
    return () => unlockScroll();
  }, []);

  React.useEffect(() => {
    const { body } = document;
    const scrollY = window.scrollY || window.pageYOffset;

    if (isLocked) {
      body.classList.add("scrollLocked");
      body.style.top = `-${scrollY}px`;
      setScrollPosition(scrollY);
    } else {
      unlockScroll();
    }
  }, [isLocked]);

  return <></>;
};
