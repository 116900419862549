import { observer } from "mobx-react";
import React from "react";
import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { useUrl } from "../../../../useRouter";
import { CourseStructure } from "../../../contentStructure/CourseStructure";
import { ActiveCourseParams } from "../../../models/Url";
import { assertUnreachable } from "../../../typeUtils";
import { Level } from "../../title/Title";
import { HighlightedThumbnail } from "../highlightedThumbnail/HighlightedThumbnail";
import { useOverlayProps } from "../hooks/useOverlayProps";
import { useSubtitle } from "../hooks/useSubtitle";
import { useThumbnailImage } from "../hooks/useThumbnailImage";
import { useVideoData } from "../hooks/useVideoData";
import { HorizontalThumbnail } from "../horizontalThumbnail/HorizontalThumbnail";
import { RectangleThumbnail } from "../rectangleThumbnail/RectangleThumbnail";
import { ThumbnailOverlay } from "../shared/ThumbnailOverlay";
import { IStepThumbnail } from "../stepThumbnail/createStepThumbnailViewModel";
import { TileThumbnail } from "../tileThumbnail/TileThumbnail";

interface DomainThumbnailProps {
  stepThumbnail: IStepThumbnail;
  courseStructure: CourseStructure;
  activeCourseParams?: ActiveCourseParams;
  paymentSidePanelViewModel?: PaymentSidePanelViewModel;
  thumbnailType?: "tile" | "highlighted" | "rectangle" | "horizontal";
  thumbnailTitleLevel?: Level;
}

export const DomainThumbnail: React.FC<DomainThumbnailProps> = observer(props => {
  const {
    stepThumbnail,
    courseStructure,
    activeCourseParams,
    paymentSidePanelViewModel,
    thumbnailType = "tile",
    thumbnailTitleLevel = 3,
  } = props;
  const { title, type, color, contentRef, hasAccess, isCompleted, bookmarkToggleViewModel, description } =
    stepThumbnail;

  const url = useUrl();
  const href = activeCourseParams && url.toLessonPage(activeCourseParams, contentRef);

  const subtitle = useSubtitle(type);
  const videoData = useVideoData(stepThumbnail);
  const image = useThumbnailImage(stepThumbnail);
  const overlayProps = useOverlayProps(
    hasAccess,
    isCompleted,
    type,
    courseStructure,
    bookmarkToggleViewModel,
    paymentSidePanelViewModel
  );

  if (thumbnailType === "tile") {
    return (
      <TileThumbnail
        type={type}
        title={{ text: title, level: thumbnailTitleLevel }}
        color={color}
        image={image}
        href={href}
        locked={!hasAccess}
        timeStamp={videoData?.timeStamp}
        subtitle={subtitle}
        progress={videoData?.progress}
        overlay={overlayProps && <ThumbnailOverlay state={overlayProps} />}
      />
    );
  }

  if (thumbnailType === "highlighted") {
    return (
      <HighlightedThumbnail
        title={{ text: title, level: thumbnailTitleLevel }}
        type={type}
        category={subtitle}
        description={description}
        image={image}
        overlay={overlayProps && <ThumbnailOverlay state={overlayProps} />}
        locked={!hasAccess}
        imageBackgroundColor={color}
        href={href}
      />
    );
  }

  if (thumbnailType === "rectangle") {
    return (
      <RectangleThumbnail
        title={title}
        type={type}
        subtitle={subtitle}
        timeStamp={videoData?.timeStamp}
        progress={videoData?.progress}
        image={image}
        overlay={overlayProps && <ThumbnailOverlay state={overlayProps} />}
        locked={!hasAccess}
        color={color}
        href={href}
      />
    );
  }

  if (thumbnailType === "horizontal") {
    return (
      <HorizontalThumbnail
        title={{ text: title, level: thumbnailTitleLevel }}
        type={type}
        subtitle={subtitle}
        timeStamp={videoData?.timeStamp}
        image={image}
        overlay={overlayProps && <ThumbnailOverlay state={overlayProps} variant="small" />}
        locked={!hasAccess}
        color={color}
        href={href}
      />
    );
  }

  return assertUnreachable(thumbnailType);
});
