import { IRoute } from "react-mvvm";
import { PageNotFoundError } from "../../shared/services/errors/HttpError";
import { ProductViewModel } from "../product/ProductViewModel";
import { ProductParams } from "../product/addProductRouting";
import { CourseViewModel } from "./CourseViewModel";

export type CourseParams = {
  courseSlug: string;
};

export const addCourseRouting = (productRoute: IRoute<ProductViewModel, unknown, ProductParams>) => {
  return productRoute.addRoute<CourseViewModel, CourseParams>(
    "/:courseSlug",
    CourseViewModel,
    async (productViewModel, courseParams) => {
      const _productViewModel = productViewModel;
      _productViewModel.isLoading = true;

      // TODO: Remove this if no longer needed, ie when licenses expire for old courses
      const invalidSlugs = ["riktig-matematikk-1-7", "riktig-matematikk-5-10"];
      const replacementPath = "/glu/algebraisk-/forside";

      if (invalidSlugs.some(slug => courseParams.courseSlug.includes(slug))) {
        window.location.replace(replacementPath);
      }

      await productViewModel.product.trySetActiveCourseStructureBySlug(courseParams.courseSlug);

      if (!productViewModel.product.courseStructure) {
        await productViewModel.handleInvalidRoute();
        throw new PageNotFoundError("Unable to find course");
      }

      const course = new CourseViewModel(
        productViewModel.config,
        productViewModel.languageService,
        productViewModel.product,
        productViewModel.userStore,
        productViewModel.tibetClient,
        productViewModel.navigation,
        productViewModel.backendApi,
        productViewModel.gtm,
        productViewModel.errorService,
        productViewModel.product.courseStructure
      );

      _productViewModel.isLoading = false;
      return productViewModel.showChildPage(course);
    },
    page => ({ courseSlug: page.courseSlug })
  );
};
