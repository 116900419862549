import classNames from "classnames";
import React from "react";
import { Image } from "../../../../../shared/components/image/Image";
import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { TrainingGroundTheme } from "../ThemesSelector";

interface ThemeSelectorImageProps {
  themePickerDefaultImage: ImageDto;
  themes: TrainingGroundTheme[];
  hoveredTheme?: TrainingGroundTheme;
}

export const ThemeSelectorImage = (props: ThemeSelectorImageProps) => {
  const { themePickerDefaultImage, themes, hoveredTheme } = props;

  return (
    <Image.Container className="ThemeSelectorImage" aspectRatio="9/16">
      <picture
        className={classNames("ThemeSelectorImage__basePicture", {
          "ThemeSelectorImage__basePicture--darken": hoveredTheme?.backgroundImage,
        })}
      >
        <source srcSet={`${themePickerDefaultImage.url}?w=1000`} media="(min-width: 768px)" />
        <img alt={themePickerDefaultImage.alt} className="ThemeSelectorImage__image" />
      </picture>
      {themes.map(theme => {
        const { backgroundImage, id } = theme;

        if (!backgroundImage) {
          return null;
        }

        return (
          <picture
            key={id}
            className={classNames("ThemeSelectorImage__themePicture", {
              "ThemeSelectorImage__themePicture--visible": id === hoveredTheme?.id,
            })}
          >
            <source srcSet={`${backgroundImage.url}?w=1000`} media="(min-width: 768px)" />
            <img alt={backgroundImage.alt} className="ThemeSelectorImage__image" />
          </picture>
        );
      })}
    </Image.Container>
  );
};
