import React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";

export const RequiredMark = () => {
  const translations = useTranslations();

  return (
    <>
      <span aria-hidden="true" className="RequiredMark">
        *
      </span>
      <span className="visuallyHidden">{translations.required}</span>
    </>
  );
};
