import { History } from "history";
import { ConstructorOf } from "../ConstructorOf";
import { HomeRouteDefinition } from "./HomeRouteDefinition";

export interface IRoutedPage {
    readonly childPage: IRoutedPage | undefined;
    removeChildPage(): void;
    handleInvalidRoute(): Promise<void>;
}

export interface IRoute<TModel, TParentParams, TParams, TQuery = {}> {
    addRoute<TNewModel extends IRoutedPage, TNewParams extends { [k: string]: string | undefined } = {}, TNewQuery = {}>(path: string, modelConstructor: ConstructorOf<TNewModel>, modelFactory: (currentModel: TModel, params: TNewParams, query: Partial<TNewQuery>) => Promise<TNewModel> | TNewModel, paramsSelector?: (page: TNewModel) => TNewParams, stateAccessor?: { get: (page: TNewModel) => string, set: (page: TNewModel, state: string) => void }): IRoute<TNewModel, TParentParams & TParams, TNewParams, TNewQuery>
    getPath(params: TParentParams & TParams, queryParams?: Partial<TQuery>): string;
}

export interface IHomeRoute<TModel> extends IRoute<TModel, {}, {}> {
    bind(history: History): Promise<{ page: TModel, destroy(): void }>
}

export function route<TModel extends IRoutedPage & {activate: () => void} & {handleLocationChange: ()=>void}, TQuery>(modelFactory: (query: Partial<TQuery>) => Promise<TModel> | TModel): IHomeRoute<TModel> {
    return new HomeRouteDefinition<TModel, TQuery>(modelFactory);
}

export function isEmptyPath(path:string) { return !path || path === '/'; }