import * as React from "react";
import { ReactSVG } from "react-svg";
import arrowsImgUrl from "../../../../../static/images/iconsAnimated/arrows.svg";
import shapesImgUrl from "../../../../../static/images/iconsAnimated/figures.svg";
import { StepThumbnailType } from "../../stepThumbnail/StepThumbnailType";

type StepTypeWithoutVideo = Exclude<StepThumbnailType, "Video">;

interface StepIconProps {
  type: StepTypeWithoutVideo;
}

const getImageUrl = (type: StepTypeWithoutVideo) => {
  switch (type) {
    case "ChoiceTask":
    case "TaskGroup":
    case "ChoiceListTask":
      return arrowsImgUrl;
    default:
      return shapesImgUrl;
  }
};

export const StepIcon: React.FC<StepIconProps> = ({ type }) => {
  const url = getImageUrl(type);

  return <ReactSVG className="StepIcon" src={url} />;
};
