import { IRoute } from "react-mvvm";
import { TaskFactory } from "../../shared/components/tasks/TaskFactory";
import { CourseParams } from "../course/addCourseRouting";
import { ThemeParams } from "../themes/addThemesRoutes";
import { ThemeViewModel } from "../themes/theme/ThemeViewModel";
import { TestApi } from "./TestApi";
import { TestPageViewModel } from "./TestPageViewModel";
import { TestStore } from "./TestStore";

export type TestParams = { testSlug: string };

export const addTestsRoutes = (themeRoute: IRoute<ThemeViewModel, CourseParams, ThemeParams>) => {
  const testsRoutes = themeRoute.addRoute<TestPageViewModel, TestParams>(
    "/:testSlug",
    TestPageViewModel,
    async (themePage, p) => {
      const testStructure = themePage.themeStructure.getTest(p.testSlug);

      const {
        themeStructure: {
          courseStructure: { urlParams },
        },
        bookmarkStore,
        gtm,
        languageService,
        testService,
        errorService,
      } = themePage;

      const testApi = new TestApi(testService);
      const testFactory = new TaskFactory();
      const testStore = new TestStore(urlParams, testApi, testFactory, bookmarkStore);

      const test = new TestPageViewModel(
        testStructure,
        languageService,
        gtm,
        errorService,
        themePage.courseNavigator,
        testStore
      );

      return themePage.showChildPage(test);
    },
    page => ({ testSlug: page.testStructure.slug })
  );

  return testsRoutes;
};
