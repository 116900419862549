import { observer } from "mobx-react";
import React from "react";
import { ISearchThumbnail } from "../../../../pages/search/searchItem/SearchItemViewModel";
import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { CourseStructure } from "../../../contentStructure/CourseStructure";
import { assertUnreachable } from "../../../typeUtils";
import { useOverlayProps } from "../hooks/useOverlayProps";
import { useSubtitle } from "../hooks/useSubtitle";
import { useThumbnailImage } from "../hooks/useThumbnailImage";
import { useVideoData } from "../hooks/useVideoData";
import { RectangleThumbnail } from "../rectangleThumbnail/RectangleThumbnail";
import { ThumbnailOverlay } from "../shared/ThumbnailOverlay";

interface SearchDomainThumbnailProps {
  thumbnail: ISearchThumbnail;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  courseStructure: CourseStructure;
  thumbnailType?: "rectangle";
  href: string;
}

export const SearchDomainThumbnail: React.FC<SearchDomainThumbnailProps> = observer(props => {
  const { thumbnail, paymentSidePanelViewModel, courseStructure, thumbnailType = "rectangle", href } = props;

  const { title, type, color, hasAccess } = thumbnail;

  const getBookmarkToggleViewModel = () => {
    switch (type) {
      case "ChoiceListTask":
      case "ChoiceTask":
      case "MaskTask":
      case "TaskGroup":
      case "TextBlock":
      case "TextTask":
      case "Video":
      case "InputTask":
      case "GapTask":
      case "MatchTask":
      case "Sound":
        return thumbnail.bookmarkToggleViewModel;
      case "Theme":
      case "Article":
      case "Test":
        return undefined;
      default:
        return assertUnreachable(type);
    }
  };

  const getIsCompleted = () => {
    switch (type) {
      case "ChoiceListTask":
      case "ChoiceTask":
      case "MaskTask":
      case "TaskGroup":
      case "TextBlock":
      case "TextTask":
      case "Video":
      case "InputTask":
      case "GapTask":
      case "MatchTask":
      case "Sound":
        return thumbnail.isCompleted;
      case "Theme":
      case "Article":
      case "Test":
        return false;
      default:
        return assertUnreachable(type);
    }
  };

  const subtitle = useSubtitle(type);
  const videoData = useVideoData(thumbnail);
  const image = useThumbnailImage(thumbnail);
  const overlayProps = useOverlayProps(
    hasAccess,
    getIsCompleted(),
    type,
    courseStructure,
    getBookmarkToggleViewModel(),
    paymentSidePanelViewModel
  );

  if (thumbnailType === "rectangle") {
    return (
      <RectangleThumbnail
        title={title}
        type={type}
        subtitle={subtitle}
        timeStamp={videoData?.timeStamp}
        progress={videoData?.progress}
        image={image}
        overlay={overlayProps && <ThumbnailOverlay state={overlayProps} />}
        locked={!hasAccess}
        color={color}
        href={href}
      />
    );
  }

  return assertUnreachable(thumbnailType);
});
