import { MatchTaskValue } from "../matchTask/MatchTaskViewModel";
import { AnswerId, QuestionId } from "../taskUtils";

export type OptionStatus = "default" | "incorrect" | "correct" | "shouldBeChecked";

export const getOptionStatus = (
  answerId: AnswerId,
  questionId: QuestionId,
  correctAnswer: MatchTaskValue,
  userAnswer: MatchTaskValue
): OptionStatus => {
  const correctAnswerId = correctAnswer.find(ca => ca.questionId === questionId);
  const userAnswerId = userAnswer.find(ca => ca.questionId === questionId);

  if (!correctAnswerId || !userAnswerId) {
    return "default";
  }

  const correctAnswerIds =
    correctAnswerId._type === "singleAnswerQuestion" ? [correctAnswerId.value] : correctAnswerId.value;
  const userAnswerIds = userAnswerId._type === "singleAnswerQuestion" ? [userAnswerId.value] : userAnswerId.value;

  if (correctAnswerIds.indexOf(answerId) >= 0 && userAnswerIds.indexOf(answerId) >= 0) {
    return "correct";
  }

  if (correctAnswerIds.indexOf(answerId) >= 0 && userAnswerIds.indexOf(answerId) === -1) {
    return "shouldBeChecked";
  }

  if (correctAnswerIds.indexOf(answerId) === -1 && userAnswerIds.indexOf(answerId) >= 0) {
    return "incorrect";
  }

  return "default";
};
