/* eslint-disable class-methods-use-this */
import { CourseId } from "../contentStructure/CourseStructure";
import { IGtmService } from "../services/GtmService";
import { ILowLevelNavigationService } from "../services/NavigationService";
import { ICourseService } from "./BackendApi";

export interface ITibetClient {
  // prepares Tibet cart and redirects to a URL that is responsible for payments
  initiateBuying: (
    courseId: CourseId,
    selectedPriceOptionId: string,
    successUrl: string,
    cancelUrl: string,
    failUrl: string,
    utmSource?: string,
    utmMedium?: string,
    utmCampaign?: string
  ) => Promise<void>;
}

export class TibetClient implements ITibetClient {
  constructor(
    public courseService: ICourseService,
    private navigation: ILowLevelNavigationService,
    private gtm: IGtmService
  ) {}

  async initiateBuying(
    courseId: CourseId,
    selectedPriceOptionId: string,
    successUrl: string,
    cancelUrl: string,
    failUrl: string,
    utmSource?: string,
    utmMedium?: string,
    utmCampaign?: string
  ) {
    const response = await this.courseService.getCoursePayment({
      courseId,
      paymentOptionId: selectedPriceOptionId,
      successUrl,
      cancelUrl,
      failUrl,
      utmSource,
      utmMedium,
      utmCampaign,
    });

    this.gtm.pushBuyStarted();
    this.navigation.redirectTo(response.redirectUrl);
  }
}
