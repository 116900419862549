import { LessonStepListItemDto } from "../../types/dto/lessonstepdtos/LessonStepListItemDto";
import { LessonStructure } from "./LessonStructure";

export class StepStructure {
  get id() {
    return this.dto.id;
  }

  get title() {
    return this.dto.title;
  }

  get slug() {
    return this.dto.slug;
  }

  get thumbnail() {
    return this.dto.thumbnail;
  }

  get hasAccess() {
    return this.dto.hasAccess;
  }

  get type() {
    return this.dto.type;
  }

  constructor(public lessonStructure: LessonStructure, private dto: LessonStepListItemDto) {}
}
