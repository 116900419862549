import { computed, observable } from "mobx";
import { CreateUserBookmarkRequest } from "../../types/api/bookmarks/CreateUserBookmarkRequest";
import { UserBookmarkDto } from "../../types/bookmarks/dto/UserBookmarkDto";
import { IBookmarkStore } from "./BookmarkStore";

export class Bookmark {
  @observable message?: string;

  constructor(private store: IBookmarkStore, public stepId: string, public courseId: string, public id: string) {}

  updateFromDto(dto: UserBookmarkDto) {
    this.message = dto.message;
  }

  async save() {
    await this.store.bookmarkService.createUserBookmark(this.asRequest);
  }

  async delete() {
    await this.store.bookmarkService.deleteUserBookmark({ id: this.id });
    this.store.removeBookmark(this);
  }

  dispose() {
    // NOP, no subscriptions to clear
  }

  @computed get asRequest(): CreateUserBookmarkRequest {
    return {
      id: this.id,
      message: this.message,
      stepId: this.stepId,
      courseId: this.courseId,
    };
  }
}
