import { ResultDto } from "../types/shared/dto/ResultDto";
import { formatProgress } from "./formatProgress";

export interface Result extends ResultDto {
  score: number;
  hasPassed: boolean;
}

export function getResultForScore(progress: number, passThreshold: number, results: ResultDto[]): Result {
  const { progressValue, unitInterval } = formatProgress(progress);
  const score = unitInterval;
  const result = results.find(r => r.startRange <= progressValue && progressValue <= r.endRange);

  if (result === undefined) {
    throw new Error("Results have to cover every score");
  }

  return {
    score,
    hasPassed: progressValue >= passThreshold,
    ...result,
  };
}
