import { computed, observable } from "mobx";
import { ITestService, IVideoService } from "../../../shared/api/BackendApi";
import {
  ALL_THEME_STATUSES,
  ThemeStatus,
} from "../../../shared/components/card/cardThemeBasic/CardThemeBasicViewModel";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { ThemeStructure } from "../../../shared/contentStructure/ThemeStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { UserStore } from "../../../shared/stores/UserStore";
import { GtmBasePage } from "../../GtmBasePage";
import { CourseNavigator } from "../../shared/courseNavigator/CourseNavigator";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";
import { ThemeListItemViewModel } from "./ThemeListItemViewModel";

export class ThemeListViewModel extends GtmBasePage {
  @observable themeStructures: ThemeStructure[];

  @observable statusFilter: ThemeStatus[] = ALL_THEME_STATUSES;

  private get allThemeViewModels() {
    return this.themeStructures.map(theme => new ThemeListItemViewModel(theme, this.progressStore));
  }

  @computed get allThemeCount() {
    return this.allThemeViewModels.length;
  }

  @computed get inProgressThemeCount() {
    const inProgressThemes = this.allThemeViewModels.filter(theme => theme.status === "inProgress");
    return inProgressThemes.length > 0 ? inProgressThemes.length : undefined;
  }

  @computed get notStartedThemeCount() {
    const notStartedThemes = this.allThemeViewModels.filter(theme => theme.status === "notStarted");
    return notStartedThemes.length > 0 ? notStartedThemes.length : undefined;
  }

  @computed get finishedThemeCount() {
    const finishedThemes = this.allThemeViewModels.filter(theme => theme.status === "finished");
    return finishedThemes.length > 0 ? finishedThemes.length : undefined;
  }

  @computed get underDevelopmentThemeCount() {
    const underDevelopmentThemes = this.allThemeViewModels.filter(theme => theme.status === "underDevelopment");
    return underDevelopmentThemes.length > 0 ? underDevelopmentThemes.length : undefined;
  }

  @computed get themeViewModels() {
    return this.allThemeViewModels.filter(theme => this.statusFilter.includes(theme.status));
  }

  @computed get hasCourseAccess() {
    return this.activeCourseStructure.hasFullAccess;
  }

  constructor(
    public activeCourseStructure: CourseStructure,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public progressStore: IProgressStore,
    public userStore: UserStore,
    public videoService: IVideoService,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    public bookmarkStore: BookmarkStore,
    public navigationService: ILowLevelNavigationService,
    public gtm: IGtmService,
    public testService: ITestService,
    public courseNavigator: CourseNavigator
  ) {
    super(languageService, gtm, errorService);
    this.themeStructures = activeCourseStructure.themeStructures;
  }

  protected async loadData() {
    this.isLoading = false;
  }
}
