import React from "react";

export const useScrollThreshold = (threshold: number) => {
  const startScrollPosition = React.useRef(0);

  const pointerTouchStart = () => {
    startScrollPosition.current = window.scrollY;
  };

  const pointerTouchEnd = (callback: () => void) => {
    if (Math.abs(startScrollPosition.current - window.scrollY) < threshold) {
      callback();
    }
  };

  return { pointerTouchStart, pointerTouchEnd } as const;
};
