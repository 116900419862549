import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { ThumbnailImage } from "../../thumbnails/shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../../thumbnails/types/ThumbnailTypes";
import { Level, Title } from "../../title/Title";

interface HorizontalCardProps {
  title: { text: string; level: Level };
  category?: string;
  type: ThumbnailTypes;
  image?: ImageDto;
  imageBackgroundColor?: string;
  href: string;
}

export const HorizontalCard: React.FC<HorizontalCardProps> = props => {
  const { title, category, type, image, imageBackgroundColor, href } = props;
  return (
    <div className="HorizontalCard">
      <div className="HorizontalCard__imageWrapper" style={{ backgroundColor: imageBackgroundColor }}>
        <ThumbnailImage type={type} image={image} />
      </div>
      <div className="HorizontalCard__infoBox">
        {category && <span className="HorizontalCard__category">{category}</span>}
        <Title className="HorizontalCard__title" level={title.level}>
          {title.text}
        </Title>
      </div>
      <Link to={href} type="clear" className="HorizontalCard__linkOverlay">
        <span className="visuallyHidden">{title.text}</span>
      </Link>
    </div>
  );
};
