import { action, observable } from "mobx";

export class Popup {
  @observable isOpen = false;

  constructor(private onClose?: () => void, private onOpen?: () => void) {}

  @action.bound
  show() {
    this.isOpen = true;
    this.onOpen && this.onOpen();
  }

  @action.bound
  close() {
    this.isOpen = false;
    this.onClose && this.onClose();
  }

  @action.bound
  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.show();
    }
  }

  @action.bound
  setIsOpen(isOpen: boolean) {
    if (isOpen) {
      this.show();
    } else {
      this.close();
    }
  }
}
