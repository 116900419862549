import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { DetailedProgressBar } from "../../../../shared/components/detailedProgressBar/DetailedProgressBar";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { Link } from "../../../../shared/components/link/Link";
import { ProgressBar } from "../../../../shared/components/progressBar/ProgressBar";
import { StatusTag } from "../../../../shared/components/statusTag/StatusTag";
import { Level, Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { useUrl } from "../../../../useRouter";
import { ExamTheme } from "../../PreviousExamsViewModel";

interface ExamThemeCardProps {
  examTheme: ExamTheme;
  level?: Level;
}

export const ExamThemeCard = observer((props: ExamThemeCardProps) => {
  const { examTheme, level = 3 } = props;
  const { theme, thumbnailImage } = examTheme;
  const { themeStructure, status, actionableStepsCount, themeProgress } = theme;
  const { title, themeColor, slug, courseStructure, isUnderDevelopment, steps } = themeStructure;
  const { continueStep, completedStepsCount, progress } = themeProgress;

  const url = useUrl();
  const translations = useTranslations();

  const renderStatusContent = () => {
    const firstLessonStructure = themeStructure.lessonStructures[0];
    const firstStepStructure = firstLessonStructure ? firstLessonStructure.stepStructures[0] : undefined;

    switch (status) {
      case "notStarted":
        if (steps.length === 0) {
          return (
            <div className="ExamThemeCard__status">
              <Link
                to={url.toThemePage(courseStructure.urlParams, slug)}
                tabIndex={-1}
                type="button"
                buttonType="outline"
                buttonVariant="secondary"
                className="ExamThemeCard__actionItem"
              >
                {translations.goToPreviousExam}
              </Link>
            </div>
          );
        }

        return (
          <div className="ExamThemeCard__status">
            {continueStep && (
              <Link
                to={url.toLessonPage(courseStructure.urlParams, continueStep)}
                type="button"
                buttonVariant="secondary"
                className="ExamThemeCard__actionItem"
              >
                {translations.start}
              </Link>
            )}
          </div>
        );
      case "inProgress":
        return (
          <div className="ExamThemeCard__status">
            <div>
              <strong className="ExamThemeCard__progressInfoSteps">{`${completedStepsCount} / ${actionableStepsCount}`}</strong>
              <span className="ExamThemeCard__progressInfoStepsInfo">{translations.stepsProgressInfo}</span>
              <div className="ExamThemeCard__progressBarWrapper">
                <ProgressBar progress={progress} type="success" />
              </div>
            </div>
            {continueStep && continueStep.hasAccess && (
              <Link
                to={url.toLessonPage(courseStructure.urlParams, continueStep)}
                type="button"
                buttonVariant="secondary"
                className="ExamThemeCard__actionItem"
              >
                {translations.progressContinueButton}
              </Link>
            )}
          </div>
        );
      case "finished":
        return (
          <div className="ExamThemeCard__status">
            <DetailedProgressBar
              totalStepsCount={actionableStepsCount}
              completedStepsCount={completedStepsCount}
              progressInfo={translations.step}
              size="small"
            />
            {firstStepStructure && firstStepStructure.hasAccess && (
              <Link
                to={url.toLessonPage(courseStructure.urlParams, firstStepStructure)}
                type="button"
                buttonType="outline"
                buttonVariant="secondary"
                className="ExamThemeCard__actionItem"
              >
                {translations.tryAgain}
              </Link>
            )}
          </div>
        );
      case "underDevelopment":
        return (
          <div className="ExamThemeCard__status ExamThemeCard__status--underDevelopment">
            <StatusTag status="error">{translations.underDevelopment}</StatusTag>
          </div>
        );
      default:
        return assertUnreachable(status);
    }
  };

  return (
    <div className={classNames("ExamThemeCard", { "ExamThemeCard--underDevelopment": isUnderDevelopment })}>
      {isUnderDevelopment === false && (
        <Link className="ExamThemeCard__linkOverlay" type="clear" to={url.toThemePage(courseStructure.urlParams, slug)}>
          <span className="visuallyHidden">{title}</span>
        </Link>
      )}
      <Image.Container aspectRatio="16/9" style={{ backgroundColor: themeColor }}>
        {thumbnailImage && <SanityImage {...thumbnailImage} />}
      </Image.Container>
      <div className="ExamThemeCard__content">
        <Title level={level} className="ExamThemeCard__title">
          {title}
        </Title>
        {status === "notStarted" && (
          <div className="ExamThemeCard__info">{`${actionableStepsCount} ${translations.step}`}</div>
        )}
        <div className="ExamThemeCard__bottomContentWrapper">{renderStatusContent()}</div>
      </div>
    </div>
  );
});
