import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../shared/components/button/Button";
import { SanityImage } from "../../../shared/components/image/Image";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { smoothScrollToSection } from "../../../shared/utils";
import { ProductHeroViewModel } from "./ProductHeroViewModel";
import { BetaNewsletterPopup } from "./betaNewsletterPopup/BetaNewsletterPopup";

export interface ProductHeroProps {
  model: ProductHeroViewModel;
}

export const ProductHero = observer((props: ProductHeroProps) => {
  const { model } = props;
  const { title, image, description, betaNewsletterModel, coursesSectionId } = model;
  const translations = useTranslations();

  return (
    <section className="ProductHero">
      <div className="ProductHero__inner">
        <div className="ProductHero__info">
          <h1 className="ProductHero__title">{title}</h1>
          {description && <p className="ProductHero__description">{description}</p>}
          <div className="ProductHero__action">
            <Button
              variant="secondary"
              type="solid"
              size="xlarge"
              onClick={() => smoothScrollToSection(coursesSectionId)}
            >
              {translations.seeProducts} <SvgIcon className="ProductHero__arrow" iconName="right-arrow-line" />
            </Button>
          </div>
        </div>
        <div className="ProductHero__imageWrapper">
          <SanityImage {...image} />
        </div>
        <BetaNewsletterPopup model={betaNewsletterModel} />
      </div>
    </section>
  );
});
