import React from "react";
import { Button } from "../../../../../shared/components/button/Button";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { ScreenSizeAwareButton } from "../../screenSizeAwareButton/ScreenSizeAwareButton";
import { LessonTaskHint } from "../lessonTaskHint/LessonTaskHint";
import { TaskSolution } from "../taskSolution/TaskSolution";

interface TaskActionsProps {
  taskId: string;
  solution?: PortableTextDto;
  hint?: TaskHintViewModel;
  additionalContent?: React.ReactNode;
}

type StateType = "isSolutionOpen" | "isMobileSolutionOpen" | "isHintOpen";

type ActionsState = {
  [key in StateType]: boolean;
};

export const TaskActions: React.FC<TaskActionsProps> = ({ taskId, solution, hint, additionalContent }) => {
  const translations = useTranslations();

  const [actions, setActions] = React.useState<ActionsState>({
    isSolutionOpen: false,
    isMobileSolutionOpen: false,
    isHintOpen: false,
  });

  const hintButtonId = `TaskHint__${taskId}`;
  const solutionButtonId = `TaskSolution__${taskId}`;

  const handleStateChange = (property: StateType, newValue?: boolean) => {
    setActions(prevState => ({ ...prevState, [property]: newValue ?? !actions[property] }));
  };

  const toggleSolutionVisibility = () => {
    solution && handleStateChange("isSolutionOpen");
    handleStateChange("isMobileSolutionOpen", false);
  };

  const toggleMobileSolutionVisibility = () => {
    solution && handleStateChange("isMobileSolutionOpen");
    handleStateChange("isSolutionOpen", false);
  };

  const hideMobileSolution = () => {
    handleStateChange("isMobileSolutionOpen", false);
  };

  const hideSolution = () => {
    handleStateChange("isSolutionOpen", false);
  };

  const toggleHintVisibility = () => {
    hint && handleStateChange("isHintOpen");
  };

  return (
    <div className="TaskActions">
      <div className="TaskActions__wrapper">
        {hint && (
          <LessonTaskHint id={hintButtonId} hint={hint} isHintOpen={actions.isHintOpen} className="TaskActions__hint" />
        )}
        {solution && (
          <TaskSolution
            id={solutionButtonId}
            solution={solution}
            isSolutionOpen={actions.isSolutionOpen}
            isMobileSolutionOpen={actions.isMobileSolutionOpen}
            hideSolution={hideSolution}
            hideMobileSolution={hideMobileSolution}
          />
        )}
      </div>
      <div className="TaskActions__actionsWrapper">
        {additionalContent}
        {hint && (
          <Button
            onClick={() => toggleHintVisibility()}
            type="outline"
            variant="secondary"
            aria-controls={hintButtonId}
            aria-expanded={actions.isHintOpen}
          >
            {actions.isHintOpen ? translations.hideHintButton : translations.seeHintButton}
          </Button>
        )}
        {solution && (
          <ScreenSizeAwareButton
            toggleValue={toggleSolutionVisibility}
            toggleMobileValue={toggleMobileSolutionVisibility}
            displayText={translations.solution}
            ariaExpanded={actions.isSolutionOpen}
            ariaControls={solutionButtonId}
            disabled={actions.isSolutionOpen || actions.isMobileSolutionOpen}
          />
        )}
      </div>
    </div>
  );
};
