import classNames from "classnames";
import { MathfieldElement, MathfieldOptions } from "mathlive";
import { observer } from "mobx-react";
import * as React from "react";
import { useEffect } from "react";

export type MathfieldProps = {
  options?: Partial<MathfieldOptions>;
  value: string;
  disabled: boolean;
  onChange: (latex: string) => void;
  onBlur?: () => void;
  className: string;
};

export const MathInput = observer((props: MathfieldProps) => {
  const { value, className, disabled, onChange, onBlur } = props;
  const mathfieldRef = React.useRef<MathfieldElement>(new MathfieldElement());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (evt: any) => {
    onChange(evt.target.value);
  };

  const handleOnBlur = () => {
    onBlur && onBlur();
  };

  useEffect(() => {
    const { current } = mathfieldRef;
    current.value = value;
    current.className = classNames("Input", "MathInput", "Input--default", { "Input--disabled": disabled }, className);
  }, [value]);

  useEffect(() => {
    const { current } = mathfieldRef;
    current.value = value;
    current.disabled = disabled;
    current.onchange = handleOnChange;
    current.onblur = handleOnBlur;
    current.className = classNames("Input", "MathInput", "Input--default", { "Input--disabled": disabled }, className);
    current.mathVirtualKeyboardPolicy = "manual";
  }, []);

  return <math-field ref={mathfieldRef} />;
});
