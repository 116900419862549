import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { Link } from "../../../link/Link";
import { SvgIcon } from "../../../svgIcon/SvgIcon";
import { TestState } from "../../domainThumbnail/ThemeTestDomainThumbnail";

interface TestThumbnailInfoProps {
  testState: TestState;
  tasksCount: number;
  href: string;
}

export const TestThumbnailInfo: React.FC<TestThumbnailInfoProps> = props => {
  const { testState, tasksCount, href } = props;

  const translations = useTranslations();

  const renderTasksProgress = () => {
    if (testState._type === "loading") {
      return <Skeleton width={150} height={40} />;
    }

    if (testState._type === "passed" || testState._type === "failed") {
      return (
        <>
          <SvgIcon className="TestThumbnailInfo__icon" iconName="completed" />
          <p className="TestThumbnailInfo__tasksNumber">
            {testState.correctAnswers} / {tasksCount}
          </p>
          <p className="TestThumbnailInfo__tasks">{translations.correctAnswers}</p>
        </>
      );
    }

    return (
      <>
        <p className="TestThumbnailInfo__tasksNumber">{tasksCount}</p>
        <p className="TestThumbnailInfo__tasks">{translations.tasks}</p>
      </>
    );
  };

  return (
    <div className="TestThumbnailInfo">
      <div className="TestThumbnailInfo__tasksProgress">{renderTasksProgress()}</div>
      {testState._type !== "locked" && (
        <Link className="TestThumbnailInfo__link" type="button" buttonVariant="secondary" to={href}>
          {testState._type === "passed" || testState._type === "failed" ? translations.tryAgain : translations.takeTest}
        </Link>
      )}
    </div>
  );
};
