import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { List } from "../../../../shared/components/list/List";
import { SectionHeading } from "../../../../shared/components/sectionHeading/SectionHeading";
import { DomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/DomainThumbnail";
import { IStepThumbnail } from "../../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { Level } from "../../../../shared/components/title/Title";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { PaymentSidePanelViewModel } from "../../paymentDialog/PaymentSidePanelViewModel";

export interface LessonSectionTitle {
  text: string;
  level?: Level;
  type?: "medium" | "large";
}

interface LessonSectionProps {
  title: LessonSectionTitle;
  steps: IStepThumbnail[];
  courseStructure: CourseStructure;
  isHighlighted?: boolean;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
}

export const LessonSection = observer(
  ({ title, steps, courseStructure, isHighlighted = false, paymentSidePanelViewModel }: LessonSectionProps) => {
    const { text, level = 2, type = "large" } = title;
    const { urlParams } = courseStructure;

    return (
      <section className="LessonSection">
        <SectionHeading title={text} level={level} size={type} />
        <List
          className={classNames("LessonSection__stepList", { "LessonSection__stepList--highlighted": isHighlighted })}
          singleItemClassName="LessonSection__singleStepItem"
          items={steps}
          renderItem={step => (
            <DomainThumbnail
              stepThumbnail={step}
              courseStructure={courseStructure}
              activeCourseParams={urlParams}
              paymentSidePanelViewModel={paymentSidePanelViewModel}
              thumbnailType={isHighlighted ? "highlighted" : "tile"}
              thumbnailTitleLevel={(level + 1) as Level}
            />
          )}
        />
      </section>
    );
  }
);
