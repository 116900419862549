import { computed, observable, onBecomeObserved } from "mobx";
import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { TestResultDto } from "../../../../types/test/dto/TestResultDto";
import { ITestService } from "../../../api/BackendApi";
import { TestStructure } from "../../../contentStructure/TestStructure";
import { TestState } from "./ThemeTestDomainThumbnail";

type TestResult = { _type: "loading" } | { _type: "loaded"; model?: TestResultDto };

export class ThemeTestDomainThumbnailViewModel {
  @observable testResult: TestResult = { _type: "loading" };

  get id() {
    return this.testStructure.id;
  }

  @computed get testState(): TestState {
    if (this.testResult._type === "loading") {
      return this.testResult;
    }

    if (this.testResult.model) {
      return this.testResult.model.isPassed
        ? { _type: "passed", correctAnswers: this.testResult.model.correctAnswers }
        : { _type: "failed", correctAnswers: this.testResult.model.correctAnswers };
    }

    if (!this.testStructure.hasAccess) {
      return {
        _type: "locked",
      };
    }

    return { _type: "default" };
  }

  constructor(
    public testStructure: TestStructure,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    private testService: ITestService
  ) {
    onBecomeObserved(this, "testResult", async () => {
      this.testResult = { _type: "loading" };
      const result = this.testStructure.hasAccess
        ? await this.testService.getTestResult({
            courseId: this.testStructure.themeStructure.courseStructure.id,
            themeId: this.testStructure.themeStructure.id,
            testId: this.testStructure.id,
          })
        : null;

      if (result) {
        this.testResult = { _type: "loaded", model: result };
      } else {
        this.testResult = { _type: "loaded" };
      }
    });
  }
}
