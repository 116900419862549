import { observer } from "mobx-react";
import React from "react";
import { Command } from "react-mvvm";
import { Button, CommandButton } from "../../../../../../shared/components/button/Button";
import { SvgIcon } from "../../../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../../../shared/components/title/Title";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { smoothScrollToSection } from "../../../../../../shared/utils";
import { LessonTaskHint } from "../../../../../lessons/lesson/shared/lessonTaskHint/LessonTaskHint";
import { HintPanel } from "../../hintPanel/HintPanel";
import { TrainingTask } from "../TrainingTask";
import { TrainingTaskView } from "../TrainingTaskView";

interface TrainingItemContainerProps {
  trainingTask: TrainingTask;
  endTrainingWithoutConfirmation: Command;
  gotoPreviousTask: Command;
  gotoNextTask: Command;
  isNextTrainingSetLast: boolean;
  isLastTask: boolean;
}

export const TrainingItemContainer = observer((props: TrainingItemContainerProps) => {
  const {
    trainingTask,
    endTrainingWithoutConfirmation,
    gotoPreviousTask,
    gotoNextTask,
    isNextTrainingSetLast,
    isLastTask,
  } = props;
  const { task, hint } = trainingTask;
  const {
    model: { submit, title, description, isAnswered, answersState, id },
  } = task;
  const translations = useTranslations();
  const taskContainerRef = React.useRef<HTMLDivElement>(null);
  const hintContainerId = "HintContainer";

  const { title: themeTitle } = trainingTask.theme;

  const [isHintVisible, setIsHintVisible] = React.useState(false);
  const [isMobileHintVisible, setIsMobileHintVisible] = React.useState(false);

  const resetTaskFocus = () => {
    if (taskContainerRef.current) {
      taskContainerRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (taskContainerRef.current) {
      taskContainerRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    setIsHintVisible(false);
    setIsMobileHintVisible(false);
  }, [id, answersState]);

  React.useEffect(() => {
    resetTaskFocus();
  }, [id]);

  const TrainingItemActions = () => {
    // SUBMITTED TASK
    if (answersState !== "default") {
      return isNextTrainingSetLast && isLastTask ? (
        <CommandButton command={endTrainingWithoutConfirmation} className="TrainingItemContainer__primaryAction">
          {translations.finishPractising}
        </CommandButton>
      ) : (
        <CommandButton command={gotoNextTask} className="TrainingItemContainer__primaryAction">
          {translations.next}
        </CommandButton>
      );
    }

    // NOT SUBMITTED TASK
    return (
      <>
        <Button onClick={submit} disabled={!isAnswered} className="TrainingItemContainer__primaryAction">
          {translations.checkAnswer}
        </Button>
      </>
    );
  };

  return (
    <div className="TrainingItemContainer">
      <p className="TrainingItemContainer__themeTitle">{themeTitle}</p>
      <Title level={2} className="TrainingItemContainer__taskTitle">
        {title}
      </Title>
      {description && <p className="TrainingItemContainer__taskDescription">{description}</p>}
      <div className="TrainingItemContainer__task">
        <div ref={taskContainerRef} className="TrainingItemContainer__taskContent">
          <div className="TrainingItemContainer__taskContainer">
            <TrainingTaskView trainingTask={trainingTask} key={id} />
            <div className="TrainingItemContainer__hintContainer" id={hintContainerId}>
              {hint && (
                <LessonTaskHint
                  hint={hint}
                  isHintOpen={isHintVisible}
                  id={id}
                  onAnimationResolve={() => smoothScrollToSection(hintContainerId)}
                  className="TrainingItemContainer__hint"
                />
              )}
            </div>
          </div>
          {hint && (
            <HintPanel
              hint={hint}
              isOpen={isMobileHintVisible}
              setIsOpen={setIsMobileHintVisible}
              onResolve={resetTaskFocus}
            />
          )}
        </div>
        <div className="TrainingItemContainer__taskFooter">
          <CommandButton
            command={gotoPreviousTask}
            type="circle"
            variant="secondary"
            className="TrainingItemContainer__mobileNavigationItem"
          >
            <span className="visuallyHidden">{translations.previous}</span>
            <SvgIcon iconName="left-arrow-line" />
          </CommandButton>
          <TrainingItemActions />
          <CommandButton
            command={gotoNextTask}
            type="circle"
            variant="secondary"
            className="TrainingItemContainer__mobileNavigationItem"
          >
            <span className="visuallyHidden">{translations.next}</span>
            <SvgIcon iconName="right-arrow-line" />
          </CommandButton>
        </div>
      </div>
    </div>
  );
});
