import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { CardThemeBasic } from "../../../shared/components/card/cardThemeBasic/CardThemeBasic";
import { List } from "../../../shared/components/list/List";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { SectionHeading } from "../../../shared/components/sectionHeading/SectionHeading";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { ThemeListFilter } from "./ThemeListFilter";
import { ThemeListViewModel } from "./ThemeListViewModel";

export const ThemeListView = observer(({ model }: { model: ThemeListViewModel }) => {
  const translations = useTranslations();

  if (model.childPage) {
    return <ContentView content={model.childPage} />;
  }

  const { isLoading, themeViewModels } = model;

  return (
    <PageTitle title={translations.allThemes}>
      <article className="ThemeList">
        <h1 className="ThemeList__heading">{translations.allThemes}</h1>
        <ActivityIndicator isLoading={isLoading}>
          <SectionHeading
            className="ThemeList__listHeading"
            title={translations.themeOverview}
            level={2}
            extra={
              <div className="ThemeList__filter">
                <ThemeListFilter model={model} />
              </div>
            }
          />
          <div className="ThemeList__filter--mobile">
            <ThemeListFilter model={model} />
          </div>
          <List
            className="ThemeList__list"
            singleItemClassName="ThemeList__listItem"
            items={themeViewModels}
            renderItem={themeVm => (
              <CardThemeBasic
                themeStructure={themeVm.themeStructure}
                totalStepsCount={themeVm.actionableStepsCount}
                completedStepsCount={themeVm.completedStepsCount}
                status={themeVm.status}
                firstUnfinishedStep={themeVm.firstUnfinishedStep}
                tags={themeVm.tags}
              />
            )}
          />
        </ActivityIndicator>
      </article>
    </PageTitle>
  );
});
