import { action, computed, observable } from "mobx";
import { Command } from "react-mvvm";
import { StaticLink } from "../../layout/header/HeaderViewModel";
import { Popup } from "../../shared/components/popup/Popup";
import { TestStructure } from "../../shared/contentStructure/TestStructure";
import { Url } from "../../shared/models/Url";
import { Result } from "../../shared/resultUtils";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { delay, smoothScrollToSection } from "../../shared/utils";
import { GtmBasePage } from "../GtmBasePage";
import { CourseNavigator } from "../shared/courseNavigator/CourseNavigator";
import { TestStore } from "./TestStore";

type TestState = "test" | "testResult";

export class TestPageViewModel extends GtmBasePage {
  @observable state: TestState = "test";

  @observable testResult?: Result;

  @observable confirmExitPanel = new Popup();

  @observable isReviewOpen = false;

  @observable submitCount = 0;

  @computed get title() {
    return this.testStructure.title;
  }

  @computed get subtitle() {
    return this.testStructure.subtitle || this.testStructure.themeStructure.title;
  }

  @computed get color() {
    return this.testStructure.color;
  }

  @computed get test() {
    return this.testStore.test;
  }

  @computed get tasksCount() {
    return this.testStructure.tasksCount;
  }

  @computed get completedTasksCount() {
    return this.test?.tasks?.filter(task => task.model.isAnswered).length ?? 0;
  }

  @computed get isCompleted() {
    return this.tasksCount === this.completedTasksCount;
  }

  @computed get exitLink() {
    const { themeStructure } = this.testStructure;
    return Url.toThemePage(themeStructure.courseStructure.urlParams, themeStructure.slug);
  }

  @computed get nextElementLink(): StaticLink {
    const { themeStructure } = this.testStructure;
    const { courseStructure } = themeStructure;
    const nextTheme = this.courseNavigator.getNextTheme(themeStructure);

    if (!nextTheme) {
      return {
        translation: "goToDashboard",
        href: Url.toDashboardPage(courseStructure.urlParams),
        id: "goToDashboard",
      };
    }

    return {
      translation: "goToNextTheme",
      href: Url.toThemePage(courseStructure.urlParams, nextTheme.slug),
      id: "goToNextTheme",
    };
  }

  submit: Command;

  reset: Command;

  constructor(
    public testStructure: TestStructure,
    languageService: ILanguageService,
    gtm: IGtmService,
    errorService: IErrorService,
    public courseNavigator: CourseNavigator,
    private testStore: TestStore
  ) {
    super(languageService, gtm, errorService);
    this.submit = new Command(async () => {
      this.submitCount += 1;

      if (this.isCompleted) {
        this.isLoading = true;

        const testResult = await this.test?.submit(this.testStructure.themeStructure.id);

        this.testResult = testResult;
        this.state = "testResult";

        this.isLoading = false;
      }

      const unansweredTask = this.test?.tasks.find(task => !task.model.isAnswered);

      if (unansweredTask) {
        smoothScrollToSection(unansweredTask.id);
      }
    });

    this.reset = new Command(async () => {
      this.isLoading = true;

      await delay(500); // to improve UX

      this.test?.reset();
      this.isReviewOpen = false;
      this.testResult = undefined;
      this.state = "test";
      this.submitCount = 0;

      this.isLoading = false;
    });
  }

  @action.bound
  toggleReview() {
    this.isReviewOpen = !this.isReviewOpen;
  }

  protected async loadData() {
    const { themeStructure } = this.testStructure;
    const { courseStructure } = themeStructure;
    await this.testStore.loadTest(this.testStructure.id, courseStructure.id);
  }
}
