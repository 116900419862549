import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../../shared/components/button/Button";
import { Image } from "../../../shared/components/image/Image";
import { PortableTextContent } from "../../../shared/components/portableTextContent/PortableTextContent";
import { ThumbnailImage } from "../../../shared/components/thumbnails/shared/thumbnailImage/ThumbnailImage";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import anonymousDog from "../../../static/images/anonymous-dog.png";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { PortableTextDto } from "../../../types/shared/dto/PortableTextDto";

export interface AuthorThumbnailProps {
  name: string;
  description: PortableTextDto;
  role: string;
  buttonOnClickAction: () => void;
  image?: ImageDto;
}

export const AuthorThumbnail: React.FC<AuthorThumbnailProps> = observer(props => {
  const { name, role, description, image, buttonOnClickAction } = props;
  const t = useTranslations();

  return (
    <div className="AuthorThumbnail">
      <Button type="clear" className="AuthorThumbnail__actionOverlay" onClick={buttonOnClickAction} tabIndex={-1}>
        <span className="visuallyHidden">{name}</span>
      </Button>
      <Image.Container aspectRatio="3/2">
        <ThumbnailImage image={image} type="Author" defaultImageSrc={anonymousDog} />
      </Image.Container>
      <div className="AuthorThumbnail__details">
        <div className="AuthorThumbnail__detailsText">
          <Title level={2} className="AuthorThumbnail__title">
            {name}
          </Title>
          <span className="AuthorThumbnail__role">{role}</span>
          <PortableTextContent content={description} className="AuthorThumbnail__description" />
        </div>
        <Button size="medium" className="AuthorThumbnail__learMoreButton" onClick={buttonOnClickAction}>
          {t.learnMore}
        </Button>
      </div>
    </div>
  );
});
