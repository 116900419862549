import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { ProductViewModel } from "../product/ProductViewModel";
import { ProductParams } from "../product/addProductRouting";
import { ArticleListViewModel } from "./articleList/ArticleListViewModel";

export type ProductArticleListQueryParams = {
  category?: string;
};

export const addProductArticleListRouting = (productRoute: IRoute<ProductViewModel, unknown, ProductParams>) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const productArticleListRouting = productRoute.addRoute<ArticleListViewModel, {}, ProductArticleListQueryParams>(
    RouteConfiguration.Articles,
    ArticleListViewModel,
    (productViewModel, _, queryParams) => {
      const articleList = new ArticleListViewModel(
        { categorySlug: queryParams.category },
        productViewModel.languageService,
        productViewModel.errorService,
        productViewModel.navigation,
        productViewModel.gtm,
        // properties needed for the nested ProductArticles
        productViewModel.handleLogin,
        productViewModel.userStore,
        productViewModel.product,
        productViewModel.backendApi,
        productViewModel.backendApi
      );

      return productViewModel.showChildPage(articleList);
    }
  );

  return productArticleListRouting;
};
