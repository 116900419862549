import { action, computed, observable } from "mobx";
import { isDefined } from "../../typeUtils";
import { SelectListItem } from "./Select";

export class SelectViewModel<T> {
  @observable private _items: SelectListItem<T>[];

  @observable private _selectedItems: T[];

  get placeholder() {
    if (this._items.length === 0) {
      return;
    }

    return this._items[0].label;
  }

  // Source: https://stackoverflow.com/questions/35929369/mobx-observable-array-does-not-display-correctly
  @computed get value() {
    return this._selectedItems.slice();
  }

  @computed get valueWithLabels() {
    return this.value
      .map(selectedItem => this.availableItems.find(item => item.value === selectedItem))
      .filter(isDefined);
  }

  @computed get availableItems() {
    return this._items.slice();
  }

  @computed get hasValue() {
    return this.value.length > 0;
  }

  @computed get areAnyItemsInSelect() {
    return this.availableItems.length > 0;
  }

  @action.bound
  setValue(newSelectedItems: T[]) {
    this._selectedItems = newSelectedItems;
    this.onChange && this.onChange(newSelectedItems);
  }

  @action.bound
  resetValue() {
    this.setValue([]);
  }

  @action.bound
  deselectItem(item: T) {
    this.setValue(this._selectedItems.filter(selectedItem => selectedItem !== item));
  }

  constructor(
    private initialItems: SelectListItem<T>[],
    private initialSelectedItems?: T[],
    private onChange?: (value: T[]) => void
  ) {
    this._items = this.initialItems;
    this._selectedItems = this.initialSelectedItems || [];
  }
}
