import { computed, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { AuthorsSliderViewModel } from "../../shared/components/authorsSlider/AuthorsSliderViewModel";
import { isDefined } from "../../shared/typeUtils";
import { AboutUsDto } from "../../types/aboutus/dto/AboutUsDto";
import { App } from "../App";
import { GtmBasePage } from "../GtmBasePage";

export class AboutUsViewModel extends GtmBasePage {
  @observable data?: AboutUsDto;

  @computed get authorsSliderViewModel() {
    return new AuthorsSliderViewModel(this.backendApi, this.data?.authorsOfProductList);
  }

  @computed get products() {
    const productIdsInOrder = this.app.config.products.productIds;
    const { products } = this.app.productStore;

    return productIdsInOrder.map(productId => products.find(product => product.id === productId)).filter(isDefined);
  }

  constructor(private app: App, private backendApi: BackendApi) {
    super(app.languageService, app.gtm, app);
  }

  protected async loadData() {
    this.data = await this.app.backendApi.getAboutUs({});
    this.isLoading = false;
  }
}
