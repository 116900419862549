import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "../../../shared/components/link/Link";
import { ArticleReferenceDto } from "../../../types/config/dto/ArticleReferenceDto";
import { LinkTypeDto } from "../../../types/shared/dto/LinkTypeDto";
import { useUrl } from "../../../useRouter";

export interface FooterListItemProps {
  model: {
    id: string;
    title?: string;
    type: LinkTypeDto;
    article?: ArticleReferenceDto;
    externalUrl?: string;
    internalUrl?: string;
  };
}

export const FooterListItem = observer((props: FooterListItemProps) => {
  const url = useUrl();
  const { title, type, article, externalUrl, internalUrl } = props.model;

  const getUrl = () => {
    switch (type) {
      case LinkTypeDto.ExternalLink:
        return externalUrl;
      case LinkTypeDto.InternalLink:
        return internalUrl;
      case LinkTypeDto.ArticleLink:
        return article && url.toArticlePage(article);
      default:
        throw new Error(`${type} is unknown type`);
    }
  };

  const navigateTo = getUrl();

  return navigateTo ? (
    <Link type="clear" to={navigateTo} className="FooterListItem">
      {title}
    </Link>
  ) : null;
});
