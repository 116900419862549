import React from "react";
import { SectionHeading } from "../../../../shared/components/sectionHeading/SectionHeading";
import { ThemeTestDomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/ThemeTestDomainThumbnail";
import { TrainingGroundDomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/trainingGroundDomainThumbnail/TrainingGroundDomainThumbnail";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../../shared/textUtils";
import { useUrl } from "../../../../useRouter";
import { ThemeTrainingSectionViewModel } from "./ThemeTrainingSectionViewModel";

interface ThemeTrainingSectionProps {
  model: ThemeTrainingSectionViewModel;
}

export const ThemeTrainingSection: React.FC<ThemeTrainingSectionProps> = props => {
  const { model } = props;
  const { tests, relatedTrainingGround, activeCourseParams, themeSlug, themeTitle } = model;
  const translations = useTranslations();
  const url = useUrl();

  return (
    <div className="ThemeTrainingSection">
      <SectionHeading title={translations.testYourSkills} level={2} />
      <ul className="ThemeTrainingSection__items">
        {tests.map(test => (
          <li key={test.id} className="ThemeTrainingSection__item">
            <ThemeTestDomainThumbnail model={test} />
          </li>
        ))}
        {relatedTrainingGround && (
          <li className="ThemeTrainingSection__item">
            <TrainingGroundDomainThumbnail
              model={{
                ...relatedTrainingGround,
                title: FormattedText(translations.themeTrainingTitle, [themeTitle]) || relatedTrainingGround.title,
              }}
              thumbnailType="highlighted"
              href={url.toTrainingGroundPage(activeCourseParams, relatedTrainingGround.slug, themeSlug)}
            />
          </li>
        )}
      </ul>
    </div>
  );
};
