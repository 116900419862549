import isNil from "lodash/isNil";

export const unsafe_cast = {
  ElementToHTMLElement: (node: Element) => node as HTMLElement,
};

export const assertUnreachable = (neverValue?: never) => {
  throw new TypeError(`Didn't expect to get here (exhaustiveness-check), Unexpected value: ${neverValue}`);
};

export const isDefined = <T>(o: T | undefined | null): o is T => {
  return !isNil(o);
};

type Flavoring<FlavorT> = { _type?: FlavorT };
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>;

type Branding<BrandT> = { _type: BrandT };
export type Brand<T, BrandT> = T & Branding<BrandT>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type Nominal<T, NameT> = T extends object ? Flavor<T, NameT> : Brand<T, NameT>;
