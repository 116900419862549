import React from "react";
import { RequiredMark } from "../requiredMark/RequiredMark";

interface LabelProps {
  required?: boolean;
  children: React.ReactNode;
}

export const Label: React.FC<LabelProps> = props => {
  const { children, required = false } = props;
  return (
    <label className="Label">
      {required && <RequiredMark />}
      {children}
    </label>
  );
};
