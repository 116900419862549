import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { SanityImage } from "../../image/Image";

export interface SectionIconBlockProps {
  title: string;
  description: string;
  image: ImageDto;
}

export const SectionIconBlock = (props: SectionIconBlockProps) => {
  const { description, image, title } = props;

  return (
    <div className="SectionIconBlock">
      <div className="SectionIconBlock__icon">
        <SanityImage {...image} />
      </div>
      <div className="SectionIconBlock__info">
        <h2 className="SectionIconBlock__title">{title}</h2>
        <p className="SectionIconBlock__description">{description}</p>
      </div>
    </div>
  );
};
