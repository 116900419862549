import { observe, reaction } from "mobx";
import { Task } from "../../../../shared/api/StepService";
import { TaskHintViewModel } from "../../../../shared/components/taskHint/TaskHintViewModel";
import { TaskFactory } from "../../../../shared/components/tasks/TaskFactory";
import { TaskViewModel } from "../../../../shared/components/tasks/TaskView";
import { isActionableTask } from "../../../../shared/components/tasks/taskUtils";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { Step, StepBase } from "./Step";

export class LessonTask extends Step {
  readonly task: TaskViewModel;

  readonly _type = "Task";

  readonly hint?: TaskHintViewModel;

  readonly solution?: PortableTextDto;

  constructor(
    stepBase: StepBase,
    activeCourseParams: ActiveCourseParams,
    taskFactory: TaskFactory,
    task: Task,
    progressStore: IProgressStore,
    bookmarkStore: IBookmarkStore
  ) {
    super(stepBase, progressStore, bookmarkStore);

    this.task = taskFactory.createTask(task);

    if (isActionableTask(this.task)) {
      const { model } = this.task;
      observe(model, "answersState", ({ newValue }) => {
        if (newValue === "correct") {
          this.markStepAsCompleted();
        }
      });

      reaction(
        () => this.isCompleted,
        async isCompleted => {
          if (isCompleted) {
            await model.showCorrectAnswer();
            return;
          }

          await model.reset();
        },
        { fireImmediately: true }
      );
    }

    this.solution = task.solution;
    this.hint =
      task?.hint &&
      new TaskHintViewModel(activeCourseParams, bookmarkStore, task.hint.content, task.hint.relatedStepInfo);
  }
}
