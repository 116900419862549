import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import { ScrollLock } from "../../../pages/shared/scrollLock/ScrollLock";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { usePopup } from "../popup/usePopup";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: "small" | "default";
  className?: string;
}

export const Modal: React.FC<ModalProps> = props => {
  const { children, isOpen, onClose, size = "default", className } = props;
  const { isDisplayed, backgroundStyles } = usePopup(isOpen);
  const translations = useTranslations();

  const modalInnerStyles = useSpring({
    from: { top: "0%", opacity: 0 },
    top: isOpen ? "0%" : "100%",
    opacity: isOpen ? 1 : 0,
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <FocusTrap active={isDisplayed} focusTrapOptions={{ allowOutsideClick: true }}>
      <div className={classNames("Modal", className)}>
        <ScrollLock isLocked={isDisplayed} />
        <animated.div style={backgroundStyles}>
          <Button onClick={onClose} type="clear" className="Modal__closeBackgroundButton">
            <span className="visuallyHidden">{translations.close}</span>
          </Button>
        </animated.div>
        <div className="Modal__outer">
          <div className={classNames("Modal__wrapper", { "Modal__wrapper--small": size === "small" })}>
            <animated.div style={modalInnerStyles}>
              <div className="Modal__panel">
                <div className="Modal__inner">{children}</div>
              </div>
              <Button onClick={onClose} type="circle" size="large" className="Modal__closeButton">
                <SvgIcon iconName="close" alt={translations.close} />
                <span className="visuallyHidden">{translations.close}</span>
              </Button>
            </animated.div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
