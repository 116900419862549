import { observer } from "mobx-react";
import React from "react";
import { bindTo, property } from "react-mvvm";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { Button } from "../../../shared/components/button/Button";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import { CheckboxGroup } from "../../../shared/components/checkbox/CheckboxGroup";
import { DebounceInput } from "../../../shared/components/input/DebounceInput";
import { List } from "../../../shared/components/list/List";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../shared/textUtils";
import { SearchListView } from "../searchList/SearchListView";
import { FiltersType, SiteSearchViewModel } from "./SiteSearchViewModel";

interface FiltersWithTranslation {
  id: FiltersType;
  translation: string;
}

export const SiteSearchView = observer(({ model }: { model: SiteSearchViewModel }) => {
  const { search, totalCount, activeCourseStructure, isLoading } = model;
  const { filters, paginatedList } = search;
  const translations = useTranslations();
  const debounceTimeMs = 500;
  const hasSearched = totalCount !== undefined;

  const filtersWithTranslation: FiltersWithTranslation[] = [
    {
      id: "video",
      translation: translations.video,
    },
    {
      id: "tasks",
      translation: translations.tasks,
    },
    {
      id: "theme",
      translation: translations.theme,
    },
    {
      id: "otherContent",
      translation: translations.otherContent,
    },
  ];

  return (
    <PageTitle title={translations.searchResults}>
      <article className="SiteSearch Search">
        <div className="SiteSearch__inner Search__inner">
          <h1 className="SiteSearch__title Search__title">{translations.searchResults}</h1>
          <div className="SiteSearch__formContainer Search__formContainer">
            <form className="SiteSearch__search" onSubmit={e => e.preventDefault()}>
              <DebounceInput
                label={translations.search}
                placeholder={translations.searchForContent}
                debounceTimeMs={debounceTimeMs}
                className="SiteSearch__input"
                {...bindTo(property(filters, "phrase"))}
              />
              <Button
                className="SiteSearch__submit"
                type="circle"
                htmltype="submit"
                onClick={paginatedList.loadFirstPage}
              >
                <SvgIcon iconName="search" className="SiteSearch__submitIcon" />
                <span className="visuallyHidden">{translations.search}</span>
              </Button>
            </form>
            <div className="SiteSearch__filtersWrapper">
              <CheckboxGroup name="site-search" {...bindTo(property(filters, "filterNames"))}>
                <List
                  items={filtersWithTranslation}
                  className="SiteSearch__filterList Search__filterList"
                  renderItem={option => <Checkbox id={option.id}>{option.translation}</Checkbox>}
                />
              </CheckboxGroup>
            </div>
          </div>
          <p className="SiteSearch__listHeading Search__listHeading">
            {hasSearched && FormattedText(translations.yourSearchReturned, [totalCount])}&#8203;
          </p>
          {hasSearched && (
            <SearchListView
              courseStructure={activeCourseStructure}
              list={paginatedList}
              totalCount={totalCount}
              emptySearchMessage={translations.sorryNoSearchResults}
            />
          )}
          {isLoading && <ActivityIndicator isLoading={isLoading} />}
        </div>
      </article>
    </PageTitle>
  );
});
