import axios, { CancelTokenSource } from "axios";
import { action, computed, observable } from "mobx";
import { ICourseService, IProductService } from "../api/BackendApi";
import { Product } from "./Product";

export class ProductStore {
  @observable public products: Product[] = [];

  @observable private product?: Product;

  cancelTokenSource?: CancelTokenSource;

  @computed get activeProduct() {
    if (this.isOnlyOneProduct) {
      this.trySetActiveProductBySlug(this.products[0].slug);
    }

    if (this.product) {
      return this.product;
    }

    return undefined;
  }

  @action.bound
  deactivateProduct() {
    this.product = undefined;
  }

  @computed get isOnlyOneProduct() {
    return this.products.length === 1;
  }

  @computed get isBeta() {
    return this.product?.isBeta;
  }

  private constructor(private productService: IProductService, private courseService: ICourseService) {}

  static async create(productService: IProductService, courseService: ICourseService) {
    const store = new ProductStore(productService, courseService);
    await store.loadProducts();
    return store;
  }

  @action.bound
  trySetActiveProductBySlug(slug: string) {
    if (slug === this.product?.slug) {
      return true;
    }

    const product = this.products.find(f => f.slug === slug);
    if (product) {
      this.product = product;
      return true;
    }
    return false;
  }

  async loadProducts() {
    const productDtos = await this.productService.getProductsInfo({});
    this.products = productDtos.map(productDto => new Product(this, productDto));
  }

  async loadCourseStructure(courseId: string) {
    this.cancelTokenSource = axios.CancelToken.source();
    const result = await this.courseService.getCourseStructure({ id: courseId }, this.cancelTokenSource.token);
    this.cancelTokenSource = undefined;

    return result;
  }
}
