import { observer } from "mobx-react";
import * as React from "react";
import { CommandButton } from "../../../../../shared/components/button/Button";
import { DialogView } from "../../../../../shared/components/dialog/DialogView";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { RemoveBookmarkDialogViewModel } from "./RemoveBookmarkDialogViewModel";

interface RemoveBookmarkDialogProps {
  modal: RemoveBookmarkDialogViewModel;
  getReferenceElementToAttach: () => HTMLElement | null;
}

export const RemoveBookmarkDialog = observer(({ modal, getReferenceElementToAttach }: RemoveBookmarkDialogProps) => {
  const translations = useTranslations();

  return (
    <DialogView
      placement="auto"
      className="RemoveBookmarkDialog"
      referenceElement={getReferenceElementToAttach()}
      cancelCommand={modal.cancel}
      isVisible={modal.isVisible}
      offset={[0, 8]}
    >
      <div className="RemoveBookmarkDialog__heading">
        <h3 className="RemoveBookmarkDialog__bookmarkTitle">{translations.removeBookmarkMessage}</h3>
        <CommandButton
          className="RemoveBookmarkDialog__exitButton"
          type="circle"
          variant="tertiary"
          command={modal.cancel}
        >
          <SvgIcon iconName="close" alt={translations.close} />
        </CommandButton>
      </div>
      <div className="RemoveBookmarkDialog__bookmarkAction">
        <CommandButton
          className="RemoveBookmarkDialog__cancelBookmarkButton"
          type="clear"
          size="small"
          command={modal.cancel}
        >
          {translations.cancel}
        </CommandButton>
        <CommandButton
          className="RemoveBookmarkDialog__addBookmarkButton"
          variant="primary"
          size="small"
          command={modal.done}
        >
          {translations.removeBookmarkConfirm}
        </CommandButton>
      </div>
    </DialogView>
  );
});
