import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../../shared/stores/ProgressStore";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { Step } from "../Step";
import { TextStepProperties } from "./CreateTextStepProperties";

export class TextBlockStep extends Step {
  readonly _type = "TextBlock";

  content: PortableTextDto;

  constructor(step: TextStepProperties, progressStore: IProgressStore, bookmarkStore: IBookmarkStore) {
    super(step, progressStore, bookmarkStore);

    this.content = step.content;
  }
}
