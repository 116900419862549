import React from "react";

export const useVerticalSwipeThreshold = (threshold: number) => {
  const startPosition = React.useRef<number>();

  const touchStart = (e: React.TouchEvent) => {
    startPosition.current = e.touches[0].clientY;
    e.preventDefault();
  };

  const touchEnd = (e: React.TouchEvent, callback: () => void) => {
    if (startPosition.current === undefined) {
      return;
    }

    if (Math.abs(startPosition.current - e.changedTouches[0].clientY) >= threshold) {
      callback();
    }
  };

  return { touchStart, touchEnd } as const;
};
