import { observer } from "mobx-react";
import * as React from "react";
import { List } from "../../list/List";
import { PortableTextContent } from "../../portableTextContent/PortableTextContent";
import { ChoiceListTaskViewModel } from "./ChoiceListTaskViewModel";
import { ChoiceListOption } from "./choiceListOption/ChoiceListOption";
import { ChoiceListTestViewer } from "./choiceListTestViewer/ChoiceListTestViewer";

export interface ChoiceListTaskViewProps {
  model: ChoiceListTaskViewModel;
}

export const ChoiceListTask: React.FC<ChoiceListTaskViewProps> = observer(props => {
  const {
    model: {
      selectedOptions,
      selectOption,
      image,
      modelId,
      content,
      shouldDisplayCorrectAnswer,
      shouldDisplayOrderNumber,
      optionsWithStatus,
      testOptions,
      isDisabled,
      isTrainingGroundTask,
    },
  } = props;

  return (
    <div className="ChoiceListTask">
      {/* TODO: Change the types on the backend and compare it to the normal null */}
      {content.value !== "null" && (
        <div className="ChoiceListTask__content">
          <PortableTextContent content={content} />
        </div>
      )}
      <div className="ChoiceListTask__viewerWrapper">
        <ChoiceListTestViewer image={image} modelId={modelId} />
      </div>
      <List
        className="ChoiceListTask__list"
        items={optionsWithStatus}
        renderItem={optionWithStatus => (
          <ChoiceListOption
            isTrainingGroundTask={isTrainingGroundTask}
            choice={optionWithStatus.option}
            status={optionWithStatus.status}
            shouldDisplayCorrectAnswer={shouldDisplayCorrectAnswer}
            shouldDisplayOrderNumber={shouldDisplayOrderNumber}
            value={selectedOptions[optionWithStatus.id]}
            testOptions={testOptions}
            handleValueChange={newValue => {
              selectOption(optionWithStatus.id, newValue);
            }}
            disabled={isDisabled}
          />
        )}
      />
    </div>
  );
});
