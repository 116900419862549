import head from "lodash/head";
import { LessonStructure } from "../../../shared/contentStructure/LessonStructure";
import { StepStructure } from "../../../shared/contentStructure/StepStructure";
import { ThemeStructure } from "../../../shared/contentStructure/ThemeStructure";
import { getNextElement, getPreviousElement } from "../../../shared/listUtils";
import { CourseProgress } from "../../../shared/progressStructure/CourseProgress";
import { assertUnreachable } from "../../../shared/typeUtils";

export class CourseNavigator {
  constructor(private courseProgress: CourseProgress) {}

  getPreviousStep = (stepStructure: StepStructure) => {
    const { lessonStructure } = stepStructure;
    return getPreviousElement(lessonStructure.stepStructures, stepStructure);
  };

  getNextStep = (stepStructure: StepStructure) => {
    const { lessonStructure } = stepStructure;
    return getNextElement(lessonStructure.stepStructures, stepStructure);
  };

  getFirstThemeTest = (lessonStructure: LessonStructure) => {
    const { themeStructure } = lessonStructure;
    return head(themeStructure.testStructures);
  };

  getFirstUnfinishedStep = (lessonStructure: LessonStructure) => {
    const { id, stepStructures } = lessonStructure;
    const lessonProgress = this.courseProgress.getLessonProgressById(id);

    return stepStructures.find(stepStructure => lessonProgress.getIsStepCompletedById(stepStructure.id) === false);
  };

  private getNextLesson = (lessonStructure: LessonStructure) => {
    const { themeStructure } = lessonStructure;
    return getNextElement(themeStructure.lessonStructures, lessonStructure);
  };

  getFirstStepFromTheNextLesson = (lessonStructure: LessonStructure) => {
    const nextLesson = this.getNextLesson(lessonStructure);

    if (!nextLesson) {
      return;
    }

    return head(nextLesson.stepStructures);
  };

  private filterOutUnderDevelopmentThemes = (themeStructures: ThemeStructure[]) => {
    return themeStructures.filter(themeStructure => !themeStructure.isUnderDevelopment);
  };

  getPreviousTheme = (themeStructure: ThemeStructure) => {
    const { courseStructure, source } = themeStructure;

    switch (source) {
      case "course":
        return getPreviousElement(
          this.filterOutUnderDevelopmentThemes(courseStructure.themeStructures),
          themeStructure
        );
      case "previousExams":
        if (!courseStructure.coursePreviousExamsThemes) {
          return;
        }

        return getPreviousElement(
          this.filterOutUnderDevelopmentThemes(courseStructure.coursePreviousExamsThemes),
          themeStructure
        );
      default:
        return assertUnreachable(source);
    }
  };

  getNextTheme = (themeStructure: ThemeStructure) => {
    const { courseStructure, source } = themeStructure;

    switch (source) {
      case "course":
        return getNextElement(this.filterOutUnderDevelopmentThemes(courseStructure.themeStructures), themeStructure);
      case "previousExams":
        if (!courseStructure.coursePreviousExamsThemes) {
          return;
        }

        return getNextElement(
          this.filterOutUnderDevelopmentThemes(courseStructure.coursePreviousExamsThemes),
          themeStructure
        );
      default:
        return assertUnreachable(source);
    }
  };

  getFirstStepFromTheNextTheme = (lessonStructure: LessonStructure) => {
    const nextTheme = this.getNextTheme(lessonStructure.themeStructure);

    if (!nextTheme) {
      return;
    }

    const nextThemeFirstLesson = head(nextTheme.lessonStructures);

    if (!nextThemeFirstLesson) {
      return;
    }

    return head(nextThemeFirstLesson.stepStructures);
  };
}
