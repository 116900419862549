import classNames from "classnames";
import * as React from "react";
import { useRef, useState } from "react";
import ReactSelect, { MultiValueProps } from "react-select";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { SvgIcon } from "../svgIcon/SvgIcon";
export interface SelectListItem<T> {
  value: T;
  label: string;
}

function selectListItemTypeGuard<T>(o: unknown): o is SelectListItem<T> {
  return !!(o as SelectListItem<T>).value;
}

type ValueType<T, IsMulti extends boolean> = IsMulti extends true ? T[] : T;

export interface SelectProps<T, IsMulti extends boolean = false> {
  isMulti?: IsMulti;
  options: SelectListItem<T>[];
  value?: IsMulti extends true ? T[] : T;
  onChange: (newValues: ValueType<T, IsMulti>) => void;
  className?: string;
  label?: string; // TODO: Make it required
  describedBy?: string;
  placeholder?: React.ReactNode;
  disabled?: boolean;
  variant?: "rounded" | "square";
  isTrainingGroundTask?: boolean;
  isMobile?: boolean;
}

const SelectIndicator = () => {
  return (
    <div className="Select__indicator">
      <SvgIcon iconName="chevron" className="Select__indicatorIcon" />
    </div>
  );
};

const ClearIndicator = () => {
  return null;
};

const MultiValue = <T, IsMulti extends boolean>(multiValueProps: MultiValueProps<SelectListItem<T>, IsMulti>) => {
  const { index, getValue } = multiValueProps;

  if (index !== 0) {
    return null;
  }

  const _value = getValue();
  return <div className="Select__multiValue">{_value.map(item => item.label).join(", ")}</div>;
};

export function Select<T, IsMulti extends boolean = false>(props: SelectProps<T, IsMulti>) {
  const {
    isMulti,
    value,
    onChange,
    options,
    className,
    label,
    describedBy,
    placeholder,
    disabled,
    variant = "rounded",
    isTrainingGroundTask,
    isMobile,
  } = props;
  const [menuPlacement, setMenuPlacement] = useState<"auto" | "bottom" | "top">("auto");
  const translations = useTranslations();

  const selectedValue =
    (isMulti && value
      ? options.filter(option => (value as T[]).includes(option.value))
      : options.find(option => option.value === value)) || null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null);

  const customMenuStyleForTask = {
    menu: () => ({
      position: menuPlacement === "top" ? ("absolute" as const) : ("relative" as const),
      overflow: menuPlacement === "top" ? "hidden" : null,
      bottom: menuPlacement === "top" ? "1rem" : "0",
      borderRadius: variant === "rounded" ? "24px" : "8px",
      width: menuPlacement === "top" ? "100%" : "auto",
    }),
  };

  const customMenuForTrainingGroundTasksForMobile = {
    menu: () => ({
      position: "absolute",
      top: "20vh",
      width: "auto",
      left: "-30px",
      borderRadius: variant === "rounded" ? "24px" : "8px",
    }),
  };

  const customMenuStyle = {
    menu: () => ({
      borderRadius: variant === "rounded" ? "24px" : "8px",
    }),
  };

  const onMenuOpen = () => {
    if (selectRef.current) {
      const rect = selectRef.current.controlRef?.getBoundingClientRect();
      if (rect && rect.bottom + 300 > window.innerHeight) {
        setMenuPlacement("top");
      } else {
        setMenuPlacement("auto");
      }
    }
  };

  const selectedStyle =
    // eslint-disable-next-line no-nested-ternary
    !isMobile && !isTrainingGroundTask
      ? customMenuStyle
      : // eslint-disable-next-line no-nested-ternary
      isTrainingGroundTask && !isMobile
      ? customMenuStyleForTask
      : isTrainingGroundTask && isMobile
      ? customMenuForTrainingGroundTasksForMobile
      : customMenuStyle;

  return (
    <ReactSelect
      key={menuPlacement}
      ref={selectRef}
      isMulti={isMulti}
      options={options}
      value={selectedValue}
      aria-label={label}
      aria-describedby={describedBy} // TODO: This is propably not working - we should verify it and change the lib if true
      className={classNames(
        "Select",
        { "Select--disabled": disabled },
        { "Select--rounded": variant === "rounded" },
        { "Select--square": variant === "square" },
        className
      )}
      placeholder={placeholder || translations.select}
      classNamePrefix="Select"
      isSearchable={false}
      isDisabled={disabled}
      onChange={newValue => {
        if (newValue === null) {
          return;
        }
        // TODO: Type casting because "IsMulti" is not applying here
        if (
          Array.isArray(newValue) &&
          newValue.every((i: unknown): i is SelectListItem<T> => selectListItemTypeGuard(i)) &&
          isMulti
        ) {
          onChange(newValue.map(i => i.value) as ValueType<T, IsMulti>);
        } else if (selectListItemTypeGuard(newValue)) {
          onChange(newValue.value as ValueType<T, IsMulti>);
        }
      }}
      menuPortalTarget={document.body}
      styles={selectedStyle}
      components={{ DropdownIndicator: SelectIndicator, ClearIndicator, MultiValue }}
      noOptionsMessage={() => translations.noMoreOptions}
      menuPlacement={menuPlacement}
      onMenuOpen={onMenuOpen}
    />
  );
}
