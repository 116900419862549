import React from "react";
import { StepStructure } from "../../../contentStructure/StepStructure";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { HighLevelStepType } from "../../../stepTypeUtils";
import { useSingularOrPluralForm } from "../../../textUtils";

interface ThemeContentByTypeProps {
  content: { [key in HighLevelStepType]: StepStructure[] };
}

export const ThemeContentByType: React.FC<ThemeContentByTypeProps> = props => {
  const { content } = props;
  const translations = useTranslations();

  const stepTypesTexts: { [key in HighLevelStepType]: string | undefined } = {
    Video: useSingularOrPluralForm(content.Video.length, [translations.videoSummary, translations.videosSummary]),
    Task: useSingularOrPluralForm(content.Task.length, [translations.taskSummary, translations.tasksSummary]),
    TextBlock: useSingularOrPluralForm(content.TextBlock.length, [translations.textSummary, translations.textsSummary]),
    Sound: useSingularOrPluralForm(content.Sound.length, [translations.soundSummary, translations.soundsSummary]),
  };

  return (
    <div className="ThemeContentByType">
      <ul className="ThemeContentByType__list">
        {Object.entries(stepTypesTexts).map(([type, text]) => {
          if (text === undefined) {
            return;
          }

          return (
            <li key={type} className="ThemeContentByType__listItem">
              {text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
