import React from "react";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { DateDto } from "../../../../types/articles/dto/DateDto";
import { DateTypeDto } from "../../../../types/articles/dto/DateTypeDto";

interface ArticleDateProps {
  date: DateDto;
}

export const ArticleDate: React.FC<ArticleDateProps> = props => {
  const { date } = props;
  const { type, value } = date;
  const formattedDate = new Date(value).toLocaleDateString();

  const translations = useTranslations();

  switch (type) {
    case DateTypeDto.CreationDate:
      return (
        <p className="ArticleDate">
          {translations.published} {formattedDate}
        </p>
      );
    case DateTypeDto.EditDate:
      return (
        <p className="ArticleDate">
          {translations.edited} {formattedDate}
        </p>
      );
    default:
      return assertUnreachable(type);
  }
};
