import { computed, observable } from "mobx";
import { asyncCommand, Command } from "react-mvvm";
import { IBookmarkStore } from "../../../stores/BookmarkStore";
import { IUserStore } from "../../../stores/UserStore";

export class BookmarkToggleViewModel {
  toggleBookmark: Command;

  @observable stepId: string;

  @observable private isLoading = false;

  @computed get isVisible() {
    return this.userStore.isAuthenticated;
  }

  @computed get isStepBookmarked() {
    return this.bookmarkStore.isBookmarked(this.stepId);
  }

  constructor(stepId: string, private bookmarkStore: IBookmarkStore, private userStore: IUserStore) {
    this.stepId = stepId;

    this.toggleBookmark = asyncCommand(
      async () => {
        this.isLoading = true;
        const stepBookmark = this.bookmarkStore.getBookmark(stepId);

        if (stepBookmark) {
          this.bookmarkStore.removeBookmark(stepBookmark);
          await stepBookmark.delete();
          this.isLoading = false;
          return;
        }

        const newBookmark = this.bookmarkStore.createBookmark(stepId);
        await newBookmark.save();
        this.isLoading = false;
      },
      () => !this.isLoading
    );
  }
}
