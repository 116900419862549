import classNames from "classnames";
import React from "react";
import { Level, Title } from "../title/Title";

export interface SectionHeadingProps {
  title: React.ReactNode;
  level: Level | "withoutLevel";
  size?: "medium" | "large";
  extra?: React.ReactNode;
  className?: string;
}

export const SectionHeading = (props: SectionHeadingProps) => {
  const { title, extra, level, size = "large", className } = props;
  return (
    <div className={classNames("SectionHeading", `SectionHeading--${size}`, className)}>
      {level === "withoutLevel" ? (
        <div className="SectionHeading__title">{title}</div>
      ) : (
        <Title level={level} className="SectionHeading__title">
          {title}
        </Title>
      )}
      {extra && <div className="SectionHeading__extra">{extra}</div>}
    </div>
  );
};
