import { createContext, useContext } from "react";
import { TranslationsDto } from "../../types/translations/dto/TranslationsDto";

interface StaticTranslationContext {
  translations: TranslationsDto;
}

export const staticTranslationContext = createContext<StaticTranslationContext | undefined>(undefined);

export const useTranslations = () => {
  const ctx = useContext(staticTranslationContext);

  if (!ctx) {
    throw new Error("Component beyond StaticTranslationContext!");
  }

  return ctx.translations;
};
