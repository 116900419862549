import { Task } from "../../api/StepService";
import { assertUnreachable } from "../../typeUtils";
import { LocalTaskService } from "./TaskService";
import { TaskViewModel } from "./TaskView";
import { ChoiceListTaskViewModel } from "./choiceListTask/ChoiceListTaskViewModel";
import { ChoiceTaskViewModel } from "./choiceTask/ChoiceTaskViewModel";
import { GapTaskViewModel } from "./gapTask/GapTaskViewModel";
import { InputTaskViewModel } from "./inputTask/InputTaskViewModel";
import { MaskTaskViewModel } from "./maskTask/MaskTaskViewModel";
import { MatchTaskViewModel } from "./matchTask/MatchTaskViewModel";
import { AnswerQuestions } from "./taskUtils";

export class TaskFactory {
  private taskService = new LocalTaskService();

  createTask(task: Task): TaskViewModel {
    switch (task._type) {
      case "ChoiceTask":
        return {
          _type: task._type,
          model: new ChoiceTaskViewModel(task.test, task.title, task.content, task.description),
        };
      case "ChoiceListTask":
        return {
          _type: task._type,
          model: new ChoiceListTaskViewModel(
            task.id,
            task.title,
            task.choiceListTest,
            task.content,
            task.shouldDisplayOrderNumber,
            task.shouldDisplayCorrectAnswer,
            task.image,
            task.modelId,
            task.description
          ),
        };
      case "InputTask":
        return {
          _type: task._type,
          model: new InputTaskViewModel(
            task.id,
            task.title,
            task.similarityThreshold,
            task.ignoreCapitalLetters,
            task.useMathInput,
            task.correctAnswers,
            task.content,
            task.description
          ),
        };
      case "TextTask":
        return {
          _type: task._type,
          model: { id: task.id, content: task.content },
        };

      case "MaskTask":
        return {
          _type: task._type,
          model: new MaskTaskViewModel(task.id, task.title, task.masks, task.image, task.content, task.description),
        };

      case "GapTask":
        return {
          _type: task._type,
          model: new GapTaskViewModel(
            task.id,
            task.title,
            task.content,
            task.shouldDisplayCorrectAnswer,
            task.description
          ),
        };
      case "MatchTask":
        const correctAnswers: AnswerQuestions = task.isMultipleChoice
          ? task.questions.map(question => ({
              questionId: question.id,
              _type: "multipleAnswerQuestion",
              value: question.correctAnswerIds,
            }))
          : task.questions.map(question => ({
              questionId: question.id,
              _type: "singleAnswerQuestion",
              value: question.correctAnswerIds[0],
            }));

        this.taskService.registerTask(task.id, correctAnswers);

        return {
          _type: task._type,
          model: new MatchTaskViewModel(
            task.id,
            task.title,
            task.content,
            task.questions,
            task.answers,
            task.isMultipleChoice,
            this.taskService,
            task.description
          ),
        };
      default:
        return assertUnreachable(task);
    }
  }
}
