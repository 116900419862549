import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { Link } from "../../../shared/components/link/Link";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { useUrl } from "../../../useRouter";
import { LessonList } from "../../shared/lessonList/LessonList";
import { LessonSection } from "../../shared/lessonList/lessonSection/LessonSection";
import { ThemeViewModel } from "./ThemeViewModel";
import { ThemeArticleSection } from "./articleSection/ThemeArticleSection";
import { EmptyTheme } from "./empty/EmptyTheme";
import { ThemeHero } from "./hero/ThemeHero";
import { ThemeProgress } from "./progress/ThemeProgress";
import { ThemeTrainingSection } from "./themeTrainingSection/ThemeTrainingSection";

export const ThemeView = observer(({ model }: { model: ThemeViewModel }) => {
  const {
    themeStructure,
    lessonList,
    themeProgress,
    userStore,
    paymentSidePanelViewModel,
    appMotif,
    childPage,
    relatedContent,
    trainingSectionViewModel,
    previousTheme,
    nextTheme,
    hasFullCourseAccess,
  } = model;
  const { title, description, image, subtitle, articles, source, courseStructure } = themeStructure;
  const { color } = appMotif;

  const isMainSectionEmpty = lessonList.length === 0 && trainingSectionViewModel === undefined;
  const translations = useTranslations();
  const url = useUrl();

  return (
    <ContentView content={childPage}>
      <PageTitle title={title}>
        <article className="Theme">
          <ThemeHero
            title={title}
            description={description}
            subtitle={subtitle}
            image={image}
            color={color}
            urlParams={courseStructure.urlParams}
            source={source}
            paymentSidePanelViewModel={paymentSidePanelViewModel}
            hasFullCourseAccess={hasFullCourseAccess}
          />
          <div className="Theme__mainSection">
            {userStore.isAuthenticated && (
              <div className="Theme__progress">
                <ThemeProgress
                  stepsCount={themeProgress.actionableStepsCount}
                  completedStepsCount={themeProgress.completedStepsCount}
                />
              </div>
            )}
            {isMainSectionEmpty ? (
              <EmptyTheme />
            ) : (
              <>
                {lessonList.length > 0 && (
                  <div className="Theme__lessons">
                    <LessonList
                      paymentSidePanelViewModel={paymentSidePanelViewModel}
                      lessonList={lessonList}
                      courseStructure={courseStructure}
                    />
                  </div>
                )}
                {trainingSectionViewModel && (
                  <div className="Theme__trainingSectionWrapper">
                    <ThemeTrainingSection model={trainingSectionViewModel} />
                  </div>
                )}
              </>
            )}
            <div className="Theme__bottomNavigation">
              {previousTheme && (
                <div className="Theme__bottomNavigationElement">
                  <Link
                    to={url.toThemePage(courseStructure.urlParams, previousTheme.slug)}
                    className="Theme__actionOverlay"
                    tabIndex={-1}
                  >
                    <span className="visuallyHidden">{previousTheme.title}</span>
                  </Link>
                  <Link
                    to={url.toThemePage(courseStructure.urlParams, previousTheme.slug)}
                    type="button"
                    buttonType="circle"
                    buttonVariant="secondary"
                    buttonSize="xlarge"
                    className="Theme__navigationLink"
                  >
                    <span className="visuallyHidden">{previousTheme.title}</span>
                    <SvgIcon iconName="left-arrow-line" />
                  </Link>
                  <div className="Theme__navigationInfo">
                    <p className="Theme__navigationText">{translations.previousTheme}</p>
                    <p className="Theme__navigationThemeTitle">{previousTheme.title}</p>
                  </div>
                </div>
              )}
              {nextTheme && (
                <div className="Theme__bottomNavigationElement Theme__bottomNavigationElement--next">
                  <div className="Theme__navigationInfo">
                    <p className="Theme__navigationText">{translations.nextTheme}</p>
                    <p className="Theme__navigationThemeTitle">{nextTheme.title}</p>
                  </div>
                  <Link
                    to={url.toThemePage(courseStructure.urlParams, nextTheme.slug)}
                    type="button"
                    buttonType="circle"
                    buttonVariant="secondary"
                    buttonSize="xlarge"
                    className="Theme__navigationLink"
                  >
                    <span className="visuallyHidden">{nextTheme.title}</span>
                    <SvgIcon iconName="right-arrow-line" />
                  </Link>
                  <Link
                    to={url.toThemePage(courseStructure.urlParams, nextTheme.slug)}
                    className="Theme__actionOverlay"
                    tabIndex={-1}
                  >
                    <span className="visuallyHidden">{nextTheme.title}</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="Theme__extraContent">
            {articles.length > 0 && (
              <ThemeArticleSection
                articles={articles}
                color={color}
                activeCourseParams={courseStructure.urlParams}
                themeTitle={title}
              />
            )}
            {relatedContent.length > 0 && (
              <div className="Theme__relatedContent">
                <LessonSection
                  title={{ text: translations.relatedContent, level: 2 }}
                  steps={relatedContent}
                  courseStructure={courseStructure}
                  paymentSidePanelViewModel={paymentSidePanelViewModel}
                />
              </div>
            )}
          </div>
        </article>
      </PageTitle>
    </ContentView>
  );
});
