import { IErrorService } from "../shared/services/ErrorService";
import { IGtmService } from "../shared/services/GtmService";
import { ILanguageService } from "../shared/services/LanguageService";
import { BasePage } from "./BasePage";

// This is a base page for all the views that are children of the app that do report to GTM on activation.
export abstract class GtmBasePage extends BasePage {
  constructor(languageService: ILanguageService, protected gtm: IGtmService, public errorService: IErrorService) {
    super(languageService, errorService);
  }

  async onActivated() {
    this.gtm.pushPageViewed();
    await super.onActivated();
  }
}
