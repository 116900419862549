import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../../../../shared/components/button/Button";
import { Popup } from "../../../../../shared/components/popup/Popup";
import { SidePanel } from "../../../../../shared/components/sidePanel/SidePanel";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { LessonViewModel } from "../../LessonViewModel";
import { StepsListProvider, StepsListItems, StepsListProgress } from "../StepsListProvider";

interface StepsListPanelProps {
  stepsListPanel: Popup;
  lesson: LessonViewModel;
}

export const StepsListPanel: React.FC<StepsListPanelProps> = observer(props => {
  const { stepsListPanel, lesson } = props;
  const translations = useTranslations();

  return (
    <SidePanel className="StepsListPanel" isOpen={stepsListPanel.isOpen} onClose={stepsListPanel.close}>
      <StepsListProvider model={lesson}>
        <div className="StepsListPanel__heading">
          <StepsListProgress className="StepsListPanel__progress" />
          <Button className="StepsListPanel__closeButton" size="medium" type="clear" onClick={stepsListPanel.close}>
            <SvgIcon iconName="chevron" alt={translations.close} />
          </Button>
        </div>
        <StepsListItems className="StepsListPanel__items" />
      </StepsListProvider>
    </SidePanel>
  );
});
