import { observable } from "mobx";
import { HintRelatedStepInfoDto } from "../../../types/shared/dto/HintRelatedStepInfoDto";
import { PortableTextDto } from "../../../types/shared/dto/PortableTextDto";
import { ActiveCourseParams } from "../../models/Url";
import { IBookmarkStore } from "../../stores/BookmarkStore";
import { HintRelatedStepViewModel } from "./hintRelatedStep/HintRelatedStepViewModel";

export class TaskHintViewModel {
  @observable relatedStep?: HintRelatedStepViewModel;

  constructor(
    public activeCourseParams: ActiveCourseParams,
    private bookmarkStore: IBookmarkStore,
    public content?: PortableTextDto,
    private hintRelatedStepInfoDto?: HintRelatedStepInfoDto
  ) {
    if (this.hintRelatedStepInfoDto) {
      this.relatedStep = new HintRelatedStepViewModel(
        this.hintRelatedStepInfoDto,
        this.activeCourseParams,
        this.bookmarkStore
      );
    }
  }
}
