import React from "react";
import { CardThemeBasic } from "../card/cardThemeBasic/CardThemeBasic";
import { List } from "../list/List";
import { SectionHeading } from "../sectionHeading/SectionHeading";
import { Level } from "../title/Title";
import { ThemeItem } from "./ThemeItem";

interface BasicThemesSectionProps {
  title: string;
  level: Level;
  themes: ThemeItem[];
}

export const BasicThemesSection = (props: BasicThemesSectionProps) => {
  const { title, level, themes } = props;

  if (themes.length === 0) {
    return null;
  }

  return (
    <section className="BasicThemesSection">
      <SectionHeading title={title} level={level} />
      <List
        className="BasicThemesSection__list"
        items={themes}
        renderItem={theme => (
          <CardThemeBasic
            themeStructure={theme.themeStructure}
            totalStepsCount={theme.actionableStepsCount}
            completedStepsCount={theme.completedStepsCount}
            status={theme.status}
            firstUnfinishedStep={theme.firstUnfinishedStep}
            tags={theme.tags}
          />
        )}
      />
    </section>
  );
};
