import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { TestState } from "../domainThumbnail/ThemeTestDomainThumbnail";
import { TestThumbnailOverlayStates } from "../shared/TestThumbnailOverlay";

type UseTestOverlayPropsType = (
  hasAccess: boolean,
  paymentSidePanelViewModel: PaymentSidePanelViewModel,
  state: TestState
) => TestThumbnailOverlayStates | undefined;

export const useTestOverlayProps: UseTestOverlayPropsType = (hasAccess, paymentSidePanelViewModel, state) => {
  if (!hasAccess) {
    return { overlayState: "locked", onClick: () => paymentSidePanelViewModel.show() };
  }

  if (state._type === "passed") {
    return {
      overlayState: "finished",
    };
  }

  if (state._type === "failed") {
    return {
      overlayState: "failed",
    };
  }

  return undefined;
};
