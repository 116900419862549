import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Command } from "react-mvvm";
import { Button } from "../../../../shared/components/button/Button";
import { DomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/DomainThumbnail";
import { IStepThumbnail } from "../../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";
import { StepNavigationItem } from "../LessonViewModel";

interface SliderThumbnailProps {
  step: IStepThumbnail;
  isActive: boolean;
  goToStep: Command<StepNavigationItem>;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  courseStructure: CourseStructure;
  id?: string;
}

export const SliderThumbnail = observer((props: SliderThumbnailProps) => {
  const { step, isActive, goToStep, paymentSidePanelViewModel, courseStructure, id } = props;

  return (
    <div id={id} className={classNames("SliderThumbnail", { "SliderThumbnail--active": isActive })}>
      {step.hasAccess && (
        <Button
          type="clear"
          className="SliderThumbnail__actionOverlay"
          onClick={() => {
            goToStep.execute(step);
          }}
          disabled={!step.hasAccess}
        >
          <span className="visuallyHidden">{step.title}</span>
        </Button>
      )}
      <DomainThumbnail
        stepThumbnail={step}
        courseStructure={courseStructure}
        thumbnailType="horizontal"
        paymentSidePanelViewModel={paymentSidePanelViewModel}
      />
    </div>
  );
});
