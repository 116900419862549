import { observer } from "mobx-react";
import React from "react";
import { ClipLoader } from "react-spinners";
import { CommandButton } from "../../../shared/components/button/Button";
import { List } from "../../../shared/components/list/List";
import { RadioGroup } from "../../../shared/components/radio/RadioGroup";
import { SidePanel } from "../../../shared/components/sidePanel/SidePanel";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../shared/textUtils";
import { BuyCourseItem } from "../buyCourse/buyCourseItem/BuyCourseItem";
import { PaymentSidePanelViewModel } from "./PaymentSidePanelViewModel";

export const PaymentSidePanel = observer(({ modal }: { modal: PaymentSidePanelViewModel }) => {
  const { isOpen, close, buyCourseVm } = modal;
  const { course, buyCourseCommand, setSelectedOption, selectedPriceOptionId, groupName } = buyCourseVm;
  const { buyCourseSection, priceOptions } = course;
  const { title } = buyCourseSection;
  const translations = useTranslations();

  return (
    <SidePanel className="PaymentSidePanel" isOpen={isOpen} onClose={close}>
      <div className="PaymentSidePanel__inner">
        <div className="PaymentSidePanel__content">
          <h2 className="PaymentSidePanel__title">{title}</h2>
          <div className="PaymentSidePanel__description">
            {FormattedText(translations.youAreBuying, [course.title])}
          </div>
          <div className="PaymentSidePanel__descriptionThemes">
            {FormattedText(translations.includedThemes, [course.themeStructures.length])}
          </div>
          {priceOptions && (
            <>
              <h3 className="PaymentSidePanel__pricingMessage">{translations.choosePricing}</h3>
              <RadioGroup name={`${groupName}_priceOption`} value={selectedPriceOptionId} onChange={setSelectedOption}>
                <List
                  items={priceOptions}
                  className="PaymentSidePanel__priceOptions"
                  singleItemClassName="PaymentSidePanel__priceOptionItem"
                  renderItem={option => {
                    return <BuyCourseItem option={option} />;
                  }}
                />
              </RadioGroup>
            </>
          )}
          <div className="PaymentSidePanel__buyCourseButtonWrapper">
            <CommandButton className="PaymentSidePanel__buyCourseButton" command={buyCourseCommand}>
              <span>{translations.buyCourseButton}</span>
              <SvgIcon iconName="right-arrow-line" />
            </CommandButton>
            {buyCourseCommand.isRunning && (
              <div className="PaymentSidePanel__paymentInProgress">
                <div className="PaymentSidePanel__paymentSpinner">
                  <ClipLoader color="#e50043" loading />
                </div>
                <span>{translations.pleaseWaitItCanTakeFewSeconds}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidePanel>
  );
});
