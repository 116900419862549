import classNames from "classnames";
import React from "react";
import { MotifImage } from "../../../../../shared/components/image/Image";
import { AnswersState } from "../../../../../shared/components/tasks/AnswersState";

export interface TaskStatusImageProps {
  state: AnswersState;
}

export const TaskStatusImage: React.FC<TaskStatusImageProps> = ({ state }) => {
  const statusWithImages: { fileName: `${string}.${string}`; status: AnswersState }[] = [
    { fileName: "iconsColor/task-wrong-state.svg", status: "wrong" },
    { fileName: "iconsColor/task-partially-correct-state.svg", status: "partiallyCorrect" },
    { fileName: "iconsColor/task-correct-state.svg", status: "correct" },
  ];

  return (
    <div className="TaskStatusImage">
      <div className="TaskStatusImage__inner">
        {statusWithImages.map(el => (
          <div
            key={el.fileName}
            className={classNames("TaskStatusImage__imageWrapper", {
              "TaskStatusImage__imageWrapper--active": el.status === state,
            })}
          >
            <MotifImage image={{ fileName: el.fileName, alt: "" }} className="TaskStatusImage__image" maxWidth="320" />
          </div>
        ))}
      </div>
    </div>
  );
};
