import { observer } from "mobx-react";
import React from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import { ProductQuoteDto } from "../../../types/productquote/dto/ProductQuoteDto";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { QuoteSlide } from "../quoteSlide/QuoteSlide";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface QuoteSliderProps {
  quotes: ProductQuoteDto[];
}

const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      className="QuotesSlider__arrow QuotesSlider__arrow--previous"
      type="circle"
      variant="secondary"
      onClick={onClick}
    >
      <span className="visuallyHidden">{translations.previous}</span>
      <SvgIcon iconName="left-arrow-line" />
    </Button>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      className="QuotesSlider__arrow QuotesSlider__arrow--next"
      type="circle"
      variant="secondary"
      onClick={onClick}
    >
      <span className="visuallyHidden">{translations.next}</span>
      <SvgIcon iconName="right-arrow-line" />
    </Button>
  );
};

export const QuotesSlider: React.FC<QuoteSliderProps> = observer(props => {
  const { quotes } = props;
  const sliderSettings = {
    infinite: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    touchThreshold: 10,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  } as Settings;

  return (
    <Slider {...sliderSettings} className="QuotesSlider">
      {quotes.map(q => (
        <QuoteSlide quote={q} key={q.authorName} />
      ))}
    </Slider>
  );
});
