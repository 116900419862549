import React from "react";
import { SanityImage } from "../../../shared/components/image/Image";
import { Link } from "../../../shared/components/link/Link";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { Product } from "../../../shared/stores/Product";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../useRouter";

export interface ProductCardItem extends Pick<Product, "title" | "slug" | "targetGroup"> {
  image: ImageDto;
  hasMoreThanOneCourse?: boolean;
  courseSlug?: string;
}

export const ProductCard: React.FC<ProductCardItem> = props => {
  const { title, targetGroup, slug, image, hasMoreThanOneCourse, courseSlug } = props;

  const url = useUrl();
  const translations = useTranslations();

  return (
    <div className="ProductCard">
      <div className="ProductCard__imageWrapper">
        <SanityImage {...image} className="ProductCard__image" />
      </div>
      <div className="ProductCard__info">
        <p className="ProductCard__targetGroup">{targetGroup}</p>
        <Title level={2} className="ProductCard__title">
          {title}
        </Title>
        <Link
          to={
            hasMoreThanOneCourse || !courseSlug
              ? url.toProductPage({ productSlug: slug })
              : url.toCoursePage({ courseSlug: courseSlug, productSlug: slug })
          }
          type="button"
          buttonSize="medium"
          className="ProductCard__link"
        >
          <span>{translations.seeProduct}</span>
          <SvgIcon iconName="right-arrow-line" />
        </Link>
      </div>
      {hasMoreThanOneCourse || !courseSlug ? (
        <Link type="clear" to={url.toProductPage({ productSlug: slug })} className="ProductCard__linkOverlay">
          <span className="visuallyHidden">{title}</span>
        </Link>
      ) : (
        <Link
          type="clear"
          to={url.toCoursePage({ courseSlug: courseSlug, productSlug: slug })}
          className="ProductCard__linkOverlay"
        >
          <span className="visuallyHidden">{title}</span>
        </Link>
      )}
    </div>
  );
};
