import clamp from "lodash/clamp";
import { Percentage } from "../../shared/formatProgress";
import { getResultForScore, Result } from "../../shared/resultUtils";
import { ResultDto } from "../../types/shared/dto/ResultDto";
import { PassThresholdDto } from "../../types/test/dto/PassThresholdDto";

export type PassThreshold = PassThresholdDto;

export class TestResultCalculator {
  private get percentagePassThreshold(): Percentage {
    const passThreshold = this.passThreshold.value;

    return clamp(passThreshold, 0, 100);
  }

  constructor(private passThreshold: PassThreshold, private testResults: ResultDto[]) {}

  calculate(correctTasks: number, totalTasks: number): Result {
    if (correctTasks < 0) {
      throw new Error("Number of correct tasks can not be less than 0");
    }

    if (totalTasks <= 0) {
      throw new Error("Number of total tasks can not be less or equal to 0");
    }

    if (correctTasks > totalTasks) {
      throw new Error("Number of correct tasks can not be greater that number of total tasks");
    }

    const result = getResultForScore(correctTasks / totalTasks, this.percentagePassThreshold, this.testResults);
    return result;
  }
}
