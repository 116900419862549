import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { ProgressBar } from "../../progressBar/ProgressBar";
import { SvgIcon } from "../../svgIcon/SvgIcon";
import { ThumbnailImage } from "../shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

export interface RectangleThumbnailProps {
  title: string;
  type: ThumbnailTypes;
  subtitle?: string;
  timeStamp?: string;
  progress?: number;
  image?: ImageDto;
  overlay?: React.ReactNode;
  locked?: boolean;
  color?: string;
  href?: string;
}

export const RectangleThumbnail: React.FC<RectangleThumbnailProps> = observer(props => {
  const { title, type, subtitle, timeStamp, progress, image, overlay, locked, color, href } = props;

  return (
    <div
      className={classNames(
        "RectangleThumbnail",
        { "RectangleThumbnail--locked": locked },
        { "RectangleThumbnail--video": type === "Video" }
      )}
    >
      <div className="RectangleThumbnail__imageWrapper" style={{ backgroundColor: color }}>
        {overlay}
        {href && type === "Video" && !locked && (
          <div className="RectangleThumbnail__linkWrapper">
            <Link
              type="button"
              buttonType="circle"
              buttonVariant="tertiary"
              buttonSize="xlarge"
              className="RectangleThumbnail__link"
              to={href}
              tabIndex={-1}
            >
              <span className="visuallyHidden">{title}</span>
              <SvgIcon iconName="play" />
            </Link>
          </div>
        )}
        <ThumbnailImage type={type} image={image} />
        <div className="RectangleThumbnail__infoWrapper">
          <div className="RectangleThumbnail__info">
            {subtitle && <div className="RectangleThumbnail__subtitle">{subtitle}</div>}
            {timeStamp && (
              <div className="RectangleThumbnail__timeStamp">
                <SvgIcon iconName="clock" className="RectangleThumbnail__timeStampIcon" />
                <span>{timeStamp}</span>
              </div>
            )}
          </div>
          {progress !== undefined && (
            <div className="RectangleThumbnail__progressBar">
              <ProgressBar progress={progress} type="success" rounded={false} size="large" variant="dark" />
            </div>
          )}
        </div>
      </div>
      {href && !locked && (
        <Link to={href} type="clear" className="RectangleThumbnail__linkOverlay">
          <span className="visuallyHidden">{title}</span>
        </Link>
      )}
    </div>
  );
});
