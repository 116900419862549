import classNames from "classnames";
import React from "react";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { PortableTextContent } from "../../../portableTextContent/PortableTextContent";
import { getOptionStatus } from "../../shared/OptionStatus";
import { ChoiceTestOptionStatus } from "../../shared/choiceTestOptionStatus/ChoiceTestOptionStatus";
import { QuestionId, AnswerId } from "../../taskUtils";
import { MatchTaskValue } from "../MatchTaskViewModel";
import { MatchTaskList } from "../list/MatchTaskList";
import { MatchTaskTable } from "../table/MatchTaskTable";

export type UserAnswers = MatchTaskValue;
export type CorrectAnswers = MatchTaskValue;

interface MatchTaskResultProps {
  questions: { id: QuestionId; content: PortableTextDto }[];
  answers: { id: AnswerId; content: PortableTextDto }[];
  userAnswers: UserAnswers;
  correctAnswers: CorrectAnswers;
  content: PortableTextDto;
  isMultipleChoice: boolean;
}

export const MatchTaskResult = (props: MatchTaskResultProps) => {
  const { userAnswers, correctAnswers, questions, answers, content, isMultipleChoice } = props;

  return (
    <div className="MatchTaskResult">
      <div className="MatchTask__content">
        <PortableTextContent content={content} />
        <div className="tablet">
          <div className="MatchTaskResult__tableWrapper">
            <MatchTaskTable
              questions={questions}
              answers={answers}
              isMultipleChoice={isMultipleChoice}
              renderAnswer={(answer, questionId) => {
                const status = getOptionStatus(answer.id, questionId, correctAnswers, userAnswers);
                return (
                  <ChoiceTestOptionStatus
                    id={answer.id}
                    content={
                      <div
                        className={classNames("MatchTaskResult__answerWrapper", {
                          "MatchTaskResult__answerWrapper--default": status === "default",
                        })}
                      >
                        <PortableTextContent content={answer.content} />
                      </div>
                    }
                    status={status}
                    type={isMultipleChoice ? "checkbox" : "radio"}
                  />
                );
              }}
              value={userAnswers}
              disabled
            />
          </div>
        </div>
        <div className="mobileOnly">
          <div className="MatchTaskResult__listWrapper">
            <MatchTaskList
              questions={questions}
              answers={answers}
              isMultipleChoice={isMultipleChoice}
              renderAnswer={(answer, questionId) => {
                const status = getOptionStatus(answer.id, questionId, correctAnswers, userAnswers);
                return (
                  <ChoiceTestOptionStatus
                    id={answer.id}
                    content={<PortableTextContent content={answer.content} />}
                    status={status}
                    type={isMultipleChoice ? "checkbox" : "radio"}
                  />
                );
              }}
              value={userAnswers}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
