import { observer } from "mobx-react";
import React from "react";
import { AuthorsSlider } from "../../shared/components/authorsSlider/AuthorsSlider";
import { Image, SanityImage } from "../../shared/components/image/Image";
import { List } from "../../shared/components/list/List";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { PersonsList } from "../../shared/components/personsList/PersonsList";
import { PortableTextContent } from "../../shared/components/portableTextContent/PortableTextContent";
import { QuotesSlider } from "../../shared/components/quotesSlider/QuotesSlider";
import { Title } from "../../shared/components/title/Title";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import quotesImage from "../../static/images/quote.png";
import { ProductCard } from "../productNest/productCard/ProductCard";
import { AboutUsViewModel } from "./AboutUsViewModel";

interface AboutUsViewProps {
  model: AboutUsViewModel;
}

export const AboutUsView: React.FC<AboutUsViewProps> = observer(props => {
  const {
    model: { data, products, authorsSliderViewModel },
  } = props;
  const translations = useTranslations();
  if (!data) {
    return null;
  }

  return (
    <PageTitle title={translations.aboutUs}>
      <article className="AboutUs">
        <div className="AboutUs__pageHeader">
          <Image.Container aspectRatio="16/9" className="AboutUs__pageHeaderImageContainer">
            <SanityImage {...data.pageImage} className="AboutUs__pageHeaderImage" />
          </Image.Container>
          <div className="AboutUs__pageHeaderText">
            <Title level={1} className="AboutUs__pageHeaderTitle">
              {data.pageTitle}
            </Title>
            <p className="AboutUs__pageHeaderSubtitle">{data.pageSubtitle}</p>
          </div>
        </div>
        <div className="AboutUs__aboutProduct">
          <div className="AboutUs__aboutProductContent">
            <Image.Container aspectRatio="16/9">
              <SanityImage {...data.aboutProductImage} />
            </Image.Container>
            <div className="AboutUs__aboutProductText">
              <p className="AboutUs__aboutProductTitle">{data.aboutProductTitle}</p>
              <PortableTextContent
                content={data.aboutProductDescription}
                className="AboutUs__aboutProductDescription"
              />
            </div>
          </div>
        </div>
        {data.authorsOfProductDescription && data.authorsOfProductList && (
          <div className="AboutUs__authorsOfProduct">
            <div className="AboutUs__authorsOfProductContent">
              <div className="AboutUs__authorsOfProductText">
                <p className="AboutUs__authorsOfProductTitle">{data.authorsOfProductTitle}</p>
                <div className="AboutUs__authorsOfProductDescription">
                  <PortableTextContent content={data.authorsOfProductDescription} />
                </div>
              </div>
              <AuthorsSlider model={authorsSliderViewModel} className="AboutUs__authorsOfProductSlider" />
            </div>
          </div>
        )}
        {data.studentsQuotesList && (
          <div className="AboutUs__studentsQuotes">
            <div className="AboutUs__studentsQuotesContent">
              <p className="AboutUs__studentsQuotesTitle">{data.studentsQuotesTitle}</p>
              <Image.Container aspectRatio="3/4" className="AboutUs__studentsQuotesImageContainer">
                <Image url={quotesImage} alt="" />
              </Image.Container>
              <div className="AboutUs__studentsQuotesSlider">
                <QuotesSlider quotes={data.studentsQuotesList} />
              </div>
            </div>
          </div>
        )}
        {data.peopleBehindProductDescription && data.peopleBehindProductImage && data.peopleBehindProductList && (
          <div className="AboutUs__peopleBehindProduct">
            <div className="AboutUs__peopleBehindProductHeader">
              <div className="AboutUs__peopleBehindProductText">
                <p className="AboutUs__peopleBehindProductTitle">{data.peopleBehindProductTitle}</p>
                <div className="AboutUs__peopleBehindProductDescription">
                  <PortableTextContent content={data.peopleBehindProductDescription} />
                </div>
              </div>
              <Image.Container aspectRatio="16/9" className="AboutUs__peopleBehingProductImage">
                <SanityImage {...data.peopleBehindProductImage} />
              </Image.Container>
            </div>
            <div className="AboutUs__peopleBehindProductList">
              <PersonsList persons={data.peopleBehindProductList} />
            </div>
          </div>
        )}
        <div className="AboutUs__bottomSection">
          <Image.Container aspectRatio="16/9" className="AboutUs__bottomSectionImageContainer">
            <SanityImage {...data.bottomImage} className="AboutUs__bottomSectionImage" />
          </Image.Container>
        </div>
        <div className="AboutUs__ourCourses">
          <p className="AboutUs__ourCoursesTitle">{data.ourCoursesTitle}</p>
          <PortableTextContent content={data.ourCoursesDescription} className="AboutUs__ourCoursesDescription" />
          <List
            className="AboutUs__ourCoursesList"
            singleItemClassName="AboutUs__ourCoursesListElement"
            items={products}
            renderItem={product => {
              return (
                <ProductCard
                  slug={product.slug}
                  image={product.cardImage}
                  targetGroup={product.targetGroup}
                  title={product.title}
                />
              );
            }}
          />
        </div>
      </article>
    </PageTitle>
  );
});
