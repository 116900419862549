import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { BuyRedirectViewModel } from "./BuyRedirectViewModel";

export type BuyRedirectQueryParams = {
  courseId: string;
  paymentOptionId: string;
  successUrl: string;
  cancelUrl: string;
  failUrl: string;
};

export const addBuyRedirectRouting = (route: IRoute<App, unknown, unknown>) => {
  const buyRedirectRoute = route.addRoute<BuyRedirectViewModel>(
    RouteConfiguration.BuyRedirect,
    BuyRedirectViewModel,
    app => {
      const buyRedirect = new BuyRedirectViewModel(app.languageService, app, app.gtm, app.tibetClient, app.userStore);

      return app.showChildPage(buyRedirect);
    }
  );
  return buyRedirectRoute;
};
