import { action, observable, onBecomeObserved } from "mobx";
import { BackendApi } from "../../../shared/api/BackendApi";
import { ArticleListItemDto } from "../../../types/articles/dto/ArticleListItemDto";
import { AuthorDto } from "../../../types/shared/dto/AuthorDto";

export type AuthorArticles = { _type: "unloaded" } | { _type: "loaded"; articles?: ArticleListItemDto[] };

export class AuthorInfo {
  @observable authorDetails: AuthorArticles = {
    _type: "unloaded",
  };

  constructor(private backendApi: BackendApi, public author: AuthorDto) {
    onBecomeObserved(this, "authorDetails", async () => {
      await this.setAuthorDetails(author.slug);
    });
  }

  @action.bound
  private async setAuthorDetails(slug: string) {
    const authorDetails = await this.backendApi.getAuthor({ slug });
    this.authorDetails = { _type: "loaded", articles: authorDetails.articles };
  }
}
