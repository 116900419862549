import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { ContentReference } from "../../../../models/Url";

export interface IArticleThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  description?: string;
  color?: string;
  hasAccess: boolean;
  category?: string;
  isCompleted: false;
  image?: ImageDto;
  type: "Article";
}

export class ArticleThumbnailViewModel implements IArticleThumbnail {
  id: string;

  contentRef: ContentReference;

  title: string;

  description?: string;

  image?: ImageDto;

  category?: string;

  readonly type = "Article";

  readonly isCompleted = false;

  color?: string;

  hasAccess: boolean;

  constructor(stepThumbnail: {
    id: string;
    title: string;
    image?: ImageDto;
    color?: string;
    category?: string;
    hasAccess: boolean;
    description?: string;
    slug: string;
  }) {
    this.id = stepThumbnail.id;
    this.contentRef = stepThumbnail;
    this.title = stepThumbnail.title;
    this.image = stepThumbnail.image;
    this.category = stepThumbnail.category;
    this.color = stepThumbnail.color;
    this.hasAccess = stepThumbnail.hasAccess;
    this.description = stepThumbnail.description;
  }
}
