import { action, computed, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { Popup } from "../../shared/components/popup/Popup";
import { Url } from "../../shared/models/Url";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { UserStore } from "../../shared/stores/UserStore";
import { ProductAuthorsHeroDto } from "../../types/product/dto/ProductAuthorsHeroDto";
import { AuthorDto } from "../../types/shared/dto/AuthorDto";
import { GtmBasePage } from "../GtmBasePage";
import { ProductParams } from "../product/addProductRouting";
import { AuthorInfo } from "./authorInfo/AuthorInfo";

export class AuthorsForProductViewModel extends GtmBasePage {
  @observable authors: AuthorDto[] = [];

  @observable author?: AuthorDto;

  @observable authorInfoPopup = new Popup(this.authorInfoClosePopupAction, this.authorInfoOpenPopupAction);

  @computed get authorInfo() {
    if (!this.author) {
      return;
    }

    return new AuthorInfo(this.backendApi, this.author);
  }

  constructor(
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public navigationService: ILowLevelNavigationService,
    public gtm: IGtmService,
    public userStore: UserStore,
    public productParams: ProductParams,
    private backendApi: BackendApi,
    public productAuthorsHero?: ProductAuthorsHeroDto,
    public queryParams?: { author?: string }
  ) {
    super(languageService, gtm, errorService);
  }

  @action.bound
  private authorInfoOpenPopupAction() {
    if (this.author) {
      const url = Url.toAuthorsForProductPage(
        { productSlug: this.productParams.productSlug },
        { author: this.author.slug }
      );
      this.navigationService.pushUrlWithoutRedirect(url);
    }
  }

  @action.bound
  private authorInfoClosePopupAction() {
    if (this.author) {
      const url = Url.toAuthorsForProductPage({ productSlug: this.productParams.productSlug });
      this.navigationService.pushUrlWithoutRedirect(url);
    }
  }

  @action.bound
  setAuthor(slug: string) {
    this.author = this.authors.find(author => author.slug === slug);
  }

  protected async loadData() {
    this.authors = await this.backendApi.getAuthorsForProduct({ slug: this.productParams.productSlug });

    if (this.queryParams?.author) {
      this.setAuthor(decodeURI(this.queryParams.author));
    }

    if (this.author && this.authorInfo) {
      this.authorInfoPopup.show();
    }
  }
}
