import * as React from "react";
import { AuthorInfo } from "../../../../shared/components/authorInfo/AuthorInfo";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { List } from "../../../../shared/components/list/List";
import { NavigationLink } from "../../../../shared/components/navigationLink/NavigationLink";
import { Title } from "../../../../shared/components/title/Title";
import { DateDto } from "../../../../types/articles/dto/DateDto";
import { AuthorDto } from "../../../../types/shared/dto/AuthorDto";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { ArticleDate } from "../articleDate/ArticleDate";

export interface ArticleHeroProps {
  title: string;
  setSelectedAuthor?: (author: AuthorDto) => void;
  category?: string;
  image?: ImageDto;
  description?: string;
  authors?: AuthorDto[];
  date?: DateDto;
  color?: string;
  backLink?: { href: string; text: string };
}

export const ArticleHero: React.FC<ArticleHeroProps> = props => {
  const { title, category, image, description, authors, date, color, backLink, setSelectedAuthor } = props;

  return (
    <section className="ArticleHero" style={{ backgroundColor: color }}>
      <div className="ArticleHero__inner">
        {backLink && (
          <div className="ArticleHero__backLinkContainer">
            <NavigationLink to={backLink.href} text={backLink.text} type="back" />
          </div>
        )}
        <div className="ArticleHero__info">
          {category && <span className="ArticleHero__category">{category}</span>}
          <Title level={1} className="ArticleHero__title">
            {title}
          </Title>
          {description && <p className="ArticleHero__description">{description}</p>}
        </div>
        {authors && authors.length > 0 && (
          <List
            className="ArticleHero__authors"
            singleItemClassName="ArticleHero__authorItem"
            items={authors}
            renderItem={author => (
              <AuthorInfo {...author} onClick={() => setSelectedAuthor && setSelectedAuthor(author)} />
            )}
          />
        )}
        {image && (
          <Image.Container aspectRatio="4/3" className="ArticleHero__imageContainer">
            <SanityImage {...image} className="ArticleHero__image" />
            {image.credit && <Image.Credit placement="top-end" text={image.credit} />}
          </Image.Container>
        )}
        {date && (
          <div className="ArticleHero__dateContainer">
            <ArticleDate date={date} />
          </div>
        )}
      </div>
    </section>
  );
};
