import { observer } from "mobx-react";
import React from "react";
import placeholderImage from "../../../static/images/superhero-dog.png";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { Button } from "../button/Button";
import { Image, SanityImage } from "../image/Image";

interface AuthorSlideProps {
  name: string;
  role: string;
  onButtonClick: () => void;
  image?: ImageDto;
}

export const AuthorSlide: React.FC<AuthorSlideProps> = observer(props => {
  const { image, name, role, onButtonClick } = props;
  return (
    <div className="AuthorSlide">
      <Button type="clear" className="AuthorSlide__actionOverlay" onClick={onButtonClick} tabIndex={-1}>
        <span className="visuallyHidden">{name}</span>
      </Button>
      <Image.Container aspectRatio="1/1" className="AuthorSlide__imageContainer">
        {image ? <SanityImage {...image} className="AuthorSlide__image" /> : <Image url={placeholderImage} />}
      </Image.Container>
      <p className="AuthorSlide__authorName">{name}</p>
      <p className="AuthorSlide__authorRole">{role}</p>
    </div>
  );
});
