/* eslint-disable @typescript-eslint/no-explicit-any */
import BlockContent from "@sanity/block-content-to-react";
import classNames from "classnames";
import chunk from "lodash/chunk";
import { observer } from "mobx-react";
import * as React from "react";
import { PortableTextDto } from "../../../types/shared/dto/PortableTextDto";
import { GeoGebraApplet } from "../geoGebraApplet/GeoGebraApplet";
import { Image } from "../image/Image";
import { RettsdataReference } from "../rettsdataReference/RettsdataReference";
import { GapTaskGap } from "../tasks/gapTask/GapTaskGap";
import { VideoPlayer } from "../videoPlayer/VideoPlayer";
import { VimeoPlayer } from "../videoPlayer/vimeoPlayer/VimeoPlayer";
import {
  BlockConditionalRenderProps,
  EmbeddedBlockProps,
  GeoGebraProps,
  ImageCollectionProps,
  ImageProps,
  ImagePropsNode,
  ImageSize,
  InlineChoiceGapTest,
  InlineGapProps,
  LatexProps,
  LinkProps,
  LocalImageSerializerType,
  RettsdataReferenceProps,
  RichTextTableProps,
  TileCollectionProps,
  VimeoProps,
} from "./PortableTextTypes";
import { EmbeddedBlock } from "./embeddedBlock/EmbeddedBlock";
import { ImageCollection } from "./imageCollection/ImageCollection";
import { InlineGap } from "./inlineGap/InlineGap";
import { BlockMath, InlineMath } from "./katex/Katex";
import { trimEmptyBlocks } from "./portableTextUtils";
import { Quote } from "./quote/Quote";
import { Table } from "./table/Table";
import { Text } from "./text/Text";
import { TileCollection } from "./tileCollection/TileCollection";

const defaultImageOptions = { fit: "max", w: 1200, auto: "format" };

const getImageOptions = (imageSize?: ImageSize) => {
  const defaults = { fit: "max", auto: "format" };
  switch (imageSize) {
    case "small":
      return { ...defaults, w: 400 };
    case "medium":
      return { ...defaults, w: 600 };
    case "large":
      return { ...defaults, w: 800 };
    case "extraLarge":
      return { ...defaults, w: 1200 };
    default:
      return defaultImageOptions;
  }
};

// This is listItem component copied from block-content-to-hyperscript/ListItemSerializer
const listItem: React.FC<any> = props => {
  const children =
    !props.node.style || props.node.style === "normal"
      ? // Don't wrap plain text in paragraphs inside of a list item
        props.children
      : // But wrap any other style in whatever the block serializer says to use
        React.createElement(props.serializers.types.span, props, props.children);

  return React.createElement(
    "li",
    {
      className: classNames("PortableTextContent__listItem", {
        "PortableTextContent__listItem--roman": props.node.listItem === "roman",
      }),
    },
    children
  );
};

export const imageSerializer = (
  node: ImagePropsNode,
  sanityProjectId: string,
  sanityDataset: string,
  isInline = false,
  imageOptions = defaultImageOptions
) => {
  const { asset, credit, alternativeText } = node;

  const options = { projectId: sanityProjectId, dataset: sanityDataset, imageOptions };

  if (!asset) {
    return null;
  }

  const img = React.createElement("img", { src: BlockContent.getImageUrl({ node, options }), alt: alternativeText });
  return isInline ? (
    img
  ) : (
    <>
      <figure>
        {img}
        {credit && <Image.Credit text={credit} />}
      </figure>
    </>
  );
};

const serializers = (sanityProjectId: string, sanityDataset: string) => {
  const renderBlocks = (blocks: unknown) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return renderBlockContent(blocks, sanityProjectId, sanityDataset);
  };

  const localImageSerializer: LocalImageSerializerType = (node, imageOptions, isInline) =>
    imageSerializer(node, sanityProjectId, sanityDataset, isInline, imageOptions);

  return {
    listItem,
    marks: {
      link: ({ mark, children }: LinkProps) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
      rettsdataReference: ({ mark, children }: RettsdataReferenceProps) => {
        return (
          <RettsdataReference mainLaw={mark.mainLaw} childLaw={mark.childLaw}>
            {children}
          </RettsdataReference>
        );
      },
    },
    types: {
      inlineGap: (props: InlineGapProps) => {
        const { value } = props.node;

        return <InlineGap value={value} />;
      },
      latex: (props: LatexProps) => {
        const { isInline, node } = props;

        if (isInline) {
          return <InlineMath node={node} />;
        }

        return (
          <div className="PortableTextContent__mathBlock">
            <BlockMath node={node} />
          </div>
        );
      },
      // Deprecated, TODO: Remove this
      vimeo: (props: VimeoProps) => {
        const { node } = props;
        if (!node.url) {
          return null;
        }
        return (
          <div className="PortableTextContent__videoPlayer">
            <VimeoPlayer videoUrl={node.url} />
          </div>
        );
      },
      embeddedVideo: (props: VimeoProps) => {
        const { node } = props;
        if (!node.url) {
          return null;
        }
        return (
          <div className="PortableTextContent__videoPlayer">
            <VideoPlayer videoUrl={node.url} />
          </div>
        );
      },
      embeddedBlock: (props: EmbeddedBlockProps) => {
        const {
          node: { iframe },
        } = props;
        if (!iframe) {
          return null;
        }
        return (
          <div className="PortableTextContent__embeddedBlock">
            <EmbeddedBlock iframe={iframe} />
          </div>
        );
      },
      geoGebra: (props: GeoGebraProps) => {
        const { node } = props;
        return (
          <GeoGebraApplet
            id={node._key}
            appType={node.appType}
            materialId={node.materialId}
            showAlgebraInput={node.showAlgebraInput}
            showMenuBar={node.showMenuBar}
            showToolBar={node.showToolBar}
          />
        );
      },
      inlineChoiceGapTest: observer((props: InlineChoiceGapTest) => {
        const { node } = props;

        return <GapTaskGap id={node._key} choiceGapTest={node.choiceGapTest} />;
      }),
      image: (props: ImageProps) => {
        const { node, isInline } = props;
        const {
          asset: { metadata },
          imageSize,
        } = node;

        const ratio = metadata && (metadata.dimensions.height / metadata.dimensions.width) * 100;
        const imageOptions = getImageOptions(imageSize);

        return (
          <div
            className={classNames(
              "PortableTextContent__imageWrapper",
              !!node.imageSize && `PortableTextContent__imageWrapper--${node.imageSize}`,
              { "PortableTextContent__imageWrapper--ratio": ratio }
            )}
          >
            {localImageSerializer(node, imageOptions, isInline)}
            {!!ratio && <div style={{ paddingTop: `${ratio}%` }} />}
          </div>
        );
      },
      imageCollection: (props: ImageCollectionProps) => {
        const {
          node: { images, imageSize },
        } = props;

        return <ImageCollection images={images} imageSize={imageSize} imageSerializer={localImageSerializer} />;
      },
      tileCollection: (props: TileCollectionProps) => {
        const {
          node: { tiles, tileSize },
        } = props;
        return <TileCollection tiles={tiles} tileSize={tileSize} renderBlocks={renderBlocks} />;
      },
      richTextTable: (props: RichTextTableProps) => {
        const { node } = props;

        const rows = chunk(node.cells, node.columns).map(rowCells => {
          const key = rowCells.map(cell => cell._key).join();
          return {
            rowCells,
            key,
          };
        });

        return <Table rows={rows} renderBlocks={renderBlocks} />;
      },
      block: (props: BlockConditionalRenderProps) => {
        const {
          children,
          node: { style },
        } = props;

        switch (style) {
          case "blockquote":
            return <Quote>{children}</Quote>;
          case "normal":
          case "h2":
          case "h3":
            return <Text type={style}>{children}</Text>;
          default:
            return BlockContent.defaultSerializers.types.block(props);
        }
      },
    },
  };
};

export const renderBlockContent = (blocks: unknown, sanityProjectId: string, sanityDataset: string) => {
  if (!blocks) {
    return null;
  }

  const formattedBlocks = trimEmptyBlocks(Array.isArray(blocks) ? blocks : [blocks]);

  return (
    <div className="PortableTextContent">
      <BlockContent
        blocks={formattedBlocks}
        serializers={serializers(sanityProjectId, sanityDataset)}
        projectId={sanityProjectId}
        dataset={sanityDataset}
        imageOptions={defaultImageOptions}
      />
    </div>
  );
};

export interface PortableTextContentProps {
  content: PortableTextDto;
  className?: string;
}

export const PortableTextContent: React.FC<PortableTextContentProps> = ({ content, className }) => {
  const blocks = JSON.parse(content.value);

  if (blocks === null) {
    return null;
  }

  const formattedBlocks = trimEmptyBlocks(blocks);

  return (
    <div className={classNames("PortableTextContent", className)}>
      {!!formattedBlocks && (
        <BlockContent
          blocks={formattedBlocks}
          serializers={serializers(content.sanityProjectId, content.sanityDataset)}
          projectId={content.sanityProjectId}
          dataset={content.sanityDataset}
        />
      )}
    </div>
  );
};
