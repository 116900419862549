import classNames from "classnames";
import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { Level, Title } from "../../title/Title";
import { ThumbnailImage } from "../shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

export interface VerticalThumbnailProps {
  title: { text: string; level: Level };
  type: ThumbnailTypes;
  category?: string;
  description?: string;
  image?: ImageDto;
  overlay?: React.ReactNode;
  locked?: boolean;
  href?: string;
  imageBackgroundColor?: string;
  backgroundColor?: string;
  bottomContent?: React.ReactNode;
}

export const VerticalThumbnail = (props: VerticalThumbnailProps) => {
  const {
    title,
    type,
    category,
    description,
    image,
    overlay,
    locked,
    href,
    imageBackgroundColor,
    backgroundColor,
    bottomContent,
  } = props;

  return (
    <div
      className={classNames("VerticalThumbnail", { "VerticalThumbnail--locked": locked })}
      style={{ backgroundColor }}
    >
      <div className="VerticalThumbnail__imageWrapper" style={{ backgroundColor: imageBackgroundColor }}>
        {overlay}
        <ThumbnailImage type={type} image={image} />
      </div>
      <div className="VerticalThumbnail__infoBox">
        {category && <span className="VerticalThumbnail__category">{category}</span>}
        <Title className="VerticalThumbnail__title" level={title.level}>
          {title.text}
        </Title>
        <p className="VerticalThumbnail__description">{description}</p>
        <div className="VerticalThumbnail__bottomContentWrapper">
          <div className="VerticalThumbnail__bottomContent">{bottomContent}</div>
        </div>
      </div>
      {href && !locked && (
        <Link to={href} type="clear" className="VerticalThumbnail__linkOverlay">
          <span className="visuallyHidden">{title.text}</span>
        </Link>
      )}
    </div>
  );
};
