import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Modal } from "../../../../shared/components/modal/Modal";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { FinishConfirmationModalViewModel } from "./FinishConfirmationModalViewModel";

interface FinishConfirmationModalProps {
  model: FinishConfirmationModalViewModel;
}

export const FinishConfirmationModal = observer((props: FinishConfirmationModalProps) => {
  const {
    model: {
      accept,
      cancel,
      popup: { isOpen },
    },
  } = props;
  const translations = useTranslations();

  return (
    <Modal isOpen={isOpen} onClose={cancel} size="small" className="FinishConfirmationModal">
      <div className="FinishConfirmationModal__container">
        <Title level={2} className="FinishConfirmationModal__title">
          {translations.quitTrainingTitle}
        </Title>
        <div className="FinishConfirmationModal__content">
          <p className="FinishConfirmationModal__description">{translations.quitTrainingDescription}</p>
          <div className="FinishConfirmationModal__action">
            <Button
              onClick={cancel}
              className="FinishConfirmationModal__actionItem FinishConfirmationModal__closeModalButton"
            >
              {translations.quitTrainingReturn}
            </Button>
            <Button onClick={accept} className="FinishConfirmationModal__actionItem" type="outline" variant="secondary">
              {translations.quitConfirm}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});
