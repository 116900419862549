import * as React from "react";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { TextContentWithPlaceholder } from "../../../../shared/components/textContentWithPlaceholder/TextContentWithPlaceholder";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";

interface TestTaskCompletionInfoProps {
  isAnswered: boolean;
  submitCount: number;
}

export const TestTaskCompletionInfo: React.FC<TestTaskCompletionInfoProps> = ({ isAnswered, submitCount }) => {
  const translations = useTranslations();

  const isVisible = isAnswered || (submitCount > 0 && !isAnswered);

  return (
    <div className="TestTaskCompletionInfo">
      <TextContentWithPlaceholder isVisible={isVisible}>
        {isAnswered ? (
          <>
            <SvgIcon iconName="check" className="TestTaskCompletionInfo__icon" />
            <p className="TestTaskCompletionInfo__text">{translations.questionHasAnswer}</p>
          </>
        ) : (
          <p className="TestTaskCompletionInfo__text TestTaskCompletionInfo__text--error">
            {translations.youHaveNotAnsweredThisQuestion}
          </p>
        )}
      </TextContentWithPlaceholder>
    </div>
  );
};
