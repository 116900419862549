import { IRoute } from "react-mvvm";
import { setQueryParamsToStorage } from "../../shared/setQueryParamsToStorage";
import { App } from "../App";
import { ProductViewModel } from "./ProductViewModel";

export type ProductParams = {
  productSlug: string;
};

export type ProductQueryParams = {
  utm_source: string;
  utm_medium: string;
  utm_term: string;
  utm_campaign: string;
  utm_content: string;
};

export const addProductRouting = (appRoute: IRoute<App, unknown, unknown>) => {
  const productRoute = appRoute.addRoute<ProductViewModel, ProductParams, ProductQueryParams>(
    "/:productSlug",
    ProductViewModel,
    (app, productParams, productQueryParams) => {
      if (!app.productStore.trySetActiveProductBySlug(productParams.productSlug)) {
        app.handleInvalidRoute();
        throw new TypeError("Invalid product slug");
      }

      if (productQueryParams) {
        if (productQueryParams.utm_source?.toLowerCase() === "akademika") {
          setQueryParamsToStorage(productQueryParams as ProductQueryParams);
        }
      }

      const product = new ProductViewModel(
        productParams,
        app.config,
        app.productStore,
        app.userStore,
        app.navigation,
        app.gtm,
        app.tibetClient,
        app.backendApi,
        app.languageService,
        app,
        app.handleCourseEnter,
        app.handleLogin
      );
      return app.showChildPage(product);
    },
    page => ({ ...page.productParams })
  );
  return productRoute;
};
