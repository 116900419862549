import { observable, computed, onBecomeObserved, action } from "mobx";
import { BackendApi } from "../../../../../shared/api/BackendApi";
import { Popup } from "../../../../../shared/components/popup/Popup";
import { IGtmService } from "../../../../../shared/services/GtmService";
import { ILowLevelNavigationService } from "../../../../../shared/services/NavigationService";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../../shared/stores/ProgressStore";
import { AuthorDto } from "../../../../../types/shared/dto/AuthorDto";
import { VideoDetailsDto } from "../../../../../types/video/dto/VideoDetailsDto";
import { AuthorInfo } from "../../../../authorsForProduct/authorInfo/AuthorInfo";
import { ProductParams } from "../../../../product/addProductRouting";
import { Step } from "../Step";
import { VideoStepProperties } from "./CreateVideoStepProperties";

export class VideoStep extends Step {
  readonly _type = "Video";

  readonly videoUrl: string;

  @observable videoDetails?: VideoDetailsDto;

  @observable author?: AuthorDto;

  @observable authorInfoPopup = new Popup();

  @computed get authorInfo() {
    if (!this.author) {
      return;
    }

    return new AuthorInfo(this.backendApi, this.author);
  }

  @computed get videoProgress() {
    return this.progressStore.getVideoProgress(this.id);
  }

  @computed get positionSeconds() {
    return this.videoProgress?.lastPosition.totalSeconds ?? 0;
  }

  @computed get startPositionSeconds() {
    return this.positionSeconds;
  }

  constructor(
    step: VideoStepProperties,
    protected progressStore: IProgressStore,
    private backendApi: BackendApi,
    private gtm: IGtmService,
    bookmarkStore: IBookmarkStore,
    private navigationService: ILowLevelNavigationService,
    private productParams: ProductParams,
    videoDetails?: VideoDetailsDto
  ) {
    super(step, progressStore, bookmarkStore);
    this.videoUrl = step.videoUrl;
    this.author = step.author;

    onBecomeObserved(this, "videoDetails", async () => {
      this.videoDetails = videoDetails ?? (await backendApi.getVideoDetails({ stepId: step.id }));
    });
  }

  @action.bound
  async updateVideoProgress(secondsSinceStart: number) {
    await this.progressStore.updateStepWithTimeProgress(this.id, secondsSinceStart, this._type);
  }

  @action.bound
  async endVideo(secondsSinceStart: number) {
    await this.progressStore.endStepWithTimeProgressContent(this.id, secondsSinceStart, this._type);
    this.gtm.pushVideoEnded(this.id, this.title, this.videoUrl);
  }
}
