import { action, computed, observable, reaction } from "mobx";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { AnswersState } from "../AnswersState";
import { GapTestsViewModel } from "./GapTestsViewModel";

export class GapTaskViewModel {
  @observable gapTestsViewModel = new GapTestsViewModel();

  @observable isDisabled = false;

  @observable isCompleted = false;

  @computed get answersState(): AnswersState {
    const { gapTests } = this.gapTestsViewModel;
    const gapTestsValues = Array.from(gapTests.values());

    if (gapTestsValues.some(value => value.state === "incorrect")) {
      return "wrong";
    }

    if (gapTestsValues.some(value => value.state === "shouldBeChecked")) {
      return "partiallyCorrect";
    }

    if (gapTestsValues.some(value => value.state === "correct")) {
      return "correct";
    }

    return "default";
  }

  @computed get isAnswered() {
    return this.gapTestsViewModel.isTestAnswered;
  }

  @computed get isSubmitted() {
    return this.answersState !== "default";
  }

  @computed get isSubmittedSuccessfully() {
    return this.answersState === "correct";
  }

  constructor(
    public id: string,
    public title: string,
    public readonly content: PortableTextDto,
    public readonly shouldDisplayCorrectAnswer: boolean,
    public description?: string
  ) {
    reaction(
      () => this.gapTestsViewModel.gapTests,
      () => {
        if (this.isSubmitted) {
          this.reset();
        }
      }
    );
  }

  @action.bound
  submit() {
    if (!this.isAnswered) {
      return;
    }

    this.gapTestsViewModel.validateTest();
    this.isDisabled = true;
  }

  @action.bound
  reset() {
    this.isDisabled = false;
    this.gapTestsViewModel.resetTest();
  }

  @action.bound
  showCorrectAnswer() {
    this.gapTestsViewModel.showCorrectTestAnswers();
    this.isDisabled = true;
    this.isCompleted = true;
  }
}
