import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { ArticleParams } from "./addArticlesRoutes";
import { ArticleViewModel } from "./article/ArticleViewModel";
import { ArticleBuySectionViewModel } from "./article/articleBuySection/ArticleBuySectionViewModel";

export const addCourseArticleRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const courseArticleRouting = courseRoute.addRoute<ArticleViewModel, ArticleParams>(
    `${RouteConfiguration.Articles}/:articleSlug`,
    ArticleViewModel,
    (coursePage, params) => {
      const actionSection = !coursePage.courseStructure.hasFullAccess
        ? new ArticleBuySectionViewModel(coursePage.paymentSidePanelViewModel)
        : undefined;

      const article = new ArticleViewModel(
        params.articleSlug,
        coursePage.languageService,
        coursePage.errorService,
        coursePage.backendApi,
        coursePage.navigation,
        coursePage.gtm,
        actionSection
      );

      return coursePage.showChildPage(article);
    },
    page => ({ articleSlug: page.articleSlug })
  );

  return courseArticleRouting;
};
