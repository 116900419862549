import { observer } from "mobx-react";
import React from "react";
import { AuthorDto } from "../../../types/shared/dto/AuthorDto";
import { List } from "../list/List";
import { PersonCard } from "../personCard/PersonCard";

interface PersonsListProps {
  persons: AuthorDto[];
}

export const PersonsList: React.FC<PersonsListProps> = observer(props => {
  const { persons } = props;
  return (
    <div className="PersonsList">
      <List
        items={persons}
        renderItem={person => {
          return <PersonCard image={person.image} name={person.name} role={person.role} />;
        }}
        singleItemClassName="PersonsList__singleItem"
        className="PersonsList__list"
      />
    </div>
  );
});
