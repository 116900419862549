import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { DashboardViewModel } from "./DashboardViewModel";

export const addDashboardRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const dashboardRouting = courseRoute.addRoute<DashboardViewModel>(
    RouteConfiguration.Dashboard,
    DashboardViewModel,
    coursePage => {
      const dashboard = new DashboardViewModel(
        coursePage.courseStructure,
        coursePage.userStore,
        coursePage.backendApi,
        coursePage.progressStore,
        coursePage.languageService,
        coursePage.tibetClient,
        coursePage.errorService,
        coursePage.paymentSidePanelViewModel,
        coursePage.navigation,
        coursePage.gtm,
        coursePage.activateSearchPage,
        coursePage.goToCourse
      );
      return coursePage.showChildPage(dashboard);
    }
  );

  return dashboardRouting;
};
