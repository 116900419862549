import classNames from "classnames";
import React from "react";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { SanityImage } from "../image/Image";
import { Tooltip } from "../tooltip/Tooltip";

export type AvatarSize = "small" | "medium" | "large" | "xLarge" | "xxLarge" | "xxxLarge" | "custom";

export interface AvatarImageProps {
  image: ImageDto;
}

const AvatarImage = (props: AvatarImageProps) => {
  const { image } = props;

  const avatarImage = (
    <div className="Avatar__imageWrapper">
      <SanityImage {...image} className="Avatar__image" />
    </div>
  );

  if (image.credit) {
    return <Tooltip label={image.credit}>{avatarImage}</Tooltip>;
  }

  return avatarImage;
};

export interface AvatarProps {
  initials: string;
  image?: ImageDto;
  size?: AvatarSize;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = props => {
  const { initials, image, size = "large", className } = props;

  return (
    <div className={classNames("Avatar", `Avatar--${size}`, className)}>
      {image ? <AvatarImage image={image} /> : initials}
    </div>
  );
};
