import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { LoginViewModel } from "./LoginViewModel";

export type LoginQuery = { returnUrl: string };

export const addLoginRouting = (route: IRoute<App, unknown, unknown>) => {
  route.addRoute<LoginViewModel, never, LoginQuery>(
    RouteConfiguration.LoginPage,
    LoginViewModel,
    (app, params, query) => {
      const login = new LoginViewModel(
        app.config.loginConfig,
        app.languageService,
        app.navigation,
        app.gtm,
        app,
        query.returnUrl
      );
      return app.showChildPage(login);
    }
  );
};
