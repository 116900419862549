import { observer } from "mobx-react";
import * as React from "react";
import Scroll from "react-scroll";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { Button, CommandButton } from "../../../shared/components/button/Button";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { useUrl } from "../../../useRouter";
import { LessonSection } from "../../shared/lessonList/lessonSection/LessonSection";
import { LessonViewModel } from "./LessonViewModel";
import { CongratulationPopup } from "./congratulationPopup/CongratulationPopup";
import { LessonHeading } from "./lessonHeading/LessonHeading";
import { LevelSidePanel } from "./levelSidePanel/LevelSidePanel";
import { BookmarkButton } from "./shared/bookmarkButton/BookmarkButton";
import { CompleteSwitch } from "./shared/completeSwitch/CompleteSwitch";
import { StepView } from "./steps/StepView";
import { StepsList } from "./stepsList/stepsList/StepsList";
import { StepsListPanel } from "./stepsList/stepsListPanel/StepsListPanel";

const scroll = Scroll.animateScroll;

export const LessonView = observer(({ model }: { model: LessonViewModel }) => {
  const url = useUrl();
  const translations = useTranslations();

  const {
    courseStructure: activeCourseStructure,
    lesson,
    paymentSidePanelViewModel,
    congratulationPopupViewModel,
    completedStepsCount,
    stepsCount,
    stepsListPanel,
    currentStep,
    isLoading,
    navigationService,
    gtm,
    showBookmarkModal,
    goToPreviousStep,
    goToNextStep,
    removeBookmarkDialogViewModel,
    addBookmarkDialogViewModel,
    toggleStepCompletion,
    levelSidePanelViewModel,
    relatedContentThumbnails,
    themeSlug,
    isAuthenticated,
  } = model;

  React.useEffect(() => {
    if (!currentStep) {
      return;
    }

    const pushUrl = url.toLessonPage(activeCourseStructure.urlParams, currentStep);
    navigationService.pushUrlWithoutRedirect(pushUrl);
    gtm.pushPageViewed();
  }, [currentStep?.slug]);

  React.useEffect(() => {
    isLoading && scroll.scrollToTop();
  }, [isLoading]);

  return (
    <PageTitle title={currentStep?.title}>
      <article className="Lesson">
        <div className="Lesson__inner">
          <LessonHeading
            themeSlug={themeSlug}
            lessonTitle={lesson.title}
            activeCourseStructure={activeCourseStructure}
          />
          <div className="Lesson__content">
            <div className="Lesson__step">
              <ActivityIndicator isLoading={isLoading}>
                {currentStep && (
                  <>
                    <div className="Lesson__activeStep">
                      <div className="Lesson__info">
                        <h1 className="Lesson__title">{currentStep.title}</h1>
                        {isAuthenticated && (
                          <div className="Lesson__bookmarkWrapper">
                            <BookmarkButton
                              showBookmarkModal={showBookmarkModal}
                              isBookmarked={currentStep.isBookmarked}
                              removeBookmarkDialogViewModel={removeBookmarkDialogViewModel}
                              addBookmarkDialogViewModel={addBookmarkDialogViewModel}
                            />
                          </div>
                        )}
                      </div>
                      {!!currentStep.description && currentStep._type !== "Video" && (
                        <p className="Lesson__description">{currentStep.description}</p>
                      )}
                      <StepView currentStep={currentStep} />
                    </div>
                    <div className="Lesson__stepActions">
                      {isAuthenticated && (
                        <div className="Lesson__markStep">
                          <CompleteSwitch
                            onChange={value => toggleStepCompletion.execute(value)}
                            disabled={!toggleStepCompletion.isEnabled}
                            checked={!!currentStep.isCompleted}
                          />
                        </div>
                      )}
                      <div className="Lesson__stepButtons">
                        <div className="Lesson__previousStep">
                          <span className="Lesson__navigationStepText">{translations.prevStepSliderButton}</span>
                          <CommandButton
                            className="Lesson__previousStepButton"
                            command={goToPreviousStep}
                            type="circle"
                            aria-label={translations.prevStepSliderButton}
                          >
                            <SvgIcon iconName="left-arrow-line" className="Lesson__previousStepIcon" />
                          </CommandButton>
                        </div>
                        <div className="Lesson__stepsProgress">
                          <Button
                            type="outline"
                            variant="secondary"
                            onClick={stepsListPanel.show}
                          >{`${completedStepsCount}/${stepsCount} ${translations.step}`}</Button>
                        </div>
                        <div className="Lesson__nextStep">
                          <span className="Lesson__navigationStepText">{translations.nextStepSliderButton}</span>
                          <CommandButton
                            className="Lesson__nextStepButton"
                            command={goToNextStep}
                            type="circle"
                            aria-label={translations.nextStepSliderButton}
                          >
                            <SvgIcon iconName="right-arrow-line" className="Lesson__nextStepIcon" />
                          </CommandButton>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </ActivityIndicator>
            </div>
            <div className="Lesson__stepsListWrapper">
              <StepsList lesson={model} />
            </div>
          </div>
        </div>
        {!isLoading && relatedContentThumbnails.length > 0 && (
          <div className="Lesson__relatedSection">
            <div className="Lesson__relatedContent">
              <LessonSection
                title={{ text: translations.relatedContent, type: "medium" }}
                steps={relatedContentThumbnails}
                courseStructure={activeCourseStructure}
                paymentSidePanelViewModel={paymentSidePanelViewModel}
              />
            </div>
          </div>
        )}
        {levelSidePanelViewModel && <LevelSidePanel model={levelSidePanelViewModel} />}
        <CongratulationPopup model={congratulationPopupViewModel} />
        <StepsListPanel stepsListPanel={stepsListPanel} lesson={model} />
      </article>
    </PageTitle>
  );
});
