import { action, computed, observable } from "mobx";
import { IVideoService } from "../../shared/api/BackendApi";
import { ITibetClient } from "../../shared/api/TibetClient";
import { ThemeItem } from "../../shared/components/basicThemesSection/ThemeItem";
import { CourseStructure } from "../../shared/contentStructure/CourseStructure";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { IProgressStore } from "../../shared/stores/ProgressStore";
import { UserStore } from "../../shared/stores/UserStore";
import { GtmBasePage } from "../GtmBasePage";
import { SearchQuery } from "../search/siteSearch/addSiteSearchRouting";
import { CourseLevel } from "../shared/courseLevel/CourseLevel";
import { PaymentSidePanelViewModel } from "../shared/paymentDialog/PaymentSidePanelViewModel";
import {
  DashboardSection,
  PreviousExamsSectionModel,
  ThemeSectionsModel,
} from "./components/dashboardSections/DashboardSection";

export class DashboardViewModel extends GtmBasePage {
  @observable public sections: DashboardSection[] = [];

  @observable public themes: ThemeItem[] = [];

  @observable public searchQuery: SearchQuery;

  @computed get user() {
    return this.userStore.user;
  }

  @computed get courseProgress() {
    return this.progressStore.courseProgress;
  }

  @computed get heroImage() {
    if (this.courseStructure.levels) {
      const courseLevel = new CourseLevel(this.progressStore, this.courseStructure.levels);
      return courseLevel.level?.image;
    }

    return this.courseStructure.image;
  }

  @action.bound
  toSearchPage() {
    this.goToSearchPage(this.searchQuery.phrase);
  }

  constructor(
    public courseStructure: CourseStructure,
    public userStore: UserStore,
    public videoService: IVideoService,
    public progressStore: IProgressStore,
    public languageService: ILanguageService,
    public tibetClient: ITibetClient,
    public errorService: IErrorService,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    public navigation: ILowLevelNavigationService,
    public gtm: IGtmService,
    public goToSearchPage: (searchPhrase: string) => void,
    public goToCourse: (courseSlug: string) => void
  ) {
    super(languageService, gtm, errorService);
    this.themes = courseStructure.themeStructures.map(
      theme => new ThemeItem(theme, this.progressStore, this.videoService)
    );
    const themeSectionsModel = new ThemeSectionsModel(this.themes);

    this.searchQuery = { phrase: "" };

    if (!courseStructure.hasFullAccess && themeSectionsModel.themesWithFreeSteps.length > 0) {
      this.sections.push({
        __type: "ThemesWithFreeStepsSection",
        themeSectionsModel,
        activeCourseParams: this.courseStructure.urlParams,
        section: this.courseStructure.freeContentSection,
      });
    }

    if (courseStructure.hasFullAccess) {
      this.sections.push({
        __type: "InProgressThemesSection",
        themeSectionsModel,
      });
      this.sections.push({
        __type: "NotStartedThemesSection",
        themeSectionsModel,
      });
    }

    if (this.courseStructure.trainingGroundInfos && this.courseStructure.trainingGroundInfos.length > 0) {
      this.sections.push({
        __type: "TrainingGroundSection",
        trainingGroundInfos: this.courseStructure.trainingGroundInfos,
        activeCourseParams: this.courseStructure.urlParams,
      });
    }

    if (courseStructure.hasFullAccess) {
      this.sections.push({ __type: "FinishedThemesSection", themeSectionsModel });
    }

    if (this.courseStructure.previousExamsStructure) {
      const previousExamsSectionModel = new PreviousExamsSectionModel(
        this.courseStructure.previousExamsStructure,
        this.courseStructure.urlParams,
        this.progressStore,
        this.videoService
      );

      this.sections.push({
        __type: "PreviousExamsSection",
        model: previousExamsSectionModel,
      });
    }

    if (!userStore.isAuthenticated && !courseStructure.hasFullAccess) {
      this.sections.push({
        __type: "OtherCoursesSection",
        courses: this.courseStructure.otherCourses,
        sectionTitle: "tryOtherCourses",
        onCourseClick: goToCourse,
        productTitle: this.courseStructure.productTitle,
      });
    }
  }

  protected async loadData() {
    this.isLoading = false;
  }
}
