import head from "lodash/head";
import { observer } from "mobx-react";
import * as React from "react";
import { CheckboxGroup } from "../../checkbox/CheckboxGroup";
import { List } from "../../list/List";
import { PortableTextContent } from "../../portableTextContent/PortableTextContent";
import { RadioGroup } from "../../radio/RadioGroup";
import { ChoiceTestOptionStatus } from "../shared/choiceTestOptionStatus/ChoiceTestOptionStatus";
import { ChoiceTaskViewModel } from "./ChoiceTaskViewModel";

export interface ChoiceTaskViewProps {
  model: ChoiceTaskViewModel;
}

export const ChoiceTask: React.FC<ChoiceTaskViewProps> = observer(props => {
  const {
    model: { id, isMultipleChoice, optionsWithStatus, content, isDisabled, selectedChoiceIds, selectOptions },
  } = props;

  return (
    <div data-testid="ChoiceTask" className="ChoiceTask">
      <div className="ChoiceTask__content">
        <PortableTextContent content={content} />
      </div>
      <div className="ChoiceTask__optionsWrapper">
        <>
          {isMultipleChoice ? (
            <CheckboxGroup name={id} value={selectedChoiceIds} disabled={isDisabled} onChange={selectOptions}>
              <List
                items={optionsWithStatus}
                className="ChoiceTask__options"
                singleItemClassName="ChoiceTask__singleOptionItem"
                renderItem={optionWithStatus => {
                  const { option, state } = optionWithStatus;
                  return (
                    <ChoiceTestOptionStatus
                      key={option.id}
                      id={option.id}
                      content={<PortableTextContent content={option.content} />}
                      status={state}
                      type="checkbox"
                      explanation={option.explanation && <PortableTextContent content={option.explanation} />}
                    />
                  );
                }}
              />
            </CheckboxGroup>
          ) : (
            <RadioGroup
              name={id}
              disabled={isDisabled}
              value={head(selectedChoiceIds)}
              onChange={e => selectOptions([e])}
            >
              <List
                className="ChoiceTask__options"
                singleItemClassName="ChoiceTask__singleOptionItem"
                items={optionsWithStatus}
                renderItem={optionWithStatus => {
                  const { option, state } = optionWithStatus;
                  return (
                    <ChoiceTestOptionStatus
                      key={option.id}
                      id={option.id}
                      content={<PortableTextContent content={option.content} />}
                      status={state}
                      type="radio"
                      explanation={option.explanation && <PortableTextContent content={option.explanation} />}
                    />
                  );
                }}
              />
            </RadioGroup>
          )}
        </>
      </div>
    </div>
  );
});
