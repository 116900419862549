import { action, observable } from "mobx";
import { StepApi } from "./StepApi";
import { LessonTask } from "./lesson/steps/LessonTask";
import { StepFactory } from "./lesson/steps/StepFactory";
import { SoundStep } from "./lesson/steps/soundStep/SoundStep";
import { TaskGroupStep } from "./lesson/steps/taskGroupStep/TaskGroupStep";
import { TextBlockStep } from "./lesson/steps/textBlockStep/TextBlockStep";
import { VideoStep } from "./lesson/steps/videoStep/VideoStep";

export type StepViewModel = LessonTask | VideoStep | TextBlockStep | TaskGroupStep | SoundStep;

export class StepStore {
  bySlug = observable.map<string, StepViewModel>();

  constructor(private stepApi: StepApi, private stepFactory: StepFactory) {}

  @action
  async load(slug: string) {
    const step = await this.stepApi.find(slug);

    this.bySlug.set(slug, this.stepFactory.createStep(step));
  }
}
