import classNames from "classnames";
import * as React from "react";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { Checkbox } from "../../../checkbox/Checkbox";
import { Radio } from "../../../radio/Radio";
import { OptionStatus } from "../OptionStatus";

export interface ChoiceTestOptionStatusProps {
  id: string;
  content: React.ReactNode;
  explanation?: React.ReactNode;
  status: OptionStatus;
  type: "checkbox" | "radio";
}

export const ChoiceTestOptionStatus: React.FC<ChoiceTestOptionStatusProps> = ({
  id,
  content,
  explanation,
  status,
  type,
}) => {
  const translations = useTranslations();

  const getInfoText = () => {
    switch (status) {
      case "correct":
        return translations.correctAnswer;
      case "incorrect":
        return translations.wrongAnswer;
      case "shouldBeChecked":
        return translations.shouldBeCheckedAnswer;
      default:
        return undefined;
    }
  };

  return (
    <div
      className={classNames("ChoiceTestOptionStatus", {
        "ChoiceTestOptionStatus--shouldBeChecked": status === "shouldBeChecked",
        "ChoiceTestOptionStatus--correct": status === "correct",
        "ChoiceTestOptionStatus--incorrect": status === "incorrect",
      })}
    >
      <div className="ChoiceTestOptionStatus__children">
        {type === "checkbox" ? (
          <Checkbox id={id} className="ChoiceTestOptionStatus__choice ChoiceTestOptionStatus__choice--checkbox">
            {content}
          </Checkbox>
        ) : (
          <Radio id={id} className="ChoiceTestOptionStatus__choice">
            {content}
          </Radio>
        )}
      </div>
      {!(status === "default") && (
        <div className="ChoiceTestOptionStatus__infoContainer">
          <p className="ChoiceTestOptionStatus__info">{getInfoText()}</p>
          {explanation && <div className="ChoiceTestOptionStatus__explanation">{explanation}</div>}
        </div>
      )}
    </div>
  );
};
