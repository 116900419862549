import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Button } from "../button/Button";
import { Collapse } from "../collapse/Collapse";
import { Popup } from "../popup/Popup";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface AccordionProps {
  model: Popup;
  title: string;
  content: React.ReactNode;
  variant: "light" | "dark";
}

export const Accordion: React.FC<AccordionProps> = observer(props => {
  const { content, title, model, variant } = props;

  return (
    <div
      className={classNames("Accordion", {
        "Accordion--light": variant === "light",
        "Accordion--dark": variant === "dark",
      })}
    >
      <Button
        onClick={model.toggle}
        className="Accordion__toggleButton"
        aria-expanded={model.isOpen}
        aria-controls={title}
      >
        <span>{title}</span>
        {model.isOpen ? (
          <SvgIcon iconName="minus" className="Accordion__closeIcon" />
        ) : (
          <SvgIcon iconName="plus" className="Accordion__openIcon" />
        )}
      </Button>
      <Collapse id={title} isOpen={model.isOpen} className="Accordion__collapseBox">
        {content}
      </Collapse>
    </div>
  );
});
