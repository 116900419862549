import { TestStructureDto } from "../../types/courses/dto/TestStructureDto";
import { ThemeStructure } from "./ThemeStructure";

export class TestStructure {
  get id() {
    return this.dto.id;
  }

  get title() {
    return this.dto.title;
  }

  get slug() {
    return this.dto.slug;
  }

  get tasksCount() {
    return this.dto.tasksCount;
  }

  get image() {
    return this.dto.image;
  }

  get hasAccess() {
    return this.dto.hasAccess;
  }

  get description() {
    return this.dto.description;
  }

  get subtitle() {
    return this.dto.subtitle;
  }

  get color() {
    return this.themeStructure.themeColor;
  }

  constructor(public themeStructure: ThemeStructure, private dto: TestStructureDto) {}
}
