import classNames from "classnames";
import React from "react";
import { CommandButton } from "../../../../shared/components/button/Button";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import articleLoginSectionUrl from "../../../../static/images/people-with-dog.png";
import { ArticleActionSectionDto } from "../../../../types/articles/dto/ArticleActionSectionDto";
import { ArticleLoginSectionViewModel } from "./ArticleLoginSectionViewModel";

interface ArticleLoginSectionProps {
  model: ArticleLoginSectionViewModel;
  section?: ArticleActionSectionDto;
}

export const ArticleLoginSection: React.FC<ArticleLoginSectionProps> = props => {
  const { model, section } = props;
  const { handleLogin } = model;
  const translations = useTranslations();

  return (
    <div className="ArticleLoginSection">
      <div className="ArticleLoginSection__wrapper">
        <div
          className={classNames("ArticleLoginSection__content", {
            "ArticleLoginSection__content--wide": !section?.image,
          })}
        >
          <Title level={2} className="ArticleLoginSection__title">
            {section?.title || translations.tryForFreeNow}
          </Title>
          <p className="ArticleLoginSection__description">
            {section?.description || translations.explorePlatformDescription}
          </p>
          <CommandButton command={handleLogin} className="ArticleLoginSection__action">
            {translations.logInAndTry}
            <SvgIcon iconName="right-arrow-line" />
          </CommandButton>
        </div>
        <Image.Container
          aspectRatio="3/2"
          className={classNames("ArticleLoginSection__imageWrapper", {
            "ArticleLoginSection__imageWrapper--default": !section?.image,
          })}
        >
          {section?.image ? <SanityImage {...section.image} /> : <Image url={articleLoginSectionUrl} />}
        </Image.Container>
      </div>
    </div>
  );
};
