import uniqueId from "lodash/uniqueId";
import React from "react";
import { SectionIconBlockDto } from "../../../../types/sections/dto/SectionIconBlockDto";
import { List } from "../../list/List";
import { SectionIconBlock } from "../SectionIconBlock/SectionIconBlock";

export interface SectionIconListBlockProps {
  sectionIcons: SectionIconBlockDto[];
}

export const SectionIconListBlock: React.FC<SectionIconListBlockProps> = props => {
  const sectionIconListBlockId = React.useRef(uniqueId());
  const { sectionIcons } = props;
  const listItems = sectionIcons.map((sectionIcon, index) => ({
    id: `${sectionIconListBlockId.current}-${index}`,
    ...sectionIcon,
  }));
  return (
    <div className="SectionIconListBlock">
      <List
        className="SectionIconListBlock__iconList"
        items={listItems}
        renderItem={item => <SectionIconBlock {...item} />}
      />
    </div>
  );
};
