import * as React from "react";
import { DetailedProgressBar } from "../../../../shared/components/detailedProgressBar/DetailedProgressBar";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { useThemeProgressTexts } from "./shared/useThemeProgressTexts";

export interface ThemeProgressProps {
  stepsCount: number;
  completedStepsCount: number;
}

export const ThemeProgress: React.FC<ThemeProgressProps> = props => {
  const { stepsCount, completedStepsCount } = props;
  const translations = useTranslations();
  const texts = useThemeProgressTexts(stepsCount, completedStepsCount);

  return (
    <div className="ThemeProgress">
      <div className="ThemeProgress__info">
        <div className="ThemeProgress__messages">
          <h2 className="ThemeProgress__mainMessage">{texts.mainMessage}</h2>
          <p className="ThemeProgress__description">{texts.description}</p>
        </div>
        <DetailedProgressBar
          className="ThemeProgress__detailedProgressBar"
          completedStepsCount={completedStepsCount}
          totalStepsCount={stepsCount}
          progressInfo={translations.stepsProgressInfo}
          variant="dark"
          type="warning"
        />
      </div>
    </div>
  );
};
