import React from "react";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";

interface ArticleSummaryProps {
  summary: string[];
}

export const ArticleSummary: React.FC<ArticleSummaryProps> = props => {
  const { summary } = props;
  const translations = useTranslations();

  return (
    <div className="ArticleSummary">
      <Title level={2} className="ArticleSummary__title">
        {translations.summary}
      </Title>
      <ul className="ArticleSummary__list">
        {summary.map(summaryItem => (
          <li className="ArticleSummary__listItem" key={summaryItem}>
            {summaryItem}
          </li>
        ))}
      </ul>
    </div>
  );
};
