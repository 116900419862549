import * as React from "react";
import YouTube from "react-youtube";

interface YoutubePlayerProps {
  videoUrl: string;
}

export const getYoutubeId = (videoUrl: string) => {
  // eslint-disable-next-line no-useless-escape
  const youtubeMatch = videoUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/);

  if (!youtubeMatch) {
    return;
  }

  return youtubeMatch[1];
};

export const YoutubePlayer = ({ videoUrl }: YoutubePlayerProps) => {
  const youtubeId = getYoutubeId(videoUrl);

  return <YouTube containerClassName="YoutubePlayer__container" className="YoutubePlayer" videoId={youtubeId} />;
};
