import { ISearchThumbnail } from "../../../../pages/search/searchItem/SearchItemViewModel";
import { getFormattedTime } from "../../../utils";

export const useVideoData = (thumbnail: ISearchThumbnail) => {
  if (thumbnail.type !== "Video") {
    return;
  }

  const totalSeconds = thumbnail.videoDetails?.duration.totalSeconds;
  return {
    timeStamp: totalSeconds !== undefined ? getFormattedTime(totalSeconds) : undefined,
    progress: thumbnail.progress,
  };
};
