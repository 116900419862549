/* eslint-disable class-methods-use-this */
import TagManager from "react-gtm-module";
import { getVimeoId } from "../utils";
import { ILowLevelNavigationService } from "./NavigationService";

export interface IGtmService {
  pushCookieConsentChanged(): void;
  pushPageViewed(): void;
  pushLoginStarted(loginLabel: string): void;
  pushBuyStarted(): void;
  pushVideoEnded(stepId: string, stepTitle: string, vimeoUrl: string): void;
  pushSoundCloudEnded(stepId: string, stepTitle: string, soundCloudUrl: string): void;
  pushUserStatusChanged(isAuthenticated: boolean): void;
}

interface DataLayerModel {
  event: string;
  eventAction?: string;
  eventLabel?: string;
  params?: GoogleTagManagerProperties;
}

interface GoogleTagManagerProperties {
  [key: string]: string | number | boolean | GoogleTagManagerProperties | null;
}

export class GtmService implements IGtmService {
  constructor(private navigation: ILowLevelNavigationService) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const containerId = (window as any).googleTagManagerContainerId;
    if (containerId) {
      TagManager.initialize({ gtmId: containerId });
    }
  }

  private push = (data: DataLayerModel) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { dataLayer } = window as any;
    dataLayer && dataLayer.push(data);
  };

  pushCookieConsentChanged() {
    this.push({ event: "cookieConsentChanged" });
  }

  pushPageViewed(): void {
    const location = this.navigation.getCurrentLocation();
    this.push({
      event: "Riktig - Viewed Page",
      eventAction: "Viewed Page",
      params: { path: location },
    });
  }

  pushLoginStarted(loginLabel: string) {
    this.push({ event: "Riktig - Login", eventAction: "Login", eventLabel: loginLabel });
  }

  pushBuyStarted() {
    this.push({ event: "Riktig - Buy", eventAction: "Buy", eventLabel: "Initiated Buying Process" });
  }

  pushSoundCloudEnded(stepId: string, stepTitle: string, soundCloudUrl: string) {
    const params = {
      id: stepId,
      title: stepTitle,
      soundCloudUrl,
    };
    this.push({
      event: "Riktig - Sound Cloud Ended",
      eventAction: "Sound Cloud Ended",
      params,
    });
  }

  pushVideoEnded(stepId: string, stepTitle: string, vimeoUrl: string) {
    const params = {
      id: stepId,
      title: stepTitle,
      vimeoId: getVimeoId(vimeoUrl),
    };
    this.push({
      event: "Riktig - Video Ended",
      eventAction: "Video Ended",
      params,
    });
  }

  pushUserStatusChanged(isAuthenticated: boolean) {
    this.push({
      event: "Riktig - Changed User Status",
      eventAction: "Changed Status Changed",
      params: { isAuthenticated },
    });
  }
}
