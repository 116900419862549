import { computed } from "mobx";
import { ContentReference } from "../../../models/Url";
import { IProgressStore } from "../../../stores/ProgressStore";
import { BookmarkToggleViewModel } from "../shared/BookmarkToggleViewModel";

export interface ITextBlockThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  description?: string;
  color?: string;
  isCompleted: boolean;
  hasAccess: boolean;
  isHighlighted?: boolean;
  category?: string;
  type: "TextBlock";
  bookmarkToggleViewModel?: BookmarkToggleViewModel;
  slug: string;
}

export class TextBlockThumbnailViewModel implements ITextBlockThumbnail {
  protected progressStore: IProgressStore;

  id: string;

  slug: string;

  contentRef: ContentReference;

  title: string;

  description?: string;

  readonly type = "TextBlock";

  color?: string;

  hasAccess: boolean;

  isHighlighted?: boolean;

  @computed get isCompleted() {
    return this.progressStore.isCompleted(this.id);
  }

  constructor(
    stepThumbnail: {
      id: string;
      title: string;
      color?: string;
      description?: string;
      hasAccess: boolean;
      slug: string;
    },
    progressStore: IProgressStore,
    public bookmarkToggleViewModel?: BookmarkToggleViewModel,
    isHighlighted?: boolean
  ) {
    this.progressStore = progressStore;
    this.id = stepThumbnail.id;
    this.contentRef = stepThumbnail;
    this.title = stepThumbnail.title;
    this.color = stepThumbnail.color;
    this.hasAccess = stepThumbnail.hasAccess;
    this.isHighlighted = isHighlighted;
    this.description = stepThumbnail.description;
    this.slug = stepThumbnail.slug;
  }
}
