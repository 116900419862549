import { action, computed, observable } from "mobx";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { TestChoiceCollectionDto } from "../../../../types/tasks/testchoices/TestChoiceCollectionDto";
import { TestChoiceDto } from "../../../../types/tasks/testchoices/TestChoiceDto";
import { AnswersState } from "../AnswersState";
import { InteractiveTask } from "../InteractiveTask";
import { OptionWithStatus } from "./OptionWithStatus";

export type BaseOptionType = {
  id: string;
};

export type TestBaseType<TOption> = {
  id: string;
  choices: TOption[];
};

export class ChoiceTaskViewModel implements InteractiveTask {
  @observable id: string;

  @observable optionsWithStatus: OptionWithStatus<TestChoiceDto>[];

  @observable isDisabled = false;

  @computed get answersState(): AnswersState {
    if (this.optionsWithStatus.some(opt => opt.state === "incorrect")) {
      return "wrong";
    }

    if (this.optionsWithStatus.some(opt => opt.state === "shouldBeChecked")) {
      return "partiallyCorrect";
    }

    if (this.optionsWithStatus.some(opt => opt.state === "correct")) {
      return "correct";
    }

    return "default";
  }

  @computed get isAnswered() {
    return this.selectedChoiceIds.length > 0;
  }

  @computed get isSubmitted() {
    return this.answersState !== "default";
  }

  @observable selectedChoiceIds: string[] = [];

  get isMultipleChoice() {
    return this.test.allowMultipleAnswers;
  }

  constructor(
    private test: TestChoiceCollectionDto,
    public title: string,
    public readonly content: PortableTextDto,
    public description?: string
  ) {
    this.optionsWithStatus = test.choices.map(choice => new OptionWithStatus(choice));
    this.id = test.id;
  }

  @action.bound
  selectOptions(newChoicesIds: string[]) {
    this.optionsWithStatus.forEach(opt => opt.reset());
    this.selectedChoiceIds = newChoicesIds;
  }

  @action.bound
  showCorrectAnswer() {
    const correctAnswers = this.optionsWithStatus.filter(opt => opt.option.isCorrect);
    correctAnswers.forEach(opt => opt.markAsCorrect());
    this.selectedChoiceIds = correctAnswers.map(opt => opt.id);
  }

  @action.bound
  submit() {
    if (!this.isAnswered) {
      return;
    }

    this.optionsWithStatus.forEach(optionWithStatus => {
      const { isCorrect } = optionWithStatus.option;
      if (this.selectedChoiceIds.includes(optionWithStatus.id)) {
        isCorrect ? optionWithStatus.markAsCorrect() : optionWithStatus.markAsIncorrect();
        return;
      }

      if (!this.selectedChoiceIds.includes(optionWithStatus.id) && optionWithStatus.state === "default") {
        isCorrect && optionWithStatus.markAsShouldBeChecked();
      }
    });
  }

  @action.bound
  reset() {
    this.optionsWithStatus.forEach(answer => {
      answer.reset();
    });

    this.selectedChoiceIds = [];
    this.isDisabled = false;
  }
}
