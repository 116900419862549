import { action, computed, observable } from "mobx";
import { mapToTask } from "../../shared/api/StepService";
import { TaskFactory } from "../../shared/components/tasks/TaskFactory";
import { ActiveCourseParams } from "../../shared/models/Url";
import { IBookmarkStore } from "../../shared/stores/BookmarkStore";
import { TestDto } from "../../types/test/dto/TestDto";
import { TestApi } from "./TestApi";
import { TestResultCalculator } from "./TestResultCalculator";
import { TestTask } from "./shared/testTask/TestTask";

class Test {
  private taskById = observable.map<string, TestTask>();

  private testResultCalculator: TestResultCalculator;

  get title() {
    return this.testDto.title;
  }

  get subtitle() {
    return this.testDto.subtitle;
  }

  get content() {
    return this.testDto.content;
  }

  get shouldTaskTitlesBeDisplayed() {
    return this.testDto.shouldTaskTitlesBeDisplayed;
  }

  @computed get tasks() {
    return Array.from(this.taskById.values());
  }

  constructor(
    activeCourseParams: ActiveCourseParams,
    private id: string,
    private courseId: string,
    private testDto: TestDto,
    public testStore: TestStore,
    taskFactory: TaskFactory,
    bookmarkStore: IBookmarkStore
  ) {
    testDto.tasks
      .map(task => new TestTask(activeCourseParams, taskFactory, mapToTask(task), bookmarkStore))
      .forEach(task => this.taskById.set(task.model.id, task));

    this.testResultCalculator = new TestResultCalculator(testDto.passThreshold, testDto.results);
  }

  @action
  async submit(themeId: string) {
    if (!this.id) {
      throw new Error("Cannot submit unloaded test");
    }

    this.tasks.forEach(_task => {
      const task = _task;
      task.submit();
      task.disable();
    });
    const correctTasks = this.tasks.filter(task => task.model.answersState === "correct").length;
    const totalTasks = this.taskById.size;

    const result = this.testResultCalculator.calculate(correctTasks, totalTasks);

    await this.testStore.testApi.submit({
      testId: this.id,
      correctAnswers: correctTasks,
      totalQuestions: totalTasks,
      courseId: this.courseId,
      themeId,
      isPassed: result.hasPassed,
    });

    return result;
  }

  @action
  reset() {
    this.tasks.forEach(task => task.model.reset());
  }
}

export class TestStore {
  @observable test?: Test;

  constructor(
    private activeCourseParams: ActiveCourseParams,
    public testApi: TestApi,
    private taskFactory: TaskFactory,
    private bookmarkStore: IBookmarkStore
  ) {}

  @action
  async loadTest(testId: string, courseId: string) {
    const testDto = await this.testApi.get(testId, courseId);
    this.test = new Test(
      this.activeCourseParams,
      testId,
      courseId,
      testDto,
      this,
      this.taskFactory,
      this.bookmarkStore
    );
  }
}
