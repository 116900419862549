import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { useUrl } from "../../../../useRouter";
import { StepStructure } from "../../../contentStructure/StepStructure";
import { ThemeStructure } from "../../../contentStructure/ThemeStructure";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../typeUtils";
import { DetailedProgressBar, DetailedProgressBarProps } from "../../detailedProgressBar/DetailedProgressBar";
import { SanityImage } from "../../image/Image";
import { Link } from "../../link/Link";
import { List } from "../../list/List";
import { StatusTag } from "../../statusTag/StatusTag";
import { Title } from "../../title/Title";
import { ThemeContentByType } from "../themeContentByType/ThemeContentByType";
import { formatThemeNumber } from "../utils";
import { CardTag, ThemeStatus } from "./CardThemeBasicViewModel";

interface CardProps {
  themeStructure: ThemeStructure;
  className?: string;
  status: ThemeStatus;
  firstUnfinishedStep?: StepStructure;
  tags?: CardTag[];
}

export type CardThemeBasicProps = CardProps & Pick<DetailedProgressBarProps, "totalStepsCount" | "completedStepsCount">;

export const CardThemeBasic: React.FC<CardThemeBasicProps> = ({
  themeStructure,
  className,
  status,
  totalStepsCount,
  completedStepsCount,
  firstUnfinishedStep,
  tags,
}) => {
  const url = useUrl();
  const id = uniqueId("CardThemeBasic__");
  const translations = useTranslations();

  const renderStatusContent = () => {
    switch (status) {
      case "notStarted":
        return (
          <Link
            className="CardThemeBasic__link"
            type="button"
            buttonVariant="secondary"
            to={url.toThemePage(themeStructure.courseStructure.urlParams, themeStructure.slug)}
            aria-describedby={id}
            tabIndex={-1}
          >
            {translations.start}
          </Link>
        );
      case "inProgress":
        return (
          <div className="CardThemeBasic__actionsWrapper">
            <DetailedProgressBar
              totalStepsCount={totalStepsCount}
              completedStepsCount={completedStepsCount}
              progressInfo={translations.progressInfo}
              variant="dark"
            />
            {firstUnfinishedStep && (
              <Link
                className="CardThemeBasic__link"
                type="button"
                buttonVariant="secondary"
                to={url.toLessonPage(themeStructure.courseStructure.urlParams, firstUnfinishedStep)}
              >
                {translations.progressContinueButton}
              </Link>
            )}
          </div>
        );
      case "finished":
        return (
          <DetailedProgressBar
            totalStepsCount={totalStepsCount}
            completedStepsCount={completedStepsCount}
            progressInfo={translations.progressInfo}
            variant="dark"
          />
        );
      case "underDevelopment":
        return null;
      default:
        return assertUnreachable(status);
    }
  };

  return (
    <div
      className={classNames(
        "CardThemeBasic",
        { "CardThemeBasic--underDevelopment": status === "underDevelopment" },
        className
      )}
    >
      {status !== "underDevelopment" && (
        <Link
          className="CardThemeBasic__linkOverlay"
          type="clear"
          to={url.toThemePage(themeStructure.courseStructure.urlParams, themeStructure.slug)}
        >
          <span className="visuallyHidden">{themeStructure.title}</span>
        </Link>
      )}
      <div className="CardThemeBasic__content">
        <div className="CardThemeBasic__header">
          <div>
            {!!themeStructure.orderNumber && (
              <div className="CardThemeBasic__cardNumber">{formatThemeNumber(themeStructure.orderNumber)}</div>
            )}
            {tags && (
              <List
                items={tags}
                renderItem={tag => <StatusTag status={tag.status}>{translations[tag.translation]}</StatusTag>}
                className="CardThemeBasic__tagsList"
                singleItemClassName="CardThemeBasic__tagsListItem"
              />
            )}
          </div>
          <div className="CardThemeBasic__imageWrapper">
            {themeStructure.image && <SanityImage {...themeStructure.image} className="CardThemeBasic__image" />}
          </div>
        </div>
        <Title level={3} className="CardThemeBasic__title">
          {themeStructure.title}
        </Title>
        <div className="CardThemeBasic__themeContent">
          <ThemeContentByType content={themeStructure.stepsByType} />
        </div>
      </div>
      <div className="CardThemeBasic__statusContent">{renderStatusContent()}</div>
    </div>
  );
};
