import * as React from "react";
import { Button } from "../../../../../../shared/components/button/Button";
import { SvgIcon } from "../../../../../../shared/components/svgIcon/SvgIcon";
import { TimeSpan } from "../../../../../../shared/models/TimeSpan";
import { smoothScrollToSection, getFormattedTime } from "../../../../../../shared/utils";
import { headingId } from "../../../lessonHeading/LessonHeading";
import { Anchor } from "../Video";

export interface AnchorsProps {
  anchors: Anchor[];
  moveVideo: (secondsSinceBeginning: TimeSpan) => void;
}

export const Anchors: React.FC<AnchorsProps> = ({ anchors, moveVideo }) => {
  const moveVideoAndScroll = (totalSeconds: number) => {
    moveVideo(totalSeconds);
    smoothScrollToSection(headingId);
  };

  return (
    <div className="Anchors">
      <ul className="Anchors__list">
        {anchors.map(anchor => (
          <li key={`${anchor.title} ${anchor.index}`}>
            <Button type="clear" onClick={() => moveVideoAndScroll(anchor.startTime)}>
              <div className="Anchor__box">
                <SvgIcon iconName="bookmark-pin" className="Anchors__anchorIcon" />
                <time className="Anchors__anchorTimeStamp">{getFormattedTime(anchor.startTime)}</time>
              </div>
              <p className="Anchors__anchorTitle">{anchor.title}</p>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};
