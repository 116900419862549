import { observer } from "mobx-react";
import * as React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { SanityImage } from "../../image/Image";
import { SvgIcon } from "../../svgIcon/SvgIcon";

export interface SectionQuoteBlockProps {
  id: string;
  quote: string;
  author?: string;
  primaryImage: ImageDto;
}
export const SectionQuoteBlock = observer(({ quote, author, primaryImage }: SectionQuoteBlockProps) => {
  return (
    <div className="SectionQuoteBlockBase">
      <div className="SectionQuoteBlockBase__inner">
        <div className="SectionQuoteBlockBase__imageContainer">
          <div className="SectionQuoteBlockBase__authorContainer">
            <SanityImage {...primaryImage} />
            <div className="SectionQuoteBlockBase__apostropheContainer">
              <SvgIcon iconName="apostrophe" />
            </div>
          </div>
        </div>
        <div className="SectionQuoteBlockBase__quote">
          <blockquote>{quote}</blockquote>
          <span className="SectionQuoteBlockBase__author">{author}</span>
        </div>
      </div>
    </div>
  );
});
