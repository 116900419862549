import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { CookiesConsent } from "../layout/cookiesConsent/CookiesConsent";
import { FooterView } from "../layout/footer/FooterView";
import { HeaderView } from "../layout/header/HeaderView";
import { SkipNavigationLink } from "../layout/skipNavigationLink/SkipNavigationLink";
import { useTranslations } from "../shared/contexts/StaticTranslationContext";
import { useNotificationService } from "../shared/contexts/notification/NotificationContext";
import { App, DisplayedContent } from "./App";
import { ErrorView } from "./error/ErrorView";
import { ProductNestView } from "./productNest/ProductNestView";

export const AppView = observer(({ model }: { model: App }) => {
  const translations = useTranslations();
  const notificationService = useNotificationService();

  React.useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, [model.childPage]);

  React.useEffect(() => {
    if (model.errorToastCounter) {
      notificationService.showNotification(translations.toastErrorMessage, "error", { autoClose: 2000 });
    }
  }, [model.errorToastCounter]);

  const { content, cookiesConsent } = model;
  const stickyHeaderRef = React.useRef<HTMLDivElement>(null);

  const renderContent = (c: DisplayedContent) => {
    // eslint-disable-next-line default-case, no-underscore-dangle
    switch (c._type) {
      case "Page":
        return <ContentView content={c.page} />;
      case "Error":
        return <ErrorView error={c.error} />;
      case "ProductNest":
        return <ProductNestView model={c.vm} />;
    }
  };

  return (
    <div>
      <SkipNavigationLink to="#mainContent" />
      <CookiesConsent model={cookiesConsent} />
      <HeaderView model={model.header} stickyHeaderRef={stickyHeaderRef} />
      <main id="mainContent">
        <div id="stickyHeader" ref={stickyHeaderRef} />
        {content && renderContent(content)}
      </main>
      <FooterView model={model.footer} />
    </div>
  );
});
