import { action, computed, observable } from "mobx";
import { BackendApi } from "../../../shared/api/BackendApi";
import { Popup } from "../../../shared/components/popup/Popup";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { ArticleDto } from "../../../types/articles/dto/ArticleDto";
import { AuthorDto } from "../../../types/shared/dto/AuthorDto";
import { GtmBasePage } from "../../GtmBasePage";
import { AuthorInfo } from "../../authorsForProduct/authorInfo/AuthorInfo";
import { ArticleBuySectionViewModel } from "./articleBuySection/ArticleBuySectionViewModel";
import { ArticleLoginSectionViewModel } from "./articleLoginSection/ArticleLoginSectionViewModel";

export class ArticleViewModel extends GtmBasePage {
  @observable authorInfoPopup = new Popup();

  @observable article?: ArticleDto;

  @observable private selectedAuthor?: AuthorDto;

  @computed get authorInfo() {
    if (!this.selectedAuthor) {
      return;
    }

    return new AuthorInfo(this.backendApi, this.selectedAuthor);
  }

  constructor(
    public articleSlug: string,
    public languageService: ILanguageService,
    public errorService: IErrorService,
    private backendApi: BackendApi,
    public navigationService: ILowLevelNavigationService,
    gtm: IGtmService,
    public actionSection?: ArticleLoginSectionViewModel | ArticleBuySectionViewModel
  ) {
    super(languageService, gtm, errorService);
  }

  protected async loadData() {
    this.article = await this.backendApi.getArticle({ slug: this.articleSlug });
    this.isLoading = false;
  }

  @action.bound
  setSelectedAuthor(author: AuthorDto) {
    this.selectedAuthor = author;
    this.authorInfoPopup.show();
  }
}
