import * as React from "react";
import { Link, HrefType } from "../../shared/components/link/Link";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";

interface SkipNavigationLinkProps {
  to: string;
}

export const SkipNavigationLink: React.FC<SkipNavigationLinkProps> = ({ to }) => {
  const translations = useTranslations();

  return (
    <Link to={to} type="button" className="SkipNavigationLink" hrefType={HrefType.Anchor}>
      {translations.goToContent}
    </Link>
  );
};
