import classNames from "classnames";
import React from "react";
import { animated, config, useSpring } from "react-spring";
import { ReactSVG } from "react-svg";
import { MessageBoxIcon } from "../../../../../../shared/components/messageBoxIcon/MessageBoxIcon";
import useSessionStorage from "../../../../../../shared/hooks/useSessionStorage";
import hintToggleDogImage from "../../../../../../static/images/iconsAnimated/hint-dog.svg";

interface HintToggleImageProps {
  isHintOpen: boolean;
}

export const HintToggleImage: React.FC<HintToggleImageProps> = props => {
  const { isHintOpen } = props;
  const [isHintMessageVisible, setIsHintMessageVisible] = useSessionStorage("is-hint-message-visible", true);
  const [isHintImageLowered, setIsHintImageLowered] = useSessionStorage("is-hint-image-lowered", false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!isHintImageLowered) {
        setIsHintImageLowered(true);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    if (isHintOpen && isHintMessageVisible) {
      setIsHintMessageVisible(false);
    }
  }, [isHintOpen]);

  const hintToggleStyles = useSpring({
    from: { transform: "translateY(100%)" },
    transform: isHintImageLowered ? "translateY(20%)" : "translateY(0)",
    config: config.stiff,
  });

  return (
    <animated.div
      className={classNames("HintToggleImage", {
        "HintToggleImage--hiddenMessage": !isHintMessageVisible,
      })}
      style={hintToggleStyles}
    >
      <div className="HintToggleImage__iconWrapper HintToggleImage__iconWrapper--message">
        <MessageBoxIcon messageText="Klikk på hunden for hint" />
      </div>
      <div className="HintToggleImage__iconWrapper">
        <ReactSVG src={hintToggleDogImage} />
      </div>
    </animated.div>
  );
};
