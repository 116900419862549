import { observable } from "mobx";
import { IVideoService } from "../../../shared/api/BackendApi";
import {
  ArticleThumbnailViewModel,
  IArticleThumbnail,
} from "../../../shared/components/thumbnails/searchThumbnail/articleThumbnail/ArticleThumbnailViewModel";
import {
  ITestThumbnail,
  TestThumbnailViewModel,
} from "../../../shared/components/thumbnails/searchThumbnail/testThumbnail/TestThumbnailViewModel";
import {
  IThemeThumbnail,
  ThemeThumbnailViewModel,
} from "../../../shared/components/thumbnails/searchThumbnail/themeThumbnail/ThemeThumbnailViewModel";
import { BookmarkToggleViewModel } from "../../../shared/components/thumbnails/shared/BookmarkToggleViewModel";
import {
  ITaskThumbnail,
  TaskThumbnailViewModel,
} from "../../../shared/components/thumbnails/stepThumbnail/TaskThumbnailViewModel";
import {
  ITextBlockThumbnail,
  TextBlockThumbnailViewModel,
} from "../../../shared/components/thumbnails/stepThumbnail/TextBlockThumbnailViewModel";
import {
  IVideoThumbnail,
  VideoThumbnailViewModel,
} from "../../../shared/components/thumbnails/stepThumbnail/VideoThumbnailViewModel";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { UserStore } from "../../../shared/stores/UserStore";
import { assertUnreachable } from "../../../shared/typeUtils";
import { SearchContentTypeDto } from "../../../types/search/dto/SearchContentTypeDto";
import { SearchResultItemDto } from "../../../types/search/dto/SearchResultItemDto";
import { StepThumbnailTypeDto } from "../../../types/shared/dto/StepThumbnailTypeDto";
import { PaymentSidePanelViewModel } from "../../shared/paymentDialog/PaymentSidePanelViewModel";

export type ISearchThumbnail =
  | IVideoThumbnail
  | ITaskThumbnail
  | ITextBlockThumbnail
  | IArticleThumbnail
  | IThemeThumbnail
  | ITestThumbnail;

// this should be removed
export const searchTaskTypeToStepThumbnailType = (
  searchType:
    | SearchContentTypeDto.ChoiceTask
    | SearchContentTypeDto.ChoiceListTask
    | SearchContentTypeDto.TextTask
    | SearchContentTypeDto.TaskGroup
    | SearchContentTypeDto.MaskTask
    | SearchContentTypeDto.InputTask
    | SearchContentTypeDto.GapTask
    | SearchContentTypeDto.MatchTask
) => {
  switch (searchType) {
    case SearchContentTypeDto.ChoiceTask:
      return StepThumbnailTypeDto.ChoiceTask;
    case SearchContentTypeDto.ChoiceListTask:
      return StepThumbnailTypeDto.ChoiceListTask;
    case SearchContentTypeDto.TextTask:
      return StepThumbnailTypeDto.TextTask;
    case SearchContentTypeDto.TaskGroup:
      return StepThumbnailTypeDto.TaskGroup;
    case SearchContentTypeDto.MaskTask:
      return StepThumbnailTypeDto.MaskTask;
    case SearchContentTypeDto.InputTask:
      return StepThumbnailTypeDto.InputTask;
    case SearchContentTypeDto.GapTask:
      return StepThumbnailTypeDto.GapTask;
    case SearchContentTypeDto.MatchTask:
      return StepThumbnailTypeDto.MatchTask;
    default: {
      return assertUnreachable(searchType);
    }
  }
};

export class SearchItemViewModel {
  @observable thumbnail: ISearchThumbnail;

  @observable id: string;

  constructor(
    public activeCourseStructure: CourseStructure,
    public searchResultItemDto: SearchResultItemDto,
    progressStore: IProgressStore,
    videoService: IVideoService,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    bookmarkStore: BookmarkStore,
    userStore: UserStore
  ) {
    this.thumbnail = this.createSearchThumbnailViewModel(progressStore, videoService, bookmarkStore, userStore);
    this.id = searchResultItemDto.id;
  }

  private createSearchThumbnailViewModel(
    progressStore: IProgressStore,
    videoService: IVideoService,
    bookmarkStore: BookmarkStore,
    userStore: UserStore
  ) {
    switch (this.searchResultItemDto.type) {
      case SearchContentTypeDto.Video:
        return new VideoThumbnailViewModel(
          { ...this.searchResultItemDto, type: StepThumbnailTypeDto.Video },
          progressStore,
          videoService,
          new BookmarkToggleViewModel(this.searchResultItemDto.id, bookmarkStore, userStore)
        );
      case SearchContentTypeDto.ChoiceTask:
      case SearchContentTypeDto.ChoiceListTask:
      case SearchContentTypeDto.TextTask:
      case SearchContentTypeDto.TaskGroup:
      case SearchContentTypeDto.MaskTask:
      case SearchContentTypeDto.InputTask:
      case SearchContentTypeDto.GapTask:
      case SearchContentTypeDto.MatchTask:
        return new TaskThumbnailViewModel(
          { ...this.searchResultItemDto, type: searchTaskTypeToStepThumbnailType(this.searchResultItemDto.type) },
          progressStore,
          new BookmarkToggleViewModel(this.searchResultItemDto.id, bookmarkStore, userStore)
        );
      case SearchContentTypeDto.Article:
        return new ArticleThumbnailViewModel({ ...this.searchResultItemDto });
      case SearchContentTypeDto.TextBlock:
        return new TextBlockThumbnailViewModel({ ...this.searchResultItemDto }, progressStore);
      case SearchContentTypeDto.Theme:
        return new ThemeThumbnailViewModel({ ...this.searchResultItemDto });
      case SearchContentTypeDto.Test:
        return new TestThumbnailViewModel({ ...this.searchResultItemDto });

      default:
        return assertUnreachable(this.searchResultItemDto.type);
    }
  }
}
