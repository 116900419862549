import React from "react";
import { Button } from "../../../../../../shared/components/button/Button";
import { AnswersState } from "../../../../../../shared/components/tasks/AnswersState";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
interface StateButtonProps {
  answersState: AnswersState;
  disabled: boolean;
  onReset: () => void;
  onSubmit?: () => void;
  className?: string;
}

export const StateButton: React.FC<StateButtonProps> = ({ answersState, disabled, onReset, onSubmit, className }) => {
  const translations = useTranslations();

  if (answersState === "default") {
    return (
      <Button htmltype="submit" className={className} onClick={onSubmit} disabled={disabled}>
        {translations.checkAnswer}
      </Button>
    );
  }

  return (
    <Button htmltype="reset" className={className} onClick={onReset}>
      {translations.reset}
    </Button>
  );
};
