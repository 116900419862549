import { IReactionDisposer, reaction, observable } from "mobx";
import { Page } from "react-mvvm";
import { ILanguageService } from "../shared/services/LanguageService";

export abstract class TranslatedPage<TDialog = never> extends Page<TDialog> {
  subscription?: IReactionDisposer;

  @observable public languageService: ILanguageService;

  constructor(languageService: ILanguageService) {
    super();
    this.languageService = languageService;
    this.registerLanguageChangeHandle();
  }

  abstract onLanguageChange(): Promise<void>;

  registerLanguageChangeHandle() {
    this.subscription = reaction(
      () => this.languageService.lang,
      () => this.onLanguageChange()
    );
  }

  async onDeactivated() {
    this.subscription && this.subscription();
  }
}
