import { action, computed, observable } from "mobx";
import stringSimilarity from "string-similarity";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { Percentage } from "../../../formatProgress";
import { assertUnreachable } from "../../../typeUtils";
import { ExactAnswersState } from "../AnswersState";
import { InteractiveTask } from "../InteractiveTask";

export type InputTaskInfo =
  | { _type: "correct" }
  | { _type: "almostCorrect"; closestCorrectAnswer: string }
  | { _type: "wrong"; firstCorrectAnswer: string };

export class InputTaskViewModel implements InteractiveTask {
  @observable value = "";

  @observable correctAnswers: string[];

  @observable answersState: ExactAnswersState = "default";

  @observable isDisabled = false;

  @computed get isAnswered() {
    return !!this.value;
  }

  @computed get isSubmitted() {
    return this.answersState === "correct" || this.answersState === "wrong";
  }

  @computed private get bestMatch() {
    const answer = this.ignoreCapitalLetters ? this.value.toLowerCase() : this.value;
    const result = stringSimilarity.findBestMatch(answer, this.correctAnswers);
    return result.bestMatch;
  }

  @computed get info(): InputTaskInfo | undefined {
    switch (this.answersState) {
      case "default":
        return;
      case "wrong":
        const [firstAnswer] = this.correctAnswers;
        return { _type: "wrong", firstCorrectAnswer: firstAnswer };
      case "correct": {
        if (this.bestMatch.rating === 1) {
          return { _type: "correct" };
        }

        return { _type: "almostCorrect", closestCorrectAnswer: this.bestMatch.target };
      }
      default:
        return assertUnreachable(this.answersState);
    }
  }

  @action.bound
  setValue(value: string) {
    this.value = value;
  }

  @action.bound
  submit() {
    this.answersState = this.isAnswerCorrect(this.bestMatch.rating) ? "correct" : "wrong";
  }

  @action.bound
  reset() {
    this.answersState = "default";
    this.value = "";
    this.isDisabled = false;
  }

  @action.bound
  showCorrectAnswer() {
    const [firstAnswer] = this.correctAnswers;
    this.value = this.isAnswerCorrect(this.bestMatch.rating) ? this.bestMatch.target : firstAnswer;
    this.answersState = "correct";
  }

  private isAnswerCorrect(rating: number) {
    return rating * 100 >= this.similarityThreshold;
  }

  constructor(
    public id: string,
    public title: string,
    private similarityThreshold: Percentage,
    private ignoreCapitalLetters: boolean,
    public useMathInput: boolean,
    correctAnswers: string[],
    public readonly content: PortableTextDto,
    public description?: string
  ) {
    this.correctAnswers = this.ignoreCapitalLetters
      ? correctAnswers.map(correctAnswer => correctAnswer.toLowerCase())
      : correctAnswers;
  }
}
