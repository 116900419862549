import noop from "lodash/noop";
import { CourseProgress } from "../../progressStructure/CourseProgress";
import { asProxy } from "../../utils";
import { IProgressStore } from "../ProgressStore";

export class ProgressStoreMock implements IProgressStore {
  async init() {
    noop();
  }

  completedStepsIds = [];

  courseProgress = asProxy<CourseProgress>({});

  isCompleted() {
    return false;
  }

  getVideoProgress() {
    return undefined;
  }

  getSoundStepProgress() {
    return undefined;
  }

  lastUpdatedFrom() {
    return undefined;
  }

  nextUncompletedFrom() {
    return undefined;
  }

  async markStepAsCompleted() {
    noop();
  }

  async markStepAsUncompleted() {
    noop();
  }

  async updateStepWithTimeProgress() {
    noop();
  }

  async endStepWithTimeProgressContent() {
    noop();
  }
}
