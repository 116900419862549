import { observer } from "mobx-react";
import * as React from "react";
import { bindTo, property } from "react-mvvm";
import { CommandButton } from "../../../../../shared/components/button/Button";
import { DialogView } from "../../../../../shared/components/dialog/DialogView";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { Textarea } from "../../../../../shared/components/textarea/Textarea";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { AddBookmarkDialogViewModel } from "./AddBookmarkDialogViewModel";

interface AddBookmarkDialogProps {
  modal: AddBookmarkDialogViewModel;
  getReferenceElementToAttach: () => HTMLElement | null;
}

export const AddBookmarkDialog = observer(({ modal, getReferenceElementToAttach }: AddBookmarkDialogProps) => {
  const translations = useTranslations();

  const messageRef = React.useRef<HTMLTextAreaElement>(null);

  return (
    <DialogView
      placement="auto"
      referenceElement={getReferenceElementToAttach()}
      cancelCommand={modal.cancel}
      isVisible={modal.isVisible}
      onShow={() => messageRef.current?.focus()}
      className="AddBookmarkDialog"
      offset={[0, 12]}
    >
      <div className="AddBookmarkDialog__heading">
        <h3 className="AddBookmarkDialog__bookmarkTitle">{translations.addUserBookmark}</h3>
        <CommandButton
          className="AddBookmarkDialog__exitButton"
          type="circle"
          variant="tertiary"
          command={modal.cancel}
        >
          <SvgIcon iconName="close" alt={translations.close} />
        </CommandButton>
      </div>
      <Textarea
        ref={messageRef}
        className="AddBookmarkDialog__textArea"
        {...bindTo(property(modal, "message"))}
        placeholder={translations.addMessage}
      />
      <div className="AddBookmarkDialog__bookmarkAction">
        <CommandButton
          className="AddBookmarkDialog__cancelBookmarkButton"
          type="clear"
          size="small"
          command={modal.cancel}
        >
          {translations.cancel}
        </CommandButton>
        <CommandButton className="AddBookmarkDialog__addBookmarkButton" type="clear" size="small" command={modal.done}>
          {translations.save}
        </CommandButton>
      </div>
    </DialogView>
  );
});
