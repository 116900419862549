import debounce from "lodash/debounce";
import * as React from "react";
import { Input, InputProps } from "./Input";

type DebounceInputProps = InputProps & { debounceTimeMs: number };

export const DebounceInput: React.FC<DebounceInputProps> = props => {
  const { value, onChange, debounceTimeMs, ...rest } = props;

  const [tempValue, setTempValue] = React.useState(value);
  const debouncedFunc = React.useCallback(
    debounce(() => onChange(tempValue), debounceTimeMs),
    [tempValue]
  );

  React.useEffect(() => {
    debouncedFunc();
    return debouncedFunc.cancel;
  }, [debouncedFunc]);

  return <Input value={tempValue} onChange={setTempValue} {...rest} />;
};
