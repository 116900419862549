import React from "react";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { PortableTextContent } from "../../../portableTextContent/PortableTextContent";
import { QuestionId } from "../../taskUtils";
import { Answer, Answers, MatchTaskValue, Questions } from "../MatchTaskViewModel";
import { getGroup } from "../matchTaskUtil";

interface MatchTaskTableProps {
  questions: Questions;
  answers: Answers;
  renderAnswer: (answer: Answer, questionId: QuestionId) => React.ReactNode;
  value: MatchTaskValue;
  disabled: boolean;
  onChange?: (newValue: MatchTaskValue) => void;
  isMultipleChoice: boolean;
}

export const MatchTaskTable = (props: MatchTaskTableProps) => {
  const { questions, answers, renderAnswer, value, disabled, onChange, isMultipleChoice } = props;
  const translations = useTranslations();
  const Group = getGroup(isMultipleChoice, value, disabled, onChange);

  return (
    <div className="MatchTaskTable">
      <table>
        <thead>
          <tr>
            <th className="MatchTaskTable__fixed">
              <span className="visuallyHidden">{translations.questions}</span>
            </th>
            {answers.map(answer => (
              <th key={answer.id} className="MatchTaskTable__topAnswersCell">
                <PortableTextContent content={answer.content} className="MatchTaskTable__topAnswersCellText" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questions.map(question => (
            <tr key={question.id}>
              <th className="MatchTaskTable__questionCell MatchTaskTable__fixed">
                <PortableTextContent content={question.content} className="MatchTaskTable__questionCellText" />
              </th>
              <Group name={`${question.id}-table`} questionId={question.id}>
                {answers.map(answer => (
                  <td key={answer.id} className="MatchTaskTable__answerCell">
                    <div>{renderAnswer(answer, question.id)}</div>
                  </td>
                ))}
              </Group>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
