import { computed } from "mobx";
import { LessonStructureDto } from "../../types/courses/dto/LessonStructureDto";
import { StepStructure } from "./StepStructure";
import { ThemeStructure } from "./ThemeStructure";

export class LessonStructure {
  @computed get stepStructures(): StepStructure[] {
    return this.dto.steps.map(step => new StepStructure(this, step));
  }

  get id() {
    return this.dto.id;
  }

  get title() {
    return this.dto.title;
  }

  get color() {
    return this.dto.color;
  }

  get hasAccess() {
    return this.dto.hasAccess;
  }

  get isHighlighted() {
    return this.dto.isHighlighted;
  }

  constructor(public themeStructure: ThemeStructure, private dto: LessonStructureDto) {}
}
