/* eslint-disable no-param-reassign */
import { action, computed, observable } from "mobx";
import { isAnswerCorrect } from "../../portableTextContent/inlineChoiceGapTest/utils";
import { OptionStatus } from "../shared/OptionStatus";

type Choices = { content: { nb: string } }[];
export class GapTest {
  @observable private selectValue = "";

  @observable state: OptionStatus = "default";

  @computed get value() {
    return this.selectValue;
  }

  constructor(public choices: Choices, public additionalChoices: Choices = []) {}

  @action.bound
  setValue(newValue: string) {
    this.selectValue = newValue;
  }
}

export class GapTestsViewModel {
  @observable gapTests: Map<string, GapTest> = new Map();

  @computed get isTestAnswered() {
    return Array.from(this.gapTests.values()).every(test => test.value !== "");
  }

  @action.bound
  registerGapTest(key: string, newGapTest: GapTest) {
    const gapTest = this.gapTests.get(key);
    if (!gapTest) {
      this.gapTests.set(key, newGapTest);
      return;
    }
    return null;
  }

  @action.bound
  validateTest() {
    this.gapTests.forEach(gapTest => {
      if (
        isAnswerCorrect(
          gapTest.value,
          gapTest.choices.map(choice => choice.content.nb)
        )
      ) {
        gapTest.state = "correct";
      } else {
        gapTest.state = "incorrect";
      }
    });
  }

  @action.bound
  showCorrectTestAnswers() {
    this.gapTests.forEach(gapTest => {
      const [correctChoice] = gapTest.choices.map(choice => choice.content.nb);
      gapTest.setValue(correctChoice);
      gapTest.state = "correct";
    });
  }

  @action.bound
  resetTest() {
    this.gapTests.forEach(gapTest => {
      gapTest.state = "default";
      gapTest.setValue("");
    });
  }
}
