import * as React from "react";
import Skeleton from "react-loading-skeleton";

export const SearchItemSkeletonView = () => {
  return (
    <div className="SearchItem">
      <div className="SearchItem__thumbnailContainer SearchItem__thumbnailContainer--skeleton">
        <Skeleton className="SearchItem__skeletonThumbnail" inline={false} />
      </div>
      <div className="SearchItem__info">
        <div className="SearchItem__infoHeading">
          <span className="SearchItem__category">
            <Skeleton />
          </span>
        </div>
        <h2 className="SearchItem__title SearchItem__title--skeleton">
          <Skeleton width={180} />
        </h2>
        <p className="SearchItem__description">
          <Skeleton count={2} className="SearchItem__description--skeleton" />
        </p>
      </div>
    </div>
  );
};
