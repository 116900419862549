import React from "react";
import { ThemeItem } from "../../../../../../shared/components/basicThemesSection/ThemeItem";
import { CardThemeDetailed } from "../../../../../../shared/components/card/cardThemeDetailed/CardThemeDetailed";
import { List } from "../../../../../../shared/components/list/List";
import { SectionHeading } from "../../../../../../shared/components/sectionHeading/SectionHeading";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { PaymentSidePanelViewModel } from "../../../../../shared/paymentDialog/PaymentSidePanelViewModel";

interface InProgressThemesSectionProps {
  themes: ThemeItem[];
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
}

export const InProgressThemesSection = (props: InProgressThemesSectionProps) => {
  const { themes, paymentSidePanelViewModel } = props;
  const translations = useTranslations();

  if (themes.length === 0) {
    return null;
  }

  return (
    <section className="InProgressThemesSection">
      <SectionHeading title={translations.inProgress} level={2} />
      <List
        className="InProgressThemesSection__list"
        items={themes}
        renderItem={theme => {
          return (
            <CardThemeDetailed
              themeStructure={theme.themeStructure}
              totalStepsCount={theme.actionableStepsCount}
              completedStepsCount={theme.completedStepsCount}
              continueStepThumbnail={theme.continueStepThumbnail}
              paymentSidePanelViewModel={paymentSidePanelViewModel}
            />
          );
        }}
      />
    </section>
  );
};
