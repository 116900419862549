/* eslint-disable react/no-danger */
import React from "react";

interface EmbeddedBlockProps {
  iframe: string;
}

export const EmbeddedBlock = (props: EmbeddedBlockProps) => {
  const { iframe } = props;
  return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
};
