import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../../course/CourseViewModel";
import { CourseParams } from "../../course/addCourseRouting";
import { ProductParams } from "../../product/addProductRouting";
import { SiteSearchViewModel } from "./SiteSearchViewModel";

export type SearchQuery = { phrase: string };

export const addSiteSearchRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const siteSearchRouting = courseRoute.addRoute<SiteSearchViewModel, {}, SearchQuery>(
    RouteConfiguration.SiteSearch,
    SiteSearchViewModel,
    (coursePage, params, query) => {
      const siteSearch = new SiteSearchViewModel(
        coursePage.courseStructure,
        coursePage.languageService,
        coursePage.errorService,
        coursePage.progressStore,
        coursePage.backendApi,
        coursePage.backendApi,
        coursePage.paymentSidePanelViewModel,
        coursePage.bookmarkStore,
        coursePage.userStore,
        coursePage.gtm,
        query
      );
      return coursePage.showChildPage(siteSearch);
    }
  );

  return siteSearchRouting;
};
