import classNames from "classnames";
import React, { forwardRef } from "react";
import { useCheckbox } from "./useCheckbox";

export interface CheckboxProps extends Pick<React.HTMLAttributes<HTMLInputElement>, "aria-describedby"> {
  id: string;
  className?: string;
  onChange?: (value: string, checked: boolean) => void;
  checked?: boolean;
  required?: boolean;
  children: React.ReactNode;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { id, className, onChange, checked, required, children, ...rest } = props;
  const { name, disabled, inputChecked, onInputChange, htmlId } = useCheckbox(id, checked, onChange);

  return (
    <div className="Checkbox">
      <input
        ref={ref}
        id={htmlId}
        name={name}
        type="checkbox"
        className={classNames("Checkbox__input", className)}
        onChange={e => onInputChange && onInputChange(id, e.currentTarget.checked)}
        checked={inputChecked}
        disabled={disabled}
        required={required}
        {...rest}
      />
      <label htmlFor={htmlId} className="Checkbox__label">
        <div className="Checkbox__labelContent">{children}</div>
      </label>
    </div>
  );
});
