import React from "react";
import { Link } from "../link/Link";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface NavigationLinkProps {
  to: string;
  text: string;
  type: "back" | "forward";
}

export const NavigationLink: React.FC<NavigationLinkProps> = props => {
  const { to, text, type } = props;
  return (
    <Link to={to} type="clear" className="NavigationLink">
      {type === "back" && (
        <SvgIcon iconName="left-arrow-line" className="NavigationLink__icon NavigationLink__icon--back" />
      )}
      <span className="NavigationLink__text">{text}</span>
      {type === "forward" && (
        <SvgIcon iconName="right-arrow-line" className="NavigationLink__icon NavigationLink__icon--forward" />
      )}
    </Link>
  );
};
