async function load(src: string) {
  return new Promise((resolve, reject) => {
    // Create script element and set attributes
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = src;

    // Append the script to the DOM
    const el = document.getElementsByTagName("script")[0];
    el && el.parentNode && el.parentNode.insertBefore(script, el);

    // Resolve the promise once the script is loaded
    script.addEventListener("load", () => {
      resolve(script);
    });

    // Catch any errors while loading the script
    script.addEventListener("error", () => {
      reject(new Error(`${src} failed to load.`));
    });
  });
}

export const loadScript = async (src: string) => {
  await load(src);
};
