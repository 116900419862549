import React from "react";
import { Image } from "../../../../shared/components/image/Image";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import noLessonImageUrl from "../../../../static/images/dog2.png";

export const EmptyTheme: React.FC = () => {
  const translations = useTranslations();

  return (
    <div className="EmptyTheme">
      <div className="EmptyTheme__imageContainer">
        <Image url={noLessonImageUrl} />
      </div>
      <div className="EmptyTheme__message">
        <h2 className="EmptyTheme__messageTitle">{translations.noLessonsTitle}</h2>
        <p className="EmptyTheme__messageDescription">{translations.noLessonsMessage}</p>
      </div>
    </div>
  );
};
