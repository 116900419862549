import classNames from "classnames";
import React from "react";
import { useLocation } from "../../../useRouter";
import { Link, LinkProps } from "./Link";
import { getIsLinkActive } from "./helpers/getIsLinkActive";

type NavLinkProps = LinkProps & { activeClassName: string; exact?: boolean };

export const NavLink: React.FC<NavLinkProps> = props => {
  const { to, className, activeClassName, exact = false } = props;
  const location = useLocation();
  const isActive = getIsLinkActive(to, location.pathname, exact);

  return <Link {...props} className={classNames(className, isActive && activeClassName)} />;
};
