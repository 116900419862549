import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { useRadioGroupContext } from "./RadioGroupContext";

export interface RadioProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

export const Radio: React.FC<RadioProps> = ({ id, children, className }) => {
  const { name, value, onChange, disabled } = useRadioGroupContext();
  const checked = id === value;
  const htmlId = uniqueId("Radio__");

  return (
    <div className="Radio">
      <input
        id={htmlId}
        name={name}
        type="radio"
        className={classNames("Radio__input", className)}
        onChange={() => onChange(id)}
        checked={checked}
        value={value}
        disabled={disabled}
      />
      <label htmlFor={htmlId} className="Radio__label">
        <div className="Radio__labelContent">{children}</div>
      </label>
    </div>
  );
};
