import classNames from "classnames";
import React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import articleBuySectionUrl from "../../../../static/images/dog-with-balls.png";
import { ArticleActionSectionDto } from "../../../../types/articles/dto/ArticleActionSectionDto";
import { ArticleBuySectionViewModel } from "./ArticleBuySectionViewModel";

interface ArticleBuySectionProps {
  model: ArticleBuySectionViewModel;
  section?: ArticleActionSectionDto;
}

export const ArticleBuySection: React.FC<ArticleBuySectionProps> = props => {
  const { model, section } = props;
  const { paymentSidePanelViewModel } = model;
  const translations = useTranslations();

  return (
    <div className="ArticleBuySection">
      <div className="ArticleBuySection__wrapper">
        <div
          className={classNames("ArticleBuySection__content", { "ArticleBuySection__content--wide": !section?.image })}
        >
          <Title level={2} className="ArticleBuySection__title">
            {section?.title || translations.getFullAccessNow}
          </Title>
          <p className="ArticleBuySection__description">{section?.description || translations.fullAccessDescription}</p>
          <Button onClick={paymentSidePanelViewModel.show} className="ArticleBuySection__action">
            {translations.buyAccess}
            <SvgIcon iconName="right-arrow-line" />
          </Button>
        </div>
        <Image.Container
          aspectRatio="3/2"
          className={classNames("ArticleBuySection__imageWrapper", {
            "ArticleBuySection__imageWrapper--default": !section?.image,
          })}
        >
          {section?.image ? <SanityImage {...section.image} /> : <Image url={articleBuySectionUrl} />}
        </Image.Container>
      </div>
    </div>
  );
};
