import classNames from "classnames";
import React, { ReactNode } from "react";
import ReactCollapse from "react-css-collapse";

interface CollapseProps {
  id: string;
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  onRest?: () => void;
}

export const Collapse: React.FC<CollapseProps> = ({ id, isOpen, className, children, onRest }) => {
  return (
    <ReactCollapse isOpen={isOpen} aria-hidden={!isOpen} className={classNames("Collapse", className)} onRest={onRest}>
      <div id={id}>{children}</div>
    </ReactCollapse>
  );
};
