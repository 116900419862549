import { HttpError } from "../../shared/services/errors/HttpError";
import { PaymentError } from "../../shared/services/errors/PaymentError";
import { TranslationsDto } from "../../types/translations/dto/TranslationsDto";

export type ErrorMessagesMapKey = "UNEXPECTED_ERROR" | "FORBIDDEN" | "NOT_FOUND" | "SYSTEM_FAILURE" | "PAYMENT_FAILURE";

export const errorMessagesMap: { [type in ErrorMessagesMapKey]: keyof TranslationsDto } = {
  UNEXPECTED_ERROR: "unexpectedErrorMessage",
  FORBIDDEN: "unauthorizedViewMessage",
  NOT_FOUND: "notFoundMessage",
  SYSTEM_FAILURE: "unexpectedErrorMessage",
  PAYMENT_FAILURE: "paymentErrorMessage",
};

export interface UserErrorMessage {
  type: keyof typeof errorMessagesMap;
}

export const HTTPStatusCodeToErrorMessageMap: {
  [code in number]?: keyof typeof errorMessagesMap;
} = {
  403: "FORBIDDEN",
  404: "NOT_FOUND",
};

export const getUserErrorMessage = (e: unknown): UserErrorMessage => {
  if (!(e instanceof Error)) {
    return { type: "UNEXPECTED_ERROR" };
  }
  if (e instanceof TypeError) {
    return { type: "UNEXPECTED_ERROR" };
  }

  if (e instanceof HttpError) {
    if (e.code >= 500) {
      return { type: "SYSTEM_FAILURE" };
    }

    const messageType = HTTPStatusCodeToErrorMessageMap[e.code];
    if (messageType) {
      return { type: messageType };
    }
  }

  if (e instanceof PaymentError) {
    return { type: "PAYMENT_FAILURE" };
  }

  return { type: "UNEXPECTED_ERROR" };
};
