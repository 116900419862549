import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { CourseStructure } from "../../../contentStructure/CourseStructure";
import { BookmarkToggleViewModel } from "../shared/BookmarkToggleViewModel";
import { ThumbnailOverlayStates } from "../shared/ThumbnailOverlay";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

type UseOverlayPropsType = (
  hasAccess: boolean,
  isCompleted: boolean,
  type: ThumbnailTypes,
  courseStructure: CourseStructure,
  bookmarkToggleViewModel?: BookmarkToggleViewModel,
  paymentSidePanelViewModel?: PaymentSidePanelViewModel
) => ThumbnailOverlayStates | undefined;

export const useOverlayProps: UseOverlayPropsType = (
  hasAccess,
  isCompleted,
  type,
  courseStructure,
  bookmarkToggleViewModel,
  paymentSidePanelViewModel
) => {
  if (!hasAccess && paymentSidePanelViewModel) {
    return {
      overlayState: "locked",
      onClick: () => paymentSidePanelViewModel.show(),
    };
  }

  if (isCompleted) {
    return {
      overlayState: "finished",
      isVideo: type === "Video",
      isBookmarked: bookmarkToggleViewModel?.isStepBookmarked,
      bookmarkCommand: bookmarkToggleViewModel?.toggleBookmark,
    };
  }

  if (courseStructure.hasFullAccess === false && hasAccess) {
    return {
      overlayState: "freeNotFinished",
      isVideo: type === "Video",
      isBookmarked: bookmarkToggleViewModel?.isStepBookmarked,
      bookmarkCommand: bookmarkToggleViewModel?.toggleBookmark,
    };
  }

  if (bookmarkToggleViewModel) {
    return {
      overlayState: "bookmark",
      isVideo: type === "Video",
      isBookmarked: bookmarkToggleViewModel.isStepBookmarked,
      bookmarkCommand: bookmarkToggleViewModel.toggleBookmark,
    };
  }

  return undefined;
};
