import { useEffect, useState } from "react";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { formatProgress } from "../../../../../shared/formatProgress";
import { FormattedText } from "../../../../../shared/textUtils";

export const useThemeProgressTexts = (stepsCount: number, completedStepsCount: number) => {
  const [progress, setProgress] = useState(formatProgress(completedStepsCount / stepsCount));
  const translations = useTranslations();

  useEffect(() => {
    setProgress(formatProgress(completedStepsCount / stepsCount));
  }, [stepsCount, completedStepsCount]);

  const getThemeInProgressDescription = () => {
    return FormattedText(translations.themeProgressDescription, [progress.progressValue]);
  };

  if (completedStepsCount === 0) {
    return {
      mainMessage: translations.themeProgressMessageNoStepCompleted,
      description: translations.themeProgressDescriptionNoStepCompleted,
    };
  }

  if (completedStepsCount === stepsCount - 1) {
    return {
      mainMessage: translations.themeProgressMessageOneStepLeft,
      description: getThemeInProgressDescription(),
    };
  }

  if (progress.isCompleted) {
    return {
      mainMessage: translations.themeProgressMessageCompleted,
      description: translations.themeProgressDescriptionCompleted,
    };
  }

  if (progress.unitInterval > 0.5) {
    return {
      mainMessage: translations.themeProgressMessageOverHalfCompleted,
      description: getThemeInProgressDescription(),
    };
  }

  return {
    mainMessage: translations.themeProgressMessageInProgress,
    description: getThemeInProgressDescription(),
  };
};
