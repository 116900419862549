import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "../../../shared/components/link/NavLink";
import { List } from "../../../shared/components/list/List";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { UserLink } from "../HeaderViewModel";

interface UserLinksProps {
  links: UserLink[];
}

export const UserLinks: React.FC<UserLinksProps> = observer(props => {
  const { links } = props;
  const translations = useTranslations();

  return (
    <List
      items={links}
      renderItem={link => (
        <NavLink
          to={link.href}
          hrefType={link.type}
          target={link.target}
          type="clear"
          className="UserLinks__link"
          activeClassName="UserLinks__link--active"
        >
          <SvgIcon
            iconName={link.icon.name}
            className={classNames("UserLinks__icon", {
              "UserLinks__icon--primary": link.icon.color === "primary",
              "UserLinks__icon--secondary": link.icon.color === "secondary",
            })}
          />
          <span className="UserLinks__linkText">{translations[link.translation]}</span>
          {link.target === "_blank" && <SvgIcon iconName="external" className="UserLinks__externalIcon" />}
        </NavLink>
      )}
      className="UserLinks"
      singleItemClassName="UserLinks__listItem"
    />
  );
});
