import { Placement } from "@popperjs/core";
import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import * as React from "react";
import { Command } from "react-mvvm";
import { usePopper } from "react-popper";
import { animated, useSpring, config } from "react-spring";
import { useDelayedDisplay } from "../../hooks/useDelayedDisplay";
import { CommandButton } from "../button/Button";

interface DialogViewProps {
  children: React.ReactNode;
  referenceElement: HTMLElement | null;
  placement: Placement;
  className?: string;
  cancelCommand: Command;
  isVisible: boolean;
  onShow?: () => void;
  offset?: [number, number];
}

export const DialogView = ({
  children,
  cancelCommand,
  referenceElement,
  className,
  placement,
  isVisible,
  onShow,
  offset = [0, 0],
}: DialogViewProps) => {
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
  });
  const [isDisplayed, hide] = useDelayedDisplay(isVisible);

  React.useEffect(() => {
    isDisplayed && onShow && onShow();
  }, [isDisplayed]);

  const dialogStyles = useSpring({
    config: config.stiff,
    opacity: isVisible ? 1 : 0,
    onResolve: () => {
      !isVisible && hide();
    },
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <FocusTrap active={isVisible}>
      <div className="Dialog">
        {isVisible && <CommandButton type="clear" command={cancelCommand} className="Dialog__background" />}
        <animated.div
          ref={a => {
            setPopperElement(a);
          }}
          style={{ ...styles.popper, ...dialogStyles }}
          {...attributes.popper}
          className={classNames("Dialog__dialog", className)}
        >
          {children}
        </animated.div>
      </div>
    </FocusTrap>
  );
};
