import React from "react";
import { Button } from "../../../../../shared/components/button/Button";
import { Collapse } from "../../../../../shared/components/collapse/Collapse";
import { Panel } from "../../../../../shared/components/panel/Panel";
import { PortableTextContent } from "../../../../../shared/components/portableTextContent/PortableTextContent";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../../shared/components/title/Title";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";

export interface TaskSolutionProps {
  id: string;
  solution: PortableTextDto;
  isSolutionOpen: boolean;
  isMobileSolutionOpen: boolean;
  hideSolution: () => void;
  hideMobileSolution: () => void;
}

export const TaskSolution: React.FC<TaskSolutionProps> = props => {
  const { id, solution, isSolutionOpen, isMobileSolutionOpen, hideSolution, hideMobileSolution } = props;
  const translations = useTranslations();

  return (
    <div className="TaskSolution">
      <Collapse id={id} isOpen={isSolutionOpen}>
        <div className="TaskSolution__panel">
          <div className="TaskSolution__heading">
            <Title level={2} className="TaskSolution__title">
              {translations.solution}
            </Title>
            <div className="TaskSolution__buttonWrapper">
              <Button
                onClick={hideSolution}
                variant="secondary"
                type="circle"
                size="xlarge"
                aria-expanded={isSolutionOpen}
                className="TaskSolution__closeButton"
              >
                <SvgIcon iconName="close" />
                <span className="visuallyHidden">{translations.close}</span>
              </Button>
            </div>
          </div>
          <div className="TaskSolution__content">
            <PortableTextContent content={solution} />
          </div>
        </div>
      </Collapse>
      <Panel
        shouldBeVisible={isMobileSolutionOpen}
        onClose={hideMobileSolution}
        header={
          <div className="TaskSolution__heading">
            <Title level={1} className="TaskSolution__title">
              {translations.solution}
            </Title>
          </div>
        }
      >
        <div className="TaskSolution__content">
          <PortableTextContent content={solution} />
        </div>
      </Panel>
    </div>
  );
};
