import classNames from "classnames";
import React from "react";
import defaultTestImageSrc from "../../../../../static/images/gril-with-barbell.png";
import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { Image, SanityImage } from "../../../image/Image";
import { ThumbnailTypes } from "../../types/ThumbnailTypes";
import { StepIcon } from "../stepIcon/StepIcon";

interface ThumbnailImageProps {
  type: ThumbnailTypes;
  image?: ImageDto;
  defaultImageSrc?: string;
}

export const ThumbnailImage: React.FC<ThumbnailImageProps> = props => {
  const { type, image, defaultImageSrc } = props;

  const defaultTestImage = (
    <Image
      url={defaultImageSrc || defaultTestImageSrc}
      className="ThumbnailImage__picture ThumbnailImage__picture--fullWidthContain ThumbnailImage__picture--bottom"
    />
  );

  const getThumbnailImage = () => {
    switch (type) {
      // Using <Image> here because of the images that are returned from vimeo
      // sanity image builder is not accepting their name format
      case "Video":
        return (
          <>{image && <Image {...image} className="ThumbnailImage__picture ThumbnailImage__picture--fullWidth" />}</>
        );
      case "Theme":
      case "Article":
        return (
          <>
            {image && <SanityImage {...image} className="ThumbnailImage__picture ThumbnailImage__picture--default" />}
          </>
        );
      case "Test":
      case "PreviousExams":
        return (
          <>
            {image ? (
              <SanityImage {...image} className="ThumbnailImage__picture ThumbnailImage__picture--fullWidthContain" />
            ) : (
              defaultTestImage
            )}
          </>
        );
      case "TrainingGround":
      case "Author":
        return (
          <>
            {image ? (
              <SanityImage {...image} className="ThumbnailImage__picture ThumbnailImage__picture--fullWidth" />
            ) : (
              defaultTestImage
            )}
          </>
        );
      default:
        return <StepIcon type={type} />;
    }
  };

  return (
    <div className={classNames("ThumbnailImage", { "ThumbnailImage--shadow": type === "Video" })}>
      {getThumbnailImage()}
    </div>
  );
};
