import classNames from "classnames";
import React from "react";
import { HrefType } from "../../../shared/components/link/Link";
import { LogoSection } from "../../../shared/components/logoSection/LogoSection";
import { useIsVisible } from "../../../shared/hooks/useIsVisible";
import { useUrl } from "../../../useRouter";

interface ErrorHeaderProps {
  stickyHeaderRef: React.RefObject<HTMLDivElement>;
}

export const ErrorHeader: React.FC<ErrorHeaderProps> = props => {
  const { stickyHeaderRef } = props;
  const url = useUrl();
  const isVisible = useIsVisible(stickyHeaderRef);

  return (
    <div className={classNames("ErrorHeader", { "ErrorHeader--sticky": !isVisible })}>
      <div className="ErrorHeader__inner">
        <div className="ErrorHeader__content">
          <LogoSection
            link={{
              id: "toHomePage",
              href: url.toProductNestPage(),
              translation: "goToProductOverview",
              type: HrefType.External,
            }}
          />
        </div>
      </div>
    </div>
  );
};
