import Konva from "konva";
import React from "react";
import { Layer as KLayer } from "react-konva";

interface LayerProps extends Konva.LayerConfig {
  children: React.ReactNode;
}

const MAX_PIXEL_RATIO = 2;

export const Layer: React.FC<LayerProps> = props => {
  const { children } = props;

  const layerRef = React.useRef<Konva.Layer>(null);

  React.useEffect(() => {
    if (layerRef.current) {
      const layer = layerRef.current;
      const pixelRatio = layer.getCanvas().getPixelRatio();

      if (pixelRatio > MAX_PIXEL_RATIO) {
        layer.getCanvas().setPixelRatio(MAX_PIXEL_RATIO);
      }
    }
  }, [layerRef.current]);

  return (
    <KLayer {...props} ref={layerRef}>
      {children}
    </KLayer>
  );
};
