import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { AboutUsViewModel } from "./AboutUsViewModel";

export const addAboutUsRouting = (route: IRoute<App, unknown, unknown>) => {
  const aboutUsRoute = route.addRoute<AboutUsViewModel>(`${RouteConfiguration.AboutUs}`, AboutUsViewModel, app => {
    const aboutUs = new AboutUsViewModel(app, app.backendApi);

    return app.showChildPage(aboutUs);
  });
  return aboutUsRoute;
};
