import Vimeo from "@u-wave/react-vimeo";
import Player from "@vimeo/player";
import * as React from "react";
import { TimeSpanDto } from "../../../../types/shared/dto/TimeSpanDto";
import { TimeSpan } from "../../../models/TimeSpan";
import { getVimeoId } from "../../../utils";

interface VimeoEventProps {
  seconds: number;
  percent: number;
  duration: number;
}

interface VimeoPlayerProps {
  videoUrl: string;
  duration?: TimeSpanDto;
  startPositionSeconds?: TimeSpan;
  onReady?: (player: Player) => void;
  onEnd?: (secondsSinceStart: TimeSpan) => void;
  onUpdateProgress?: (seekedToSeconds: TimeSpan) => void;
  onVideoSeeked?: (secondsSinceStart: TimeSpan) => void;
}

export const VimeoPlayer: React.FC<VimeoPlayerProps> = ({
  videoUrl,
  duration,
  startPositionSeconds,
  onReady,
  onEnd,
  onVideoSeeked,
  onUpdateProgress,
}) => {
  const vimeoId = getVimeoId(videoUrl);

  const onTimeUpdate = (vimeoEvent: VimeoEventProps) => {
    onUpdateProgress && onUpdateProgress(vimeoEvent.seconds);
  };

  const onEndVideo = (vimeoEvent: VimeoEventProps) => {
    // We have to round the value because duration reported by Vimeo
    // is also rounded. If we don't do that, then what we keep as video
    // last position is different from duration as kept by Vimeo.
    onEnd && onEnd(Math.round(vimeoEvent.seconds));
  };

  const onSeeked = (vimeoEvent: VimeoEventProps) => {
    onVideoSeeked && onVideoSeeked(vimeoEvent.seconds);
  };

  const calculateStartTime = () => {
    if (startPositionSeconds) {
      return Math.ceil(startPositionSeconds) === duration?.totalSeconds ? 0 : startPositionSeconds;
    }
    return 0;
  };

  return (
    <div className="VimeoPlayer">
      <Vimeo
        onReady={onReady}
        video={vimeoId}
        color="E50043"
        onTimeUpdate={onTimeUpdate}
        onSeeked={onSeeked}
        onEnd={onEndVideo}
        showTitle={false}
        showByline={false}
        start={calculateStartTime()}
      />
    </div>
  );
};
