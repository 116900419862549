import { action, computed, observable } from "mobx";
import { ProductParams } from "../../pages/product/addProductRouting";
import { CourseColorDto } from "../../types/courses/dto/CourseColorDto";
import { ProductDto } from "../../types/product/dto/ProductDto";
import { ImageDto } from "../../types/shared/dto/ImageDto";
import { CourseStructure } from "../contentStructure/CourseStructure";
import { ProductStore } from "./ProductStore";

export type CourseColor = CourseColorDto | undefined;

export type CourseInfo = {
  title: string;
  id: string;
  slug: string;
  isBeta: boolean;
  color: CourseColor;
  image: ImageDto;
};

export class Product {
  @observable courseStructure?: CourseStructure;

  get title() {
    return this.productDto.title;
  }

  get id() {
    return this.productDto.id;
  }

  get slug() {
    return this.productDto.slug;
  }

  get isBeta() {
    return this.courses.some(course => course.isBeta);
  }

  get targetGroup() {
    return this.productDto.targetGroup;
  }

  get studiesType() {
    return this.productDto.studiesType;
  }

  get heroSection() {
    return {
      title: this.productDto.section.title,
      description: this.productDto.heroDescription,
    };
  }

  get sections() {
    return this.productDto.section.blocks;
  }

  get image() {
    return this.productDto.image;
  }

  get cardImage() {
    return this.productDto.cardImage;
  }

  get courses(): CourseInfo[] {
    return this.productDto.courses;
  }

  get seoTitle() {
    return this.productDto.seoTitle;
  }

  get seoDescription() {
    return this.productDto.seoDescription;
  }

  get productParams(): ProductParams {
    return { productSlug: this.slug };
  }

  get productAuthorsHero() {
    return this.productDto.productAuthorsHero;
  }

  get shouldShowProductArticleList() {
    return this.productDto.shouldShowProductArticleList;
  }

  @computed get isOnlyOneCourse() {
    return this.courses.length === 1;
  }

  @computed get defaultCourseInfo() {
    if (this.courseStructure) {
      return this.courses.find(courseInfo => courseInfo.id === this.courseStructure?.id);
    }

    if (!this.isOnlyOneCourse) {
      return;
    }

    return this.courses[0];
  }

  @computed get availableCourses() {
    return this.courses.filter(c => !c.isBeta);
  }

  constructor(private productStore: ProductStore, private productDto: ProductDto) {}

  @action.bound
  async trySetActiveCourseStructureBySlug(slug: string) {
    const course = this.courses.find(c => c.slug === slug);
    if (course) {
      const dto = await this.productStore.loadCourseStructure(course.id);

      if (dto) {
        this.courseStructure = new CourseStructure(this, dto);
      }
    }
  }

  @action.bound
  resetActiveCourseStructure() {
    this.productStore.cancelTokenSource?.cancel("Canceled after resetting active course structure");
    this.courseStructure = undefined;
  }
}
