import React from "react";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { PortableTextContent } from "../../portableTextContent/PortableTextContent";

export interface TextTaskModel {
  id: string;
  content: PortableTextDto;
}

interface TextTaskProps {
  model: TextTaskModel;
}

export const TextTask: React.FC<TextTaskProps> = props => {
  const {
    model: { content },
  } = props;

  return (
    <div className="TextTask">
      <PortableTextContent content={content} />
    </div>
  );
};
