import classNames from "classnames";
import * as React from "react";
import { loadScript } from "./loadScript/loadScript";

export const enum GeoGebraAppType {
  Classic = "classic",
  Graphing = "graphing",
  Geometry = "geometry",
  _3d = "3d",
  Suite = "suite",
  Evaluator = "evaluator",
}

interface GeoGebraAppletProps {
  id: string;
  appType: GeoGebraAppType;
  materialId?: string;
  showAlgebraInput: boolean;
  showMenuBar: boolean;
  showToolBar: boolean;
}

export const GeoGebraApplet: React.FC<GeoGebraAppletProps> = ({
  id,
  appType,
  materialId,
  showAlgebraInput,
  showMenuBar,
  showToolBar,
}) => {
  const appletContainerClassName = `GeoGebraApplet__container-${id}`;

  const loadGeoGebraApp = () => {
    const params = {
      appName: appType,
      material_id: materialId,
      showToolBar,
      showAlgebraInput,
      showMenuBar,
      scaleContainerClass: appletContainerClassName,
      autoHeight: true,
      allowUpscale: true,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ggbApp = new (window as any).GGBApplet(params, true);
    ggbApp.inject(id);
  };

  React.useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (typeof (window as any).GGBApplet === "undefined") {
        await loadScript("https://geogebra.org/apps/deployggb.js");
      }
      loadGeoGebraApp();
    })();
  }, []);

  return (
    <div className={classNames("GeoGebraApplet", appletContainerClassName)}>
      <div id={id} />
    </div>
  );
};
