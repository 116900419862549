import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../../shared/components/button/Button";
import { Link } from "../../../shared/components/link/Link";
import { Modal } from "../../../shared/components/modal/Modal";
import { Popup } from "../../../shared/components/popup/Popup";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";

interface ConfirmExitPanelProps {
  model: Popup;
  exitLink: string;
}

export const ConfirmExitPanel: React.FC<ConfirmExitPanelProps> = observer(props => {
  const { model, exitLink } = props;
  const { isOpen, close } = model;
  const translations = useTranslations();

  return (
    <Modal isOpen={isOpen} onClose={close} size="small" className="ConfirmExitModal">
      <div className="ConfirmExitModal__container">
        <Title level={2} className="ConfirmExitModal__title">
          {translations.quitTestTitle}
        </Title>
        <div className="ConfirmExitModal__content">
          <p className="ConfirmExitModal__description">{translations.quitTestDescription}</p>
          <div className="ConfirmExitModal__action">
            <Button onClick={close} className="ConfirmExitModal__closeModalButton">
              {translations.quitTestReturn}
            </Button>
            <Link
              to={exitLink}
              type="button"
              buttonType="outline"
              buttonVariant="secondary"
              className="ConfirmExitModal__exitLink"
            >
              {translations.quitConfirm}
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
});
