import axios from "axios";
import React from "react";
import { RettsdataPortableTextDto } from "../../../types/rettsdatas/dto/RettsdataPortableTextDto";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { ActivityIndicator } from "../activityIndicator/ActivityIndicator";
import { Link } from "../link/Link";
import { RettsdataPortableText } from "./RettsdataPortableText";
import { Law } from "./RettsdataReference";

export interface RettsdataReferenceContentProps {
  mainLaw: Law;
  childLaw: Law;
}

type RettsdataReferenceContentState =
  | { _type: "loading" }
  | { _type: "loaded"; content: RettsdataPortableTextDto }
  | { _type: "error" };

export const RettsdataReferenceContent = (props: RettsdataReferenceContentProps) => {
  const { childLaw, mainLaw } = props;
  const [state, setState] = React.useState<RettsdataReferenceContentState>({ _type: "loading" });
  const translations = useTranslations();

  // Maybe we should use react-query to cache data?
  const loadContent = async () => {
    const getLawContent = await axios
      .get<RettsdataPortableTextDto>(`/api/GetLawContent/${mainLaw.id}/${childLaw.id}`)
      .catch(e => {
        setState({ _type: "error" });
        throw e;
      });

    if (getLawContent.data) {
      setState({ _type: "loaded", content: getLawContent.data });
    }
  };

  React.useEffect(() => {
    loadContent();
  }, []);

  if (state._type === "error") {
    return (
      <div className="RettsdataReferenceContent">
        <div className="RettsdataReferenceContent__error">{translations.unexpectedErrorMessage}</div>
      </div>
    );
  }

  if (state._type === "loading") {
    return <ActivityIndicator isLoading />;
  }

  return (
    <div className="RettsdataReferenceContent">
      <RettsdataPortableText content={state.content} />
      <div className="RettsdataReferenceContent__linkContainer">
        <Link
          className="RettsdataReferenceContent__link"
          target="_blank"
          to="https://www.rettsdata.no/produkter/student/"
        >
          {translations.rettsdataLinkText}
        </Link>
      </div>
    </div>
  );
};
