import { Command } from "../../../../react-mvvm/src";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { getResultForScore, Result } from "../../../../shared/resultUtils";
import { ResultDto } from "../../../../types/shared/dto/ResultDto";
import { ThemeInfoDto } from "../../../../types/trainingground/dto/ThemeInfoDto";
import { TrainingTask } from "../training/testTask/TrainingTask";
import {
  getThemesAvailabilityForResults as getThemesAvailabilityForResults,
  getResultPerTheme,
} from "./trainingResultUtils";

export interface ProgressPerTheme {
  id: string;
  totalStepsCount: number;
  completedStepsCount: number;
  themeInfo: ThemeInfoDto;
}

export interface ProgressPerThemeWithAvailability extends ProgressPerTheme {
  isThemeAvailable: boolean;
}

export interface TrainingGroundResult extends Result {
  progressPerTheme: ProgressPerThemeWithAvailability[];
}

export class TrainingResultViewModel {
  result: TrainingGroundResult;

  get activeCourseParams() {
    return this.courseStructure.urlParams;
  }

  constructor(
    public title: string,
    private results: ResultDto[],
    public successThreshold: number,
    public resetTraining: Command,
    public courseStructure: CourseStructure,
    private visitedTasks: TrainingTask[]
  ) {
    const correctAnswersCount = this.visitedTasks.filter(
      trainingTask => trainingTask.task.model.answersState === "correct"
    ).length;

    this.result = {
      ...getResultForScore(correctAnswersCount / this.visitedTasks.length, this.successThreshold, this.results),
      progressPerTheme: getThemesAvailabilityForResults(getResultPerTheme(this.visitedTasks), this.courseStructure),
    };
  }
}
