import React from "react";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { List } from "../../../list/List";
import { PortableTextContent } from "../../../portableTextContent/PortableTextContent";
import { Title } from "../../../title/Title";
import { AnswerId, QuestionId } from "../../taskUtils";
import { MatchTaskValue } from "../MatchTaskViewModel";
import { getGroup } from "../matchTaskUtil";

interface MatchTaskListProps {
  questions: { id: QuestionId; content: PortableTextDto }[];
  answers: { id: AnswerId; content: PortableTextDto }[];
  renderAnswer: (answer: { id: AnswerId; content: PortableTextDto }, questionId: QuestionId) => React.ReactNode;
  value: MatchTaskValue;
  disabled: boolean;
  onChange?: (newValue: MatchTaskValue) => void;
  isMultipleChoice: boolean;
}

export const MatchTaskList = (props: MatchTaskListProps) => {
  const { questions, answers, renderAnswer, value, disabled, onChange, isMultipleChoice } = props;
  const Group = getGroup(isMultipleChoice, value, disabled, onChange);

  return (
    <div className="MatchTaskList">
      <List
        items={questions}
        renderItem={question => (
          <div className="MatchTaskList__questionWrapper">
            <Title level={2} className="MatchTaskList__question">
              <PortableTextContent content={question.content} className="MatchTaskList__questionText" />
            </Title>
            <div className="MatchTaskList__answersWrapper">
              <Group questionId={question.id} name={`${question.id}-list`}>
                <List
                  items={answers}
                  singleItemClassName="MatchTaskList__singleOptionItem"
                  renderItem={answer => renderAnswer(answer, question.id)}
                />
              </Group>
            </div>
          </div>
        )}
      />
    </div>
  );
};
