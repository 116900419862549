import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { Select } from "../../shared/components/select/Select";
import { assertUnreachable } from "../../shared/typeUtils";
import { PreviewModeType, StepPreviewViewModel } from "./StepPreviewViewModel";
import { LessonPreview } from "./lessonPreview/LessonPreview";
import { TrainingGroundPreview } from "./trainingGroundPreview/TrainingGroundPreview";

export const StepPreviewView = observer(({ model }: { model: StepPreviewViewModel }) => {
  const { mode, isTrainingPreviewAvailable, changeMode, isLoading } = model;

  const renderPreview = () => {
    switch (mode.type) {
      case "lesson":
        return <LessonPreview step={mode.step} />;
      case "training":
        return <TrainingGroundPreview task={mode.task} />;
      case "loading":
        return <ActivityIndicator isLoading />;
      default:
        return assertUnreachable(mode);
    }
  };

  return (
    <article className="StepPreview">
      <div className="StepPreview__inner">
        <div className="StepPreview__content">
          <ActivityIndicator isLoading={mode.type === "loading" || isLoading}>
            <div>
              {renderPreview()}
              {isTrainingPreviewAvailable && (
                <div className="StepPreview__modeWrapper">
                  <div className="StepPreview__modeInner">
                    <Select<PreviewModeType>
                      options={[
                        { label: "Lesson preview", value: "lesson" },
                        { label: "Training preview", value: "training" },
                      ]}
                      value={mode.type}
                      onChange={changeMode}
                      className="StepPreview__mode"
                    />
                  </div>
                </div>
              )}
            </div>
          </ActivityIndicator>
        </div>
      </div>
    </article>
  );
});
