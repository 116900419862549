import { action, computed } from "mobx";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { TaskHintDto } from "../../../../types/dto/tasks/TaskHintDto";
import { StepTypeDto } from "../../../../types/shared/dto/StepTypeDto";

export type StepType = `${StepTypeDto}`;

export interface StepBase {
  id: string;
  title: string;
  slug: string;
  description?: string;
  relatedContentIds?: string[];
  hint?: TaskHintDto;
}

export abstract class Step {
  readonly description?: string;

  get id() {
    return this.step.id;
  }

  get title() {
    return this.step.title;
  }

  get slug() {
    return this.step.slug;
  }

  get relatedContentIds() {
    return this.step.relatedContentIds ?? [];
  }

  @computed get isCompleted() {
    return this.progressStore.isCompleted(this.id);
  }

  @computed get isBookmarked() {
    return this.bookmarkStore.isBookmarked(this.id);
  }

  @action.bound
  async markStepAsCompleted() {
    await this.progressStore.markStepAsCompleted(this.id);
  }

  @action.bound
  async markStepAsUncompleted() {
    await this.progressStore.markStepAsUncompleted(this.id);
  }

  constructor(
    private step: StepBase,
    protected progressStore: IProgressStore,
    protected bookmarkStore: IBookmarkStore
  ) {
    this.description = step.description;
  }
}
