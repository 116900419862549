import React, { useContext } from "react";
import { LessonViewModel } from "../LessonViewModel";

interface StepsListContextValue {
  model: LessonViewModel;
}

const StepsListContext = React.createContext<StepsListContextValue | undefined>(undefined);

export const useStepsList = () => {
  const ctx = useContext(StepsListContext);

  if (!ctx) {
    throw new Error("Component beyond StepsListContext!");
  }

  return ctx.model;
};

export const StepsListContextProvider = StepsListContext.Provider;
