import noop from "lodash/noop";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { INavigationService } from "../../shared/services/NavigationService";
import { LoginConfigDto } from "../../types/config/dto/LoginConfigDto";
import { GtmBasePage } from "../GtmBasePage";

export class LoginViewModel extends GtmBasePage {
  constructor(
    public loginConfig: LoginConfigDto,
    public languageService: ILanguageService,
    public navigation: INavigationService,
    public gtmService: IGtmService,
    errorService: IErrorService,
    public returnUrl?: string
  ) {
    super(languageService, gtmService, errorService);
  }

  // eslint-disable-next-line class-methods-use-this
  protected async loadData() {
    noop();
  }
}
