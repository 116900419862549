import Player, { VimeoChapter } from "@vimeo/player";
import { observer } from "mobx-react";
import * as React from "react";
import Skeleton from "react-loading-skeleton";
import { AuthorInfo } from "../../../../../shared/components/authorInfo/AuthorInfo";
import { Button } from "../../../../../shared/components/button/Button";
import { Popup } from "../../../../../shared/components/popup/Popup";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { VimeoPlayer } from "../../../../../shared/components/videoPlayer/vimeoPlayer/VimeoPlayer";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { TimeSpan } from "../../../../../shared/models/TimeSpan";
import { AuthorDto } from "../../../../../types/shared/dto/AuthorDto";
import { TimeSpanDto } from "../../../../../types/shared/dto/TimeSpanDto";
import { AuthorDetailsSidePanel } from "../../../../authorsForProduct/authorDetailsSidePanel/AuthorDetailsSidePanel";
import { AuthorInfo as SidePanelAuthorInfo } from "../../../../authorsForProduct/authorInfo/AuthorInfo";
import { VideoTab } from "./videoTab/VideoTab";

interface VideoProps {
  title: string;
  description?: string;
  duration?: TimeSpanDto;
  videoUrl: string;
  startPositionSeconds?: TimeSpan;
  author?: AuthorDto;
  authorInfo?: SidePanelAuthorInfo;
  authorInfoPopup: Popup;
  updateVideoProgress: (secondsSinceStart: number) => Promise<void>;
  endVideo: (secondsSinceStart: number) => Promise<void>;
}

export type Anchor = VimeoChapter;

type AnchorsState = { type: "loaded"; anchors: Anchor[] } | { type: "loading" } | { type: "empty" };

export const Video = observer((props: VideoProps) => {
  const defaultVideoDuration = { totalSeconds: 0 };
  const {
    description,
    duration = defaultVideoDuration,
    videoUrl,
    startPositionSeconds,
    author,
    endVideo,
    updateVideoProgress,
    authorInfo,
    authorInfoPopup,
  } = props;
  const translations = useTranslations();
  const playerRef = React.useRef<Player>();
  const [isEndPanelVisible, setIsEndPanelVisible] = React.useState(false);
  const [anchorsState, setAnchorsState] = React.useState<AnchorsState>({ type: "empty" });

  const onReady = async (player: Player) => {
    playerRef.current = player;
    setAnchorsState({ type: "loading" });

    player
      .getChapters()
      .then((chapters: Anchor[]) => {
        if (chapters.length === 0) {
          setAnchorsState({ type: "empty" });
          return;
        }

        setAnchorsState({ type: "loaded", anchors: chapters });
      })
      .catch((error: Error) => {
        setAnchorsState({ type: "empty" });
        throw error;
      });
  };

  const moveVideo = async (secondsSinceBeginning: TimeSpan) => {
    setIsEndPanelVisible(false);

    if (playerRef && playerRef.current) {
      playerRef.current.setCurrentTime(secondsSinceBeginning);
      playerRef.current.play();
    }
  };

  const moveToStart = () => moveVideo(0);

  const onVideoEnd = async (secondsSinceStart: TimeSpan) => {
    setIsEndPanelVisible(true);
    endVideo(secondsSinceStart);
  };

  const onUpdateProgress = async (secondsSinceStart: TimeSpan) => {
    updateVideoProgress(secondsSinceStart);
  };

  return (
    <div id="video" className="Video">
      <div className="Video__player">
        {videoUrl && (
          <VimeoPlayer
            duration={duration}
            videoUrl={videoUrl}
            startPositionSeconds={startPositionSeconds}
            onReady={onReady}
            onEnd={onVideoEnd}
            onUpdateProgress={onUpdateProgress}
          />
        )}
        {isEndPanelVisible && (
          <div className="Video__endPanel">
            <Button onClick={moveToStart} className="Video__button Video__button--replay" type="circle" size="xlarge">
              <SvgIcon iconName="replay-current-step" className="Video__replayCurrentIcon" />
              <span className="visuallyHidden">{translations.replay}</span>
            </Button>
          </div>
        )}
      </div>
      {author && (
        <div className="Video__author">
          <AuthorInfo image={author.image} name={author.name} role={author.role} onClick={authorInfoPopup.show} />
        </div>
      )}
      {!!description && <p className="Video__description">{description}</p>}
      {anchorsState.type !== "empty" && (
        <div className="Video__anchorsContainer">
          {anchorsState.type === "loading" ? (
            <Skeleton width="100%" height={35} />
          ) : (
            <VideoTab anchors={anchorsState.anchors} moveVideo={moveVideo} />
          )}
        </div>
      )}
      <AuthorDetailsSidePanel popup={authorInfoPopup} authorInfo={authorInfo} />
    </div>
  );
});
