import classNames from "classnames";
import * as React from "react";
import { ReactSVG } from "react-svg";
import lockIcon from "../../../../../static/images/icons/lock.svg";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { Button } from "../../../button/Button";

export interface LockedLayerProps {
  onClick: () => void;
  variant: "small" | "default";
}

export const LockedLayer: React.FC<LockedLayerProps> = ({ onClick, variant }) => {
  const translations = useTranslations();

  return (
    <div className={classNames("LockedLayer", `LockedLayer--${variant}`)}>
      <ReactSVG src={lockIcon} className="LockedLayer__icon" />
      <div className="LockedLayer__content">
        <div className="LockedLayer__actions">
          <Button className="LockedLayer__button" onClick={onClick} tabIndex={-1}>
            {translations.buyAccess}
          </Button>
        </div>
      </div>
      <Button onClick={onClick} type="clear" className="LockedLayer__actionOverlay">
        <span className="visuallyHidden">{translations.buyAccess}</span>
      </Button>
    </div>
  );
};
