import React from "react";
import { RadioGroupProvider } from "./RadioGroupContext";

interface RadioGroupProps {
  name: string;
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ name, onChange, value, disabled, children }) => {
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleOnChange = (checkedId: string) => {
    setCurrentValue(checkedId);
    onChange && onChange(checkedId);
  };

  return (
    <RadioGroupProvider name={name} onChange={handleOnChange} value={currentValue} disabled={disabled}>
      <>{children}</>
    </RadioGroupProvider>
  );
};
