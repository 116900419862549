import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { SectionArticleItem } from "../../../shared/components/blocks/SectionArticleListBlock/SectionArticleItem/SectionArticleItem";
import { Image, SanityImage } from "../../../shared/components/image/Image";
import { List } from "../../../shared/components/list/List";
import { PortableTextContent } from "../../../shared/components/portableTextContent/PortableTextContent";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import anonymousDog from "../../../static/images/anonymous-dog.png";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { PortableTextDto } from "../../../types/shared/dto/PortableTextDto";
import { AuthorArticles } from "../authorInfo/AuthorInfo";
interface AuthorDetailsProps {
  id: string;
  name: string;
  role: string;
  description: PortableTextDto;
  image?: ImageDto;
  details: AuthorArticles;
}

export const AuthorDetails: React.FC<AuthorDetailsProps> = observer(props => {
  const { description, image, name, role, details } = props;
  const translations = useTranslations();

  const authorContent = () => {
    if (details._type === "unloaded") {
      return <ActivityIndicator isLoading />;
    }
    if (details._type === "loaded" && details.articles && details.articles.length > 0) {
      return (
        <>
          <Title level={3} className="AuthorDetails__authorContentTitle">
            {translations.contentMadeByAuthor}
          </Title>
          <List
            items={details.articles}
            renderItem={article => (
              <SectionArticleItem
                {...article}
                title={{ level: 4, size: "small", text: article.title }}
                category={article.category?.name}
              />
            )}
            className="AuthorDetails__articlesList"
          />
        </>
      );
    }

    return null;
  };

  return (
    <div className="AuthorDetails">
      {image ? (
        <Image.Container aspectRatio="16/9">
          <SanityImage {...image} />
        </Image.Container>
      ) : (
        <Image.Container aspectRatio="16/9">
          <Image url={anonymousDog} />
        </Image.Container>
      )}
      <div className="AuthorDetails__wrapper">
        <Title level={2} className="AuthorDetails__name">
          {name}
        </Title>
        <p className="AuthorDetails__role">{role}</p>
        <div className="AuthorDetails__content">
          <PortableTextContent content={description} className="AuthorDetails__description" />
          <div className="AuthorDetails__authorContent">{authorContent()}</div>
        </div>
      </div>
    </div>
  );
});
