import { action } from "mobx";
import { Popup } from "../../../../shared/components/popup/Popup";

export class FinishConfirmationModalViewModel {
  private resolve?: (_: boolean) => void;

  public popup = new Popup();

  async confirm() {
    const confirmPromise = new Promise(resolve => {
      this.resolve = resolve;
    });
    this.popup.show();
    const result = await confirmPromise;
    this.popup.close();

    return result;
  }

  @action.bound
  cancel() {
    this.resolve && this.resolve(false);
  }

  @action.bound
  accept() {
    this.resolve && this.resolve(true);
  }
}
