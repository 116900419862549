import { observer } from "mobx-react";
import * as React from "react";
import { HorizontalCard } from "../../../../shared/components/card/horizontalCard/HorizontalCard";
import { MotifImage } from "../../../../shared/components/image/Image";
import { Link } from "../../../../shared/components/link/Link";
import { ProgressBar } from "../../../../shared/components/progressBar/ProgressBar";
import { SidePanel } from "../../../../shared/components/sidePanel/SidePanel";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { TrainingGroundDomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/trainingGroundDomainThumbnail/TrainingGroundDomainThumbnail";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { formatProgress } from "../../../../shared/formatProgress";
import { FormattedText } from "../../../../shared/textUtils";
import { useUrl } from "../../../../useRouter";
import { BuyCourse } from "../../../shared/buyCourse/BuyCourse";
import { CongratulationPopupViewModel } from "./CongratulationPopupViewModel";

interface CongratulationPopupProps {
  model: CongratulationPopupViewModel;
}

export const CongratulationPopup = observer(({ model }: CongratulationPopupProps) => {
  const translations = useTranslations();
  const url = useUrl();

  const {
    isOpen,
    close,
    congratulationPopupModel,
    isLastLesson,
    isLastElement,
    buyCourseViewModel,
    relatedTrainingGround,
    previousExamsStructure,
    isAnyTrainingAvailable,
  } = model;
  const { courseStructure, lesson, courseNavigator, lessonProgress } = congratulationPopupModel;
  const { themeStructure } = lesson;
  const { urlParams } = courseStructure;

  const { actionableStepsCount, completedStepsCount, progress, isCompleted } = lessonProgress;
  const unfinishedStepsCount = actionableStepsCount - completedStepsCount;
  const { unitInterval } = formatProgress(progress);

  const {
    getFirstThemeTest,
    getFirstUnfinishedStep,
    getFirstStepFromTheNextLesson,
    getFirstStepFromTheNextTheme,
    getNextTheme,
  } = courseNavigator;

  const getPopupContent = () => {
    if (isCompleted) {
      if (!isLastLesson) {
        const firstStepFromTheNextLesson = getFirstStepFromTheNextLesson(lesson);
        return {
          title: translations.congratulationsSingleLessonCompleted,
          linkText: translations.nextLesson,
          linkTo: firstStepFromTheNextLesson && url.toLessonPage(urlParams, firstStepFromTheNextLesson),
          hasAccess: firstStepFromTheNextLesson?.hasAccess,
        };
      }

      const themeTest = getFirstThemeTest(lesson);
      if (themeTest) {
        return {
          title: translations.congratulationsTestAfterTheme,
          linkText: translations.takeTest,
          linkTo: url.toThemeTestPage(urlParams, themeTest.themeStructure.slug, themeTest.slug),
          hasAccess: themeTest.hasAccess,
        };
      }

      if (isLastElement) {
        return {
          title: translations.congratulationsWellDone,
          linkText: translations.goToDashboard,
          linkTo: url.toCoursePage(urlParams),
          hasAccess: true,
        };
      }

      const firstStepFromTheNextTheme = getFirstStepFromTheNextTheme(lesson);
      return {
        title: translations.congratulationsFinishedTheme,
        linkText: translations.goToNextTheme,
        linkTo: firstStepFromTheNextTheme && url.toLessonPage(urlParams, firstStepFromTheNextTheme),
        hasAccess: firstStepFromTheNextTheme?.hasAccess,
      };
    }

    const firstUnfinishedStep = getFirstUnfinishedStep(lesson);
    return {
      title: FormattedText(translations.missingXStepsInLesson, [unfinishedStepsCount]),
      linkText: translations.yesIWantToFinish,
      linkTo: firstUnfinishedStep && url.toLessonPage(urlParams, firstUnfinishedStep),
      hasAccess: firstUnfinishedStep?.hasAccess,
    };
  };

  const getSecondaryLink = () => {
    if (isLastLesson) {
      const nextTheme = getNextTheme(lesson.themeStructure);

      if (nextTheme) {
        const themeTest = getFirstThemeTest(lesson);

        if ((isCompleted && themeTest) || !isCompleted) {
          return { text: translations.goToNextTheme, to: url.toThemePage(urlParams, nextTheme.slug) };
        }
      }
    }

    return { text: translations.goToThemePage, to: url.toThemePage(urlParams, lesson.themeStructure.slug) };
  };

  const popupContent = getPopupContent();
  const secondaryLink = getSecondaryLink();

  return (
    <SidePanel isOpen={isOpen} onClose={close}>
      <div className="CongratulationPopup">
        <div className="CongratulationPopup__inner">
          <div className="CongratulationPopup__progressWrapper">
            <span className="CongratulationPopup__progressInfo">{`${completedStepsCount} / ${actionableStepsCount}`}</span>
            <ProgressBar progress={unitInterval} size="large" variant="dark" type="success" />
          </div>
          {popupContent.hasAccess === false ? (
            <div className="CongratulationPopup__buyCourseWrapper">
              <BuyCourse model={buyCourseViewModel} />
            </div>
          ) : (
            <>
              <Title level={2} className="CongratulationPopup__title">
                {popupContent.title}
              </Title>
              <div className="CongratulationPopup__actionWrapper">
                {popupContent.linkTo && (
                  <Link className="CongratulationPopup__link" type="button" to={popupContent.linkTo} onClick={close}>
                    <span>{popupContent.linkText}</span>
                    <SvgIcon iconName="right-arrow-line" />
                  </Link>
                )}
                {secondaryLink && (
                  <Link
                    to={secondaryLink.to}
                    className="CongratulationPopup__link CongratulationPopup__link--secondary"
                    type="button"
                    buttonVariant="secondary"
                    buttonType="outline"
                  >
                    {secondaryLink.text}
                  </Link>
                )}
              </div>
              {isAnyTrainingAvailable && (
                <div className="CongratulationPopup__trainingSection">
                  <Title level={3} className="CongratulationPopup__trainingTitle">
                    {translations.additionalContentHeading}
                  </Title>
                  <ul className="CongratulationPopup__trainingList">
                    {relatedTrainingGround && (
                      <li>
                        <TrainingGroundDomainThumbnail
                          model={{
                            ...relatedTrainingGround,
                            title:
                              FormattedText(translations.themeTrainingTitle, [themeStructure.title]) ||
                              relatedTrainingGround.title,
                          }}
                          href={url.toTrainingGroundPage(urlParams, relatedTrainingGround.slug, themeStructure.slug)}
                          thumbnailTitleLevel={4}
                          thumbnailType="horizontalCard"
                        />
                      </li>
                    )}
                    {previousExamsStructure && (
                      <li>
                        <HorizontalCard
                          title={{
                            text: previousExamsStructure.title,
                            level: 4,
                          }}
                          type="PreviousExams"
                          href={url.toPreviousExamsPage(urlParams)}
                          category={translations.previousExams}
                          image={previousExamsStructure.image}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              )}
              <div className="CongratulationPopup__imageWrapper">
                <MotifImage image={{ fileName: "congratulations.png" }} className="CongratulationPopup__image" />
              </div>
            </>
          )}
        </div>
      </div>
    </SidePanel>
  );
});
