import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { TaskView } from "../../../../../shared/components/tasks/TaskView";
import { MaskTaskContent } from "../../../../../shared/components/tasks/maskTask/maskTaskContent/MaskTaskContent";
import { MaskTaskFeedback } from "../../../../../shared/components/tasks/maskTask/maskTaskFeedback/MaskTaskFeedback";
import { MaskTaskStage } from "../../../../../shared/components/tasks/maskTask/maskTaskStage/MaskTaskStage";
import { TrainingTask } from "./TrainingTask";

interface TrainingTaskProps {
  trainingTask: TrainingTask;
}

export const TrainingTaskView = observer((props: TrainingTaskProps) => {
  const { trainingTask } = props;
  const { task } = trainingTask;

  React.useEffect(() => {
    trainingTask.visit();
  }, [trainingTask.task.model.id]);

  return (
    <div data-testid="TrainingTask" className="TrainingTask">
      {task._type === "MaskTask" ? (
        <>
          <MaskTaskFeedback
            state={task.model.state}
            className={classNames("TrainingMaskTask__feedback", {
              "TrainingMaskTask__feedback--correct": task.model.state.type === "correct",
              "TrainingMaskTask__feedback--wrong":
                task.model.state.type === "incorrect" || task.model.state.type === "partiallyCorrect",
            })}
          />
          <MaskTaskContent content={task.model.content} />
          <MaskTaskStage model={task.model} />
        </>
      ) : (
        <TaskView task={task} />
      )}
    </div>
  );
});
