import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { StepApi } from "./StepApi";
import { StepStore } from "./StepStore";
import { LessonViewModel } from "./lesson/LessonViewModel";
import { StepFactory } from "./lesson/steps/StepFactory";

export type LessonParams = { stepSlug: string };

export const addLessonsRoutes = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const lessonsRoutes = courseRoute.addRoute<LessonViewModel, LessonParams>(
    `${RouteConfiguration.Lessons}/:stepSlug`,
    LessonViewModel,
    (coursePage, p) => {
      const {
        courseStructure,
        courseNavigator,
        progressStore,
        bookmarkStore,
        userStore,
        paymentSidePanelViewModel,
        navigation,
        backendApi,
        languageService,
        errorService,
        gtm,
      } = coursePage;
      const lesson = courseStructure.getLessonByStepSlug(p.stepSlug);

      if (lesson.themeStructure.isUnderDevelopment) {
        throw new Error(
          `Trying to enter the step from the under development theme with id: ${lesson.themeStructure.id}`
        );
      }

      const { urlParams } = courseStructure;

      const stepFactory = new StepFactory(
        lesson.color,
        progressStore,
        bookmarkStore,
        backendApi,
        gtm,
        urlParams,
        navigation
      );

      const stepStore = new StepStore(new StepApi(courseStructure.id, backendApi), stepFactory);

      const lessonViewModel = new LessonViewModel(
        p.stepSlug,
        lesson,
        stepStore,
        backendApi,
        progressStore,
        bookmarkStore,
        userStore,
        languageService,
        errorService,
        paymentSidePanelViewModel,
        navigation,
        gtm,
        courseNavigator
      );

      return coursePage.showChildPage(lessonViewModel);
    },
    page => ({ stepSlug: page.currentStepSlug })
  );

  return lessonsRoutes;
};
