import * as React from "react";
import { NavigationLink } from "../../../../shared/components/navigationLink/NavigationLink";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { useUrl } from "../../../../useRouter";

interface LessonHeadingProps {
  activeCourseStructure: CourseStructure;
  lessonTitle: string;
  themeSlug: string;
}

export const headingId = "lessonHeading";

export const LessonHeading: React.FC<LessonHeadingProps> = ({ lessonTitle, themeSlug, activeCourseStructure }) => {
  const url = useUrl();
  const translations = useTranslations();

  return (
    <div className="LessonHeading">
      <div className="LessonHeading__navigation">
        <NavigationLink
          to={url.toThemePage(activeCourseStructure.urlParams, themeSlug)}
          text={translations.goBackToThemePage}
          type="back"
        />
      </div>
      <div className="LessonHeading__titleWrapper" id={headingId}>
        <p className="LessonHeading__title">{lessonTitle}</p>
      </div>
    </div>
  );
};
