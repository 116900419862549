import sample from "lodash/sample";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { ProgressRing } from "../../../../shared/components/progressRing/ProgressRing";
import { SidePanel } from "../../../../shared/components/sidePanel/SidePanel";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { LevelSidePanelViewModel } from "./LevelSidePanelViewModel";
import { AnimatedLevel } from "./animatedLevel/AnimatedLevel";

export interface LevelSidePanelProps {
  model: LevelSidePanelViewModel;
}

type PanelTextType = [title: string, description?: string];

export const LevelSidePanel = observer((props: LevelSidePanelProps) => {
  const { model } = props;
  const { isOpen, newLevel, oldLevel, progressValue, close } = model;
  const translations = useTranslations();
  const panelTexts: PanelTextType[] = [
    [translations.goodJob],
    [translations.keepGoing],
    [translations.youAreMakingProgress],
    [translations.youAreDoingGreat],
    [translations.wellDone],
    [translations.dontStopNow],
  ];

  const [title, description] = React.useMemo(() => sample(panelTexts) ?? [], [newLevel]);

  return (
    <SidePanel isOpen={isOpen} onClose={close} className="LevelSidePanel">
      <div className="LevelSidePanel__inner">
        <div className="LevelSidePanel__text">
          <h2 className="LevelSidePanel__title">{title}</h2>
          <p className="LevelSidePanel__description">{description}</p>
        </div>
        {newLevel && (
          <div className="LevelSidePanel__level">
            <AnimatedLevel newLevel={newLevel} oldLevel={oldLevel} />
          </div>
        )}
        <div className="LevelSidePanel__progressRingWrapper">
          <div className="LevelSidePanel__progressRing">
            <ProgressRing progress={progressValue} />
          </div>
        </div>
        <Button className="LevelSidePanel__button" type="outline" variant="secondary" onClick={close}>
          {translations.ok}
        </Button>
      </div>
    </SidePanel>
  );
});
