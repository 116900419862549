import { ISearchThumbnail } from "../../../../pages/search/searchItem/SearchItemViewModel";

export const useThumbnailImage = (thumbnail: ISearchThumbnail) => {
  const { type } = thumbnail;

  switch (type) {
    case "Video":
    case "Article":
    case "Theme":
      return thumbnail.image;
    default:
      return undefined;
  }
};
