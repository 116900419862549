import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { TermsAndConditionsViewModel } from "./TermsAndConditionsViewModel";

export const addTermsAndConditionsRoute = (route: IRoute<App, unknown, unknown>) => {
  const termsAndConditionsRoute = route.addRoute<TermsAndConditionsViewModel>(
    `${RouteConfiguration.TermsAndConditions}`,
    TermsAndConditionsViewModel,
    app => {
      const termsAndConditions = new TermsAndConditionsViewModel(
        app.languageService,
        app,
        app.gtm,
        app.config.termsAndConditions
      );

      return app.showChildPage(termsAndConditions);
    }
  );
  return termsAndConditionsRoute;
};
