import classNames from "classnames";
import * as React from "react";
import { OptionStatus } from "../../shared/OptionStatus";

interface ChoiceListOptionStatusProps {
  status: OptionStatus;
  variant: "full-width" | "limited-width";
  children: React.ReactNode;
  className?: string;
}

export const ChoiceListOptionStatus: React.FC<ChoiceListOptionStatusProps> = props => {
  const { status, children, className, variant } = props;

  return (
    <div className={classNames("ChoiceListOptionStatus", `ChoiceListOptionStatus--${status}`, className)}>
      <div
        className={classNames(
          { "ChoiceListOptionStatus__children--limitedWidth": variant === "limited-width" },
          { "ChoiceListOptionStatus__children--fullWidth": variant === "full-width" }
        )}
      >
        {children}
      </div>
    </div>
  );
};
