import { action, computed } from "mobx";
import { assertState } from "../../../../stateMembers";
import { MaskTaskViewModel } from "../MaskTaskViewModel";
import { MaskViewModel } from "../MaskViewModel";
import { MaskSelectionStrategy } from "./MaskSelectionStrategy";

export class SingleChoiceMaskStrategy implements MaskSelectionStrategy {
  @computed private get selectedMask() {
    return this.maskTask.masks.find(mask => mask.state.type === "selected");
  }

  constructor(private maskTask: MaskTaskViewModel) {}

  @action.bound
  toggleSelection(_mask: MaskViewModel) {
    const mask = _mask;
    assertState(mask.state, "notSelected", "selected", "highlighted");

    if (mask.state.type === "selected") {
      mask.state = { type: "notSelected", image: mask.state.image };
      return;
    }

    if (this.selectedMask) {
      this.selectedMask.toggleSelection();
    }

    mask.state = { type: "selected", image: mask.state.image };
  }
}
