import { computed } from "mobx";
import { ITestService } from "../../../../shared/api/BackendApi";
import { ThemeTestDomainThumbnailViewModel } from "../../../../shared/components/thumbnails/domainThumbnail/ThemeTestDomainThumbnailViewModel";
import { ThemeStructure } from "../../../../shared/contentStructure/ThemeStructure";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";

export class ThemeTrainingSectionViewModel {
  @computed private get testStructures() {
    return this.theme.testStructures;
  }

  @computed get tests() {
    return this.testStructures.map(
      test => new ThemeTestDomainThumbnailViewModel(test, this.paymentSidePanelViewModel, this.testService)
    );
  }

  @computed get relatedTrainingGround() {
    return this.theme.relatedTrainingGround;
  }

  get activeCourseParams() {
    return this.theme.courseStructure.urlParams;
  }

  get themeSlug() {
    return this.theme.slug;
  }

  get themeTitle() {
    return this.theme.title;
  }

  constructor(
    private theme: ThemeStructure,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    private testService: ITestService
  ) {}
}
