import * as PopperJS from "@popperjs/core";
import classNames from "classnames";
import isString from "lodash/isString";
import uniqueId from "lodash/uniqueId";
import React, { AriaAttributes } from "react";
import ReactDOM from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import { animated, useTransition } from "react-spring";
import "react-popper-tooltip/dist/styles.css";

interface TriggerElementProps {
  children: React.ReactNode;
  setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const TriggerElement = (props: TriggerElementProps & Pick<AriaAttributes, "aria-describedby">) => {
  const { children, setTriggerRef } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const ariaDescribedby = props["aria-describedby"];
  const getTriggerProps = (defaultProps = {}) => ({
    ...defaultProps,
    "aria-describedby": ariaDescribedby,
    ref: setTriggerRef,
  });
  const shouldWrap = isString(children);

  if (shouldWrap) {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <span tabIndex={0} {...getTriggerProps()}>
        {children}
      </span>
    );
  }

  const child = React.Children.only(children) as React.ReactElement & {
    ref?: React.Ref<unknown>;
  };
  return React.cloneElement(child, { ...getTriggerProps(child.props) });
};

interface TooltipProps {
  label: React.ReactNode;
  children: React.ReactNode;
  placement?: PopperJS.Placement;
}

export const Tooltip: React.FC<TooltipProps> = props => {
  const { label, children, placement } = props;

  const [controlledVisible, setControlledVisible] = React.useState(false);
  const tooltipId = React.useRef(uniqueId("tooltip-"));

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    trigger: ["hover", "focus"],
    placement,
    offset: [0, 8],
  });

  const transitions = useTransition(controlledVisible, {
    from: { opacity: 0 },
    enter: { opacity: 0.8 },
    leave: { opacity: 0 },
  });

  const tooltipProps = getTooltipProps({
    className: classNames("Tooltip__container", "tooltip-container"),
  });

  return (
    <>
      <TriggerElement setTriggerRef={setTriggerRef} aria-describedby={tooltipId.current}>
        {children}
      </TriggerElement>
      {transitions(({ opacity }, isVisible, { key }) => {
        if (!isVisible) {
          return null;
        }

        return ReactDOM.createPortal(
          <animated.div
            role="tooltip"
            id={tooltipId.current}
            key={key}
            ref={setTooltipRef}
            {...tooltipProps}
            style={{ ...tooltipProps.style, opacity }}
          >
            {label}
            <div {...getArrowProps({ className: classNames("Tooltip__arrow", "tooltip-arrow") })} />
          </animated.div>,
          document.body
        );
      })}
    </>
  );
};
