import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { ActiveCourseParams, Url } from "../models/Url";

/* eslint-disable class-methods-use-this */
export interface INavigationService {
  isRoot(productSlug: string, courseSlug?: string): boolean;
  goToTibetLogin(returnUrl?: string): void;
  goToLoginPage(): void;
  goToSearch(phrase: string, params: ActiveCourseParams): void;
  goToLandingPage(): void;
}

export interface ILowLevelNavigationService extends INavigationService {
  getCurrentLocation(): string;
  siteOrigin: string;
  redirectTo(url: string): void;
  pushUrlWithoutRedirect(url: string): void;
}

// The idea is that this should be the only place where we access window.location functionalities.
// We should never refer to them directly from our viewmodels (and, preferably, services).
export class NavigationService implements ILowLevelNavigationService {
  redirectTo(url: string) {
    window.location.href = url;
  }

  getCurrentLocation() {
    return window.location.pathname;
  }

  get siteOrigin() {
    return window.location.origin;
  }

  isRoot(productSlug: string, courseSlug?: string) {
    return (
      this.getCurrentLocation() ===
      (courseSlug
        ? Url.toCoursePage({
            productSlug,
            courseSlug,
          })
        : RouteConfiguration.ProductNest)
    );
  }

  goToTibetLogin(returnUrl?: string) {
    this.redirectTo(Url.toTibetLoginPage(returnUrl));
  }

  goToLoginPage() {
    this.redirectTo(Url.toLoginPage());
  }

  goToLandingPage() {
    this.redirectTo(Url.toProductNestPage());
  }

  pushUrlWithoutRedirect(url: string): void {
    const currentPath = window.location.pathname + window.location.search;
    if (url !== currentPath) {
      window.history.pushState({}, "", encodeURI(url));
    }
  }

  goToSearch(phrase: string, params: ActiveCourseParams): void {
    this.redirectTo(Url.toSearchPage(params, phrase));
  }
}
