import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

interface ListProps<T> {
  items: ReadonlyArray<T>;
  renderItem: (item: T, index: number) => React.ReactNode;
  empty?: React.ReactNode;
  className?: string;
  singleItemClassName?: string;
  id?: string;
}

export const List = observer(<T extends { id: string | number }>(props: ListProps<T>) => {
  const { items, renderItem, empty, className, id, singleItemClassName } = props;
  if (items.length <= 0) {
    return empty !== undefined ? <>{empty}</> : null;
  }

  return (
    <ul id={id} className={classNames("List", className)}>
      {items.map((item, index) => (
        <li key={item.id} className={singleItemClassName}>
          {renderItem(item, index)}
        </li>
      ))}
    </ul>
  );
});
