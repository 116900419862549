import { observer } from "mobx-react";
import React from "react";
import { ClipLoader } from "react-spinners";
import { Button } from "../../shared/components/button/Button";
import PeopleCelebrating from "../../static/images/people-celebrating.png";
import { PurchaseConfirmationViewModel } from "./PurchaseConfirmationViewModel";
import "./PurchaseConfirmation.css";

interface PurchaseConfirmationProps {
  model: PurchaseConfirmationViewModel;
}

export const PurchaseConfirmation: React.FC<PurchaseConfirmationProps> = observer(({ model }) => {
  const navigateToCoursePage = () => {
    if (model.courseId) {
      window.location.href = model.productAndCourseUrl;
    }
  };

  return (
    <div className="Wrapper">
      {model.courseTitle.length > 0 && !model.productIsLoading ? (
        <div className="Content">
          <img src={PeopleCelebrating} alt="" />
          <div className="TextAndButtonContainer">
            <h2>Velkommen!</h2>
            <p>Hurra, kjøpet var vellykket. Du har fått tilgang til {model.courseTitle}. Vi håper du blir fornøyd!</p>
            <p>Du får kvittering på e-post.</p>
            <p>
              Det kan hende du må laste inn siden på nytt etter at du har trykket på &quot;Kom i gang med læringen&quot;
              for å få tilgang.
            </p>
            <div className="GetStartedButtonContainer">
              <Button type="solid" variant="primary" onClick={navigateToCoursePage}>
                Kom i gang med læringen
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="Loading">
          <h2>Bekrefter kjøp...</h2>
          <ClipLoader color="var(--primaryBase)" />
        </div>
      )}
    </div>
  );
});
