import { observable } from "mobx";
import React from "react";
import { UserDto } from "../../types/shared/dto/UserDto";
import { UserStore } from "../stores/UserStore";

export class AnonymousUser {
  readonly _type = "anonymous";
}

export class LoggedInUser {
  @observable id: number;

  @observable firstName: string;

  @observable lastName: string;

  @observable accessibleCourseIds: string[];

  readonly _type = "loggedIn";

  constructor(userDto: UserDto) {
    this.id = userDto.id;
    this.firstName = userDto.firstName;
    this.lastName = userDto.lastName;
    this.accessibleCourseIds = userDto.accessibleCourseIds;
  }
}

export type User = AnonymousUser | LoggedInUser;

interface UserContext {
  user: User;
}

const userContext = React.createContext<UserContext>({
  user: new AnonymousUser(),
});

export const useAppUser = () => React.useContext<UserContext>(userContext).user;

export const useLoggedInAppUser = () => {
  const appUser = React.useContext<UserContext>(userContext).user;

  if (appUser._type !== "loggedIn") {
    throw new Error("Expected logged in user");
  }

  return appUser;
};

export const UserProvider = ({ children, userStore }: { children: React.ReactNode; userStore?: UserStore }) => {
  const { Provider } = userContext;
  const userDto = userStore ? userStore.user : undefined;
  const user = userDto ? new LoggedInUser(userDto) : new AnonymousUser();

  return <Provider value={{ user }}>{children}</Provider>;
};
