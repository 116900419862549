import { action, computed, observable } from "mobx";
import { IVideoService } from "../../../../shared/api/BackendApi";
import { Popup } from "../../../../shared/components/popup/Popup";
import {
  createStepThumbnailViewModel,
  IStepThumbnail,
} from "../../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";
import { Bookmark } from "../../BookmarkListStore";

export class BookmarkViewModel {
  @observable isInEditMode = false;

  @observable popover = new Popup();

  get id() {
    return this.bookmark.id;
  }

  @computed get thumbnail(): IStepThumbnail {
    return createStepThumbnailViewModel(this.bookmark.thumbnail, this.progressStore, this.videoService);
  }

  constructor(
    public bookmark: Bookmark,
    public paymentSidePanelViewModel: PaymentSidePanelViewModel,
    public activeCourseStructure: CourseStructure,
    private progressStore: IProgressStore,
    private videoService: IVideoService
  ) {}

  @action.bound
  setIsInEditMode(val: boolean) {
    this.isInEditMode = val;
  }

  @action.bound
  deleteBookmark() {
    this.bookmark.delete();
  }

  @action.bound
  updateMessage(message?: string) {
    if (message === this.bookmark.message) {
      return;
    }
    this.bookmark.message = message;
    this.bookmark.saveChanges();
  }
}
