import debounce from "lodash/debounce";
import { action, computed, observable, reaction } from "mobx";
import { IPage } from "react-mvvm";
import Scroll from "react-scroll";
import { CookiesConsentViewModel } from "../layout/cookiesConsent/CookiesConsentViewModel";
import { FooterViewModel } from "../layout/footer/FooterViewModel";
import { HeaderViewModel } from "../layout/header/HeaderViewModel";
import { BackendApi } from "../shared/api/BackendApi";
import { ITibetClient } from "../shared/api/TibetClient";
import { Url } from "../shared/models/Url";
import { IErrorService } from "../shared/services/ErrorService";
import { IGtmService } from "../shared/services/GtmService";
import { ILanguageService } from "../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../shared/services/NavigationService";
import { PageNotFoundError } from "../shared/services/errors/HttpError";
import { ProductStore } from "../shared/stores/ProductStore";
import { UserStore } from "../shared/stores/UserStore";
import { ConfigDto } from "../types/config/dto/ConfigDto";
import { TranslatedPage } from "./TranslatedPage";
import { CourseViewModel } from "./course/CourseViewModel";
import { ProductViewModel } from "./product/ProductViewModel";
import { ProductNestViewModel } from "./productNest/ProductNestViewModel";
import { getMotif, IAppMotifProvider } from "./shared/AppMotif";

export interface AppQuery {
  product: string;
}

export type DisplayedContent =
  | { _type: "Page"; page: IPage }
  | { _type: "Error"; error: Error }
  | { _type: "ProductNest"; vm: ProductNestViewModel };

export class App extends TranslatedPage implements IErrorService, IAppMotifProvider {
  @observable.ref public header: HeaderViewModel;

  @observable.ref public footer: FooterViewModel;

  @observable.ref public cookiesConsent: CookiesConsentViewModel;

  @observable splashError?: Error;

  @observable errorToastCounter: number;

  @observable productNest?: ProductNestViewModel;

  @computed get appMotif() {
    return getMotif(this.childPage);
  }

  @computed get content(): DisplayedContent | undefined {
    if (this.splashError) {
      return { _type: "Error", error: this.splashError };
    }

    if (this.childPage) {
      return { _type: "Page", page: this.childPage };
    }

    if (this.productNest) {
      // I believe that this is the time when we can reorganize routing and add product nest as first child of App not keep it parallel with Product
      this.productNest.activate();
      return { _type: "ProductNest", vm: this.productNest };
    }
    return undefined;
  }

  @computed get isAuthenticated() {
    return this.userStore.isAuthenticated;
  }

  // react-mvvm provides currently displayed page via childPage. The issue is that
  // when the page changes, sometimes it is first set to undefined and only then set to
  // the next child page (depending on how we redirect). In those cases, when we subscribe to
  // childPage, we get a sequence page_prev -> undefined -> page_current.
  // In order not to report on seeing product page on this undefined in between, we use
  // debouncing.
  //
  // We only report here product when there is no child page - this is how we detect Landing is displayed.
  // For the rest of the pages, the reporting is done in onActivated, and this should be
  // the default behavior for majority of the pages.
  debouncedLog = debounce((page: IPage | undefined) => {
    if (!page) {
      this.gtm.pushPageViewed();
    }
  }, 100);

  constructor(
    public config: ConfigDto,
    public languageService: ILanguageService,
    public productStore: ProductStore,
    public userStore: UserStore,
    public tibetClient: ITibetClient,
    public navigation: ILowLevelNavigationService,
    public backendApi: BackendApi,
    public gtm: IGtmService
  ) {
    super(languageService);
    this.header = new HeaderViewModel(Url, this, this, this.handleLogin);
    this.footer = new FooterViewModel(Url, this);
    this.cookiesConsent = new CookiesConsentViewModel(this.config, this.gtm);
    this.errorToastCounter = 0;
    reaction(() => this.childPage, this.debouncedLog, {
      fireImmediately: true,
    });
    reaction(
      () => this.isAuthenticated,
      isAuthenticated => this.gtm.pushUserStatusChanged(isAuthenticated),
      { fireImmediately: true }
    );

    reaction(
      () => this.childPage,
      _ => {
        this.productStore.activeProduct?.resetActiveCourseStructure();
      }
    );
  }

  async onLanguageChange() {
    this.header.headerHamburgerModel.mobileMenu.close();
    this.header.headerHamburgerModel.desktopMenu.close();
    this.cookiesConsent.cookiesDetailsModal.close();
    this.initProductNest();
  }

  @action
  showErrorSplash(error: Error) {
    this.splashError = error;
  }

  @action
  showErrorToast() {
    const next = this.errorToastCounter + 1;
    this.errorToastCounter = Number.isSafeInteger(next) ? next : 1;
  }

  @action.bound
  async handleLogin(courseSlug?: string) {
    const activeProduct = this.productStore.activeProduct;

    if (!activeProduct) {
      this.gtm.pushLoginStarted("Started Login process");
      this.navigation.goToTibetLogin(courseSlug);
      return;
    } else {
      if (courseSlug === undefined) {
        this.navigation.goToTibetLogin(Url.toProductPage(activeProduct.productParams));
        return;
      }

      this.gtm.pushLoginStarted("Started Login Process");
      this.navigation.goToTibetLogin(Url.toCoursePage({ ...activeProduct.productParams, courseSlug }));
    }
  }

  @action.bound
  async handleCourseEnter(courseSlug?: string) {
    const activeProduct = this.productStore.activeProduct;

    if (!activeProduct) {
      return;
    }

    if (courseSlug === undefined) {
      this.navigation.redirectTo(Url.toProductPage(activeProduct.productParams));
      return;
    }

    this.navigation.redirectTo(Url.toDashboardPage({ ...activeProduct.productParams, courseSlug }));
  }

  async onInvalidRoute() {
    this.showErrorSplash(new PageNotFoundError("Invalid route"));
  }

  handleLocationChange() {
    this.splashError = undefined;
    this.header.headerHamburgerModel.mobileMenu.close();
    this.header.headerHamburgerModel.desktopMenu.close();
    this.header.userLinksSheet.close();
    this.cookiesConsent.cookiesDetailsModal.close();
    setTimeout(Scroll.animateScroll.scrollToTop, 0); // It's a hack!
  }

  initProductNest() {
    this.productNest = new ProductNestViewModel(
      this.config,
      this.languageService,
      this.productStore,
      this.userStore,
      this.tibetClient,
      this.navigation,
      this.backendApi,
      this.gtm,
      this
    );
  }

  validateNextChildOfPage() {
    // if child is course and do not have next child render error page eg. /math/
    const { activeProduct } = this.productStore;
    if (
      activeProduct &&
      this.childPage instanceof CourseViewModel &&
      !this.childPage.childPage &&
      !this.navigation.isRoot(activeProduct.slug, activeProduct.courseStructure?.slug)
    ) {
      this.showErrorSplash(new PageNotFoundError("Invalid next child page"));
    }
  }

  // Loading happens before activation, because onActivated may not be called if the url is malformed
  async beforeActivated() {
    if (!this.productNest) {
      this.initProductNest();
    }

    this.validateNextChildOfPage();
  }

  // TODO: I think that this function in unreachable (we are resetting the product on Product Nest)
  async onActivated() {
    const product = this.productStore.activeProduct;
    // temporary cause for now productNestVM force to navigate to /glu HARDCODED!
    // if (this.productStore.isOnlyOneProduct && product && this.navigation.isRoot(product.slug)) {
    if (product && this.navigation.isRoot(product.slug)) {
      this.navigation.pushUrlWithoutRedirect(product.slug);
      this.showChildPage(
        new ProductViewModel(
          { productSlug: product.slug },
          this.config,
          this.productStore,
          this.userStore,
          this.navigation,
          this.gtm,
          this.tibetClient,
          this.backendApi,
          this.languageService,
          this,
          this.handleCourseEnter,
          this.handleLogin
        )
      );
    }
    await super.onActivated();
  }
}
