import { computed, observable } from "mobx";
import { IVideoService } from "../../../shared/api/BackendApi";
import { BookmarkToggleViewModel } from "../../../shared/components/thumbnails/shared/BookmarkToggleViewModel";
import {
  createStepThumbnailViewModel,
  IStepThumbnail,
} from "../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { LessonStructure } from "../../../shared/contentStructure/LessonStructure";
import { BookmarkStore } from "../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../shared/stores/ProgressStore";
import { UserStore } from "../../../shared/stores/UserStore";

type LessonListItem = Omit<LessonStructure, "themeStructure" | "courseStructure">;

export class LessonListItemViewModel {
  @computed get id() {
    return this.lesson.id;
  }

  @observable stepThumbnails: IStepThumbnail[];

  constructor(
    public index: number,
    public lesson: LessonListItem,
    public progressStore: IProgressStore,
    public videoService: IVideoService,
    bookmarkStore: BookmarkStore,
    userStore: UserStore,
    isBookmarkToggleVisible = true
  ) {
    this.stepThumbnails = lesson.stepStructures.map(step =>
      createStepThumbnailViewModel(
        step.thumbnail,
        progressStore,
        videoService,
        isBookmarkToggleVisible ? new BookmarkToggleViewModel(step.id, bookmarkStore, userStore) : undefined,
        lesson.isHighlighted
      )
    );
  }
}
