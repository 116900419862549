import { observer } from "mobx-react";
import * as React from "react";
import { TranslationsDto } from "../../../types/translations/dto/TranslationsDto";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { CourseInfo } from "../../stores/Product";
import { FormattedText } from "../../textUtils";
import { CourseThumbnail } from "../courseThumbnail/CourseThumbnail";
import { List } from "../list/List";

export interface CoursesSectionProps {
  courses: CourseInfo[];
  coursesSectionId: string;
  sectionTitle: keyof TranslationsDto;
  productName: string;
  onCourseClick: (slug: string) => void;
}

export const CoursesSection = observer((props: CoursesSectionProps) => {
  const { courses, coursesSectionId, sectionTitle, onCourseClick, productName } = props;
  const translations = useTranslations();

  return (
    <div className="CoursesSection" id={coursesSectionId}>
      <div className="CoursesSection__inner">
        <h2 className="CoursesSection__title">{FormattedText(translations[sectionTitle], [productName])}</h2>
        <div className="CoursesSection__listWrapper">
          <List
            className="CoursesSection__list"
            items={courses}
            renderItem={course => <CourseThumbnail {...course} productTitle={productName} onClick={onCourseClick} />}
          />
        </div>
      </div>
    </div>
  );
});
