//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum SearchContentTypeDto { 
	TextTask = "TextTask", 
	ChoiceTask = "ChoiceTask", 
	ChoiceListTask = "ChoiceListTask", 
	TaskGroup = "TaskGroup", 
	MaskTask = "MaskTask", 
	InputTask = "InputTask", 
	Video = "Video", 
	TextBlock = "TextBlock", 
	Article = "Article", 
	Theme = "Theme", 
	Test = "Test", 
	GapTask = "GapTask", 
	MatchTask = "MatchTask"
}
