import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { BookmarkListApi } from "./BookmarkListApi";
import { BookmarkListStore } from "./BookmarkListStore";
import { BookmarkListViewModel } from "./bookmarkList/BookmarkListViewModel";

export const addBookmarksRoutes = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const bookmarksRoutes = courseRoute.addRoute<BookmarkListViewModel>(
    RouteConfiguration.Bookmarks,
    BookmarkListViewModel,
    coursePage => {
      const bookmarkListApi = new BookmarkListApi(coursePage.backendApi);
      const bookmarkListStore = new BookmarkListStore(bookmarkListApi);

      const bookmarks = new BookmarkListViewModel(
        coursePage.courseStructure,
        coursePage.languageService,
        coursePage.errorService,
        bookmarkListStore,
        coursePage.progressStore,
        coursePage.backendApi,
        coursePage.paymentSidePanelViewModel,
        coursePage.gtm
      );
      return coursePage.showChildPage(bookmarks);
    }
  );

  return bookmarksRoutes;
};
