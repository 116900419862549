import { computed } from "mobx";
import { IVideoService } from "../../../../shared/api/BackendApi";
import { Popup } from "../../../../shared/components/popup/Popup";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { LessonStructure } from "../../../../shared/contentStructure/LessonStructure";
import { isLastElement } from "../../../../shared/listUtils";
import { LessonProgress } from "../../../../shared/progressStructure/LessonProgress";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { IUserStore } from "../../../../shared/stores/UserStore";
import { CourseNavigator } from "../../../shared/courseNavigator/CourseNavigator";
import { PaymentSidePanelViewModel } from "../../../shared/paymentDialog/PaymentSidePanelViewModel";

interface CongratulationPopupModel {
  courseStructure: CourseStructure;
  lesson: LessonStructure;
  progressStore: IProgressStore;
  lessonProgress: LessonProgress;
  videoService: IVideoService;
  bookmarkStore: IBookmarkStore;
  userStore: IUserStore;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  courseNavigator: CourseNavigator;
}

export class CongratulationPopupViewModel extends Popup {
  @computed get relatedTrainingGround() {
    const { lesson } = this.congratulationPopupModel;
    const { themeStructure } = lesson;

    return themeStructure.relatedTrainingGround;
  }

  @computed get previousExamsStructure() {
    const { courseStructure } = this.congratulationPopupModel;

    return courseStructure.previousExamsStructure;
  }

  @computed get isAnyTrainingAvailable() {
    return !!this.relatedTrainingGround || !!this.previousExamsStructure;
  }

  @computed get isLastLesson() {
    const { lesson } = this.congratulationPopupModel;
    const { themeStructure } = lesson;

    return isLastElement(themeStructure.lessonStructures, lesson);
  }

  @computed get isLastElement() {
    const { lesson } = this.congratulationPopupModel;
    const { themeStructure } = lesson;
    const { courseStructure } = themeStructure;
    return isLastElement(courseStructure.themeStructures, themeStructure);
  }

  @computed get buyCourseViewModel() {
    return this.congratulationPopupModel.paymentSidePanelViewModel.buyCourseVm;
  }

  constructor(public congratulationPopupModel: CongratulationPopupModel) {
    super();
  }
}
