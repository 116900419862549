import React from "react";
import placeholderImage from "../../../static/images/superhero-dog.png";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { Image, SanityImage } from "../image/Image";

interface PersonCardProps {
  name: string;
  role: string;
  image?: ImageDto;
}

export const PersonCard: React.FC<PersonCardProps> = props => {
  const { image, name, role } = props;
  return (
    <div className="PersonCard">
      <Image.Container aspectRatio="1/1" className="PersonCard__imageContainer">
        {image ? <SanityImage {...image} className="PersonCard__image" /> : <Image url={placeholderImage} />}
      </Image.Container>
      <p className="PersonCard__name">{name}</p>
      <p className="PersonCard__role">{role}</p>
    </div>
  );
};
