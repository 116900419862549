import React from "react";

export interface Row {
  rowCells: {
    content: unknown[];
    _key: string;
  }[];
  key: string;
}

interface TableProps {
  rows: Row[];
  renderBlocks: (blocks: unknown) => React.ReactNode;
}

export const Table: React.FC<TableProps> = props => {
  const { rows, renderBlocks } = props;

  return (
    <div className="Table">
      <table className="Table__table">
        <tbody>
          {rows.map(row => (
            <tr key={row.key} className="Table__row">
              {row.rowCells.map(cell => (
                <td key={cell._key} className="Table__cell">
                  {renderBlocks(cell.content)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
