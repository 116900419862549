import classNames from "classnames";
import React from "react";
import { List } from "../../../../../shared/components/list/List";
import { OrderIndicator } from "../../../../../shared/components/orderIndicator/OrderIndicator";
import { PortableTextContent } from "../../../../../shared/components/portableTextContent/PortableTextContent";
import { SectionHeading } from "../../../../../shared/components/sectionHeading/SectionHeading";
import { Title } from "../../../../../shared/components/title/Title";
import { alphabet } from "../../../../../shared/textUtils";
import { TaskStepView } from "../taskStep/TaskStepView";
import { TaskGroupStep } from "./TaskGroupStep";

export type TaskGroupStepViewProps = {
  model: TaskGroupStep;
};

export const TaskGroupStepView: React.FC<TaskGroupStepViewProps> = props => {
  const {
    model: { taskGroupItems, content, shouldTaskTitlesBeDisplayed, orderIndicatorColor },
  } = props;
  return (
    <div className="TaskGroupStep">
      {content && (
        <div className="TaskGroupStep__content">
          <PortableTextContent content={content} />
        </div>
      )}
      <List
        className="TaskGroupStep__tasksList"
        items={taskGroupItems}
        renderItem={(taskGroupItem, index) => {
          return (
            <>
              <SectionHeading
                level="withoutLevel"
                title={<OrderIndicator color={orderIndicatorColor}>{`${alphabet.charAt(index)})`}</OrderIndicator>}
              />
              <div className="TaskGroupStep__task">
                <Title
                  level={3}
                  className={classNames("TaskGroupStep__taskTitle", {
                    "TaskGroupStep__taskTitle--hidden": !shouldTaskTitlesBeDisplayed,
                  })}
                >
                  {taskGroupItem.title}
                </Title>
                {taskGroupItem.description && (
                  <div className="TaskGroupStep__taskDescription">{taskGroupItem.description}</div>
                )}
                <div className="TaskGroupStep__taskContent">
                  <TaskStepView task={taskGroupItem.task} hint={taskGroupItem.hint} solution={taskGroupItem.solution} />
                </div>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
