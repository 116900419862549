import { observable } from "mobx";
import { AddBookmarkDialogViewModel } from "../../../../pages/lessons/lesson/bookmarkDialog/addBookmarkDialog/AddBookmarkDialogViewModel";
import { RemoveBookmarkDialogViewModel } from "../../../../pages/lessons/lesson/bookmarkDialog/removeBookmarkDialog/RemoveBookmarkDialogViewModel";
import { command, Command } from "../../../../react-mvvm/src";
import { HintRelatedStepInfoDto } from "../../../../types/shared/dto/HintRelatedStepInfoDto";
import { ActiveCourseParams } from "../../../models/Url";
import { IBookmarkStore } from "../../../stores/BookmarkStore";

export class HintRelatedStepViewModel {
  @observable addBookmarkDialogViewModel: AddBookmarkDialogViewModel;

  @observable removeBookmarkDialogViewModel: RemoveBookmarkDialogViewModel;

  showBookmarkDialog: Command;

  get isRelatedStepBookmarked() {
    return this.bookmarkStore.isBookmarked(this.relatedStep.id);
  }

  constructor(
    public relatedStep: HintRelatedStepInfoDto,
    public activeCourseParams: ActiveCourseParams,
    private bookmarkStore: IBookmarkStore
  ) {
    this.addBookmarkDialogViewModel = new AddBookmarkDialogViewModel(this.relatedStep.id, this.bookmarkStore);
    this.removeBookmarkDialogViewModel = new RemoveBookmarkDialogViewModel(this.relatedStep.id, this.bookmarkStore);
    this.showBookmarkDialog = command(() => {
      if (this.isRelatedStepBookmarked) {
        this.removeBookmarkDialogViewModel.show();
      } else {
        this.addBookmarkDialogViewModel.show();
      }
    });
  }
}
