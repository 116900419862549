import React from "react";
import { TextContentWithPlaceholder } from "../../textContentWithPlaceholder/TextContentWithPlaceholder";

interface InlineGapProps {
  value?: number;
}

export const InlineGap: React.FC<InlineGapProps> = props => {
  const { value } = props;

  return (
    <span className="InlineGap">
      <TextContentWithPlaceholder isVisible={!!value}>{value}</TextContentWithPlaceholder>
    </span>
  );
};
