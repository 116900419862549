import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import * as React from "react";
import { animated, config, useSpring } from "react-spring";
import { ScrollLock } from "../../../pages/shared/scrollLock/ScrollLock";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { useDelayedDisplay } from "../../hooks/useDelayedDisplay";
import { Button } from "../button/Button";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface PanelProps {
  children: React.ReactNode;
  shouldBeVisible: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  className?: string;
}

export const Panel: React.FC<PanelProps> = ({ children, shouldBeVisible, onClose, header, className }) => {
  const [isDisplayed, hide] = useDelayedDisplay(shouldBeVisible);
  const translations = useTranslations();

  const panelStyles = useSpring({
    opacity: shouldBeVisible ? 1 : 0,
    onResolve: hide,
    config: config.stiff,
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className={classNames("Panel", className)}>
      <ScrollLock isLocked={isDisplayed} />
      <FocusTrap active={isDisplayed} focusTrapOptions={{ allowOutsideClick: true }}>
        <div className="Panel__outer">
          <animated.div className="Panel__wrapper" style={panelStyles}>
            <div className="Panel__inner">
              {header && <div className="Panel__header">{header}</div>}
              <div className="Panel__content">{children}</div>
            </div>
            <div className="Panel__closeButton">
              <Button type="circle" onClick={onClose}>
                <SvgIcon iconName="close" alt="" />
                <span className="visuallyHidden">{translations.close}</span>
              </Button>
            </div>
          </animated.div>
        </div>
      </FocusTrap>
      <animated.div style={panelStyles} aria-hidden onClick={onClose} className="Panel__background" />
    </div>
  );
};
