import classNames from "classnames";
import React from "react";

export interface OrderIndicatorProps {
  children: React.ReactNode;
  color: string;
  className?: string;
}

export const OrderIndicator = (props: OrderIndicatorProps) => {
  const { children, className, color } = props;
  return (
    <div style={{ backgroundColor: color }} className={classNames("OrderIndicator", className)}>
      {children}
    </div>
  );
};
