import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { TestChoiceListOptionDto } from "../../../../../types/tasks/testchoices/TestChoiceListOptionDto";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { SelectListItem, Select } from "../../../select/Select";
import { OptionStatus } from "../../shared/OptionStatus";
import { ChoiceListOptionStatus } from "../choiceListOptionStatus/ChoiceListOptionStatus";

interface ChoiceListOptionProps {
  choice: TestChoiceListOptionDto;
  shouldDisplayCorrectAnswer: boolean;
  status: OptionStatus;
  testOptions: SelectListItem<string>[];
  shouldDisplayOrderNumber?: boolean;
  value?: string;
  disabled?: boolean;
  handleValueChange: (value: string) => void;
  isTrainingGroundTask?: boolean;
}

export const ChoiceListOption = observer(
  ({
    choice,
    shouldDisplayCorrectAnswer,
    shouldDisplayOrderNumber,
    status,
    value,
    disabled,
    testOptions,
    handleValueChange,
    isTrainingGroundTask,
  }: ChoiceListOptionProps) => {
    const translations = useTranslations();

    return (
      <div className={classNames("ChoiceListOption", { "ChoiceListOption--checked": status !== "default" })}>
        {shouldDisplayOrderNumber && (
          <div className="ChoiceListOption__choiceNumberWrapper">
            <p className="ChoiceListOption__choiceNumber">{choice.number}</p>
          </div>
        )}
        <ChoiceListOptionStatus className="ChoiceListOption__answerStatus" status={status} variant="limited-width">
          <Select<string>
            isTrainingGroundTask={isTrainingGroundTask}
            className="ChoiceListOption__select"
            options={testOptions}
            placeholder={translations.select}
            onChange={handleValueChange}
            value={value}
            disabled={disabled}
          />
        </ChoiceListOptionStatus>
        {shouldDisplayCorrectAnswer && (
          <p
            className={classNames("ChoiceListOption__label", {
              "ChoiceListOption__label--visible": status === "incorrect",
            })}
          >
            {choice.content}
          </p>
        )}
      </div>
    );
  }
);
