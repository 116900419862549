import * as React from "react";

interface TestPageContextVariables {
  testPageBackgroundColor: string;
}

const testPageContextVariables: TestPageContextVariables = {
  testPageBackgroundColor: "--testPageBackgroundColor",
};

export const useTestPageColor = (testColor: string) => {
  React.useEffect(() => {
    document.body.style.setProperty(testPageContextVariables.testPageBackgroundColor, testColor);

    return () => {
      document.body.style.removeProperty(testPageContextVariables.testPageBackgroundColor);
    };
  }, [testColor]);
};
