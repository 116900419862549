import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { observer } from "mobx-react";
import React from "react";
import { Element, scroller } from "react-scroll";
import { CommandButton } from "../../../../shared/components/button/Button";
import { DetailedProgressBar } from "../../../../shared/components/detailedProgressBar/DetailedProgressBar";
import { List } from "../../../../shared/components/list/List";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { LessonViewModel } from "../LessonViewModel";
import { SliderThumbnail } from "../sliderThumbnail/SliderThumbnail";
import { StepsListContextProvider, useStepsList } from "./useStepList";

interface StepsListProviderProps {
  model: LessonViewModel;
  children: React.ReactNode;
}

export const StepsListProvider: React.FC<StepsListProviderProps> = observer(props => {
  const { model } = props;

  return <StepsListContextProvider value={{ model }}>{props.children}</StepsListContextProvider>;
});

interface StepsListProgressProps {
  className?: string;
}

export const StepsListProgress = observer((props: StepsListProgressProps) => {
  const { completedStepsCount, stepsCount } = useStepsList();
  const { className } = props;
  const translations = useTranslations();

  return (
    <DetailedProgressBar
      totalStepsCount={stepsCount}
      completedStepsCount={completedStepsCount}
      progressInfo={translations.stepsProgressInfo}
      className={className}
    />
  );
});

const containerStepsListId = "containerStepsListId";

interface StepsListItemsProps {
  className?: string;
}

export const StepsListItems = observer((props: StepsListItemsProps) => {
  const { steps, goToStep, currentStepSlug, paymentSidePanelViewModel, courseStructure } = useStepsList();
  const { className } = props;
  const stepsListUniqueId = uniqueId(`${containerStepsListId}__`);

  React.useEffect(() => {
    if (!currentStepSlug) {
      return;
    }

    scroller.scrollTo(currentStepSlug, {
      smooth: true,
      containerId: stepsListUniqueId,
      offset: -8,
    });

    return () => scroller.unmount();
  }, [currentStepSlug]);

  return (
    <div id={stepsListUniqueId} className={classNames("StepsListItems", className)}>
      <List
        className="StepsListItems__list"
        items={steps}
        renderItem={step => (
          <Element name={step.slug}>
            <SliderThumbnail
              step={step.thumbnail}
              goToStep={goToStep}
              isActive={currentStepSlug === step.slug}
              paymentSidePanelViewModel={paymentSidePanelViewModel}
              courseStructure={courseStructure}
            />
          </Element>
        )}
      />
    </div>
  );
});

export const StepsListNavigation = () => {
  const { goToPreviousStep, goToNextStep } = useStepsList();
  const translations = useTranslations();

  return (
    <div className="StepsListNavigation">
      <CommandButton
        variant="secondary"
        type="outline"
        size="small"
        className="StepsListNavigation__button"
        command={goToPreviousStep}
      >
        {translations.prevStepSliderButton}
      </CommandButton>
      <CommandButton
        type="outline"
        variant="secondary"
        size="small"
        className="StepsListNavigation__button"
        command={goToNextStep}
      >
        {translations.nextStepSliderButton}
      </CommandButton>
    </div>
  );
};
