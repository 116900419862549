import React from "react";

export const useIsVisible = (ref: React.RefObject<HTMLElement>) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const scrollObserver = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      { threshold: [0, 1] }
    );

    if (ref && ref.current) {
      scrollObserver.observe(ref.current);
    }
  }, []);

  return isVisible;
};
