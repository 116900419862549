import FocusTrap from "focus-trap-react";
import React from "react";
import { animated, useSpring } from "react-spring";
import { PaymentSidePanelViewModel } from "../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { ScrollLock } from "../../../pages/shared/scrollLock/ScrollLock";
import { Button } from "../../../shared/components/button/Button";
import { Input } from "../../../shared/components/input/Input";
import { NavLink } from "../../../shared/components/link/NavLink";
import { List } from "../../../shared/components/list/List";
import { Popup } from "../../../shared/components/popup/Popup";
import { usePopup } from "../../../shared/components/popup/usePopup";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { DynamicLink } from "../HeaderViewModel";

interface HamburgerMenuProps {
  model: Popup;
  links: DynamicLink[];
  secondaryLinks?: DynamicLink[];
  studiesType?: string;
  paymentSidePanel?: PaymentSidePanelViewModel;
  goToSearchPage?: (searchPhrase: string) => void;
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = props => {
  const { model, links, goToSearchPage, secondaryLinks, studiesType, paymentSidePanel } = props;
  const { isOpen, close } = model;
  const translations = useTranslations();
  const { isDisplayed, backgroundStyles } = usePopup(isOpen);
  const [searchPhrase, setSearchPhrase] = React.useState("");

  const panelStyles = useSpring({
    from: { translateX: "100vw" },
    translateX: isOpen ? "0vw" : "100vw",
  });

  if (!isDisplayed) {
    return null;
  }

  const renderPrimaryLinks = () => {
    if (links.length === 0 && secondaryLinks?.length === 0) {
      return;
    }

    const renderItems: DynamicLink[] = [];
    if (links.length > 0) {
      renderItems.push(...links);
    }

    if (links.length == 0 && secondaryLinks && secondaryLinks.length > 0) {
      renderItems.push(...secondaryLinks);
    }

    return (
      <List
        className="HamburgerMenu__links"
        items={renderItems}
        renderItem={link => (
          <NavLink
            to={link.href}
            hrefType={link.type}
            type="clear"
            className="HamburgerMenu__link"
            activeClassName="HamburgerMenu__link--active"
            exact
          >
            <span className="HamburgerMenu__linkText">{link.title}</span>
          </NavLink>
        )}
      />
    );
  };

  return (
    <div className="HamburgerMenu">
      <animated.div style={backgroundStyles}>
        <Button onClick={close} type="clear" className="HamburgerMenu__closeBackgroundButton">
          <span className="visuallyHidden">{translations.close}</span>
        </Button>
      </animated.div>
      <div className="HamburgerMenu__inner">
        <FocusTrap active={isOpen} focusTrapOptions={{ allowOutsideClick: true }}>
          <animated.div style={panelStyles} className="HamburgerMenu__panel">
            <ScrollLock isLocked={isOpen} />
            <div className="HamburgerMenu__linksSection">
              <div className="HamburgerMenu__userActions">
                {goToSearchPage && (
                  <form
                    onSubmit={event => {
                      event.preventDefault();
                      goToSearchPage(searchPhrase);
                    }}
                    className="HamburgerMenu__searchLinkWrapper"
                  >
                    <SvgIcon iconName="search" className="HamburgerMenu__searchIcon" />
                    <Input
                      value={searchPhrase}
                      onChange={setSearchPhrase}
                      label={translations.search}
                      variant="clear"
                      className="HamburgerMenu__searchInput"
                    />
                  </form>
                )}
                <div className="HamburgerMenu__gap" />
                {paymentSidePanel && (
                  <Button
                    onClick={() => {
                      close();
                      paymentSidePanel.show();
                    }}
                    className="HamburgerMenu__link HamburgerMenu__buyLink"
                  >
                    {translations.buyAccess}
                  </Button>
                )}
                {renderPrimaryLinks()}
                {links.length > 0 && secondaryLinks && secondaryLinks.length > 0 && (
                  <List
                    className="HamburgerMenu__secondaryLinks"
                    items={secondaryLinks}
                    renderItem={link => (
                      <NavLink
                        to={link.href}
                        hrefType={link.type}
                        type="clear"
                        className="HamburgerMenu__secondaryLink"
                        activeClassName="HamburgerMenu__secondaryLink--active"
                      >
                        <span className="HamburgerMenu__secondaryLinkText">{link.title}</span>
                      </NavLink>
                    )}
                  />
                )}
              </div>
              <div className="HamburgerMenu__studiesType">{studiesType}</div>
            </div>
            <div className="HamburgerMenu__closeBar">
              <Button onClick={close} type="circle" className="HamburgerMenu__closeButton">
                <SvgIcon iconName="close" />
                <span className="visuallyHidden">{translations.close}</span>
              </Button>
            </div>
          </animated.div>
        </FocusTrap>
      </div>
    </div>
  );
};
