import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import { AuthorDetailsSidePanel } from "../../../pages/authorsForProduct/authorDetailsSidePanel/AuthorDetailsSidePanel";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { AuthorSlide } from "../authorSlide/AuthorSlide";
import { Button } from "../button/Button";
import { SvgIcon } from "../svgIcon/SvgIcon";
import { AuthorsSliderViewModel } from "./AuthorsSliderViewModel";

interface AuthorsSliderProps {
  model: AuthorsSliderViewModel;
  className?: string;
}

const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      className="AuthorsSlider__arrow AuthorsSlider__arrow--previous"
      type="circle"
      variant="secondary"
      onClick={onClick}
    >
      <span className="visuallyHidden">{translations.previous}</span>
      <SvgIcon iconName="left-arrow-line" />
    </Button>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      className="AuthorsSlider__arrow AuthorsSlider__arrow--next"
      type="circle"
      variant="secondary"
      onClick={onClick}
    >
      <span className="visuallyHidden">{translations.next}</span>
      <SvgIcon iconName="right-arrow-line" />
    </Button>
  );
};

export const AuthorsSlider: React.FC<AuthorsSliderProps> = observer(props => {
  const {
    model: { authors, setAuthor, authorInfo, authorInfoPopup },
    className,
  } = props;
  const sliderSettings = {
    dots: true,
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 750,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    touchThreshold: 10,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          centerMode: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          centerMode: false,
          dots: false,
        },
      },
    ],
  } as Settings;

  if (!authors) {
    return null;
  }

  return (
    <>
      <Slider {...sliderSettings} className={classNames("AuthorsSlider", className)}>
        {authors.map(author => (
          <AuthorSlide
            name={author.name}
            role={author.role}
            image={author.image}
            key={author.id}
            onButtonClick={() => {
              setAuthor(author.slug);
              authorInfoPopup.show();
            }}
          />
        ))}
      </Slider>
      <AuthorDetailsSidePanel authorInfo={authorInfo} popup={authorInfoPopup} />
    </>
  );
});
