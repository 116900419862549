import { IRoute } from "react-mvvm";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { ThemeViewModel } from "./theme/ThemeViewModel";
import { ThemeListViewModel } from "./themeList/ThemeListViewModel";

export type ThemeParams = { themeSlug: string };

export const addThemesRoutes = (themeListRoute: IRoute<ThemeListViewModel, ProductParams, CourseParams>) => {
  const themesRoutes = themeListRoute.addRoute<ThemeViewModel, ThemeParams>(
    "/:themeSlug",
    ThemeViewModel,
    (themesPage, p) => {
      const currentTheme = themesPage.activeCourseStructure.getThemeBySlug(p.themeSlug);

      if (currentTheme.isUnderDevelopment) {
        throw new Error(`Trying to enter under development theme with id: ${currentTheme.id}`);
      }

      const theme = new ThemeViewModel(
        currentTheme,
        themesPage.videoService,
        themesPage.languageService,
        themesPage.errorService,
        themesPage.progressStore,
        themesPage.userStore,
        themesPage.paymentSidePanelViewModel,
        themesPage.bookmarkStore,
        themesPage.gtm,
        themesPage.testService,
        themesPage.courseNavigator,
        themesPage.activeCourseStructure
      );

      return themesPage.showChildPage(theme);
    },
    page => ({ themeSlug: page.themeStructure.slug })
  );

  return themesRoutes;
};
