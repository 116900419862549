import classNames from "classnames";
import RcSwitch from "rc-switch";
import * as React from "react";
import "rc-switch/assets/index.css";

export type SwitchType = "light" | "dark";

export interface SwitchProps
  extends Pick<React.HTMLAttributes<HTMLButtonElement>, "aria-labelledby" | "aria-describedby"> {
  checked?: boolean;
  disabled?: boolean;
  type?: SwitchType;
  onChange?: (value: boolean) => void;
  className?: string;
}

export const Switch: React.FC<SwitchProps> = props => {
  const { onChange, checked = false, type = "dark", disabled = false, className, ...buttonProps } = props;
  return (
    <RcSwitch
      className={classNames(
        "Switch",
        { "Switch--light": type === "light", "Switch--dark": type === "dark", "Switch--disabled": disabled },
        className
      )}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      {...buttonProps}
    />
  );
};
