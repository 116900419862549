import { observer } from "mobx-react";
import React from "react";
import { ProductParams } from "./addProductRouting";

interface ProductParamsContext {
  params?: ProductParams;
}

const productParamsContext = React.createContext<ProductParamsContext | undefined>(undefined);

export const useProductParams = () => {
  const context = React.useContext<ProductParamsContext | undefined>(productParamsContext);

  if (context === undefined) {
    throw new Error("Product Params context shouldn't be used outside of the context provider");
  }

  return context.params;
};

export const ProductParamsContextProvider = observer(
  ({ children, params }: { children: React.ReactNode; params?: ProductParams }) => {
    const { Provider } = productParamsContext;
    return <Provider value={{ params }}>{children}</Provider>;
  }
);
