import { IRoutedPage } from "react-mvvm";

export interface IAppMotif {
  color?: string;
}

export interface IAppMotifProvider {
  appMotif?: IAppMotif;
}

export const getMotif = (page?: IRoutedPage): IAppMotif | undefined => {
  if (!page) {
    return;
  }

  const motifProvider = page as IAppMotifProvider;

  return { ...motifProvider.appMotif, ...getMotif(page.childPage) };
};
