import * as React from "react";
import { LockedLayer } from "./lockedLayer/LockedLayer";
import { StatusLayer } from "./statusLayer/StatusLayer";

export type TestThumbnailOverlayStates =
  | { overlayState: "locked"; onClick: () => void }
  | { overlayState: "finished" }
  | { overlayState: "failed" };

export interface TestThumbnailOverlayStatesProps {
  props: TestThumbnailOverlayStates;
}

export const TestThumbnailOverlay: React.FC<TestThumbnailOverlayStatesProps> = ({ props }) => {
  switch (props.overlayState) {
    case "finished":
      return <StatusLayer variant="default" status="success" />;
    case "locked":
      return <LockedLayer variant="default" onClick={props.onClick} />;
    case "failed":
      return <StatusLayer variant="default" status="warning" />;
    default:
      throw new Error("You specified wrong layer state!");
  }
};
