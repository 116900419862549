import Konva from "konva";

export const incorrectColorVarName = "--actionErrorBase";
export const correctColorVarName = "--actionSuccessBase";
export const highlightedColorVarName = "--grayscaleBlackLighten50";
export const selectedColorVarName = "--notificationFocusBase";
export const shouldBeCheckedColorVarName = "--actionSuccessBase";

const styleDeclaration = getComputedStyle(document.body);
const incorrectColorRgb = () => Konva.Util.getRGB(styleDeclaration.getPropertyValue(incorrectColorVarName));
const correctColorRgb = () => Konva.Util.getRGB(styleDeclaration.getPropertyValue(correctColorVarName));
const highlightedColorRgb = () => Konva.Util.getRGB(styleDeclaration.getPropertyValue(highlightedColorVarName));
const selectedColorRgb = () => Konva.Util.getRGB(styleDeclaration.getPropertyValue(selectedColorVarName));
const shouldBeCheckedColorRgb = () => Konva.Util.getRGB(styleDeclaration.getPropertyValue(shouldBeCheckedColorVarName));

export interface RGB {
  red: number;
  green: number;
  blue: number;
}

interface Filters {
  [key: string]: RGB | undefined;
}

const map = (a: { r: number; g: number; b: number }) => ({
  red: a.r,
  green: a.g,
  blue: a.b,
});

export const getFilters: () => Filters = () => ({
  highlighted: map(highlightedColorRgb()),
  selected: map(selectedColorRgb()),
  correct: map(correctColorRgb()),
  incorrect: map(incorrectColorRgb()),
  shouldBeChecked: map(shouldBeCheckedColorRgb()),
});
