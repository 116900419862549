export const getPreviousElement = <T extends { id: string | number }>(list: T[], element: T) => {
  const indexOfCurrentElement = list.findIndex(listElement => listElement.id === element.id);

  if (indexOfCurrentElement < 1) {
    return;
  }

  return list[indexOfCurrentElement - 1];
};

export const getNextElement = <T extends { id: string | number }>(list: T[], element: T) => {
  const indexOfCurrentElement = list.findIndex(listElement => listElement.id === element.id);

  if (indexOfCurrentElement === -1 || indexOfCurrentElement === list.length - 1) {
    return;
  }

  return list[indexOfCurrentElement + 1];
};

export const isLastElement = <T extends { id: string | number }>(list: T[], element: T) => {
  if (list.length === 0) {
    return false;
  }

  const indexOfCurrentElement = list.findIndex(listElement => listElement.id === element.id);

  return indexOfCurrentElement === list.length - 1;
};
