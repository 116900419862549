import { observer } from "mobx-react";
import React from "react";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { StepViewModel } from "../../StepStore";
import { SoundStepView } from "./soundStep/SoundStepView";
import { TaskGroupStepView } from "./taskGroupStep/TaskGroupStepView";
import { TaskStepView } from "./taskStep/TaskStepView";
import { TextBlockStepView } from "./textBlockStep/TextBlockStepView";
import { VideoStepView } from "./videoStep/VideoStepView";

export const StepView = observer(({ currentStep }: { currentStep: StepViewModel }) => {
  const { _type } = currentStep;

  switch (_type) {
    case "Video":
      return <VideoStepView model={currentStep} />;
    case "TextBlock":
      return <TextBlockStepView model={currentStep} />;
    case "TaskGroupTask":
      return <TaskGroupStepView model={currentStep} />;
    case "Task":
      return <TaskStepView task={currentStep.task} hint={currentStep.hint} solution={currentStep.solution} />;
    case "Sound":
      return <SoundStepView model={currentStep} />;
    default:
      throw assertUnreachable(_type);
  }
});
