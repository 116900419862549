import { createLocation } from "history";
import * as React from "react";
import { HistoryLocationState, useHistory } from "../../../useRouter";
import { ButtonType, ButtonVariant, ButtonSize } from "../button/Button";
import { getLinkClassNames } from "./helpers/getLinkClassNames";
import { isExternal } from "./helpers/isExternal";
import { isModifiedEvent } from "./helpers/isModifiedEvent";

export type LinkType = "default" | "button" | "clear";

export enum HrefType {
  Autodetect,
  Internal,
  External,
  Anchor,
}

interface DefaultLinkProps {
  to: string;
  replace?: boolean;
  target?: "_blank" | "_self";
  type?: LinkType;
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  hrefType?: HrefType;
  tabIndex?: number;
  buttonType?: Exclude<ButtonType, "clear">;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  goBackText?: string;
}

export type LinkProps = DefaultLinkProps &
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "aria-describedby" | "aria-label">;

export const Link: React.FC<LinkProps> = props => {
  const {
    to,
    replace = false,
    children,
    target,
    type = "default",
    className,
    onClick,
    hrefType = HrefType.Autodetect,
    tabIndex,
    buttonType = "solid",
    buttonVariant = "primary",
    buttonSize = "large",
    goBackText,
  } = props;
  const history = useHistory();
  const isExternalHref = hrefType === HrefType.External || (hrefType === HrefType.Autodetect && isExternal(to));
  // eslint-disable-next-line react/destructuring-assignment
  const ariaDescribedBy = props["aria-describedby"];
  // eslint-disable-next-line react/destructuring-assignment
  const ariaLabel = props["aria-label"];

  const targetProps = target === "_blank" ? { target, rel: "noreferrer" } : { target };

  if (isExternalHref || hrefType === HrefType.Anchor) {
    return (
      <a
        aria-describedby={ariaDescribedBy}
        aria-label={ariaLabel}
        href={to}
        tabIndex={tabIndex}
        className={getLinkClassNames(type, buttonType, buttonVariant, buttonSize, className)}
        onClick={onClick}
        {...targetProps}
      >
        {children}
      </a>
    );
  }

  if (!history) {
    throw new Error("History is undefined");
  }

  const navigate = replace ? history.replace : history.push;
  const location = createLocation<HistoryLocationState | undefined>(
    to,
    { goBackHref: history.location.pathname, goBackText },
    undefined,
    history.location
  );
  const href = location ? history.createHref(location) : "";

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    try {
      if (onClick) onClick(event);
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate(location);
    }
  };
  return (
    <a
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      href={href}
      tabIndex={tabIndex}
      {...targetProps}
      className={getLinkClassNames(type, buttonType, buttonVariant, buttonSize, className)}
      onClick={handleOnClick}
    >
      {children}
    </a>
  );
};
