import noop from "lodash/noop";
import React from "react";
import { Button } from "../../../shared/components/button/Button";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { StepViewModel } from "../../lessons/StepStore";
import { CompleteSwitch } from "../../lessons/lesson/shared/completeSwitch/CompleteSwitch";
import { StepView } from "../../lessons/lesson/steps/StepView";

interface LessonPreviewProps {
  step: StepViewModel;
}

export const LessonPreview = (props: LessonPreviewProps) => {
  const { step } = props;
  const { title, description, _type } = step;
  const translations = useTranslations();

  return (
    <div className="LessonPreview">
      <div className="LessonPreview__themeTitle">Theme title</div>
      <div className="LessonPreview__content">
        <div className="LessonPreview__step">
          <Title level={1} className="LessonPreview__title">
            {title}
          </Title>
          {description && _type !== "Video" && <p className="LessonPreview__description">{description}</p>}
          <StepView currentStep={step} />
          <div className="LessonPreview__stepActions">
            <div className="LessonPreview__markStep">
              <CompleteSwitch disabled checked={false} onChange={() => noop()} />
            </div>
            <div className="LessonPreview__stepButtons">
              <div className="LessonPreview__stepWrapper LessonPreview__stepWrapper--reverse">
                <span className="LessonPreview__navigationStepText">{translations.prevStepSliderButton}</span>
                <Button type="circle" disabled>
                  <SvgIcon iconName="left-arrow-line" />
                </Button>
              </div>
              <div className="LessonPreview__stepWrapper">
                <span className="LessonPreview__navigationStepText">{translations.nextStepSliderButton}</span>
                <Button type="circle" disabled>
                  <SvgIcon iconName="right-arrow-line" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="LessonPreview__stepListMock" />
      </div>
    </div>
  );
};
