import React from "react";
import { CheckboxGroupProvider } from "./CheckboxGroupContext";

interface CheckboxGroupProps {
  name: string;
  onChange?: (values: string[]) => void;
  value?: string[];
  disabled?: boolean;
  children: React.ReactNode;
}

interface CheckboxGroupComposition extends React.FC<CheckboxGroupProps> {
  Fieldset: typeof Fieldset;
}

export const CheckboxGroup: CheckboxGroupComposition = ({ onChange, value, name, disabled, children }) => {
  const [currentValue, setCurrentValue] = React.useState<string[]>(value || []);

  React.useEffect(() => {
    setCurrentValue(value || []);
  }, [value]);

  const getUpdatedValues = (singleValue: string) => {
    if (currentValue?.includes(singleValue)) {
      return currentValue.filter(v => v !== singleValue);
    }
    return [...currentValue, singleValue];
  };

  const handleOnChange = (singleValue: string) => {
    const updatedValues = getUpdatedValues(singleValue);
    setCurrentValue(updatedValues);
    onChange && onChange(updatedValues);
  };

  return (
    <CheckboxGroupProvider name={name} onChange={handleOnChange} value={currentValue} disabled={disabled}>
      {children}
    </CheckboxGroupProvider>
  );
};

interface FieldsetProps {
  legend?: string;
  children: React.ReactNode;
}

const Fieldset: React.FC<FieldsetProps> = ({ legend, children }) => {
  return (
    <fieldset className="CheckboxGroup">
      {legend && <legend className="visuallyHidden">{legend}</legend>}
      {children}
    </fieldset>
  );
};

CheckboxGroup.Fieldset = Fieldset;
