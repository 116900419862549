import React from "react";

interface TextContentWithPlaceholderProps {
  isVisible: boolean;
  children: React.ReactNode;
}

export const TextContentWithPlaceholder: React.FC<TextContentWithPlaceholderProps> = props => {
  const { isVisible, children } = props;

  if (!isVisible) {
    return <>&#8203;</>;
  }

  return <>{children}</>;
};
