type ChildrenType = { _type: string; text?: string };

interface TextBlockType {
  children: ChildrenType[];
}

const isTextBlock = (block: unknown): block is TextBlockType => {
  if (typeof block !== "object" || block === null) {
    return false;
  }

  const potentialTextBlock = block as TextBlockType;

  if (!potentialTextBlock.children) {
    return false;
  }

  return true;
};

const isBlockEmpty = (block: unknown) => {
  if (!isTextBlock(block)) {
    return false;
  }

  if (block.children.length !== 1) {
    return false;
  }

  const firstChild = block.children[0];

  return firstChild._type === "span" && firstChild.text === "";
};

export const trimEmptyBlocks = (blocks: unknown): unknown[] => {
  if (!Array.isArray(blocks)) {
    throw new Error("Wrong argument");
  }

  let startIndex = 0;
  let item = blocks[startIndex];
  while (isBlockEmpty(item) && startIndex !== blocks.length - 1) {
    startIndex += 1;
    item = blocks[startIndex];
  }

  let endIndex = blocks.length - 1;
  item = blocks[endIndex];
  while (isBlockEmpty(item) && endIndex !== 0) {
    endIndex -= 1;
    item = blocks[endIndex];
  }

  return blocks.slice(startIndex, endIndex + 1);
};
