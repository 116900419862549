import uniqueId from "lodash/uniqueId";
import { useCheckboxGroupContext } from "./CheckboxGroupContext";

export const useCheckbox = (id: string, checked?: boolean, onChange?: (value: string, checked: boolean) => void) => {
  const context = useCheckboxGroupContext();
  const { value, name, onChange: checkboxButtonGroupOnChange, disabled } = context;

  const CheckboxButtonGroupChecked = value ? value.includes(id) : undefined;
  const inputChecked = checked !== undefined ? checked : CheckboxButtonGroupChecked;
  const onInputChange = onChange || checkboxButtonGroupOnChange;
  const htmlId = uniqueId("CheckboxButton__");

  return { name, disabled, inputChecked, onInputChange, htmlId };
};
