import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { ContentReference } from "../../../../models/Url";

export interface IThemeThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  description?: string;
  color?: string;
  image?: ImageDto;
  hasAccess: boolean;
  type: "Theme";
}

export class ThemeThumbnailViewModel implements IThemeThumbnail {
  id: string;

  contentRef: ContentReference;

  title: string;

  description?: string;

  image?: ImageDto;

  color?: string;

  hasAccess: boolean;

  readonly type = "Theme";

  constructor(themeThumbnail: {
    id: string;
    title: string;
    image?: ImageDto;
    color?: string;
    description?: string;
    hasAccess: boolean;
    slug: string;
  }) {
    this.id = themeThumbnail.id;
    this.contentRef = themeThumbnail;
    this.title = themeThumbnail.title;
    this.description = themeThumbnail.description;
    this.color = themeThumbnail.color;
    this.hasAccess = true;
    this.image = themeThumbnail.image;
  }
}
