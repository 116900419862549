import { action, computed, observable } from "mobx";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { AnswersState } from "../AnswersState";
import { InteractiveTask } from "../InteractiveTask";
import { TaskService } from "../TaskService";
import { AnswerId, MultipleAnswerQuestion, QuestionId, SingleAnswerQuestion, TaskId } from "../taskUtils";

export type Answer = { id: AnswerId; content: PortableTextDto };
export type Question = { id: QuestionId; content: PortableTextDto };

export type Answers = Answer[];
export type Questions = Question[];

export type MatchTaskValue = (SingleAnswerQuestion | MultipleAnswerQuestion)[];

export type MatchTaskState =
  | { _type: "task"; value: MatchTaskValue }
  | { _type: "taskResult"; value: MatchTaskValue; correctAnswers: MatchTaskValue };

export class MatchTaskViewModel implements InteractiveTask {
  @observable state: MatchTaskState = {
    _type: "task",
    value: [],
  };

  @observable isDisabled = false;

  @computed get answersState(): AnswersState {
    if (this.state._type === "task") {
      return "default";
    }

    const correctAnswer = this.state.correctAnswers;
    const value = this.state.value;

    const questionsState = this.questions.map(question => {
      const correctAnswerToQuestion = correctAnswer.find(ca => ca.questionId === question.id);
      const userAnswerToQuestion = value.find(v => v.questionId === question.id);

      if (!correctAnswerToQuestion || !userAnswerToQuestion) {
        return "incorrect";
      }

      if (
        userAnswerToQuestion._type === "singleAnswerQuestion" &&
        correctAnswerToQuestion._type === "singleAnswerQuestion"
      ) {
        return userAnswerToQuestion.value === correctAnswerToQuestion.value ? "correct" : "incorrect";
      }

      if (
        userAnswerToQuestion._type === "multipleAnswerQuestion" &&
        correctAnswerToQuestion._type === "multipleAnswerQuestion"
      ) {
        return correctAnswerToQuestion.value.every(
          questionCorrectAnswers => userAnswerToQuestion.value.find(cav => cav === questionCorrectAnswers) !== undefined
        )
          ? "correct"
          : "incorrect";
      }

      return "incorrect";
    });

    if (questionsState.every(questionState => questionState === "correct")) {
      return "correct";
    }

    if (questionsState.every(questionState => questionState === "incorrect")) {
      return "wrong";
    }

    return "partiallyCorrect";
  }

  @computed get isSubmitted() {
    return this.answersState !== "default";
  }

  @computed get isAnswered() {
    const { value } = this.state;

    return this.questions.every(question => value.find(v => v.questionId === question.id) !== undefined);
  }

  constructor(
    public id: TaskId,
    public title: string,
    public content: PortableTextDto,
    public questions: Questions,
    public answers: Answers,
    public isMultipleChoice: boolean,
    private taskService: TaskService,
    public description?: string
  ) {}

  @action.bound
  submit() {
    const userAnswer = { taskId: this.id, value: this.state.value };
    const submitResult = this.taskService.checkAnswers([userAnswer]);

    this.state = {
      _type: "taskResult",
      value: this.state.value,
      correctAnswers: submitResult.taskResults[0].correctAnswers as MatchTaskValue,
    };
  }

  @action.bound
  reset() {
    this.state = {
      _type: "task",
      value: [],
    };

    this.isDisabled = false;
  }

  @action.bound
  async showCorrectAnswer() {
    const correctAnswers = await this.taskService.getCorrectAnswers(this.id);
    this.state = {
      _type: "taskResult",
      value: correctAnswers as MatchTaskValue,
      correctAnswers: correctAnswers as MatchTaskValue,
    };
  }
}
