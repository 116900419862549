import { observable, onBecomeObserved, action, computed } from "mobx";
import { MaskDto } from "../../../../types/shared/dto/MaskDto";
import { assertState } from "../../../stateMembers";
import { assertUnreachable } from "../../../typeUtils";
import { loadImages } from "../../../utils";
import { ImageType, MaskTaskViewModel, MAX_MASK_SIZE } from "./MaskTaskViewModel";

type MaskState =
  | { type: "loading" }
  | { type: "error" }
  | { type: "notSelected"; image: ImageType }
  | { type: "selected"; image: ImageType }
  | { type: "highlighted"; image: ImageType; removeHighlight: () => void }
  | { type: "correct"; image: ImageType }
  | { type: "incorrect"; image: ImageType }
  | { type: "shouldBeChecked"; image: ImageType };

export class MaskViewModel {
  readonly isCorrect: boolean;

  readonly id: string;

  readonly name: string;

  readonly imageUrl: string;

  @observable state: MaskState = { type: "loading" };

  @computed get isDisabled() {
    return this.maskTask.isDisabled;
  }

  constructor(mask: MaskDto, private maskTask: MaskTaskViewModel) {
    if (!mask.image.url) {
      throw new Error(`Not defined image for mask with id: ${mask.id}`);
    }
    this.isCorrect = mask.isCorrect;
    this.id = mask.id;
    this.name = mask.name;

    this.imageUrl = `${mask.image.url}?w=${MAX_MASK_SIZE}`;

    onBecomeObserved(this, "state", this.load);
  }

  @action.bound
  async load() {
    if (this.state.type !== "loading" && this.state.type !== "error") {
      return;
    }

    assertState(this.state, "loading", "error");
    this.state = { type: "loading" };
    const [loadedImage] = await loadImages([this.imageUrl]).catch(e => {
      this.state = { type: "error" };
      throw e;
    });
    this.state = { type: "notSelected", image: loadedImage };
  }

  @action.bound
  toggleSelection() {
    if (this.isDisabled) {
      return;
    }

    const stateType = this.state.type;

    switch (stateType) {
      case "notSelected":
      case "selected":
      case "highlighted":
        return this.maskTask.selectionStrategy.toggleSelection(this);
    }
  }

  @action.bound
  highlight() {
    if (this.isDisabled) {
      return;
    }

    const stateType = this.state.type;

    if (stateType !== "notSelected") {
      return;
    }

    assertState(this.state, "notSelected");

    this.state = { type: "highlighted", image: this.state.image, removeHighlight: this.removeHighlight };
  }

  @action.bound
  private removeHighlight() {
    if (this.isDisabled || this.state.type !== "highlighted") {
      return;
    }

    assertState(this.state, "highlighted");
    this.state = { type: "notSelected", image: this.state.image };
  }

  @action.bound
  submit() {
    assertState(this.state, "selected", "notSelected");

    switch (this.state.type) {
      case "selected":
        this.state = this.isCorrect
          ? { type: "correct", image: this.state.image }
          : { type: "incorrect", image: this.state.image };
        return;
      case "notSelected":
        if (this.isCorrect) {
          this.state = { type: "shouldBeChecked", image: this.state.image };
        }
        return;
      default:
        assertUnreachable(this.state);
    }
  }

  @action.bound
  reset() {
    assertState(this.state, "selected", "incorrect", "correct", "highlighted", "notSelected", "shouldBeChecked");

    this.state = { type: "notSelected", image: this.state.image };
  }
}
