import * as React from "react";
import { Switch } from "../../../../../shared/components/switch/Switch";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";

interface CompleteSwitchProps {
  checked: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

export const CompleteSwitch = ({ onChange, checked, disabled }: CompleteSwitchProps) => {
  const translations = useTranslations();

  return (
    <div className="CompleteSwitch">
      <div className="CompleteSwitch__markAction">
        <Switch onChange={onChange} checked={checked} disabled={disabled} />
        <span className="CompleteSwitch__markDescription">{translations.markAsFinished}</span>
      </div>
    </div>
  );
};
