import classNames from "classnames";
import React from "react";
import { Command } from "react-mvvm";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { CommandButton } from "../../../button/Button";
import { SvgIcon } from "../../../svgIcon/SvgIcon";

export interface BookmarkLayerProps {
  isBookmarked: boolean;
  bookmarkCommand: Command;
  isVideo: boolean;
  variant: "small" | "default";
}

export const BookmarkLayer: React.FC<BookmarkLayerProps> = ({ isBookmarked, bookmarkCommand, isVideo, variant }) => {
  const translations = useTranslations();

  return (
    <div className={classNames("BookmarkLayer", { "BookmarkLayer--video": isVideo }, `BookmarkLayer--${variant}`)}>
      <CommandButton type="clear" command={bookmarkCommand} className="BookmarkLayer__bookmarkButton">
        <SvgIcon
          iconName="bookmark"
          className={classNames("BookmarkLayer__bookmarkIcon", {
            "BookmarkLayer__bookmarkIcon--bookmarked": isBookmarked,
          })}
        />
        <span className="visuallyHidden">
          {isBookmarked ? translations.removeBookmark : translations.addUserBookmark}
        </span>
      </CommandButton>
    </div>
  );
};

export default BookmarkLayer;
