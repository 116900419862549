import { observer } from "mobx-react";
import React from "react";
import { bindTo, property } from "react-mvvm";
import { ThemeStatus } from "../../../shared/components/card/cardThemeBasic/CardThemeBasicViewModel";
import { SingleFilter } from "../../../shared/components/singleFilter/SingleFilter";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";

interface ThemeListViewModel {
  statusFilter: ThemeStatus[];
  allThemeCount: number;
  inProgressThemeCount?: number;
  finishedThemeCount?: number;
  notStartedThemeCount?: number;
  underDevelopmentThemeCount?: number;
}

interface ThemeListFilterProps {
  model: ThemeListViewModel;
}

const getFilterText = (statusText: string, numberOfItems?: number) => {
  if (numberOfItems === undefined) {
    return;
  }

  return `${statusText} (${numberOfItems})`;
};

export const ThemeListFilter: React.FC<ThemeListFilterProps> = observer(props => {
  const { model } = props;
  const translations = useTranslations();

  const filterValueWithTranslations: { [k in ThemeStatus]: { text?: string } } = {
    inProgress: { text: getFilterText(translations.started, model.inProgressThemeCount) },
    notStarted: { text: getFilterText(translations.notStarted, model.notStartedThemeCount) },
    finished: { text: getFilterText(translations.completed, model.finishedThemeCount) },
    underDevelopment: { text: getFilterText(translations.underDevelopment, model.underDevelopmentThemeCount) },
  };

  const filteredFilterValueWithTranslations = Object.fromEntries(
    Object.entries(filterValueWithTranslations).filter(([_, { text }]) => text !== undefined)
  ) as { [k in ThemeStatus]: { text: string } };

  return (
    <>
      <SingleFilter
        {...bindTo(property(model, "statusFilter"))}
        filterByWithText={filteredFilterValueWithTranslations}
        allFilterText={getFilterText(translations.all, model.allThemeCount) as string}
      />
    </>
  );
});
