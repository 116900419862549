import { action } from "mobx";
import { assertState } from "../../../../stateMembers";
import { MaskViewModel } from "../MaskViewModel";
import { MaskSelectionStrategy } from "./MaskSelectionStrategy";

export class MultipleChoiceMaskStrategy implements MaskSelectionStrategy {
  @action.bound
  toggleSelection(_mask: MaskViewModel) {
    const mask = _mask;
    assertState(mask.state, "notSelected", "selected", "highlighted");

    if (mask.state.type === "selected") {
      mask.state = { type: "notSelected", image: mask.state.image };
      return;
    }

    mask.state = { type: "selected", image: mask.state.image };
  }
}
