import { observer } from "mobx-react";
import React from "react";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { FormattedText, splitWords } from "../../../../textUtils";
import { MaskTaskState } from "../MaskTaskViewModel";

interface MaskTaskFeedbackProps {
  state: MaskTaskState;
  className?: string;
}

export const MaskTaskFeedback = observer((props: MaskTaskFeedbackProps) => {
  const { state, className = "MaskTaskFeedback" } = props;
  const translations = useTranslations();

  switch (state.type) {
    case "correct":
    case "incorrect":
    case "partiallyCorrect":
      return (
        <p className={className}>
          {`${FormattedText(translations.youHaveChosen, [
            splitWords(
              state.selectedMasks.map(m => m.name),
              translations.thisAndThis
            ),
          ])}`}
        </p>
      );
    default:
      return null;
  }
});
