import classNames from "classnames";
import React from "react";
import { ProductParams } from "../../../../../pages/product/addProductRouting";
import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../../useRouter";
import { Image } from "../../../image/Image";
import { Link } from "../../../link/Link";
import { ThumbnailImage } from "../../../thumbnails/shared/thumbnailImage/ThumbnailImage";
import { Level, Title } from "../../../title/Title";

export interface SectionArticleItemProps {
  id: string;
  slug: string;
  title: { text: string; level: Level; size: "small" | "big" };
  category?: string;
  description?: string;
  image?: ImageDto;
  goBackText?: string;
  productParams?: ProductParams;
}

export const SectionArticleItem: React.FC<SectionArticleItemProps> = props => {
  const { id, title, slug, category, description, image, goBackText, productParams } = props;
  const url = useUrl();
  const navigationUrl = productParams
    ? url.toProductArticlePage(productParams, { id, slug })
    : url.toArticlePage({ id, slug });

  return (
    <div className="SectionArticleItem">
      <Image.Container aspectRatio="3/2">{image && <ThumbnailImage image={image} type="Article" />}</Image.Container>
      <div className="SectionArticleItem__details">
        {category && <span className="SectionArticleItem__category">{category}</span>}
        <Title
          level={title.level}
          className={classNames("SectionArticleItem__title", {
            "SectionArticleItem__title--small": title.size === "small",
            "SectionArticleItem__title--big": title.size === "big",
          })}
        >
          {title.text}
        </Title>
        {description && <p className="SectionArticleItem__description">{description}</p>}
      </div>
      <Link tabIndex={0} to={navigationUrl} className="StepThumbnail__linkOverlay" goBackText={goBackText}>
        <span className="visuallyHidden">{title.text}</span>
      </Link>
    </div>
  );
};
