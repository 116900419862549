import findLast from "lodash/findLast";
import throttle from "lodash/throttle";
import { action, computed, observable } from "mobx";
import { TimeSpanDto } from "../../types/shared/dto/TimeSpanDto";
import { IProgressService } from "../api/BackendApi";
import { CourseStructure } from "../contentStructure/CourseStructure";
import { CourseProgress } from "../progressStructure/CourseProgress";
import { IUserStore } from "./UserStore";

type TimeProgressStep = "Video" | "Sound";
interface TaskProgress {
  id: string;
  isCompleted: boolean;
}

interface VideoProgress {
  id: string;
  isCompleted: boolean;
  lastPosition: TimeSpanDto;
}

interface SoundStepProgress {
  id: string;
  isCompleted: boolean;
  lastPosition: TimeSpanDto;
}

export type StepProgress = VideoProgress | TaskProgress | SoundStepProgress;

const isVideoProgress = (step: StepProgress): step is VideoProgress => {
  return (step as VideoProgress).lastPosition !== undefined;
};

const isSoundStepProgress = (step: StepProgress): step is SoundStepProgress => {
  return (step as SoundStepProgress).lastPosition !== undefined;
};

export interface IProgressStore {
  init(): Promise<void>;
  completedStepsIds: string[];
  readonly courseProgress: CourseProgress;
  isCompleted(stepId: string): boolean;
  getVideoProgress(stepId: string): VideoProgress | undefined;
  getSoundStepProgress(stepId: string): SoundStepProgress | undefined;
  lastUpdatedFrom(stepIds: string[]): string | undefined;
  nextUncompletedFrom(stepIds: string[]): string | undefined;
  markStepAsCompleted(stepId: string): Promise<void>;
  markStepAsUncompleted(stepId: string): Promise<void>;
  updateStepWithTimeProgress(stepId: string, seconds: number, type: TimeProgressStep): Promise<void>;
  endStepWithTimeProgressContent(stepId: string, seconds: number, type: TimeProgressStep): Promise<void>;
}

export class ProgressStore implements IProgressStore {
  @observable private stepsProgress: StepProgress[] = [];

  @observable public courseProgress: CourseProgress;

  @computed get completedStepsIds() {
    return this.stepsProgress.filter(v => v.isCompleted).map(m => m.id);
  }

  public constructor(
    public progressService: IProgressService,
    private userStore: IUserStore,
    private activeCourseStructure: CourseStructure
  ) {
    this.courseProgress = new CourseProgress(this, this.activeCourseStructure);
  }

  public async init() {
    if (this.userStore.isAuthenticated) {
      await this.loadProgress();
    }
  }

  private async loadProgress() {
    const courseProgressDto = await this.progressService.getProgress({
      courseId: this.activeCourseStructure.id,
    });

    const mergedStepsProgress = [
      ...courseProgressDto.stepProgress.map(progress => {
        return {
          id: progress.stepId,
          isCompleted: progress.isCompleted,
          updatedAt: new Date(progress.updatedAt),
        };
      }),
      ...courseProgressDto.videoProgress.map(progress => {
        return {
          id: progress.videoId,
          isCompleted: progress.isCompleted,
          updatedAt: new Date(progress.updatedAt),
          lastPosition: progress.lastPosition,
        };
      }),
      ...courseProgressDto.soundStepProgress.map(progress => {
        return {
          id: progress.soundStepId,
          isCompleted: progress.isCompleted,
          updatedAt: new Date(progress.updatedAt),
          lastPosition: progress.lastPosition,
        };
      }),
    ];

    mergedStepsProgress.sort((a, b) => {
      return a.updatedAt.getTime() - b.updatedAt.getTime();
    });

    this.stepsProgress = mergedStepsProgress.map(progress => {
      if (isVideoProgress(progress)) {
        return { id: progress.id, isCompleted: progress.isCompleted, lastPosition: progress.lastPosition };
      }
      if (isSoundStepProgress(progress)) {
        return { id: progress.id, isCompleted: progress.isCompleted, lastPosition: progress.lastPosition };
      }
      return { id: progress.id, isCompleted: progress.isCompleted };
    });
  }

  public isCompleted(stepId: string) {
    return this.completedStepsIds.includes(stepId);
  }

  public getVideoProgress(stepId: string) {
    return this.stepsProgress.filter(isVideoProgress).find(f => f.id === stepId);
  }

  public getSoundStepProgress(stepId: string) {
    return this.stepsProgress.filter(isSoundStepProgress).find(f => f.id === stepId);
  }

  public lastUpdatedFrom(stepIds: string[]) {
    return findLast(this.stepsProgress, progressStep => stepIds.includes(progressStep.id))?.id;
  }

  public nextUncompletedFrom(stepIds: string[]) {
    return stepIds.find(f => !this.isCompleted(f));
  }

  @action
  async markStepAsCompleted(stepId: string) {
    if (!this.userStore.isAuthenticated) {
      return;
    }
    await this.progressService.updateStepCompletionStatusRequest({
      stepId,
      isCompleted: true,
      courseId: this.activeCourseStructure.id,
    });

    this.setStepStatus(stepId, true);
  }

  @action
  async markStepAsUncompleted(stepId: string) {
    if (!this.userStore.isAuthenticated) {
      return;
    }
    await this.progressService.updateStepCompletionStatusRequest({
      stepId,
      isCompleted: false,
      courseId: this.activeCourseStructure.id,
    });

    this.setStepStatus(stepId, false);
  }

  @action
  async updateStepWithTimeProgress(stepId: string, seconds: number, type: TimeProgressStep) {
    if (!this.userStore.isAuthenticated) {
      return;
    }
    await this.updateProgressThrottled(stepId, seconds, type);

    const step = this.stepsProgress
      .filter(type === "Video" ? isVideoProgress : isSoundStepProgress)
      .find(v => v.id === stepId);
    if (!step) {
      const newStep = {
        id: stepId,
        isCompleted: false,
        lastPosition: { totalSeconds: seconds },
      };
      this.stepsProgress.push(newStep);
      return;
    }
    step.lastPosition = { totalSeconds: seconds };
  }

  @action
  async endStepWithTimeProgressContent(stepId: string, seconds: number, type: TimeProgressStep) {
    if (!this.userStore.isAuthenticated) {
      return;
    }
    await this.updateProgressThrottled(stepId, seconds, type);
    await this.progressService.updateStepCompletionStatusRequest({
      stepId,
      isCompleted: true,
      courseId: this.activeCourseStructure.id,
    });

    const step = this.stepsProgress
      .filter(type === "Video" ? isVideoProgress : isSoundStepProgress)
      .find(v => v.id === stepId);
    if (!step) {
      const newStep = {
        id: stepId,
        isCompleted: true,
        lastPosition: { totalSeconds: seconds },
      };
      this.stepsProgress.push(newStep);
      return;
    }
    step.lastPosition = { totalSeconds: seconds };
    step.isCompleted = true;
  }

  private updateProgressThrottled = throttle(
    (stepId, seconds, mode: TimeProgressStep) =>
      mode === "Video"
        ? this.progressService.updateVideoProgress({
            videoId: stepId,
            lastVideoPosition: { totalSeconds: seconds },
            courseId: this.activeCourseStructure.id,
          })
        : this.progressService.updateSoundStepProgress({
            soundStepId: stepId,
            lastTrackPosition: { totalSeconds: seconds },
            courseId: this.activeCourseStructure.id,
          }),
    4000
  );

  private setStepStatus(stepId: string, isCompleted: boolean) {
    const step = this.stepsProgress.find(v => v.id === stepId);
    if (step) {
      step.isCompleted = isCompleted;
    } else {
      this.stepsProgress.push({ id: stepId, isCompleted });
    }
  }
}
