import React from "react";
import { Image, SanityImage } from "../../../../../../shared/components/image/Image";
import { Link } from "../../../../../../shared/components/link/Link";
import { Title } from "../../../../../../shared/components/title/Title";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../../../shared/models/Url";
import { PreviousExamsSectionDto } from "../../../../../../types/courses/dto/PreviousExamsSectionDto";
import { useUrl } from "../../../../../../useRouter";

interface PreviousExamsSectionProps {
  section: PreviousExamsSectionDto;
  activeCourseParams: ActiveCourseParams;
}

export const PreviousExamsSection = (props: PreviousExamsSectionProps) => {
  const { section, activeCourseParams } = props;
  const { title, description, image } = section;
  const translations = useTranslations();
  const url = useUrl();

  return (
    <section className="PreviousExamsSection">
      <div className="PreviousExamsSection__content">
        <Title level={2} className="PreviousExamsSection__title">
          {title}
        </Title>
        <p className="PreviousExamsSection__description">{description}</p>
        <Link to={url.toPreviousExamsPage(activeCourseParams)} type="button" className="PreviousExamsSection__link">
          {translations.goToPreviousExams}
        </Link>
      </div>
      <Image.Container aspectRatio="1/1" className="PreviousExamsSection__imageWrapper">
        <SanityImage {...image} className="PreviousExamsSection__image" />
      </Image.Container>
    </section>
  );
};
