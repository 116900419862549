import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { Collapse } from "../collapse/Collapse";
import { SvgIcon } from "../svgIcon/SvgIcon";

export interface TextCollapseProps {
  children: string;
  className?: string;
}

export const TextCollapse: React.FunctionComponent<TextCollapseProps> = ({ children, className }) => {
  const textContentRef = React.useRef<HTMLParagraphElement>(null);

  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] = React.useState(false);

  const translations = useTranslations();

  const { ref: collapseWrapperRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: "throttle",
    refreshRate: 500,
    onResize: React.useCallback(() => {
      if (textContentRef?.current && collapseWrapperRef?.current && !isCollapseOpen) {
        setIsCollapseButtonVisible(textContentRef.current.scrollHeight > collapseWrapperRef.current.scrollHeight);
      }
    }, [isCollapseOpen]),
  });

  const htmlId = uniqueId("TextCollapse__");

  return (
    <div className="TextCollapse">
      <div ref={collapseWrapperRef}>
        <Collapse isOpen={isCollapseOpen} className={classNames("TextCollapse__collapse", className)} id={htmlId}>
          <p ref={textContentRef} className="TextCollapse__content">
            {children}
          </p>
        </Collapse>
      </div>
      {isCollapseButtonVisible && (
        <Button
          aria-expanded={isCollapseOpen}
          aria-controls={htmlId}
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          type="clear"
          className="TextCollapse__button"
        >
          <span className="TextCollapse__buttonText">
            {isCollapseOpen ? translations.viewLess : translations.viewMore}
          </span>
          <SvgIcon
            iconName="down-arrow-line"
            className={classNames("TextCollapse__buttonIcon", { "TextCollapse__buttonIcon--reversed": isCollapseOpen })}
          />
        </Button>
      )}
    </div>
  );
};
