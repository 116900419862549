import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { Percentage } from "../../../shared/formatProgress";
import { FormattedText } from "../../../shared/textUtils";

export const useDashboardProgressTexts = (progressPercent: Percentage) => {
  const translations = useTranslations();
  const formattedDescription = FormattedText(translations.dashboardProgressDescriptionInProgress, [progressPercent]);

  if (progressPercent === 0) {
    return {
      mainMessage: translations.dashboardProgressMessageNothingCompleted,
      description: translations.dashboardProgressDescriptionNothingCompleted,
    };
  }

  if (progressPercent <= 30) {
    return {
      mainMessage: translations.dashboardProgressMessage_1_30,
      description: formattedDescription,
    };
  }

  if (progressPercent <= 50) {
    return {
      mainMessage: translations.dashboardProgressMessage_31_50,
      description: formattedDescription,
    };
  }

  if (progressPercent <= 80) {
    return {
      mainMessage: translations.dashboardProgressMessage_51_80,
      description: formattedDescription,
    };
  }

  if (progressPercent <= 99) {
    return {
      mainMessage: translations.dashboardProgressMessage_81_99,
      description: formattedDescription,
    };
  }

  return {
    mainMessage: translations.dashboardProgressMessageCompleted,
    description: formattedDescription,
  };
};
