import * as React from "react";
import { Command } from "react-mvvm";
import ErrorImage from "../../../../../static/images/dog4.png";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { CommandButton } from "../../../button/Button";
import { Image } from "../../../image/Image";
import { SvgIcon } from "../../../svgIcon/SvgIcon";

export interface MaskTaskErrorProps {
  refresh: Command;
}

export const MaskTaskError = ({ refresh }: MaskTaskErrorProps) => {
  const translations = useTranslations();

  return (
    <div className="MaskTaskError">
      <CommandButton className="MaskTaskError__button" type="circle" command={refresh}>
        <SvgIcon className="MaskTaskError__icon" iconName="refresh" alt={translations.goToVideoPage} />
      </CommandButton>
      <p className="MaskTaskError__text">{translations.taskLoadingError}</p>
      <Image className="MaskTaskError__image" url={ErrorImage} />
    </div>
  );
};
