import React from "react";

export type Level = 1 | 2 | 3 | 4 | 5;

export interface TitleProps {
  children: React.ReactNode;
  id?: string;
  className?: string;
  level: Level;
}

export const Title = (props: TitleProps) => {
  const { children, id, level, className } = props;
  const CustomTag = (level > 5 ? "p" : `h${level}`) as keyof JSX.IntrinsicElements;

  return (
    <CustomTag id={id} className={className}>
      {children}
    </CustomTag>
  );
};
