import Konva from "konva";
import React from "react";

export const useKonvaImageCash = (imageRef: React.RefObject<Konva.Image>) => {
  React.useEffect(() => {
    return () => {
      imageRef.current?.clearCache();
    };
  }, []);

  React.useEffect(() => {
    if (imageRef.current && !imageRef.current.isCached()) {
      imageRef.current.cache({ pixelRatio: 0.5 });
      imageRef.current.drawHitFromCache();
    }
  }, [imageRef.current]);
};
