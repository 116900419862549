import React from "react";

interface RadioGroupContextProps {
  name: string;
  onChange: (id: string) => void;
  value?: string;
  disabled?: boolean;
}

const radioGroupContext = React.createContext<RadioGroupContextProps>({
  name: "",
  onChange: () => {},
});

export const useRadioGroupContext = () => {
  const context = React.useContext(radioGroupContext);
  if (!context) {
    throw new Error(`Radio compound components cannot be rendered outside the RadioGroup component`);
  }
  return context;
};

export const RadioGroupProvider = ({
  children,
  name,
  onChange,
  value,
  disabled,
}: {
  children: React.ReactNode;
  name: string;
  onChange: (id: string) => void;
  value?: string;
  disabled?: boolean;
}) => {
  const { Provider } = radioGroupContext;

  return <Provider value={{ name, onChange, value, disabled }}>{children}</Provider>;
};
