import classNames from "classnames";
import React from "react";
import { TextContentWithPlaceholder } from "../../textContentWithPlaceholder/TextContentWithPlaceholder";

type TextType = "normal" | "h3" | "h2";

export interface TextProps {
  className?: string;
  children: React.ReactNode;
  type: TextType;
}

export const Text: React.FC<TextProps> = props => {
  const { children, className, type } = props;

  const CustomTag = (type === "normal" ? "span" : type) as keyof JSX.IntrinsicElements;
  const isVisible = !(Array.isArray(children) && children.length === 1 && children[0] === "");

  return (
    <CustomTag
      className={classNames(
        "Text",
        {
          "Text--normal": type === "normal",
          "Text--h3": type === "h3",
          "Text--h2": type === "h2",
        },
        className
      )}
    >
      <TextContentWithPlaceholder isVisible={isVisible}>{children}</TextContentWithPlaceholder>
    </CustomTag>
  );
};
