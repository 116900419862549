import { ITestService } from "../../shared/api/BackendApi";

export class TestApi {
  constructor(private testService: ITestService) {}

  get(id: string, courseId: string) {
    return this.testService.getTest({ id, courseId });
  }

  async submit(testResult: {
    courseId: string;
    themeId: string;
    testId: string;
    correctAnswers: number;
    totalQuestions: number;
    isPassed: boolean;
  }) {
    await this.testService.submitTest({ testResult });
  }
}
