//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum StepTypeDto { 
	Video = "Video", 
	TextBlock = "TextBlock", 
	TaskGroup = "TaskGroup", 
	Task = "Task", 
	Sound = "Sound"
}
