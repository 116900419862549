import shuffle from "lodash/shuffle";
import { action, computed, observable } from "mobx";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { TestChoiceListCollectionDto } from "../../../../types/tasks/testchoices/TestChoiceListCollectionDto";
import { TestChoiceListOptionDto } from "../../../../types/tasks/testchoices/TestChoiceListOptionDto";
import { AnswersState } from "../AnswersState";
import { InteractiveTask } from "../InteractiveTask";
import { OptionStatus } from "../shared/OptionStatus";

export type DataValuesType = {
  [key: string]: string;
};

export type TestOption = {
  value: string;
  label: string;
};

export class ChoiceListTaskViewModel implements InteractiveTask {
  @observable testOptions: TestOption[];

  @observable selectedOptions: DataValuesType = {};

  @observable readonly optionsWithStatus: { id: string; option: TestChoiceListOptionDto; status: OptionStatus }[];

  @observable isDisabled = false;

  @computed get isAnswered() {
    const selectedOptionsValues = Object.values(this.selectedOptions);
    return selectedOptionsValues.length === this.optionsWithStatus.length;
  }

  @computed get isSubmitted() {
    return this.answersState !== "default";
  }

  @computed get answersState(): AnswersState {
    if (this.optionsWithStatus.some(opt => opt.status === "incorrect")) {
      return "wrong";
    }

    if (this.optionsWithStatus.some(opt => opt.status === "shouldBeChecked")) {
      return "partiallyCorrect";
    }

    if (this.optionsWithStatus.some(opt => opt.status === "correct")) {
      return "correct";
    }

    return "default";
  }

  constructor(
    public id: string,
    public title: string,
    test: TestChoiceListCollectionDto,
    public readonly content: PortableTextDto,
    public readonly shouldDisplayOrderNumber: boolean,
    public readonly shouldDisplayCorrectAnswer: boolean,
    public readonly image?: ImageDto,
    public readonly modelId?: string,
    public description?: string
  ) {
    this.testOptions = this.createTestOptions(test);
    this.optionsWithStatus = test.choices
      .slice()
      .sort((a, b) => a.number - b.number)
      .map(choice => ({ id: choice.id, option: choice, status: "default" }));
  }

  @action.bound
  reset() {
    this.resetOptionsStatus();

    this.selectedOptions = {};
    this.isDisabled = false;
  }

  @computed get isTrainingGroundTask() {
    return window.location.href.includes("/ov-pa-anatomien");
  }

  @action.bound
  resetOptionsStatus() {
    this.optionsWithStatus.forEach(optionWithStatus => {
      // eslint-disable-next-line no-param-reassign
      optionWithStatus.status = "default";
    });
  }

  @action.bound
  showCorrectAnswer() {
    this.optionsWithStatus.forEach(optionWithStatus => {
      this.selectedOptions[optionWithStatus.option.id] = optionWithStatus.option.content;
      // eslint-disable-next-line no-param-reassign
      optionWithStatus.status = "correct";
    });
  }

  @action.bound
  selectOption(answerId: string, newValue: string) {
    this.selectedOptions[answerId] = newValue;

    if (this.answersState !== "default") {
      this.resetOptionsStatus();
    }
  }

  @action.bound
  submit() {
    this.optionsWithStatus.forEach(answer => {
      this.selectedOptions?.[answer.option.id] === answer.option.content
        ? // eslint-disable-next-line no-param-reassign
          (answer.status = "correct")
        : // eslint-disable-next-line no-param-reassign
          (answer.status = "incorrect");
    });
  }

  private createTestOptions(test: TestChoiceListCollectionDto) {
    const choices = test.choices.map(choice => ({ value: choice.content, label: choice.content }));
    const additionalChoices = test.additionalChoices.map(choice => ({
      value: choice.content,
      label: choice.content,
    }));
    return shuffle([...choices, ...additionalChoices]);
  }
}
