import classNames from "classnames";
import * as React from "react";
import { PaymentSidePanelViewModel } from "../../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { useUrl } from "../../../../useRouter";
import { ThemeStructure } from "../../../contentStructure/ThemeStructure";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { DetailedProgressBar } from "../../detailedProgressBar/DetailedProgressBar";
import { SanityImage } from "../../image/Image";
import { Link } from "../../link/Link";
import { DomainThumbnail } from "../../thumbnails/domainThumbnail/DomainThumbnail";
import { IStepThumbnail } from "../../thumbnails/stepThumbnail/createStepThumbnailViewModel";
import { formatThemeNumber } from "../utils";

export interface CardThemeDetailedProps {
  themeStructure: ThemeStructure;
  totalStepsCount: number;
  completedStepsCount: number;
  continueStepThumbnail?: IStepThumbnail;
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
}

export const CardThemeDetailed: React.FC<CardThemeDetailedProps> = ({
  themeStructure,
  totalStepsCount,
  completedStepsCount,
  continueStepThumbnail,
  paymentSidePanelViewModel,
}) => {
  const translations = useTranslations();
  const url = useUrl();
  const { courseStructure, title, description, slug, image, themeColor, orderNumber } = themeStructure;
  const { urlParams } = courseStructure;

  return (
    <div className={classNames("CardThemeDetailed", { "CardThemeDetailed--withoutNumber": !orderNumber })}>
      <div className="CardThemeDetailed__themeInfo" style={{ backgroundColor: themeColor }}>
        <div className="CardThemeDetailed__themeDetails">
          {!!orderNumber && <div className="CardThemeDetailed__cardNumber">{formatThemeNumber(orderNumber)}</div>}
          <div className="CardThemeDetailed__titleWrapper">
            <h3 className="CardThemeDetailed__title">{title}</h3>
          </div>
          <p className="CardThemeDetailed__description">{description}</p>
        </div>
        <div className="CardThemeDetailed__imageWrapper">
          {image && <SanityImage {...image} className="CardThemeDetailed__image" />}
        </div>
        <Link className="CardThemeDetailed__linkOverlay" type="clear" to={url.toThemePage(urlParams, slug)}>
          <span className="visuallyHidden">{title}</span>
        </Link>
      </div>
      <div className="CardThemeDetailed__lessonsInfo">
        {continueStepThumbnail && (
          <div className="CardThemeDetailed__thumbnailWrapper">
            <DomainThumbnail
              stepThumbnail={continueStepThumbnail}
              courseStructure={courseStructure}
              activeCourseParams={urlParams}
              paymentSidePanelViewModel={paymentSidePanelViewModel}
              thumbnailType="horizontal"
              thumbnailTitleLevel={4}
            />
          </div>
        )}
        <div className="CardThemeDetailed__lessonInfoBottom">
          <DetailedProgressBar
            className="CardThemeDetailed__progress"
            totalStepsCount={totalStepsCount}
            completedStepsCount={completedStepsCount}
            progressInfo={translations.stepsProgressInfo}
          />
          {continueStepThumbnail && (
            <Link
              tabIndex={-1}
              className="CardThemeDetailed__nextStepLink"
              type="button"
              buttonVariant="secondary"
              to={url.toLessonPage(courseStructure.urlParams, continueStepThumbnail.contentRef)}
            >
              {translations.progressContinueButton}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
