import React from "react";
import { ProductQuoteDto } from "../../../types/productquote/dto/ProductQuoteDto";
import { Image, SanityImage } from "../image/Image";

interface QuoteSlideProps {
  quote: ProductQuoteDto;
}

export const QuoteSlide: React.FC<QuoteSlideProps> = props => {
  const {
    quote: { quote, authorImage, authorName, authorRole, productTag },
  } = props;

  return (
    <div className="QuoteSlide">
      <div className="QuoteSlide__content">
        <p className="QuoteSlide__productTag">{productTag}</p>
        <blockquote className="QuoteSlide__quote">{quote}</blockquote>
        <div className="QuoteSlide__authorInfo">
          <Image.Container aspectRatio="1/1" className="QuoteSlide__authorImageContainer">
            <SanityImage {...authorImage} className="QuoteSlide__authorImage" />
          </Image.Container>
          <div className="QuoteSlide__authorInfoSplitline" />
          <div className="QuoteSlide__authorData">
            <p className="QuoteSlide__authorName">{authorName}</p>
            <p className="QuoteSlide__authorRole">{authorRole}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
