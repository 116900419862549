import React from "react";
import ReactDOM from "react-dom";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { HrefType, Link } from "../link/Link";
import { Modal } from "../modal/Modal";
import { RettsdataReferenceContent } from "./RettsdataReferenceContent";
import "./RettsdataReference.css";

export interface Law {
  id: string;
  title: string;
}

export interface RettsdataReferenceProps {
  children: React.ReactNode;
  mainLaw: Law;
  childLaw: Law;
}

export const RettsdataReference: React.FC<RettsdataReferenceProps> = props => {
  const { children, mainLaw, childLaw } = props;
  const [isContentVisible, setContentVisible] = React.useState(false);
  const translations = useTranslations();

  const modalElement = (
    <Modal className="RettsdataReference__modal" onClose={() => setContentVisible(false)} isOpen={isContentVisible}>
      <div className="RettsdataReference__content">
        <RettsdataReferenceContent childLaw={childLaw} mainLaw={mainLaw} />
      </div>
      <div className="RettsdataReference__modalCloseContainer">
        <Button
          type="outline"
          variant="secondary"
          className="RettsdataReference__modalCloseButton"
          onClick={() => setContentVisible(false)}
        >
          {translations.close}
        </Button>
      </div>
    </Modal>
  );

  return (
    <span className="RettsdataReference">
      <Link
        hrefType={HrefType.External}
        onClick={e => {
          e.preventDefault();
          setContentVisible(true);
        }}
        to={`https://arbeidsbok.gyldendal.no/lawesome/law_indexes/${mainLaw.id}/law_documents/${childLaw.id}`}
      >
        {children}
      </Link>
      {isContentVisible && ReactDOM.createPortal(modalElement, document.body)}
    </span>
  );
};
