import { ProductQueryParams } from "../pages/product/addProductRouting";

export const setQueryParamsToStorage = (productQueryParams: ProductQueryParams) => {
  if (Object.keys(productQueryParams).length > 0) {
    Object.keys(productQueryParams).forEach(key => {
      const value = productQueryParams[key as keyof ProductQueryParams];
      window.sessionStorage.setItem(key, value);
    });
  }
};
