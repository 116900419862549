import noop from "lodash/noop";
import { computed } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { ITibetClient } from "../../shared/api/TibetClient";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { ProductStore } from "../../shared/stores/ProductStore";
import { UserStore } from "../../shared/stores/UserStore";
import { isDefined } from "../../shared/typeUtils";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { App } from "../App";
import { GtmBasePage } from "../GtmBasePage";

export class ProductNestViewModel extends GtmBasePage {
  constructor(
    public config: ConfigDto,
    public languageService: ILanguageService,
    public productStore: ProductStore,
    public userStore: UserStore,
    public tibetClient: ITibetClient,
    public navigation: ILowLevelNavigationService,
    public backendApi: BackendApi,
    public gtm: IGtmService,
    public app: App
  ) {
    super(languageService, gtm, app);
  }

  @computed get title() {
    return this.config.productNestTitle;
  }

  @computed get description() {
    return this.config.productNestDescription;
  }

  @computed get listInfo() {
    return this.config.productNestListInfo.map(info => ({ id: info, value: info }));
  }

  @computed get products() {
    const productIdsInOrder = this.config.products.productIds;
    const { products } = this.productStore;

    return productIdsInOrder.map(productId => products.find(product => product.id === productId)).filter(isDefined);
  }

  protected async loadData() {
    noop();
  }

  public async onActivated() {
    if (this.app.childPage) {
      return super.onActivated();
    }

    if (this.productStore.isOnlyOneProduct) {
      this.app.activate();
    }

    this.productStore.deactivateProduct();
  }
}
