import { StepTypeDto } from "../types/shared/dto/StepTypeDto";

export type HighLevelStepType = "Video" | "Task" | "TextBlock" | "Sound";
export type StepType = `${StepTypeDto}`;

export const stepTypeDtoToStepType: { [key in StepType]: HighLevelStepType } = {
  Video: "Video",
  TextBlock: "TextBlock",
  Task: "Task",
  TaskGroup: "Task",
  Sound: "Sound",
};
