import classNames from "classnames";
import React from "react";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { StatusTag } from "../../../statusTag/StatusTag";

interface FreeInfoLayerProps {
  size: "small" | "default";
}

export const FreeInfoLayer: React.FC<FreeInfoLayerProps> = props => {
  const { size } = props;
  const translations = useTranslations();

  return (
    <div className="FreeInfoLayer">
      <div
        className={classNames("FreeInfoLayer__tagWrapper", { "FreeInfoLayer__tagWrapper--small": size === "small" })}
      >
        <StatusTag status="success" size={size}>
          {translations.gratis}
        </StatusTag>
      </div>
    </div>
  );
};
