import React from "react";
import { ReactSVG } from "react-svg";
import { DynamicLink, StaticLink } from "../../../layout/header/HeaderViewModel";
import gyldendalLogoUrl from "../../../static/images/iconsAnimated/logo-desktop.svg";
import { CourseColorVersionDto } from "../../../types/courses/dto/CourseColorVersionDto";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { MotifImage } from "../image/Image";
import { Link } from "../link/Link";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface LogoSectionProps {
  link: StaticLink;
  colorVersion?: CourseColorVersionDto;
  studiesTypeLink?: DynamicLink;
}

export const LogoSection: React.FC<LogoSectionProps> = props => {
  const { link, colorVersion, studiesTypeLink } = props;
  const translations = useTranslations();

  const logoUrl = colorVersion === "Light" ? "icons/logo-light.svg" : "icons/logo.svg";
  const gyldendalLogoClassName =
    colorVersion === "Light"
      ? "LogoSection__gyldendalLogoWrapper LogoSection__gyldendalLogoWrapper--white"
      : "LogoSection__gyldendalLogoWrapper";
  const studiesTypeClassName =
    colorVersion === "Light" ? "LogoSection__studiesType LogoSection__studiesType--white" : "LogoSection__studiesType";

  return (
    <div className="LogoSection">
      <Link to="/" hrefType={link.type} className="LogoSection__logoLink" aria-label={translations[link.translation]}>
        <MotifImage className="LogoSection__logo" image={{ fileName: logoUrl }} />
      </Link>
      {studiesTypeLink ? (
        <>
          <div className="LogoSection__splitLine  tablet" />
          <Link to={studiesTypeLink.href} hrefType={studiesTypeLink.type} className={studiesTypeClassName} type="clear">
            {studiesTypeLink.title}
          </Link>
        </>
      ) : (
        <>
          <div className="LogoSection__splitLine LogoSection__mobileLogoSplitLine" />
          <div className={gyldendalLogoClassName}>
            <SvgIcon iconName="logo-mobile" className="LogoSection__gyldendalLogoMobile" />
            <ReactSVG src={gyldendalLogoUrl} className="LogoSection__gyldendalLogoDesktop" />
          </div>
        </>
      )}
    </div>
  );
};
