import { observer } from "mobx-react";
import * as React from "react";
import { SectionHeading } from "../../../../../../shared/components/sectionHeading/SectionHeading";
import { Tab } from "../../../../../../shared/components/tabs/tab/Tab";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { TimeSpan } from "../../../../../../shared/models/TimeSpan";
import { Anchor } from "../Video";
import { Anchors } from "../anchors/Anchors";

interface VideoTabProps {
  anchors: Anchor[];
  moveVideo: (secondsSinceBeginning: TimeSpan) => void;
}

export const VideoTab = observer((props: VideoTabProps) => {
  const { anchors, moveVideo } = props;

  const translations = useTranslations();

  return (
    <Tab key="anchors" eventKey="anchors" tabTitle={translations.highlights} isVisible={anchors.length > 0}>
      <div className="VideoTab">
        <SectionHeading title={translations.highlights} level={2} size="medium" />
        <div className="VideoTab__anchors">
          <Anchors anchors={anchors} moveVideo={moveVideo} />
        </div>
      </div>
    </Tab>
  );
});
