import { action, observable } from "mobx";
import { Task } from "../../../../../shared/api/StepService";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { TaskFactory } from "../../../../../shared/components/tasks/TaskFactory";
import { ActionableTask } from "../../../../../shared/components/tasks/TaskView";
import { ActiveCourseParams } from "../../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { ThemeInfoDto } from "../../../../../types/trainingground/dto/ThemeInfoDto";

export class TrainingTask {
  readonly task: ActionableTask;

  readonly hint?: TaskHintViewModel;

  @observable public visited = false;

  @action.bound
  visit() {
    this.visited = true;
  }

  constructor(
    activeCourseParams: ActiveCourseParams,
    taskFactory: TaskFactory,
    task: Task,
    public readonly isLastFromTheme: boolean,
    public theme: ThemeInfoDto,
    bookmarkStore: IBookmarkStore
  ) {
    if (task._type === "TextTask") {
      throw new Error(`${task._type} is invalid task type in Training Ground`);
    }

    this.task = taskFactory.createTask(task) as ActionableTask;
    this.hint =
      task?.hint &&
      new TaskHintViewModel(activeCourseParams, bookmarkStore, task.hint.content, task.hint.relatedStepInfo);
  }
}
