import flatten from "lodash/flatten";
import sumBy from "lodash/sumBy";
import { computed } from "mobx";
import { ThemeStructure } from "../contentStructure/ThemeStructure";
import { IProgressStore } from "../stores/ProgressStore";
import { LessonProgress } from "./LessonProgress";

export class ThemeProgress {
  @computed get lessonsProgress() {
    return this.themeStructure.lessonStructures.map(lesson => new LessonProgress(this.progressStore, lesson));
  }

  @computed get steps() {
    return flatten(this.lessonsProgress.map(lessonProgress => lessonProgress.steps));
  }

  @computed get actionableStepsCount() {
    return sumBy(this.lessonsProgress, lessonProgress => lessonProgress.actionableStepsCount);
  }

  @computed get completedStepsCount() {
    return sumBy(this.lessonsProgress, lessonProgress => lessonProgress.completedStepsCount);
  }

  @computed get progress() {
    if (this.actionableStepsCount === 0) {
      return 0;
    }
    return this.completedStepsCount / this.actionableStepsCount;
  }

  @computed get id() {
    return this.themeStructure.id;
  }

  @computed get continueStep() {
    const steps = this.themeStructure.steps.filter(step => step.hasAccess);
    const nextUncompletedStepId = this.progressStore.nextUncompletedFrom(steps.map(step => step.id));
    return steps.find(f => f.id === nextUncompletedStepId);
  }

  @computed get isStarted() {
    return this.completedStepsCount > 0;
  }

  constructor(private progressStore: IProgressStore, private themeStructure: ThemeStructure) {}

  getLessonProgressById(lessonId: string) {
    const lessonProgress = this.lessonsProgress.find(lesson => lesson.id === lessonId);

    if (!lessonProgress) {
      throw new Error(`Lesson with id: ${lessonId} not found.`);
    }

    return lessonProgress;
  }
}
