import { observer } from "mobx-react";
import React from "react";
import { GapTaskContextProvider } from "../../../contexts/gapTask/GapTaskContext";
import { PortableTextContent } from "../../portableTextContent/PortableTextContent";
import { GapTaskViewModel } from "./GapTaskViewModel";

interface GapTaskProps {
  model: GapTaskViewModel;
}

export const GapTask: React.FC<GapTaskProps> = observer(props => {
  const { model } = props;

  return (
    <GapTaskContextProvider gapTaskViewModel={model}>
      <div className="GapTask">
        <div className="GapTask__content">
          <PortableTextContent content={model.content} />
        </div>
      </div>
    </GapTaskContextProvider>
  );
});
