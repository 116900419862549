import { computed } from "mobx";
import { StepThumbnailDto } from "../../../../types/shared/dto/StepThumbnailDto";
import { ContentReference } from "../../../models/Url";
import { IProgressStore } from "../../../stores/ProgressStore";
import { BookmarkToggleViewModel } from "../shared/BookmarkToggleViewModel";
import { StepThumbnailType } from "./StepThumbnailType";

type TaskType = Exclude<StepThumbnailType, "Video" | "TextBlock">;

export interface ITaskThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  hasAccess: boolean;
  isHighlighted?: boolean;
  description?: string;
  color?: string;
  isCompleted: boolean;
  type: TaskType;
  slug: string;
  bookmarkToggleViewModel?: BookmarkToggleViewModel;
}

export class TaskThumbnailViewModel implements ITaskThumbnail {
  protected progressStore: IProgressStore;

  id: string;

  slug: string;

  title: string;

  contentRef: ContentReference;

  description?: string | undefined;

  type: TaskType;

  color?: string;

  hasAccess: boolean;

  isHighlighted?: boolean;

  @computed get isCompleted() {
    return this.progressStore.isCompleted(this.id);
  }

  constructor(
    stepThumbnail: StepThumbnailDto,
    progressStore: IProgressStore,
    public bookmarkToggleViewModel?: BookmarkToggleViewModel,
    isHighlighted?: boolean
  ) {
    this.progressStore = progressStore;
    this.id = stepThumbnail.id;
    this.contentRef = stepThumbnail;
    this.title = stepThumbnail.title;
    this.color = stepThumbnail.color;
    this.hasAccess = stepThumbnail.hasAccess;
    this.description = stepThumbnail.description;
    this.isHighlighted = isHighlighted;
    this.slug = stepThumbnail.slug;

    // TODO: task thumbnail vm creation shouldn't base on dto
    if (stepThumbnail.type === "Video" || stepThumbnail.type === "TextBlock") {
      throw new Error("Not allowed step type");
    }

    this.type = stepThumbnail.type;
  }
}
