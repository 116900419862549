import { IBookmarkService } from "../../shared/api/BackendApi";
import { BookmarkInfo } from "./BookmarkListStore";

export class BookmarkListApi {
  constructor(private bookmarkService: IBookmarkService) {}

  async getByCourseId(courseId: string) {
    const res = await this.bookmarkService.getUserBookmarks({ courseId });
    return res.map(o => ({
      id: o.id,
      thumbnail: o.thumbnail,
      message: o.message,
      subTitle: o.subTitle,
      title: o.title,
    }));
  }

  async delete(id: string) {
    await this.bookmarkService.deleteUserBookmark({ id });
  }

  async update(bookmark: BookmarkInfo) {
    await this.bookmarkService.updateUserBookmark({ id: bookmark.id, message: bookmark.message });
  }
}
