import classNames from "classnames";
import range from "lodash/range";
import { observer } from "mobx-react";
import * as React from "react";
import { PaginatedList } from "react-mvvm";
import { SearchItemSkeletonView } from "../../../pages/search/searchItem/SearchItemSkeletonView";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { FormattedText } from "../../textUtils";
import { CommandButton } from "../button/Button";
import { List } from "./List";

interface LoadMoreListProps<T extends { id: string | number }> {
  list: PaginatedList<T>;
  renderItem: (item: T, index: number) => React.ReactNode;
  totalCount: number;
  className?: string;
}

export const LoadMoreList = observer(<T extends { id: string | number }>(props: LoadMoreListProps<T>) => {
  const { list, renderItem, className, totalCount } = props;
  const translations = useTranslations();

  const calculateSkeletonItemsNumber = () => {
    if (totalCount - list.items.length < list.pageSize) {
      return totalCount - list.items.length;
    }
    return list.pageSize;
  };
  return (
    <div className={classNames("LoadMoreList", className)}>
      <List items={list.items} renderItem={renderItem} />
      {list.isLoadingNextPage &&
        range(calculateSkeletonItemsNumber()).map(item => (
          <SearchItemSkeletonView key={`SearchItemSkeleton__${item}`} />
        ))}
      <div className="LoadMoreList__showMore">
        <CommandButton command={list.loadNextPage} size="small" variant="secondary" type="outline">
          {translations.showMore}
        </CommandButton>
        <p className="LoadMoreList__showingOf">
          {FormattedText(translations.showingOf, [list.items.length, totalCount])}
        </p>
      </div>
    </div>
  );
});
