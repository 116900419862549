import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../shared/components/button/Button";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { DynamicLink, SourceLink } from "../HeaderViewModel";
import { HamburgerMenu } from "../hamburgerMenu/HamburgerMenu";
import { HeaderHamburgerViewModel } from "./HeaderHamburgerViewModel";

interface HeaderHamburgerProps {
  model: HeaderHamburgerViewModel;
}

export const HeaderHamburgerView = observer((props: HeaderHamburgerProps) => {
  const { model } = props;
  const {
    mobileHamburgerMenuLinks,
    desktopHamburgerPrimaryLinks,
    desktopHamburgerSecondaryLinks,
    desktopMenu,
    mobileMenu,
    studiesType,
    searchLink,
    paymentSidePanelViewModel,
    goToSearchPage,
  } = model;
  const translations = useTranslations();

  const convertToLinkWithTitle = (links: SourceLink[]): DynamicLink[] => {
    return links.map(link => {
      if (link.source === "static") {
        return { ...link, title: translations[link.translation] };
      }

      return link;
    });
  };

  return (
    <div>
      {(desktopHamburgerPrimaryLinks.length > 0 || desktopHamburgerSecondaryLinks.length > 0) && (
        <Button
          type="clear"
          className="Header__iconWrapperStyling Header__tabletActionItem"
          onClick={() => desktopMenu.toggle()}
        >
          <SvgIcon iconName="menu" className="Header__actionIcon" />
          <span className="visuallyHidden">{desktopMenu.isOpen ? translations.closeMenu : translations.openMenu}</span>
        </Button>
      )}
      {mobileHamburgerMenuLinks.primaryLinks.length > 0 && (
        <Button
          type="clear"
          className="Header__iconWrapperStyling Header__mobileActionItem"
          onClick={() => mobileMenu.toggle()}
        >
          <SvgIcon iconName="menu" className="Header__actionIcon" />
          <span className="visuallyHidden">{mobileMenu.isOpen ? translations.closeMenu : translations.openMenu}</span>
        </Button>
      )}
      <HamburgerMenu
        model={desktopMenu}
        links={convertToLinkWithTitle(desktopHamburgerPrimaryLinks)}
        secondaryLinks={convertToLinkWithTitle(desktopHamburgerSecondaryLinks)}
        paymentSidePanel={paymentSidePanelViewModel}
      />
      <HamburgerMenu
        model={mobileMenu}
        links={convertToLinkWithTitle(mobileHamburgerMenuLinks.primaryLinks)}
        secondaryLinks={convertToLinkWithTitle(mobileHamburgerMenuLinks.secondaryLinks)}
        goToSearchPage={searchLink && goToSearchPage}
        paymentSidePanel={paymentSidePanelViewModel}
        studiesType={studiesType}
      />
    </div>
  );
});
