import { observable } from "mobx";
import { IErrorService } from "../shared/services/ErrorService";
import { ILanguageService } from "../shared/services/LanguageService";
import { PageNotFoundError } from "../shared/services/errors/HttpError";
import { TranslatedPage } from "./TranslatedPage";

// This is a base page for all the views that are children of the app that do not report to GTM on activation.
export abstract class BasePage extends TranslatedPage {
  constructor(languageService: ILanguageService, public errorService: IErrorService) {
    super(languageService);
  }

  @observable isLoading = true;

  protected abstract loadData(): Promise<void>;

  async onLanguageChange() {
    await this.loadData();
  }

  // Loading data happens in beforeActivated, so that data is first loaded in parent view model, then child, etc.
  async beforeActivated() {
    await this.loadData().catch(e => this.errorService.showErrorSplash(e));
    await super.beforeActivated();
  }

  async onActivated() {
    this.isLoading = false;
    await super.onActivated();
  }

  async onInvalidRoute() {
    this.errorService.showErrorSplash(new PageNotFoundError("Page Not Found"));
  }
}
