import { action } from "mobx";
import { Task } from "../../../../shared/api/StepService";
import { TaskHintViewModel } from "../../../../shared/components/taskHint/TaskHintViewModel";
import { TaskFactory } from "../../../../shared/components/tasks/TaskFactory";
import { ActionableTask } from "../../../../shared/components/tasks/TaskView";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";

export class TestTask {
  get id() {
    return this.task.model.id;
  }

  readonly task: ActionableTask;

  get model() {
    return this.task.model;
  }

  readonly hint?: TaskHintViewModel;

  constructor(
    activeCourseParams: ActiveCourseParams,
    taskFactory: TaskFactory,
    task: Task,
    bookmarkStore: IBookmarkStore
  ) {
    if (task._type === "TextTask") {
      throw new Error(`${task._type} is invalid task type in Training Ground`);
    }

    this.task = taskFactory.createTask(task) as ActionableTask;
    this.hint =
      task?.hint &&
      new TaskHintViewModel(activeCourseParams, bookmarkStore, task.hint.content, task.hint.relatedStepInfo);
  }

  @action.bound
  disable() {
    this.task.model.isDisabled = true;
  }

  @action.bound
  submit() {
    this.task.model.submit();
  }

  @action.bound
  reset() {
    this.task.model.reset();
  }
}
