import { action, observable } from "mobx";
import { Popup } from "../../../../shared/components/popup/Popup";
import { CourseInfo } from "../../../../shared/stores/Product";

export interface NewsletterData {
  fullName?: string;
  email: string;
  profession: string;
}

export class BetaNewsletterPopupViewModel extends Popup {
  @observable course: CourseInfo | undefined;

  @action.bound
  showNewsletterForm(course: CourseInfo) {
    this.course = course;
    this.show();
  }

  @action.bound
  handleSubmit(formData: NewsletterData) {
    if (!this.course) {
      throw new Error("Course not specified?");
    }

    this.handleNewsletterSubmit(this.course, formData);
    this.close();
  }

  constructor(private handleNewsletterSubmit: (course: CourseInfo, formData: NewsletterData) => void) {
    super();
  }
}
