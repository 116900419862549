import classNames from "classnames";
import React, { ReactNode } from "react";
import { List } from "../../../../shared/components/list/List";
import { OrderIndicator } from "../../../../shared/components/orderIndicator/OrderIndicator";
import { SectionHeading } from "../../../../shared/components/sectionHeading/SectionHeading";
import { TestTask } from "../testTask/TestTask";

interface TestTaskListItemProps {
  orderNumber: string | number;
  color: string;
  children: ReactNode;
}

const TestTaskListItem = (props: TestTaskListItemProps) => {
  const { children, orderNumber, color } = props;
  return (
    <div className="TestTaskList__item">
      <SectionHeading level="withoutLevel" title={<OrderIndicator color={color}>{orderNumber}</OrderIndicator>} />
      {children}
    </div>
  );
};

interface TestTaskListProps {
  testTasks: TestTask[];
  renderTask: (item: TestTask, index: number) => React.ReactNode;
  className?: string;
}

export const TestTaskList = (props: TestTaskListProps) => {
  const { testTasks, renderTask, className } = props;
  return <List className={classNames("TestTaskList", className)} items={testTasks} renderItem={renderTask} />;
};

TestTaskList.Item = TestTaskListItem;
