import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { SectionIconBlockDto } from "../../../../types/sections/dto/SectionIconBlockDto";
import { VimeoPlayer } from "../../videoPlayer/vimeoPlayer/VimeoPlayer";
import { SectionIconListBlock } from "../SectionIconListBlock/SectionIconListBlock";

export interface SectionTextBlockProps {
  id: string;
  title: string;
  description?: string;
  videoUrl?: string;
  sectionIcons?: SectionIconBlockDto[];
}
export const SectionTextBlock = observer(({ title, description, videoUrl, sectionIcons }: SectionTextBlockProps) => {
  const isAdditionalInfoEnabled = videoUrl && sectionIcons && sectionIcons.length > 0;

  return (
    <div className="SectionTextBlock">
      <div
        className={classNames("SectionTextBlock__inner", {
          "SectionTextBlock__inner--withAdditionalInfo": isAdditionalInfoEnabled,
        })}
      >
        <div className="SectionTextBlock__innerContainer">
          <div className="SectionTextBlock__info">
            <h2 className="SectionTextBlock__title">{title}</h2>
            <p className="SectionTextBlock__description">{description}</p>
          </div>
        </div>

        {isAdditionalInfoEnabled && (
          <>
            <div className="SectionTextBlock__additionalInfo">
              <div className="SectionTextBlock__videoPlayerWrapper">
                <div className="SectionTextBlock__videoPlayer">
                  <VimeoPlayer videoUrl={videoUrl} />
                </div>
              </div>
            </div>
            <SectionIconListBlock sectionIcons={sectionIcons} />
          </>
        )}
      </div>
    </div>
  );
});
