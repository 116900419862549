import classNames from "classnames";
import React from "react";
import { Button, CommandButton } from "../../../../shared/components/button/Button";
import { Link } from "../../../../shared/components/link/Link";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { TestPageViewModel } from "../../TestPageViewModel";

type TestResultActionButtonsProps = {
  reviewId: string;
  className?: string;
} & Pick<TestPageViewModel, "reset" | "nextElementLink" | "isReviewOpen" | "toggleReview">;

export const TestResultActionButtons = (props: TestResultActionButtonsProps) => {
  const { reset, nextElementLink, isReviewOpen, toggleReview, reviewId, className } = props;
  const translations = useTranslations();

  return (
    <div className={classNames("TestResultActionButtons", className)}>
      <div className="TestResultActionButtons__container">
        <CommandButton className="TestResultActionButtons__button" command={reset}>
          {translations.tryAgain}
          <SvgIcon iconName="repeat" className="TestResultActionButtons__icon" />
        </CommandButton>
        <Link
          to={nextElementLink.href}
          hrefType={nextElementLink.type}
          type="button"
          buttonVariant="secondary"
          buttonType="outline"
          className="TestResultActionButtons__link"
        >
          {translations[nextElementLink.translation]}
        </Link>
      </div>
      <Button
        className="TestResultActionButtons__button"
        type="outline"
        variant="secondary"
        onClick={toggleReview}
        aria-expanded={isReviewOpen}
        aria-controls={reviewId}
      >
        {isReviewOpen ? translations.hideAnswers : translations.seeAnswers}
        <SvgIcon
          iconName="down-arrow-line"
          className={classNames("TestResultActionButtons__icon", {
            "TestResultActionButtons__icon--reversed": isReviewOpen,
          })}
        />
      </Button>
    </div>
  );
};
