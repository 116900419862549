import React from "react";
import { ProductParams } from "../../../../pages/product/addProductRouting";
import { ArticleListItemDto } from "../../../../types/articles/dto/ArticleListItemDto";
import { useUrl } from "../../../../useRouter";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { Link } from "../../link/Link";
import { List } from "../../list/List";
import { SectionHeading } from "../../sectionHeading/SectionHeading";
import { SectionArticleItem } from "./SectionArticleItem/SectionArticleItem";

export interface SectionArticleListBlockProps {
  title: string;
  articles: ArticleListItemDto[];
  goBackText?: string;
  productParams?: ProductParams;
}

export const SectionArticleListBlock: React.FC<SectionArticleListBlockProps> = props => {
  const { title, articles, goBackText, productParams } = props;
  const url = useUrl();
  const translations = useTranslations();

  return (
    <div className="SectionArticleListBlock">
      <div className="SectionArticleListBlock__inner">
        <SectionHeading
          title={title}
          level={2}
          extra={
            productParams && (
              <Link
                to={url.toProductArticleListPage(productParams)}
                type="button"
                buttonVariant="secondary"
                className="SectionArticleListBlock__actionLink"
              >
                {translations.seeAllArticles}
              </Link>
            )
          }
        />
        <List
          className="SectionArticleListBlock__list"
          singleItemClassName="SectionArticleListBlock__listItem"
          items={articles}
          renderItem={article => {
            return (
              <SectionArticleItem
                id={article.id}
                slug={article.slug}
                title={{ level: 3, size: "big", text: article.title }}
                category={article.category?.name}
                description={article.description}
                image={article.image}
                goBackText={goBackText}
                productParams={productParams}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
