import classNames from "classnames";
import Konva from "konva";
import { observer } from "mobx-react";
import React from "react";
import { Image } from "react-konva";
import { useIsVisible } from "../../../../hooks/useIsVisible";
import { useScrollThreshold } from "../../../../hooks/useScrollThreshold";
import { ActivityIndicator } from "../../../activityIndicator/ActivityIndicator";
import { Layer } from "../../../konva/layer/Layer";
import { ResponsiveStage } from "../../../konva/responsiveStage/ResponsiveStage";
import { MaskTaskViewModel } from "../MaskTaskViewModel";
import { MaskTaskError } from "../maskTaskError/MaskTaskError";
import { useKonvaImageCash } from "../useKonvaImageCash";
import { Mask } from "./Mask";

interface MaskTaskStageProps {
  model: MaskTaskViewModel;
}

export const MaskTaskStage = observer((props: MaskTaskStageProps) => {
  const { model } = props;
  const { state, refresh, stage, id, highlightMasks, load } = model;
  const { pointerTouchStart, pointerTouchEnd } = useScrollThreshold(10);
  const stageContainerRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(stageContainerRef);
  const imageRef = React.useRef<Konva.Image>(null);
  useKonvaImageCash(imageRef);

  React.useEffect(() => {
    if (isVisible && state.type === "loading") {
      load();
    }
  }, [isVisible]);

  if (state.type === "error") {
    return <MaskTaskError refresh={refresh} />;
  }

  return (
    <div ref={stageContainerRef} className="MaskTaskStage">
      <ActivityIndicator isLoading={state.type === "loading"}>
        <div
          className={classNames("MaskTaskStage__inner", {
            "MaskTaskStage__inner--correct": state.type === "correct",
            "MaskTaskStage__inner--incorrect": state.type === "incorrect",
            "MaskTaskStage__inner--partiallyCorrect": state.type === "partiallyCorrect",
          })}
        >
          <div className="MaskTaskStage__wrapper">
            <ResponsiveStage {...stage} key={id} preventDefault={false}>
              {state.type !== "loading" && (
                <>
                  <Layer preventDefault={false}>
                    <Image
                      image={state.image}
                      onMouseDown={pointerTouchStart}
                      onTouchStart={pointerTouchStart}
                      onMouseUp={() => pointerTouchEnd(highlightMasks)}
                      onTouchEnd={() => pointerTouchEnd(highlightMasks)}
                      perfectDrawEnabled={false}
                      preventDefault={false}
                      ref={imageRef}
                      shadowBlur={5}
                      shadowColor="rgb(33, 33, 33)"
                      shadowOffset={{ x: 0, y: 0 }}
                      shadowOpacity={0.6}
                    />
                  </Layer>
                  <Layer preventDefault={false}>
                    {state.masks.map(m => (
                      <Mask
                        key={m.id}
                        model={m}
                        pointerTouchStart={pointerTouchStart}
                        pointerTouchEnd={() => pointerTouchEnd(m.toggleSelection)}
                      />
                    ))}
                  </Layer>
                </>
              )}
            </ResponsiveStage>
          </div>
        </div>
      </ActivityIndicator>
    </div>
  );
});
