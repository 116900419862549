import { observer } from "mobx-react";
import * as React from "react";
import { isLoaded } from "react-mvvm";
import { ActivityIndicator } from "../../../shared/components/activityIndicator/ActivityIndicator";
import { Image } from "../../../shared/components/image/Image";
import { List } from "../../../shared/components/list/List";
import { PageTitle } from "../../../shared/components/pageTitle/PageTitle";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { FormattedText } from "../../../shared/textUtils";
import emptyBookmarksImageUrl from "../../../static/images/dog.png";
import { BookmarkListViewModel } from "./BookmarkListViewModel";
import { BookmarkView } from "./bookmark/BookmarkView";

export const BookmarkListView = observer(({ model }: { model: BookmarkListViewModel }) => {
  const { state } = model;
  const translations = useTranslations();

  return (
    <PageTitle title={translations.myBookmarks}>
      <article className="BookmarkList">
        <div className="BookmarkList__inner">
          <div className="BookmarkList__heading">
            <h1 className="BookmarkList__title">{translations.myBookmarks}</h1>
          </div>
          <ActivityIndicator isLoading={!isLoaded(state)}>
            {isLoaded(state) &&
              (state.bookmarks.length > 0 ? (
                <>
                  <p className="BookmarkList__listHeading">
                    {FormattedText(translations.bookmarks, [state.bookmarks.length])}
                  </p>
                  <List
                    items={state.bookmarks}
                    className="BookmarkList__list"
                    renderItem={bookmark => {
                      return <BookmarkView model={bookmark} />;
                    }}
                  />
                </>
              ) : (
                <div className="BookmarkList__emptyList">
                  <Image.Container aspectRatio="1/1" className="BookmarkList__emptyListImageWrapper">
                    <Image url={emptyBookmarksImageUrl} />
                  </Image.Container>
                  <p className="BookmarkList__emptyListText">{translations.youHaveNoSavedBookmarks}</p>
                </div>
              ))}
          </ActivityIndicator>
        </div>
      </article>
    </PageTitle>
  );
});
