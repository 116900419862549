import classNames from "classnames";
import React from "react";
import { List } from "../../list/List";
import { ImagePropsNode } from "../PortableTextTypes";

export interface ImageCollectionProps {
  images: ImagePropsNode[];
  imageSize?: "small" | "normal" | "large";
  imageSerializer: (props: ImagePropsNode) => React.ReactNode;
}

export const ImageCollection: React.FC<ImageCollectionProps> = props => {
  const { images, imageSize, imageSerializer } = props;

  return (
    <List
      className={classNames("ImageCollection", {
        "ImageCollection--small": imageSize === "small",
        "ImageCollection--large": imageSize === "large",
      })}
      items={images.map(image => {
        return { ...image, id: image.asset.url };
      })}
      renderItem={image => {
        return <div className="ImageCollection__imageWrapper">{imageSerializer(image)}</div>;
      }}
    />
  );
};
