import * as React from "react";
import { command } from "react-mvvm";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { DialogView } from "../dialog/DialogView";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface PopoverProps {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = props => {
  const { isOpen, toggle, children } = props;
  const translations = useTranslations();

  const buttonWrapperRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div className="Popover">
      <div className="Popover__buttonWrapper" ref={buttonWrapperRef}>
        <Button onClick={toggle} type="clear" className="Popover__button">
          <span className="visuallyHidden">{isOpen ? translations.close : translations.open}</span>
          <SvgIcon iconName="more-vertical" className="Popover__icon" />
        </Button>
      </div>
      <DialogView
        placement="auto"
        isVisible={isOpen}
        referenceElement={buttonWrapperRef.current}
        cancelCommand={command(() => toggle())}
        offset={[0, 8]}
      >
        {children}
      </DialogView>
    </div>
  );
};
