import { IHomeRoute, route } from "react-mvvm";
import { App, AppQuery } from "./pages/App";
import { addAboutUsRouting } from "./pages/aboutUs/addAboutUsRouting";
import { addArticlesRoutes } from "./pages/articles/addArticlesRoutes";
import { addCourseArticleRouting } from "./pages/articles/addCourseArticleRouting";
import { addProductArticleListRouting } from "./pages/articles/addProductArticleListRouting";
import { addProductArticleRouting } from "./pages/articles/addProductArticleRouting";
import { addAuthorsForProductRouting } from "./pages/authorsForProduct/addAuthorsForProductRouting";
import { addBookmarksRoutes } from "./pages/bookmarks/addBookmarksRoutes";
import { addBuyRedirectRouting } from "./pages/buyRedirect/addBuyRedirectRouting";
import { addCourseRouting } from "./pages/course/addCourseRouting";
import { addDashboardRouting } from "./pages/dashboard/addDashboardRouting";
import { addErrorRoutes } from "./pages/error/addErrorsRoutes";
import { addLessonsRoutes } from "./pages/lessons/addLessonsRoutes";
import { addLoginRouting } from "./pages/login/addLoginRouting";
import { addPreviousExamsRouting } from "./pages/previousExams/addPreviousExamsRouting";
import { addProductRouting } from "./pages/product/addProductRouting";
import { addPurchaseConfirmationRouting } from "./pages/purchaseConfirmation/addPurchaseConfirmationRouting";
import { addSiteSearchRouting } from "./pages/search/siteSearch/addSiteSearchRouting";
import { addStepPreviewRouting } from "./pages/stepPreview/addStepPreviewRouting";
import { addTermsAndConditionsRoute } from "./pages/termsAndConditions/addTermsAndConditionsRouting";
import { addTestsRoutes } from "./pages/tests/addTestsRoutes";
import { addThemeListRouting } from "./pages/themes/addThemeListRouting";
import { addThemesRoutes } from "./pages/themes/addThemesRoutes";
import { addTrainingGroundsRouting } from "./pages/trainingGrounds/addTrainingGroundsRouting";
import { initAxiosGlobalInterceptors } from "./shared/api/AxiosClient";
import { BackendApi } from "./shared/api/BackendApi";
import { TibetClient } from "./shared/api/TibetClient";
import { GtmService } from "./shared/services/GtmService";
import { LanguageService } from "./shared/services/LanguageService";
import { NavigationService } from "./shared/services/NavigationService";
import { ProductStore } from "./shared/stores/ProductStore";
import { UserStore } from "./shared/stores/UserStore";

export const appRoute = route<App, AppQuery>(async () => {
  const navigation = new NavigationService();
  const languageService = new LanguageService();
  initAxiosGlobalInterceptors(navigation, languageService);
  const backendApi = new BackendApi();
  const productStorePromise = ProductStore.create(backendApi, backendApi);
  const configPromise = backendApi.getConfig({});
  const userStorePromise = UserStore.create(backendApi);
  const [config, userStore, productStore] = await Promise.all([configPromise, userStorePromise, productStorePromise]);
  const gtmService = new GtmService(navigation);
  const tibetClient = new TibetClient(backendApi, navigation, gtmService);
  const app = new App(
    config,
    languageService,
    productStore,
    userStore,
    tibetClient,
    navigation,
    backendApi,
    gtmService
  );
  backendApi.initErrorInterceptors(app);
  return app;
});

class AppRoutes {
  buyRedirectRouting = addBuyRedirectRouting(this._appRoute);

  purchaseConfirmation = addPurchaseConfirmationRouting(this._appRoute);

  aboutUsRouting = addAboutUsRouting(this._appRoute);

  articlesRoutes = addArticlesRoutes(this._appRoute);

  loginRouting = addLoginRouting(this._appRoute);

  stepPreviewRouting = addStepPreviewRouting(this._appRoute);

  errorRouting = addErrorRoutes(this._appRoute);

  termsAndConditionsRouting = addTermsAndConditionsRoute(this._appRoute);

  productRouting = addProductRouting(this._appRoute);

  productArticleListRouting = addProductArticleListRouting(this.productRouting);

  productArticleRouting = addProductArticleRouting(this.productArticleListRouting);

  authorsForProduct = addAuthorsForProductRouting(this.productRouting);

  courseRouting = addCourseRouting(this.productRouting);

  themeListRoute = addThemeListRouting(this.courseRouting);

  themesRoutes = addThemesRoutes(this.themeListRoute);

  testsRoutes = addTestsRoutes(this.themesRoutes);

  lessonsRoutes = addLessonsRoutes(this.courseRouting);

  bookmarkRoutes = addBookmarksRoutes(this.courseRouting);

  dashboardRouting = addDashboardRouting(this.courseRouting);

  siteSearchRouting = addSiteSearchRouting(this.courseRouting);

  courseArticleRouting = addCourseArticleRouting(this.courseRouting);

  trainingGroundsRouting = addTrainingGroundsRouting(this.courseRouting);

  previousExamsRouting = addPreviousExamsRouting(this.courseRouting);

  constructor(private _appRoute: IHomeRoute<App>) {}
}

export const appRoutes = new AppRoutes(appRoute);
