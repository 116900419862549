import { observer } from "mobx-react";
import React from "react";
import { GapTaskViewModel } from "../../components/tasks/gapTask/GapTaskViewModel";

export interface GapTaskOptions {
  gapTaskViewModel: GapTaskViewModel;
}

const gapTaskContext = React.createContext<GapTaskOptions | undefined>(undefined);

export const useGapTaskContext = () => {
  const context = React.useContext<GapTaskOptions | undefined>(gapTaskContext);

  if (!context) {
    throw new Error("Gap task context shouldn't be used outside of the context provider");
  }

  return context;
};

export const GapTaskContextProvider = observer(
  ({ children, gapTaskViewModel }: { children: React.ReactNode; gapTaskViewModel: GapTaskViewModel }) => {
    const { Provider } = gapTaskContext;
    return <Provider value={{ gapTaskViewModel }}>{children}</Provider>;
  }
);
