import React, { FunctionComponent } from "react";
import "./MessageBoxIcon.css";

interface MessageBoxIconProps {
  messageText: string;
}

export const MessageBoxIcon: FunctionComponent<MessageBoxIconProps> = ({ messageText }) => {
  return (
    <div className="message-box-icon">
      <p>{messageText}</p>
    </div>
  );
};
