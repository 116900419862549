import { ImageCropDto } from "../../../types/shared/dto/ImageCropDto";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { ImageHotspotDto } from "../../../types/shared/dto/ImageHotspotDto";

export const imageCropMock: ImageCropDto = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

export const imageHotspotMock: ImageHotspotDto = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const imageParamsMock: Pick<ImageDto, "width" | "height" | "crop" | "hotspot" | "alt"> = {
  width: 0,
  height: 0,
  crop: imageCropMock,
  hotspot: imageHotspotMock,
  alt: "",
};
