import { computed, observable } from "mobx";
import { UserDto } from "../../types/shared/dto/UserDto";
import { IUserService } from "../api/BackendApi";

export interface IUserStore {
  isAuthenticated: boolean;
}

export class UserStore implements IUserStore {
  @observable public user?: UserDto;

  @computed get isAuthenticated() {
    return !!this.user;
  }

  private constructor(public userService: IUserService) {}

  static async create(userService: IUserService) {
    const store = new UserStore(userService);
    await store.loadUser();
    return store;
  }

  private async loadUser() {
    this.user = await this.userService.getUser({});
  }
}
