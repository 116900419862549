import { observer } from "mobx-react";
import * as React from "react";
import { BasicThemesSection } from "../../../../shared/components/basicThemesSection/BasicThemesSection";
import { CoursesSection } from "../../../../shared/components/coursesSection/CoursesSection";
import { Link } from "../../../../shared/components/link/Link";
import { List } from "../../../../shared/components/list/List";
import { SectionHeading } from "../../../../shared/components/sectionHeading/SectionHeading";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { useAppUser } from "../../../../shared/contexts/UserProvider";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { useUrl } from "../../../../useRouter";
import { ExamThemeCard } from "../../../previousExams/components/examThemeCard/ExamThemeCard";
import { DashboardViewModel } from "../../DashboardViewModel";
import { DashboardSection } from "./DashboardSection";
import { InProgressThemesSection } from "./sections/inProgressThemesSection/InProgressThemesSection";
import { PreviousExamsSection } from "./sections/previousExamsSection/PreviousExamsSection";
import { ThemesWithFreeStepsSection } from "./sections/themesWithFreeStepsSection/ThemesWithFreeStepsSection";
import { TrainingGroundSection } from "./sections/trainingGroundSection/TrainingGroundSection";

interface DashboardSectionssProps {
  model: DashboardViewModel;
}

export const DashboardSections = observer(({ model }: DashboardSectionssProps) => {
  const { paymentSidePanelViewModel, sections } = model;
  const translations = useTranslations();
  const url = useUrl();
  const user = useAppUser();

  const renderSection = (section: DashboardSection) => {
    switch (section.__type) {
      case "FinishedThemesSection":
        return (
          <BasicThemesSection
            title={translations.finished}
            themes={section.themeSectionsModel.finishedThemes}
            level={2}
          />
        );
      case "InProgressThemesSection":
        return (
          <InProgressThemesSection
            themes={section.themeSectionsModel.inProgressThemes}
            paymentSidePanelViewModel={paymentSidePanelViewModel}
          />
        );
      case "NotStartedThemesSection":
        return (
          <BasicThemesSection
            title={user._type === "loggedIn" ? translations.notStarted : translations.allThemes}
            themes={section.themeSectionsModel.notStartedThemes}
            level={2}
          />
        );
      case "TrainingGroundSection":
        return (
          <TrainingGroundSection
            trainingGroundInfos={section.trainingGroundInfos}
            activeCourseParams={section.activeCourseParams}
          />
        );
      case "PreviousExamsSection":
        if (section.model.startedExamThemes.length > 0) {
          return (
            <div>
              <SectionHeading
                level={2}
                title={translations.startedExams}
                extra={
                  <Link
                    to={url.toPreviousExamsPage(section.model.urlParams)}
                    type="button"
                    buttonVariant="secondary"
                    className="DashboardSections__examsLink"
                  >
                    {translations.seeAllExams}
                  </Link>
                }
              />
              <List
                items={section.model.startedExamThemes}
                renderItem={examTheme => <ExamThemeCard examTheme={examTheme} />}
                className="DashboardSections__examsList"
              />
            </div>
          );
        }

        if (section.model.previousExamsSection) {
          return (
            <div className="DashboardSections__section--stickToTheBottom">
              <PreviousExamsSection
                section={section.model.previousExamsSection}
                activeCourseParams={section.model.urlParams}
              />
            </div>
          );
        }

        return null;
      case "ThemesWithFreeStepsSection":
        return (
          <div className="DashboardSections__section--noSpacingAbove">
            <ThemesWithFreeStepsSection
              section={section.section}
              themes={section.themeSectionsModel.themesWithFreeSteps}
              activeCourseParams={section.activeCourseParams}
            />
          </div>
        );
      case "OtherCoursesSection":
        if (section.courses.length <= 1) {
          return;
        }

        return (
          <div className="DashboardSections__section--stickToTheBottom DashboardSections__section--noSpacingAbove">
            <CoursesSection
              courses={section.courses}
              coursesSectionId=""
              onCourseClick={section.onCourseClick}
              sectionTitle={section.sectionTitle}
              productName={section.productTitle}
            />
          </div>
        );
      default:
        return assertUnreachable(section);
    }
  };

  return (
    <div className="DashboardSections">
      {sections.map((el, index) => (
        <React.Fragment key={index}>{renderSection(el)}</React.Fragment>
      ))}
    </div>
  );
});
