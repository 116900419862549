import classNames from "classnames";
import * as React from "react";
import { ButtonSize, ButtonType, ButtonVariant } from "../button/Button";
import { useCheckbox } from "./useCheckbox";

interface ButtonStyling {
  type: ButtonType;
  variant: ButtonVariant;
  size: ButtonSize;
}

export interface CheckboxButtonProps {
  id: string;
  className?: string;
  onChange?: (value: string) => void;
  checked?: boolean;
  children: React.ReactNode;
  defaultButtonStyling?: ButtonStyling;
  activeButtonStyling?: ButtonStyling;
}

export const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  id,
  className,
  onChange,
  checked,
  children,
  defaultButtonStyling: propsDefaultButtonStyling,
  activeButtonStyling: propsActiveButtonStyling,
}) => {
  const { name, disabled, inputChecked, onInputChange, htmlId } = useCheckbox(id, checked, onChange);

  const defaultButtonStyling = propsDefaultButtonStyling || { size: "small", type: "outline", variant: "secondary" };
  const activeButtonStyling = propsActiveButtonStyling || { size: "small", type: "solid", variant: "primary" };

  const getButtonClassName = (properties: ButtonStyling) => {
    const { type, variant, size } = properties;

    return classNames(
      "CheckboxButton__label",
      "Button",
      `Button--${type}`,
      `Button--${variant}`,
      `Button--${size}`,
      className
    );
  };

  const activeStyling = inputChecked ? activeButtonStyling : defaultButtonStyling;

  const buttonClassName = getButtonClassName(activeStyling);

  return (
    <div className="CheckboxButton">
      <input
        id={htmlId}
        name={name}
        type="checkbox"
        className="CheckboxButton__input"
        onChange={e => onInputChange && onInputChange(id, e.currentTarget.checked)}
        checked={inputChecked}
        disabled={disabled}
      />
      <label htmlFor={htmlId} className={buttonClassName}>
        {children}
      </label>
    </div>
  );
};
