/* eslint-disable react/no-children-prop */
import React from "react";
import { CheckboxGroup } from "../../checkbox/CheckboxGroup";
import { RadioGroup } from "../../radio/RadioGroup";
import { QuestionId, MultipleAnswerQuestion, SingleAnswerQuestion } from "../taskUtils";
import { MatchTaskValue } from "./MatchTaskViewModel";

export const getGroup = (
  isMultipleChoice: boolean,
  value: MatchTaskValue,
  disabled?: boolean,
  onChange?: (value: MatchTaskValue) => void
) => {
  return ({ name, children, questionId }: { name: string; questionId: QuestionId; children: React.ReactNode }) => {
    const handleOnChange = (newValue: string | string[]) => {
      if (onChange) {
        const answerOption = isMultipleChoice
          ? ({ questionId: questionId, _type: "multipleAnswerQuestion", value: newValue } as MultipleAnswerQuestion)
          : ({ questionId: questionId, _type: "singleAnswerQuestion", value: newValue } as SingleAnswerQuestion);

        onChange([...value.filter(v => v.questionId !== questionId), answerOption]);
      }
    };
    const getValue = <T extends MultipleAnswerQuestion | SingleAnswerQuestion>() =>
      value.find(q => q.questionId === questionId)?.value as T["value"];

    return isMultipleChoice ? (
      <CheckboxGroup
        onChange={handleOnChange}
        value={getValue<MultipleAnswerQuestion>()}
        disabled={disabled}
        name={name}
        children={children}
      />
    ) : (
      <RadioGroup
        onChange={handleOnChange}
        value={getValue<SingleAnswerQuestion>()}
        disabled={disabled}
        name={name}
        children={children}
      />
    );
  };
};
