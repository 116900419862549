import classNames from "classnames";
import React from "react";
import { List } from "../../../../../../shared/components/list/List";
import { SectionHeading } from "../../../../../../shared/components/sectionHeading/SectionHeading";
import { TrainingGroundDomainThumbnail } from "../../../../../../shared/components/thumbnails/domainThumbnail/trainingGroundDomainThumbnail/TrainingGroundDomainThumbnail";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../../../shared/models/Url";
import { ImageDto } from "../../../../../../types/shared/dto/ImageDto";
import { useUrl } from "../../../../../../useRouter";

export interface TrainingGroundInfo {
  slug: string;
  id: string;
  title: string;
  description?: string;
  image?: ImageDto;
  hasAccess: boolean;
}

export interface TrainingGroundSectionProps {
  trainingGroundInfos: TrainingGroundInfo[];
  activeCourseParams: ActiveCourseParams;
}

export const TrainingGroundSection = (props: TrainingGroundSectionProps) => {
  const { trainingGroundInfos, activeCourseParams } = props;
  const translations = useTranslations();
  const url = useUrl();

  const layout = trainingGroundInfos.length >= 3 ? "vertical" : "highlighted";

  return (
    <section className="TrainingGroundSection">
      <SectionHeading title={translations.testYourself} level={2} />
      <List
        items={trainingGroundInfos}
        className={classNames("TrainingGroundSection__list", {
          "TrainingGroundSection__list--vertical": layout === "vertical",
        })}
        renderItem={trainingGroundInfo => {
          return (
            <TrainingGroundDomainThumbnail
              model={trainingGroundInfo}
              thumbnailType={layout}
              href={url.toTrainingGroundPage(activeCourseParams, trainingGroundInfo.slug)}
            />
          );
        }}
      />
    </section>
  );
};
