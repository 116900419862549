import difference from "lodash/difference";
import { useEffect } from "react";

const getIsFilterSelectAllChecked = <TFilterValue>(
  availableFilterValues: TFilterValue[],
  selectedFilterValues?: TFilterValue[]
) => {
  const notSelectedValues = difference(availableFilterValues, selectedFilterValues || []);

  if (notSelectedValues.length === 0) {
    return true;
  }

  if (notSelectedValues.length === availableFilterValues.length) {
    return true;
  }

  return false;
};

const getIsSelectAllChecked = <TFilterValue>(
  availableFilterValues: TFilterValue[],
  selectedFilterValues?: TFilterValue[]
) => {
  const notSelectedValues = difference(availableFilterValues, selectedFilterValues || []);

  if (notSelectedValues.length === 0) {
    return true;
  }

  return false;
};

export const useSelectAllCheckbox = <TValue extends string>(
  availableValues: TValue[],
  selectValues: (values: TValue[]) => void,
  values?: TValue[],
  mode: "normal" | "filter" = "normal"
) => {
  const isSelectAllChecked =
    mode === "filter"
      ? getIsFilterSelectAllChecked(availableValues, values)
      : getIsSelectAllChecked(availableValues, values);

  useEffect(() => {
    if (isSelectAllChecked) {
      selectValues(availableValues);
    }
  }, [isSelectAllChecked]);

  return { isSelectAllChecked };
};
