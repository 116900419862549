import { action, computed, observable } from "mobx";
import { StepThumbnailDto } from "../../types/shared/dto/StepThumbnailDto";
import { BookmarkListApi } from "./BookmarkListApi";

type BookmarkThumbnail = StepThumbnailDto;

export interface BookmarkInfo {
  id: string;
  thumbnail: BookmarkThumbnail;
  title: string;
  subTitle?: string;
  message?: string;
}

export class Bookmark implements BookmarkInfo {
  readonly id: string;

  readonly thumbnail: BookmarkThumbnail;

  readonly title: string;

  readonly subTitle?: string;

  @observable message?: string;

  constructor(private store: BookmarkListStore, bookmarkInfo: BookmarkInfo) {
    this.id = bookmarkInfo.id;
    this.thumbnail = bookmarkInfo.thumbnail;
    this.title = bookmarkInfo.title;
    this.subTitle = bookmarkInfo.subTitle;
    this.message = bookmarkInfo.message;
  }

  async saveChanges() {
    await this.store.bookmarkListApi.update(this);
  }

  async delete() {
    await this.store.bookmarkListApi.delete(this.id);
    this.store.removeBookmark(this);
  }
}

export class BookmarkListStore {
  byId = observable.map<string, Bookmark>();

  constructor(public bookmarkListApi: BookmarkListApi) {}

  async loadByCourseId(courseId: string) {
    this.load(await this.bookmarkListApi.getByCourseId(courseId));
  }

  @action
  private load(bookmarkable: BookmarkInfo[]) {
    bookmarkable.forEach(it => this.byId.set(it.id, new Bookmark(this, it)));
  }

  removeBookmark(bookmark: Bookmark) {
    this.byId.delete(bookmark.id);
  }

  @computed get all() {
    return Array.from(this.byId.values());
  }
}
