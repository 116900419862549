import React from "react";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { getInitials } from "../../textUtils";
import { Avatar } from "../avatar/Avatar";
import { Button } from "../button/Button";

export interface AuthorInfoProps {
  name: string;
  role: string;
  image?: ImageDto;
  onClick?: () => void;
}

export const AuthorInfo: React.FC<AuthorInfoProps> = props => {
  const { name, role, image, onClick } = props;
  const initials = getInitials(name);

  return (
    <div className="AuthorInfo">
      {onClick ? (
        <Button type="clear" onClick={onClick}>
          <Avatar image={image} initials={initials} className="AuthorInfo__avatar" />
        </Button>
      ) : (
        <div>
          <Avatar image={image} initials={initials} className="AuthorInfo__avatar" />
        </div>
      )}
      <div className="AuthorInfo__detailed">
        <p className="AuthorInfo__name">{name}</p>
        <span className="AuthorInfo__role">{role}</span>
      </div>
    </div>
  );
};
