import { observer } from "mobx-react";
import React from "react";
import { Button } from "../../../shared/components/button/Button";
import { Link } from "../../../shared/components/link/Link";
import { ProgressBar } from "../../../shared/components/progressBar/ProgressBar";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { formatProgress } from "../../../shared/formatProgress";
import { TestPageViewModel } from "../TestPageViewModel";

export const TestHeader = observer(({ model }: { model: TestPageViewModel }) => {
  const translations = useTranslations();
  const { title, subtitle, tasksCount, completedTasksCount, confirmExitPanel, state, exitLink } = model;
  const { unitInterval } = formatProgress(completedTasksCount / tasksCount);

  const exitTestLinkContent = (
    <>
      <div className="mobileOnly">
        <SvgIcon iconName="close" />
        <span className="visuallyHidden">{state === "test" ? translations.cancel : translations.exit}</span>
      </div>
      <div className="tablet">{state === "test" ? translations.cancel : translations.exit}</div>
    </>
  );

  return (
    <header data-testid="TestHeader" className="TestHeader">
      <div className="TestHeader__inner">
        <div className="TestHeader__content">
          <div className="TestHeader__testInfo">
            <span className="TestHeader__subtitle">{subtitle}</span>
            <div className="TestHeader__title">{title}</div>
          </div>
          <div className="TestHeader__interactive">
            <div className="TestHeader__progress">
              <span className="TestHeader__tasksCount">{`${completedTasksCount}/${tasksCount}`}</span>
              <span className="TestHeader__tasksCountDescription">{translations.finishedTasks}</span>
              <ProgressBar progress={unitInterval} type="info" />
            </div>
            {state === "test" ? (
              <Button
                variant="secondary"
                type="outline"
                size="small"
                className="TestHeader__quitItem"
                onClick={() => confirmExitPanel.show()}
              >
                {exitTestLinkContent}
              </Button>
            ) : (
              <Link
                to={exitLink}
                type="button"
                buttonVariant="secondary"
                buttonType="outline"
                buttonSize="small"
                className="TestHeader__quitItem"
              >
                {exitTestLinkContent}
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
});
