import React from "react";
import { Stage, StageProps } from "react-konva";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { ActivityIndicator } from "../../activityIndicator/ActivityIndicator";

interface ResponsiveStageProps extends Exclude<StageProps, "ref"> {
  width: number;
  height: number;
  preventDefault?: boolean;
  children: React.ReactNode;
  className?: string;
}

interface InnerStageProps extends Exclude<StageProps, "ref"> {
  width: number;
  baseWidth: number;
  aspectRatio: number;
  preventDefault: boolean;
  children: React.ReactNode;
}

const InnerStage = (props: InnerStageProps) => {
  const { children, aspectRatio, baseWidth, width } = props;
  const height = width / aspectRatio;
  const scale = { x: width / baseWidth, y: width / baseWidth };

  return (
    <Stage {...props} scale={scale} width={width} height={height}>
      {children}
    </Stage>
  );
};

// ResponsiveStage for children with static position
export const ResponsiveStage = (props: ResponsiveStageProps) => {
  const { children, width, height, preventDefault = true } = props;
  const { width: newWidth, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 0,
  });
  const aspectRatio = width / height;

  return (
    <div className="ResponsiveStage" ref={ref}>
      <ActivityIndicator isLoading={newWidth === undefined}>
        {newWidth !== undefined && (
          <InnerStage
            {...props}
            preventDefault={preventDefault}
            baseWidth={width}
            aspectRatio={aspectRatio}
            width={newWidth}
          >
            {children}
          </InnerStage>
        )}
      </ActivityIndicator>
    </div>
  );
};
