import { computed, observable, observe } from "mobx";
import { Popup } from "../../../../shared/components/popup/Popup";
import { CourseLevel, Level } from "../../../shared/courseLevel/CourseLevel";

interface LevelSidePanelCourseProgress {
  progress: number;
}

export class LevelSidePanelViewModel extends Popup {
  @observable newLevel = this.courseLevel.level;

  @observable oldLevel?: Level;

  @computed get progressValue() {
    return this.courseProgress.progress;
  }

  constructor(private courseProgress: LevelSidePanelCourseProgress, private courseLevel: CourseLevel) {
    super();

    observe(courseLevel, "level", ({ newValue, oldValue }) => {
      this.newLevel = newValue;
      this.oldLevel = oldValue;
    });
  }
}
