import { observer } from "mobx-react";
import * as React from "react";
import Scroll from "react-scroll";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { TestPageViewModel } from "./TestPageViewModel";
import { ConfirmExitPanel } from "./confirmExitModal/ConfirmExitModal";
import { Test } from "./test/Test";
import { TestHeader } from "./testHeader/TestHeader";
import { TestResult } from "./testResult/TestResult";
import { useTestPageColor } from "./useTestPageColor";

const scroll = Scroll.animateScroll;

export const TestPageView = observer(({ model }: { model: TestPageViewModel }) => {
  const { title, color, state, isLoading, confirmExitPanel, exitLink } = model;
  useTestPageColor(color);

  React.useEffect(() => {
    scroll.scrollToTop();
  }, [state]);

  return (
    <PageTitle title={title}>
      <article className="TestPageView">
        <TestHeader model={model} />
        <div className="TestPageView__inner">
          <div className="TestPageView__content">
            <ActivityIndicator className="TestPageView__loader" isLoading={isLoading}>
              <div className="TestPageView__contentInner">
                {state === "test" && <Test model={model} />}
                {state === "testResult" && <TestResult model={model} />}
              </div>
            </ActivityIndicator>
          </div>
        </div>
        <ConfirmExitPanel model={confirmExitPanel} exitLink={exitLink} />
      </article>
    </PageTitle>
  );
});
