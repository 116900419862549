import * as React from "react";
import { PortableTextContent } from "../../../../../shared/components/portableTextContent/PortableTextContent";
import { TextBlockStep } from "./TextBlockStep";

export interface TextStepProps {
  model: TextBlockStep;
}

export const TextBlockStepView: React.FC<TextStepProps> = (props: TextStepProps) => {
  const {
    model: { content },
  } = props;

  return (
    <>
      <div className="TextStep">
        <PortableTextContent content={content} />
      </div>
    </>
  );
};
