import React from "react";
import { ScrollLock } from "../../../pages/shared/scrollLock/ScrollLock";
import { BottomSheet } from "../../../shared/components/bottomSheet/BottomSheet";
import { Popup } from "../../../shared/components/popup/Popup";
import { useLocation } from "../../../useRouter";
import { UserLink } from "../HeaderViewModel";
import { UserLinks } from "../userLinks/UserLinks";

interface UserLinksSheetProps {
  links: UserLink[];
  sheet: Popup;
}

export const UserLinksSheet: React.FC<UserLinksSheetProps> = props => {
  const { links, sheet } = props;
  const { isOpen, setIsOpen } = sheet;
  const location = useLocation();

  React.useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <div className="UserLinksSheet">
      <div className="UserLinksSheet__inner">
        <ScrollLock isLocked={isOpen} />
        <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
          <UserLinks links={links} />
        </BottomSheet>
      </div>
    </div>
  );
};
