import React from "react";

interface CheckboxGroupContextProps {
  name: string;
  onChange: (value: string) => void;
  value: string[];
  disabled?: boolean;
}

const checkboxGroupContext = React.createContext<CheckboxGroupContextProps>({
  name: "",
  value: [],
  onChange: () => {},
  disabled: false,
});

export const useCheckboxGroupContext = () => {
  const context = React.useContext(checkboxGroupContext);
  return context;
};

export const CheckboxGroupProvider = ({
  children,
  name,
  onChange,
  value,
  disabled,
}: {
  children: React.ReactNode;
  name: string;
  onChange: (id: string) => void;
  value: string[];
  disabled?: boolean;
}) => {
  const { Provider } = checkboxGroupContext;
  return <Provider value={{ name, onChange, value, disabled }}>{children}</Provider>;
};
