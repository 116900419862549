import classNames from "classnames";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import { formatProgress } from "../../formatProgress";

type ProgressRingType = "default" | "success";

export interface ProgressRingProps {
  progress: number;
  type?: ProgressRingType;
}

export const ProgressRing: React.FC<ProgressRingProps> = ({ progress, type = "default" }) => {
  const { progressValue, unitInterval, isCompleted } = formatProgress(progress);

  const r = 59;
  const circumference = 2 * Math.PI * r;
  const strokeStyles = useSpring({
    to: { strokeDashoffset: circumference - unitInterval * circumference },
    from: { strokeDashoffset: circumference },
  });
  const indicatorWidth = 30;
  const svgSize = r * 2 + indicatorWidth;

  return (
    <div className="ProgressRing">
      <div className="ProgressRing__inner">
        <span className="ProgressRing__percent">{`${progressValue} %`}</span>
      </div>
      <svg viewBox={`0 0 ${svgSize} ${svgSize}`} width="100%" height="100%">
        <circle
          className="ProgressRating_negativeIndicator"
          strokeWidth={indicatorWidth}
          fill="transparent"
          r={r}
          cx="50%"
          cy="50%"
        />
        <animated.circle
          className={classNames("ProgressRating__indicator", `ProgressRating__indicator--${type}`, {
            "ProgressRating__indicator--completed": isCompleted,
          })}
          strokeWidth={indicatorWidth}
          fill="transparent"
          strokeDasharray={`${circumference} ${circumference}`}
          style={strokeStyles}
          r={r}
          cx="50%"
          cy="50%"
        />
      </svg>
    </div>
  );
};
