import classNames from "classnames";
import * as React from "react";
import { Title } from "../../../../shared/components/title/Title";

interface TestTaskInfoProps {
  shouldTaskTitlesBeDisplayed: boolean;
  title: string;
  description?: string;
}

export const TestTaskInfo: React.FC<TestTaskInfoProps> = ({ shouldTaskTitlesBeDisplayed, title, description }) => {
  return (
    <div className={classNames("TestTaskInfo", { "TestTaskInfo--withSpacing": shouldTaskTitlesBeDisplayed })}>
      <Title level={2} className={classNames("TestTaskInfo__title", { visuallyHidden: !shouldTaskTitlesBeDisplayed })}>
        {title}
      </Title>
      {description && <div className="TestTaskInfo__description">{description}</div>}
    </div>
  );
};
