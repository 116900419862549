import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { PortableTextDto } from "../../../../types/shared/dto/PortableTextDto";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { FormattedText } from "../../../textUtils";
import { assertUnreachable } from "../../../typeUtils";
import { Input } from "../../input/Input";
import { MathInput } from "../../mathInput/MathInput";
import { PortableTextContent } from "../../portableTextContent/PortableTextContent";
import { ExactAnswersState } from "../AnswersState";
import { InputTaskInfo } from "./InputTaskViewModel";

export interface InputTaskProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onEnter?: () => void;
  content: PortableTextDto;
  answersState: ExactAnswersState;
  isSubmitted: boolean;
  isDisabled: boolean;
  info?: InputTaskInfo;
  useMathInput: boolean;
}

export const InputTask = observer((props: InputTaskProps) => {
  const { value, onChange, content, answersState, isSubmitted, isDisabled, onBlur, onEnter, info, useMathInput } =
    props;
  const translations = useTranslations();

  const infoRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (isSubmitted && infoRef.current) {
      setTimeout(() => {
        infoRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  }, [isSubmitted]);

  const displayTaskInfo = () => {
    if (info === undefined) {
      return null;
    }

    switch (info._type) {
      case "correct":
        return `${translations.correctAnswer}`;
      case "almostCorrect":
        return `${FormattedText(translations.inputTaskAlmostCorrectAnswer, [info.closestCorrectAnswer])}`;
      case "wrong":
        return `${translations.wrongAnswer} ${FormattedText(translations.inputTaskCorrectAnswer, [
          info.firstCorrectAnswer,
        ])}`;
      default:
        return assertUnreachable(info);
    }
  };

  return (
    <div data-testid="InputTask" className="InputTask">
      <div className="InputTask__content">
        <PortableTextContent content={content} />
      </div>
      <h2 className="InputTask__text">{translations.enterCorrectAnswer}</h2>
      {useMathInput ? (
        <MathInput
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={isDisabled}
          className={classNames(
            "InputTask__input",
            { "InputTask__input--correct": answersState === "correct" },
            { "InputTask__input--wrong": answersState === "wrong" }
          )}
        />
      ) : (
        <Input
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          onEnter={onEnter}
          label={translations.enterCorrectAnswer}
          className={classNames(
            "InputTask__input",
            { "InputTask__input--correct": answersState === "correct" },
            { "InputTask__input--wrong": answersState === "wrong" }
          )}
        />
      )}

      {isSubmitted && (
        <p ref={infoRef} className="InputTask__info">
          {displayTaskInfo()}
        </p>
      )}
    </div>
  );
});
