import { observer } from "mobx-react";
import React from "react";
import { Collapse } from "../../../shared/components/collapse/Collapse";
import { SanityImage } from "../../../shared/components/image/Image";
import { ProgressRing } from "../../../shared/components/progressRing/ProgressRing";
import { TaskHint } from "../../../shared/components/taskHint/TaskHint";
import { Title } from "../../../shared/components/title/Title";
import { useDelayedDisplay } from "../../../shared/hooks/useDelayedDisplay";
import { TestPageViewModel } from "../TestPageViewModel";
import { TestTaskView } from "../shared/testTask/TestTaskView";
import { TestTaskList } from "../shared/testTaskList/TestTaskList";
import { TestResultActionButtons } from "./testResultActionButtons/TestResultActionButtons";

interface TestResultProps {
  model: TestPageViewModel;
}

export const TestResult = observer((props: TestResultProps) => {
  const { model } = props;
  const { testResult, color, reset, nextElementLink, isReviewOpen, toggleReview, test } = model;

  const [isDisplayed, hide] = useDelayedDisplay(isReviewOpen);

  if (!testResult || !test) {
    return null;
  }

  const { shouldTaskTitlesBeDisplayed, tasks } = test;
  const { resultInfo, score, hasPassed } = testResult;
  const { title, description, image } = resultInfo;
  const reviewId = "TestReviewID";

  return (
    <div data-testid="TestResult" className="TestResult">
      <div className="TestResult__heading">
        <div className="TestResult__info">
          <div className="TestResult__progressRing">
            <ProgressRing progress={score} type={hasPassed ? "success" : "default"} />
          </div>
          <Title level={2} className="TestResult__title">
            {title}
          </Title>
          {description && <p className="TestResult__description">{description}</p>}
        </div>
        {image && (
          <div className="TestResult__imageWrapper">
            <SanityImage {...image} className="TestResult__image" />
          </div>
        )}
        <TestResultActionButtons
          className="TestResult__actionButtons"
          reset={reset}
          nextElementLink={nextElementLink}
          isReviewOpen={isReviewOpen}
          toggleReview={toggleReview}
          reviewId={reviewId}
        />
      </div>
      {isDisplayed && (
        <Collapse isOpen={isReviewOpen} id={reviewId} onRest={hide}>
          <div className="TestResult__content">
            <TestTaskList
              testTasks={tasks}
              renderTask={(task, index) => (
                <TestTaskList.Item orderNumber={index + 1} color={color}>
                  <TestTaskView testTask={task} shouldTaskTitlesBeDisplayed={shouldTaskTitlesBeDisplayed} />
                  {task.hint && task.model.answersState !== "correct" && (
                    <div className="TestResult__taskHint">
                      <TaskHint hint={task.hint} />
                    </div>
                  )}
                </TestTaskList.Item>
              )}
            />
            <TestResultActionButtons
              className="TestResult__bottomActionButtons"
              reset={reset}
              nextElementLink={nextElementLink}
              isReviewOpen={isReviewOpen}
              toggleReview={toggleReview}
              reviewId={reviewId}
            />
          </div>
        </Collapse>
      )}
    </div>
  );
});
