import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import { ScrollLock } from "../../../pages/shared/scrollLock/ScrollLock";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { Button } from "../button/Button";
import { usePopup } from "../popup/usePopup";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface SidePanelProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  type?: "rounded" | "notRounded";
  variant?: "wrapper" | "fullWidth";
  className?: string;
}

export const SidePanel: React.FC<SidePanelProps> = ({
  children,
  isOpen,
  onClose,
  className,
  variant = "wrapper",
  type = "rounded",
}) => {
  const translations = useTranslations();
  const { isDisplayed, backgroundStyles } = usePopup(isOpen);

  const sidePanelInnerStyles = useSpring({
    from: { left: "0%", top: "0%" },
    left: isOpen ? "0%" : "100%",
    top: isOpen ? "0%" : "100%",
  });

  if (!isDisplayed) {
    return null;
  }
  return (
    <FocusTrap active={isOpen} focusTrapOptions={{ allowOutsideClick: true }}>
      <div className={classNames("SidePanel", className)}>
        <ScrollLock isLocked={isOpen} />
        <animated.div style={backgroundStyles}>
          <Button onClick={onClose} type="clear" className="SidePanel__closeBackgroundButton">
            <span className="visuallyHidden">{translations.close}</span>
          </Button>
        </animated.div>
        <div
          className={classNames("SidePanel__panelWrapper", {
            "SidePanel__panelWrapper--fullWidth": variant === "fullWidth",
            "SidePanel__panelWrapper--wrapper": variant === "wrapper",
          })}
        >
          <animated.div
            style={sidePanelInnerStyles}
            className={classNames("SidePanel__panel", { "SidePanel__panel--notRounded": type === "notRounded" })}
          >
            <div className="SidePanel__inner">
              {children}
              {variant === "fullWidth" && (
                <Button
                  type="circle"
                  variant="secondary"
                  className="SidePanel__closeMobileButton"
                  onClick={onClose}
                  size="medium"
                >
                  <SvgIcon iconName="close" />
                  <span className="visuallyHidden">{translations.close}</span>
                </Button>
              )}
              <Button
                onClick={onClose}
                type="circle"
                size="large"
                variant="secondary"
                className="SidePanel__closeButton"
              >
                <SvgIcon iconName="close" alt={translations.close} />
                <span className="visuallyHidden">{translations.close}</span>
              </Button>
            </div>
          </animated.div>
        </div>
      </div>
    </FocusTrap>
  );
};
