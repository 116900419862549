import FocusTrap from "focus-trap-react";
import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator } from "../../../../shared/components/activityIndicator/ActivityIndicator";
import { CommandButton } from "../../../../shared/components/button/Button";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { useNotificationService } from "../../../../shared/contexts/notification/NotificationContext";
import { FormattedText } from "../../../../shared/textUtils";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { ScrollLock } from "../../../shared/scrollLock/ScrollLock";
import { LoadedTrainingSetState, TrainingViewModel } from "./TrainingViewModel";
import { TrainingItemContainer } from "./testTask/trainingItemContainer/TrainingItemContainer";

type TrainingLoaded = Omit<TrainingViewModel, "currentTrainingSetState"> & {
  currentTrainingSetState: LoadedTrainingSetState;
};

interface TrainingLoadedViewProps {
  model: TrainingLoaded;
}

const TrainingLoadedView = observer((props: TrainingLoadedViewProps) => {
  const {
    model: {
      currentTrainingSetState,
      isNextTrainingSetLast,
      endTrainingWithConfirmation,
      endTrainingWithoutConfirmation,
      trainingGroundTitle,
      selectedThemesCount,
    },
  } = props;

  const {
    trainingSet: { currentTask, currentTaskTheme, gotoPreviousTask, gotoNextTask, isLastTask },
  } = currentTrainingSetState;
  const {
    isLastFromTheme,
    task: {
      model: { isSubmitted: isCurrentTaskAnswered },
    },
  } = currentTask;
  const { title: themeTitle } = currentTaskTheme;

  const translations = useTranslations();
  const notificationService = useNotificationService();

  React.useEffect(() => {
    if (isLastFromTheme && isCurrentTaskAnswered && selectedThemesCount > 1) {
      notificationService.showNotification(FormattedText(translations.finishedLastFromTheme, [themeTitle]), "info");
    }
  }, [isLastFromTheme, isCurrentTaskAnswered, selectedThemesCount]);

  return (
    <div className="Training">
      <FocusTrap active focusTrapOptions={{ allowOutsideClick: true }}>
        <div className="Training__inner">
          <ScrollLock isLocked />
          <div className="Training__heading">
            <Title level={1} className="Training__title">
              {trainingGroundTitle}
            </Title>
            <CommandButton
              command={endTrainingWithConfirmation}
              type="outline"
              variant="tertiary"
              size="small"
              className="Training__finishButton"
              tabIndex={-1}
            >
              {translations.exit}
            </CommandButton>
          </div>
          <TrainingItemContainer
            trainingTask={currentTask}
            endTrainingWithoutConfirmation={endTrainingWithoutConfirmation}
            gotoPreviousTask={gotoPreviousTask}
            gotoNextTask={gotoNextTask}
            isNextTrainingSetLast={isNextTrainingSetLast}
            isLastTask={isLastTask}
          />
          <CommandButton command={endTrainingWithConfirmation} className="Training__tabFinishButton">
            {translations.exit}
          </CommandButton>
        </div>
      </FocusTrap>
    </div>
  );
});

interface TrainingViewProps {
  model: TrainingViewModel;
}

export const TrainingView = observer((props: TrainingViewProps) => {
  const { model } = props;
  const { currentTrainingSetState } = model;

  switch (currentTrainingSetState._type) {
    case "loading":
      return <ActivityIndicator isLoading={currentTrainingSetState._type === "loading"} />;
    case "loaded":
      return (
        <TrainingLoadedView
          model={{
            ...model,
            currentTrainingSetState,
            nextTrainingSetState: model.nextTrainingSetState,
            isFirstTrainingSet: model.isFirstTrainingSet,
            isNextTrainingSetLast: model.isNextTrainingSetLast,
            trainingGroundTitle: model.trainingGroundTitle,
            getVisitedTasks: model.getVisitedTasks,
            selectedThemesCount: model.selectedThemesCount,
          }}
        />
      );
    default:
      return assertUnreachable(currentTrainingSetState);
  }
});
