import React from "react";
import { ThemeItem } from "../../../../../../shared/components/basicThemesSection/ThemeItem";
import { CardThemeBasic } from "../../../../../../shared/components/card/cardThemeBasic/CardThemeBasic";
import { Link } from "../../../../../../shared/components/link/Link";
import { List } from "../../../../../../shared/components/list/List";
import { SvgIcon } from "../../../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../../../shared/components/title/Title";
import { useTranslations } from "../../../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../../../shared/models/Url";
import { useUrl } from "../../../../../../useRouter";

export interface ThemesWithFreeStepsSectionProps {
  section?: { title?: string; description?: string };
  themes: ThemeItem[];
  activeCourseParams: ActiveCourseParams;
}

export const ThemesWithFreeStepsSection: React.FC<ThemesWithFreeStepsSectionProps> = props => {
  const { section, themes, activeCourseParams } = props;
  const url = useUrl();
  const translations = useTranslations();

  return (
    <section className="ThemesWithFreeStepsSection">
      <div className="ThemesWithFreeStepsSection__header">
        <Title level={2} className="ThemesWithFreeStepsSection__title">
          {section?.title || translations.gratisContentExclamation}
        </Title>
        <p className="ThemesWithFreeStepsSection__description">
          {section?.description || translations.gratisContentDescription}
        </p>
      </div>
      <div className="ThemesWithFreeStepsSection__circle">{translations.tryForFreeNow}</div>
      <div className="ThemesWithFreeStepsSection__content">
        <List
          items={themes}
          renderItem={themeItem => (
            <CardThemeBasic
              themeStructure={themeItem.themeStructure}
              totalStepsCount={themeItem.actionableStepsCount}
              completedStepsCount={themeItem.completedStepsCount}
              status={themeItem.status}
              firstUnfinishedStep={themeItem.firstUnfinishedStep}
              tags={themeItem.tags}
            />
          )}
          className="ThemesWithFreeStepsSection__themesList"
        />
      </div>
      <div className="ThemesWithFreeStepsSection__linkWrapper">
        <Link
          to={url.toThemesPage(activeCourseParams)}
          type="button"
          buttonType="outline"
          buttonVariant="secondary"
          className="ThemesWithFreeStepsSection__link"
        >
          <span>{translations.seeAllThemes}</span>
          <SvgIcon iconName="right-arrow-line" />
        </Link>
      </div>
    </section>
  );
};
