import flatten from "lodash/flatten";
import { computed, action } from "mobx";
import { getProgressByType } from "../components/progressByType/shared/calculateProgressByType";
import { CourseStructure } from "../contentStructure/CourseStructure";
import { formatProgress } from "../formatProgress";
import { HighLevelStepType, stepTypeDtoToStepType } from "../stepTypeUtils";
import { IProgressStore } from "../stores/ProgressStore";
import { assertUnreachable } from "../typeUtils";
import { ThemeProgress } from "./ThemeProgress";

export class CourseProgress {
  @computed get themesProgress() {
    return this.courseStructure.themeStructures
      .filter(theme => theme.isUnderDevelopment === false)
      .map(theme => new ThemeProgress(this.progressStore, theme));
  }

  @computed get previousExamsThemesProgress() {
    return (
      this.courseStructure.previousExamsStructure?.examThemes.map(
        examTheme => new ThemeProgress(this.progressStore, examTheme.theme)
      ) || []
    );
  }

  @computed get steps() {
    return flatten(this.themesProgress.map(themeProgress => themeProgress.steps));
  }

  @computed get actionableStepsCount() {
    return this.steps.length;
  }

  @computed get completedStepsCount() {
    return this.steps.filter(step => step.isCompleted).length;
  }

  @computed get progress() {
    if (this.actionableStepsCount === 0) {
      return 0;
    }
    return this.completedStepsCount / this.actionableStepsCount;
  }

  @computed get progressPercent() {
    const { progressValue } = formatProgress(this.progress);
    return progressValue;
  }

  @computed get progressByType() {
    return getProgressByType(this.steps);
  }

  @action.bound
  isAnyOfType(stepType: HighLevelStepType) {
    switch (stepType) {
      case "Video":
      case "TextBlock":
      case "Sound": {
        return this.steps.filter(s => s.type === stepType).length > 0;
      }
      case "Task": {
        return (
          this.steps.filter(
            s =>
              s.type !== stepTypeDtoToStepType.Video &&
              s.type !== stepTypeDtoToStepType.TextBlock &&
              s.type !== stepTypeDtoToStepType.Sound
          ).length > 0
        );
      }
      default: {
        return assertUnreachable(stepType);
      }
    }
  }

  constructor(private progressStore: IProgressStore, private courseStructure: CourseStructure) {}

  getThemeProgressById(themeId: string) {
    const allThemesProgress = this.themesProgress.concat(this.previousExamsThemesProgress);
    const themeProgress = allThemesProgress.find(theme => theme.id === themeId);

    if (!themeProgress) {
      throw new Error(`Theme with id: ${themeId} not found.`);
    }

    return themeProgress;
  }

  getLessonProgressById(lessonId: string) {
    const lessonsFromThemesProgress = flatten(this.themesProgress.map(theme => theme.lessonsProgress));
    const lessonsFromPreviousExamsThemes = flatten(
      this.previousExamsThemesProgress.map(theme => theme.lessonsProgress)
    );

    const lessonsProgress = lessonsFromThemesProgress.concat(lessonsFromPreviousExamsThemes);

    const lessonProgress = lessonsProgress.find(lesson => lesson.id === lessonId);

    if (!lessonProgress) {
      throw new Error(`Lesson with id: ${lessonId} not found.`);
    }

    return lessonProgress;
  }
}
