import classNames from "classnames";
import * as React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useMotif } from "../../contexts/MotifContext";

interface ActivityIndicatorProps {
  isLoading: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({ isLoading, className, children }) => {
  const motif = useMotif();
  const activityIndicatorColor = motif === "degrees37" ? "#212121" : "#e50043";

  return (
    <div aria-busy={isLoading}>
      {isLoading ? (
        <div className={classNames("ActivityIndicator", className)}>
          <ClipLoader color={activityIndicatorColor} loading={isLoading} />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
