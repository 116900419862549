import { computed } from "mobx";
import { ITibetClient } from "../../../shared/api/TibetClient";
import { Popup } from "../../../shared/components/popup/Popup";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { IErrorService } from "../../../shared/services/ErrorService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { BuyCourseViewModel } from "../buyCourse/BuyCourseViewModel";

export class PaymentSidePanelViewModel extends Popup {
  @computed get buyCourseVm() {
    return new BuyCourseViewModel(
      this.activeCourseStructure,
      "sidepanel",
      this.tibetClient,
      this.errorService,
      this.navigationService
    );
  }

  constructor(
    public tibetClient: ITibetClient,
    private activeCourseStructure: CourseStructure,
    private errorService: IErrorService,
    private navigationService: ILowLevelNavigationService
  ) {
    super();
  }
}
