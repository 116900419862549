import classNames from "classnames";
import * as React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { ProgressBar } from "../../progressBar/ProgressBar";
import { SvgIcon } from "../../svgIcon/SvgIcon";
import { Level, Title } from "../../title/Title";
import { ThumbnailImage } from "../shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

export interface TileThumbnailProps {
  title: { text: string; level: Level };
  type: ThumbnailTypes;
  subtitle?: string;
  timeStamp?: string;
  progress?: number;
  image?: ImageDto;
  overlay?: React.ReactNode;
  locked?: boolean;
  color?: string;
  href?: string;
}

export const TileThumbnail = (props: TileThumbnailProps) => {
  const { title, subtitle, timeStamp, progress, type, image, overlay, color, href, locked = false } = props;

  return (
    <div
      className={classNames(
        "TileThumbnail",
        { "TileThumbnail--locked": locked },
        { "TileThumbnail--video": type === "Video" }
      )}
    >
      {href && !locked && (
        <Link to={href} type="clear" className="TileThumbnail__linkOverlay">
          <span className="visuallyHidden">{title.text}</span>
        </Link>
      )}
      <div className="TileThumbnail__imageWrapper" style={{ backgroundColor: color }}>
        {overlay}
        {href && type === "Video" && !locked && (
          <div className="TileThumbnail__linkWrapper">
            <Link
              type="button"
              buttonType="circle"
              buttonVariant="tertiary"
              buttonSize="xlarge"
              className="TileThumbnail__link"
              to={href}
              tabIndex={-1}
            >
              <span className="visuallyHidden">{title.text}</span>
              <SvgIcon iconName="play" />
            </Link>
          </div>
        )}
        <ThumbnailImage type={type} image={image} />
        {progress !== undefined && (
          <div className="TileThumbnail__progressBar">
            <ProgressBar progress={progress} type="success" rounded={false} />
          </div>
        )}
      </div>
      <div className="TileThumbnail__infoBox">
        <Title className="TileThumbnail__title" level={title.level}>
          {title.text}
        </Title>
        <div className="TileThumbnail__secondaryContent">
          {subtitle && <div className="TileThumbnail__subtitle">{subtitle}</div>}
          {timeStamp && (
            <div className="TileThumbnail__timeStamp">
              <SvgIcon iconName="clock" className="TileThumbnail__timeStampIcon" />
              <span>{timeStamp}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
