import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Command } from "react-mvvm";
import { CommandButton } from "../../../../../shared/components/button/Button";
import { SvgIcon } from "../../../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../../../shared/contexts/StaticTranslationContext";
import { AddBookmarkDialog } from "../../bookmarkDialog/addBookmarkDialog/AddBookmarkDialog";
import { AddBookmarkDialogViewModel } from "../../bookmarkDialog/addBookmarkDialog/AddBookmarkDialogViewModel";
import { RemoveBookmarkDialog } from "../../bookmarkDialog/removeBookmarkDialog/RemoveBookmarkDialog";
import { RemoveBookmarkDialogViewModel } from "../../bookmarkDialog/removeBookmarkDialog/RemoveBookmarkDialogViewModel";

export interface BookmarkButtonProps {
  className?: string;
  showBookmarkModal: Command;
  isBookmarked: boolean;
  removeBookmarkDialogViewModel: RemoveBookmarkDialogViewModel;
  addBookmarkDialogViewModel: AddBookmarkDialogViewModel;
}

export const BookmarkButton = observer((model: BookmarkButtonProps) => {
  const { className, showBookmarkModal, isBookmarked, removeBookmarkDialogViewModel, addBookmarkDialogViewModel } =
    model;
  const translations = useTranslations();
  const bookmarkRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <div data-testid="BookmarkButton" className={classNames("BookmarkButton", className)}>
        <CommandButton
          ref={bookmarkRef}
          command={showBookmarkModal}
          type="clear"
          size="medium"
          className={classNames("BookmarkButton__button", { BookmarkButton__bookmarked: isBookmarked })}
        >
          <span className="BookmarkButton__text">
            {model.isBookmarked ? translations.userBookmarkAdded : translations.addUserBookmark}
          </span>
          <SvgIcon className="BookmarkButton__icon" iconName="bookmark-outlined" />
        </CommandButton>
      </div>

      <RemoveBookmarkDialog
        getReferenceElementToAttach={() => bookmarkRef.current}
        modal={removeBookmarkDialogViewModel}
      />
      <AddBookmarkDialog getReferenceElementToAttach={() => bookmarkRef.current} modal={addBookmarkDialogViewModel} />
    </>
  );
});
