import React from "react";
import { RettsdataPortableTextDto } from "../../../types/rettsdatas/dto/RettsdataPortableTextDto";

interface RettsdataPortableTextProps {
  content: RettsdataPortableTextDto;
}

export const RettsdataPortableText: React.FC<RettsdataPortableTextProps> = props => {
  const { content } = props;

  const formattedContentValue = React.useMemo(() => {
    return content.value.replace(/<a/g, "<span").replace(/<\/a>/g, "</span>");
  }, [content]);

  // eslint-disable-next-line react/no-danger
  return <div className="RettsdataPortableText" dangerouslySetInnerHTML={{ __html: formattedContentValue }} />;
};
