import { observer } from "mobx-react";
import * as React from "react";
import { Button, CommandButton } from "../../../shared/components/button/Button";
import { PortableTextContent } from "../../../shared/components/portableTextContent/PortableTextContent";
import { Title } from "../../../shared/components/title/Title";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { TestPageViewModel } from "../TestPageViewModel";
import { TestTaskView } from "../shared/testTask/TestTaskView";
import { TestTaskList } from "../shared/testTaskList/TestTaskList";
import { TestTaskCompletionInfo } from "./testTaskCompletionInfo/TestTaskCompletionInfo";

export const Test = observer(({ model }: { model: TestPageViewModel }) => {
  const { title, color, subtitle, submit, confirmExitPanel: confirmExitModal, test, submitCount } = model;
  const translations = useTranslations();

  if (!test) {
    return null;
  }

  const { shouldTaskTitlesBeDisplayed, content, tasks } = test;

  return (
    <div data-testid="Test" className="Test">
      <div className="Test__info">
        <span className="Test__subtitle">{subtitle}</span>
        <Title className="Test__title" level={1}>
          {title}
        </Title>
        {content && (
          <div className="Test__content">
            <PortableTextContent content={content} />
          </div>
        )}
      </div>
      {tasks && (
        <div className="Test__list">
          <TestTaskList
            testTasks={tasks}
            renderTask={(task, index) => {
              const {
                model: { isAnswered, id, title: taskTitle },
              } = task;
              return (
                <div className="Test__item">
                  <div className="Test__itemAnchor" id={id} aria-label={taskTitle} />
                  <TestTaskList.Item orderNumber={index + 1} color={color}>
                    <TestTaskView testTask={task} shouldTaskTitlesBeDisplayed={shouldTaskTitlesBeDisplayed} />
                    <TestTaskCompletionInfo isAnswered={isAnswered} submitCount={submitCount} />
                  </TestTaskList.Item>
                </div>
              );
            }}
          />
        </div>
      )}
      <div className="Test__actionButtons">
        <CommandButton command={submit}>{translations.submitTest}</CommandButton>
        <Button variant="secondary" type="outline" onClick={() => confirmExitModal.show()}>
          {translations.cancel}
        </Button>
      </div>
    </div>
  );
});
