import * as React from "react";

export interface TabProps {
  children: React.ReactNode;
  eventKey: string;
  tabTitle: string;
  isVisible?: boolean;
  className?: string;
}

export const Tab: React.FC<TabProps> = ({ children, isVisible }) => {
  return <>{isVisible && children}</>;
};
