import * as React from "react";
import { SoundStep } from "./SoundStep";

export interface SoundStepProps {
  model: SoundStep;
}

export const SoundStepView: React.FC<SoundStepProps> = props => {
  const {
    model: { soundCloudUrl, id, startPositionSeconds, updateSoundStepProgress, endSoundStep },
  } = props;
  const scriptId = `soundcloud-${id}`;

  const soundCloudRef = React.useRef(null);
  const soundCloudTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (!soundCloudRef.current) {
      return;
    }

    const soundCloudWidget = window.SC.Widget(soundCloudRef.current);
    const widgetEvents = {
      READY: window.SC.Widget.Events.READY,
      PLAY_PROGRESS: window.SC.Widget.Events.PLAY_PROGRESS,
      FINISH: window.SC.Widget.Events.FINISH,
    };

    soundCloudWidget.bind(widgetEvents.READY, () => {
      soundCloudWidget.seekTo(startPositionSeconds);
    });

    soundCloudWidget.bind(widgetEvents.PLAY_PROGRESS, () => {
      soundCloudWidget.getPosition(duration => {
        soundCloudTimeoutRef.current = setTimeout(() => {
          updateSoundStepProgress(duration);
        }, 1000);
      });
    });

    soundCloudWidget.bind(widgetEvents.FINISH, () => {
      soundCloudWidget.getPosition((duration: number) => {
        endSoundStep(duration);
      });
    });

    return () => {
      if (soundCloudTimeoutRef.current) {
        clearTimeout(soundCloudTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="SoundStep">
      <iframe
        ref={soundCloudRef}
        width="100%"
        allow="autoplay"
        src={soundCloudUrl}
        title={scriptId}
        id={scriptId}
        className="SoundStep__soundCloudWidget"
      />
    </div>
  );
};
