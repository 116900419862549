import { observer } from "mobx-react";
import * as React from "react";
import { animated, config, useSpring } from "react-spring";
import { Button } from "../../shared/components/button/Button";
import { List } from "../../shared/components/list/List";
import { Modal } from "../../shared/components/modal/Modal";
import { Switch } from "../../shared/components/switch/Switch";
import { Title } from "../../shared/components/title/Title";
import { useMotif } from "../../shared/contexts/MotifContext";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { useDelayedDisplay } from "../../shared/hooks/useDelayedDisplay";
import { AnalysisCookieName, CookiesConsentViewModel, NecessaryCookieName } from "./CookiesConsentViewModel";

export interface CookiesConsentProps {
  model: CookiesConsentViewModel;
}

export const CookiesConsent: React.FC<CookiesConsentProps> = observer(({ model }) => {
  const {
    consentText,
    privacyPolicyArticle,
    acceptCookies,
    toggleCookie,
    cookiesDetailsModal,
    shouldCookiesConsentBeOpen,
    selectedCookies,
  } = model;

  const translations = useTranslations();
  const [isDisplayed, hide] = useDelayedDisplay(shouldCookiesConsentBeOpen);
  const cookiesConsentStyles = useSpring({
    from: { transform: `translateY(100%)` },
    transform: shouldCookiesConsentBeOpen ? `translateY(0%)` : `translateY(100%)`,
    onResolve: hide,
    config: config.stiff,
  });

  const cookiesConsentTitleId = "CookiesConsent__title";
  const cookiesConsentDescriptionId = "CookiesConsent__message";
  const motif = useMotif();

  const cookies = [
    {
      id: NecessaryCookieName,
      cookieName: NecessaryCookieName,
      title: translations.necessaryCookiesTitle,
      description: translations.necessaryCookiesDescription,
      mandatory: true,
    },
    {
      id: AnalysisCookieName,
      cookieName: AnalysisCookieName,
      title: translations.analysisCookiesTitle,
      description: translations.analysisCookiesDescription,
      mandatory: false,
    },
  ];

  const acceptAllCookies = () => {
    acceptCookies(cookies.map(cookie => cookie.cookieName));
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="CookiesConsent">
      <animated.div
        className="CookiesConsent__container"
        role="alertdialog"
        aria-labelledby={cookiesConsentTitleId}
        aria-describedby={cookiesConsentDescriptionId}
        style={cookiesConsentStyles}
      >
        <div className="CookiesConsent__inner">
          <p id={cookiesConsentTitleId} className="visuallyHidden">
            {translations.cookieNotice}
          </p>
          <p id={cookiesConsentDescriptionId} className="CookiesConsent__message">
            {consentText}
            &nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              href={
                motif === "riktig"
                  ? "https://www.gyldendal.no/om-gyldendal/personvern/personvernerklaeringer/personverndokument-riktig/"
                  : "https://www.gyldendal.no/om-gyldendal/personvern/personvernerklaeringer/personverndokument-bok-nett/"
              }
            >
              Les mer
            </a>
          </p>
          <div className="CookiesConsent__buttons">
            <Button
              onClick={cookiesDetailsModal.show}
              type="outline"
              variant="secondary"
              className="CookiesConsent__button"
            >
              {translations.editCookieSettings}
            </Button>
            <Button onClick={acceptAllCookies} className="CookiesConsent__button">
              {translations.itIsOkay}
            </Button>
          </div>
        </div>
      </animated.div>
      <Modal onClose={cookiesDetailsModal.close} isOpen={cookiesDetailsModal.isOpen} size="small">
        <div className="CookiesConsent__modal">
          <Title level={1} className="CookiesConsent__title">
            {translations.cookiesConsentSettings}
          </Title>
          <p className="CookiesConsent__description">{translations.cookiesDescription}</p>
          {privacyPolicyArticle && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a
                type="clear"
                className={"CookiesConsent__policyLink"}
                target="_blank"
                rel="noreferrer"
                href="https://www.gyldendal.no/om-gyldendal/personvern/personverndokumenter/personverndokument-riktig/"
              >
                Gyldendals bruk av informasjonskapsler
              </a>
            </div>
          )}
          <List
            className="CookiesConsent__typesList"
            items={cookies}
            renderItem={cookie => {
              const cookieTitleId = `Title__${cookie.id}`;
              const cookieDescriptionId = `Description__${cookie.id}`;

              return (
                <div className="CookiesConsent__cookiesType">
                  <div className="CookiesConsent__cookiesTypeHeading">
                    <Title level={2} id={cookieTitleId} className="CookiesConsent__cookiesTypeTitle">
                      {cookie.title}
                    </Title>
                    <div className="CookiesConsent__toggleWrapper">
                      <Switch
                        disabled={cookie.mandatory}
                        checked={selectedCookies.includes(cookie.cookieName)}
                        onChange={value => toggleCookie(cookie.cookieName, value)}
                        aria-labelledby={cookieTitleId}
                        aria-describedby={cookieDescriptionId}
                      />
                    </div>
                  </div>
                  <p id={cookieDescriptionId} className="CookiesConsent__cookiesTypeDescription">
                    {cookie.description}
                  </p>
                </div>
              );
            }}
          />
          <Button
            onClick={() => {
              acceptCookies(selectedCookies);
              cookiesDetailsModal.close();
            }}
            className="CookiesConsent__submitButton"
          >
            {translations.submitUpdatedCookies}
          </Button>
        </div>
      </Modal>
    </div>
  );
});
