import { computed, observable } from "mobx";
import { TranslationsDto } from "../../../../types/translations/dto/TranslationsDto";
import { Status } from "../../../Status";
import { ThemeStructure } from "../../../contentStructure/ThemeStructure";
import { ThemeProgress } from "../../../progressStructure/ThemeProgress";
import { IProgressStore } from "../../../stores/ProgressStore";

const _ALL_THEME_STATUSES = ["notStarted", "inProgress", "finished", "underDevelopment"] as const;
type ThemeStatusTuple = typeof _ALL_THEME_STATUSES;
export type ThemeStatus = ThemeStatusTuple[number];
export const ALL_THEME_STATUSES = _ALL_THEME_STATUSES as unknown as ThemeStatus[];

export type CardTag = { id: string; translation: keyof TranslationsDto; status: Status };
export abstract class CardThemeBasicViewModel {
  @observable id: string;

  @observable themeProgress: ThemeProgress;

  @computed get actionableStepsCount() {
    return this.themeProgress.actionableStepsCount;
  }

  @computed get completedStepsCount() {
    return this.themeProgress.completedStepsCount;
  }

  @computed get status(): ThemeStatus {
    if (this.themeStructure.isUnderDevelopment) {
      return "underDevelopment";
    }

    switch (this.progress) {
      case 1:
        return "finished";
      case 0:
        return "notStarted";
      default:
        return "inProgress";
    }
  }

  @computed get progress() {
    return this.themeProgress.progress;
  }

  @computed get firstUnfinishedStep() {
    return this.themeProgress.continueStep;
  }

  @computed get tags() {
    const _tags: CardTag[] = [];

    if (this.themeStructure.isUnderDevelopment) {
      _tags.push({ id: "comingSoon", status: "error", translation: "comingSoon" });
    }

    if (this.themeStructure.hasStepsWithAccess && !this.themeStructure.courseStructure.hasFullAccess) {
      _tags.push({ id: "all", status: "success", translation: "gratisContent" });
    }

    if (_tags.length === 0) {
      return undefined;
    }

    return _tags;
  }

  constructor(public themeStructure: ThemeStructure, protected progressStore: IProgressStore) {
    this.id = themeStructure.id;
    this.themeProgress = new ThemeProgress(this.progressStore, this.themeStructure);
  }
}
