import { observer } from "mobx-react";
import React from "react";
import { Popup } from "../../../shared/components/popup/Popup";
import { SidePanel } from "../../../shared/components/sidePanel/SidePanel";
import { AuthorDetails } from "../authorDetails/AuthorDetails";
import { AuthorInfo } from "../authorInfo/AuthorInfo";

interface AuthorDetailsSidePanelProps {
  popup: Popup;
  authorInfo?: AuthorInfo;
}

export const AuthorDetailsSidePanel: React.FC<AuthorDetailsSidePanelProps> = observer(props => {
  const { popup, authorInfo } = props;

  return (
    <SidePanel
      isOpen={popup.isOpen}
      onClose={popup.close}
      variant="fullWidth"
      type="notRounded"
      className="AuthorDetailsSidePanel"
    >
      {authorInfo && <AuthorDetails {...authorInfo.author} details={authorInfo.authorDetails} />}
    </SidePanel>
  );
});
