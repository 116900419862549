import axios from "axios";
import * as React from "react";
import { TranslationsDto } from "../../types/translations/dto/TranslationsDto";
import { staticTranslationContext } from "./StaticTranslationContext";

export const TranslationProvider = ({ children, lang }: { children: React.ReactNode; lang: string }) => {
  const [translations, setTranslations] = React.useState<TranslationsDto>();

  const getTranslationData = async () => {
    const getLessonsResponse = await axios.get<TranslationsDto>("/api/GetTranslations");

    if (getLessonsResponse.data) {
      setTranslations(getLessonsResponse.data);
    }
  };

  React.useEffect(() => {
    getTranslationData();
  }, [lang]);

  if (!translations) {
    return null;
  }

  const { Provider } = staticTranslationContext;
  return <Provider value={{ translations }}>{children}</Provider>;
};
