import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { PreviousExamsViewModel } from "./PreviousExamsViewModel";

export const addPreviousExamsRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const previousExamsRouting = courseRoute.addRoute<PreviousExamsViewModel>(
    RouteConfiguration.PreviousExamsPage,
    PreviousExamsViewModel,
    coursePage => {
      const previousExamsViewModel = new PreviousExamsViewModel(
        coursePage.languageService,
        coursePage.gtm,
        coursePage.errorService,
        coursePage.courseStructure,
        coursePage.navigation,
        coursePage.progressStore,
        coursePage.backendApi
      );

      return coursePage.showChildPage(previousExamsViewModel);
    }
  );

  return previousExamsRouting;
};
