// eslint-disable-next-line @typescript-eslint/ban-types
export const asProxy = <TAsserted extends TActual, TActual extends object = object>(t: Partial<TAsserted>) => {
  const proxy = new Proxy(t, {
    get(obj, prop) {
      if (!(prop in obj)) {
        throw new Error(`Trying to access non-existent property "${String(prop)}" on object ${JSON.stringify(obj)}`);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (obj as any)[prop];
    },
  });
  return proxy as TAsserted;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const asObjectWithDefaultKeyAsValue = <TExpected extends TActual, TActual extends object = object>(
  t: Partial<TExpected>
) => {
  const proxy = new Proxy(t, {
    get(obj, prop) {
      if (!(prop in obj)) {
        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
        (obj as any)[prop] = prop;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (obj as any)[prop];
    },
  });
  return proxy as TExpected;
};

export const getVimeoId = (vimeoUrl: string) => {
  const videoMatch = vimeoUrl.match(/vimeo.*(?:\/|clip_id=)([0-9a-z]*)/i);

  if (!videoMatch) {
    return null;
  }

  return videoMatch[1];
};

export const getFormattedTime = (seconds: number): string => {
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);

  return `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
};

export const smoothScrollToSection = (id: string) => {
  const section = document.getElementById(id);
  if (section) {
    setTimeout(
      () =>
        section.scrollIntoView({
          behavior: "smooth",
        }),
      0
    );
  }
};

export const loadImages = async (urls: Array<string>) => {
  if (urls.length === 0) {
    return Promise.resolve([]);
  }

  const promises = urls.map(
    url =>
      new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = () => reject();
        image.crossOrigin = "Anonymous";
        image.src = url;
      })
  );

  const aggregatedPromise = Promise.all(promises);
  const images = await aggregatedPromise;

  return images;
};

export const delay = (ms: number) => {
  return new Promise(res => {
    setTimeout(res, ms);
  });
};

export const wrapPromise = <T>(promise: Promise<T>) => {
  let status: "pending" | "success" | "error" = "pending";
  let result: T;
  const suspender = promise.then(
    r => {
      status = "success";
      result = r;
    },
    e => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }

      throw new Error("Invalid status");
    },
  };
};
