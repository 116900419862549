import { computed, reaction } from "mobx";
import { ActiveCourseParams } from "../../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../../shared/stores/ProgressStore";
import { isDefined } from "../../../../../shared/typeUtils";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { Step } from "../Step";
import { TaskGroupStepProperties } from "./CreateTaskGroupStepProperties";
import { TaskGroupFactory, TaskGroupItem } from "./TaskGroupFactory";

export class TaskGroupStep extends Step {
  readonly _type = "TaskGroupTask";

  readonly taskGroupItems: TaskGroupItem[];

  readonly content?: PortableTextDto;

  readonly shouldTaskTitlesBeDisplayed: boolean;

  readonly orderIndicatorColor: string;

  @computed private get tasks() {
    return this.taskGroupItems.map(taskGroupItem => taskGroupItem.task);
  }

  private get actionableTasks() {
    return this.tasks
      .map(task => {
        if (task._type === "TextTask") {
          return;
        }
        return task;
      })
      .filter(isDefined);
  }

  constructor(
    step: TaskGroupStepProperties,
    progressStore: IProgressStore,
    bookmarkStore: IBookmarkStore,
    public activeCourseParams: ActiveCourseParams
  ) {
    super(step, progressStore, bookmarkStore);

    const taskFactory = new TaskGroupFactory(bookmarkStore, activeCourseParams);

    this.taskGroupItems = step.tasks.map(task => taskFactory.createTask(task));
    this.content = step.content;
    this.shouldTaskTitlesBeDisplayed = step.shouldTaskTitlesBeDisplayed;
    this.orderIndicatorColor = step.lessonColor;

    reaction(
      () => this.actionableTasks.map(task => task && task.model.answersState),
      tasksStatus => {
        if (tasksStatus.every(status => status === "correct")) {
          this.markStepAsCompleted();
        }
      }
    );

    reaction(
      () => this.isCompleted,
      async isCompleted => {
        if (isCompleted) {
          await Promise.all(this.actionableTasks.map(task => task && task.model.showCorrectAnswer()));
          return;
        }

        await Promise.all(this.actionableTasks.map(task => task.model.reset()));
      },
      { fireImmediately: true }
    );
  }
}
