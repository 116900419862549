import { StepThumbnailDto } from "../../../../types/shared/dto/StepThumbnailDto";
import { IVideoService } from "../../../api/BackendApi";
import { IProgressStore } from "../../../stores/ProgressStore";
import { assertUnreachable } from "../../../typeUtils";
import { BookmarkToggleViewModel } from "../shared/BookmarkToggleViewModel";
import { StepThumbnailType } from "./StepThumbnailType";
import { ITaskThumbnail, TaskThumbnailViewModel } from "./TaskThumbnailViewModel";
import { TextBlockThumbnailViewModel, ITextBlockThumbnail } from "./TextBlockThumbnailViewModel";
import { IVideoThumbnail, VideoThumbnailViewModel } from "./VideoThumbnailViewModel";

export type IStepThumbnail = IVideoThumbnail | ITextBlockThumbnail | ITaskThumbnail;

export const createStepThumbnailViewModel = (
  stepThumbnail: StepThumbnailDto,
  progressStore: IProgressStore,
  videoService: IVideoService,
  bookmarkToggleViewModel?: BookmarkToggleViewModel,
  isHighlighted?: boolean
): IStepThumbnail => {
  const type: StepThumbnailType = stepThumbnail.type;

  switch (type) {
    case "Video":
      return new VideoThumbnailViewModel(
        stepThumbnail,
        progressStore,
        videoService,
        bookmarkToggleViewModel,
        undefined,
        isHighlighted
      );
    case "TextBlock":
      return new TextBlockThumbnailViewModel(stepThumbnail, progressStore, bookmarkToggleViewModel, isHighlighted);
    case "TextTask":
    case "ChoiceTask":
    case "ChoiceListTask":
    case "TaskGroup":
    case "MaskTask":
    case "InputTask":
    case "GapTask":
    case "MatchTask":
    case "Sound":
      return new TaskThumbnailViewModel(stepThumbnail, progressStore, bookmarkToggleViewModel, isHighlighted);
    default:
      return assertUnreachable(type);
  }
};
