import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ProductParamsContextProvider } from "../../pages/product/ProductParamsContext";
import { ProductParams } from "../../pages/product/addProductRouting";
import { UserStore } from "../stores/UserStore";
import { MotifProvider } from "./MotifContext";
import { TranslationProvider } from "./TranslationProvider";
import { UserProvider } from "./UserProvider";
import { NotificationProvider } from "./notification/NotificationContext";

interface AppProviderProps {
  children: React.ReactNode;
  lang: string;
  productParams?: ProductParams;
  userStore?: UserStore;
}

export const AppProvider: React.FC<AppProviderProps> = props => {
  const { children, lang, productParams, userStore } = props;

  return (
    <HelmetProvider>
      <MotifProvider>
        <UserProvider userStore={userStore}>
          <TranslationProvider lang={lang}>
            <NotificationProvider>
              <ProductParamsContextProvider params={productParams}>{children}</ProductParamsContextProvider>
            </NotificationProvider>
          </TranslationProvider>
        </UserProvider>
      </MotifProvider>
    </HelmetProvider>
  );
};
