import { action, computed, observable } from "mobx";
import { BackendApi } from "../../shared/api/BackendApi";
import { ITibetClient } from "../../shared/api/TibetClient";
import { CourseStructure } from "../../shared/contentStructure/CourseStructure";
import { Url } from "../../shared/models/Url";
import { IErrorService } from "../../shared/services/ErrorService";
import { IGtmService } from "../../shared/services/GtmService";
import { ILanguageService } from "../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../shared/services/NavigationService";
import { BookmarkStore } from "../../shared/stores/BookmarkStore";
import { Product } from "../../shared/stores/Product";
import { IProgressStore, ProgressStore } from "../../shared/stores/ProgressStore";
import { UserStore } from "../../shared/stores/UserStore";
import { ConfigDto } from "../../types/config/dto/ConfigDto";
import { BasePage } from "../BasePage";
import { DashboardViewModel } from "../dashboard/DashboardViewModel";
import { SiteSearchViewModel } from "../search/siteSearch/SiteSearchViewModel";
import { CourseNavigator } from "../shared/courseNavigator/CourseNavigator";
import { PaymentSidePanelViewModel } from "../shared/paymentDialog/PaymentSidePanelViewModel";

export class CourseViewModel extends BasePage {
  @observable public bookmarkStore: BookmarkStore;

  @observable public progressStore: IProgressStore;

  @observable public courseNavigator: CourseNavigator;

  @observable public paymentSidePanelViewModel: PaymentSidePanelViewModel;

  @computed get courseSlug() {
    return this.courseStructure.slug;
  }

  constructor(
    public config: ConfigDto,
    public languageService: ILanguageService,
    public product: Product,
    public userStore: UserStore,
    public tibetClient: ITibetClient,
    public navigation: ILowLevelNavigationService,
    public backendApi: BackendApi,
    public gtm: IGtmService,
    public errorService: IErrorService,
    public courseStructure: CourseStructure
  ) {
    super(languageService, errorService);
    this.bookmarkStore = new BookmarkStore(backendApi, userStore, this.courseStructure);
    this.progressStore = new ProgressStore(backendApi, userStore, this.courseStructure);
    this.courseNavigator = new CourseNavigator(this.progressStore.courseProgress);
    this.paymentSidePanelViewModel = new PaymentSidePanelViewModel(
      tibetClient,
      this.courseStructure,
      this.errorService,
      this.navigation
    );
  }

  protected async loadData() {
    await Promise.all([this.bookmarkStore.init(), this.progressStore.init()]);
    this.isLoading = false;
  }

  @action.bound
  goToCourse(courseSlug: string) {
    this.navigation.redirectTo(Url.toDashboardPage({ ...this.product.productParams, courseSlug }));
  }

  @action.bound
  activateSearchPage(searchPhrase: string) {
    this.showChildPage(
      new SiteSearchViewModel(
        this.courseStructure,
        this.languageService,
        this.errorService,
        this.progressStore,
        this.backendApi,
        this.backendApi,
        this.paymentSidePanelViewModel,
        this.bookmarkStore,
        this.userStore,
        this.gtm,
        { phrase: searchPhrase, filterNames: [] }
      )
    );
  }

  async onActivated() {
    if (this.userStore.isAuthenticated && this.navigation.isRoot(this.product.slug, this.courseSlug)) {
      this.showChildPage(
        new DashboardViewModel(
          this.courseStructure,
          this.userStore,
          this.backendApi,
          this.progressStore,
          this.languageService,
          this.tibetClient,
          this.errorService,
          this.paymentSidePanelViewModel,
          this.navigation,
          this.gtm,
          this.activateSearchPage,
          this.goToCourse
        )
      );
    }
  }
}
