import { BackendApi } from "../../../../shared/api/BackendApi";
import { Step } from "../../../../shared/api/StepService";
import { TaskFactory } from "../../../../shared/components/tasks/TaskFactory";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { IGtmService } from "../../../../shared/services/GtmService";
import { ILowLevelNavigationService } from "../../../../shared/services/NavigationService";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../shared/stores/ProgressStore";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { LessonTask } from "./LessonTask";
import { SoundStep } from "./soundStep/SoundStep";
import { TaskGroupStep } from "./taskGroupStep/TaskGroupStep";
import { TextBlockStep } from "./textBlockStep/TextBlockStep";
import { VideoStep } from "./videoStep/VideoStep";

export class StepFactory {
  private taskFactory = new TaskFactory();

  createStep(param: Step) {
    switch (param._type) {
      case "Task":
        return new LessonTask(
          param,
          this.activeCourseParams,
          this.taskFactory,
          param.task,
          this.progressStore,
          this.bookmarkStore
        );
      case "TaskGroup":
        return new TaskGroupStep(
          { ...param, lessonColor: this.lessonColor },
          this.progressStore,
          this.bookmarkStore,
          this.activeCourseParams
        );

      case "TextBlock":
        return new TextBlockStep(param, this.progressStore, this.bookmarkStore);
      case "Video":
        return new VideoStep(
          param,
          this.progressStore,
          this.backendApi,
          this.gtm,
          this.bookmarkStore,
          this.navigationService,
          { productSlug: this.activeCourseParams.productSlug }
        );
      case "Sound":
        return new SoundStep(param, this.progressStore, this.gtm, this.bookmarkStore);
      default:
        return assertUnreachable(param);
    }
  }

  constructor(
    private lessonColor: string,
    private progressStore: IProgressStore,
    private bookmarkStore: IBookmarkStore,
    private backendApi: BackendApi,
    private gtm: IGtmService,
    private activeCourseParams: ActiveCourseParams,
    private navigationService: ILowLevelNavigationService
  ) {}
}
