/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRoute } from "react-mvvm";
import { PaymentError } from "../../shared/services/errors/PaymentError";
import { assertUnreachable } from "../../shared/typeUtils";
import { App } from "../App";

class ErrorViewModel {}

export type ErrorParams = {
  errorType: "payment";
};

export const addErrorRoutes = (appRoute: IRoute<App, unknown, unknown>) => {
  const productRoute = appRoute.addRoute<any, ErrorParams>("/error/:errorType", ErrorViewModel, (_, errorParams) => {
    switch (errorParams.errorType) {
      case "payment": {
        throw new PaymentError(errorParams.errorType);
      }
      default:
        return assertUnreachable(errorParams.errorType);
    }
  });
  return productRoute;
};
