import { StepType, HighLevelStepType, stepTypeDtoToStepType } from "../../../stepTypeUtils";

export interface StepWithCompleted {
  type: StepType;
  isCompleted: boolean;
}

const getProgress = (type: HighLevelStepType, steps: StepWithCompleted[]) => {
  const filteredSteps = steps.filter(step => stepTypeDtoToStepType[step.type] === type);
  const total = filteredSteps.length;

  if (total > 0) {
    const completed = filteredSteps.filter(step => step.isCompleted).length;
    return completed / total;
  }

  return 0;
};

export const getProgressByType = (steps: StepWithCompleted[]) => {
  const stepsByTypeProgress: { [key in HighLevelStepType]: number } = {
    Video: getProgress("Video", steps),
    Task: getProgress("Task", steps),
    TextBlock: getProgress("TextBlock", steps),
    Sound: getProgress("Sound", steps),
  };

  return stepsByTypeProgress;
};
