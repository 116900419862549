import { createBrowserHistory, History, Location } from "history";
import * as React from "react";
import { IUrl, Url } from "./shared/models/Url";

export interface HistoryLocationState {
  goBackHref?: string;
  goBackText?: string;
}

export interface RouterContext {
  history: History<HistoryLocationState | undefined>;
  url: IUrl;
}

export interface LocationContext {
  location: Location<HistoryLocationState | undefined>;
}

const routerContext = React.createContext<RouterContext | undefined>(undefined);
const locationContext = React.createContext<LocationContext | undefined>(undefined);

export const useRouterContext = () => {
  const routerCtx = React.useContext(routerContext);

  if (!routerCtx) {
    throw new Error("Component beyond RouterContext!");
  }

  return routerCtx;
};

export const useHistory = (): History<HistoryLocationState | undefined> => {
  const routerCtx = useRouterContext();
  return routerCtx.history;
};

export const useUrl = (): IUrl => {
  const routerCtx = useRouterContext();
  return routerCtx.url;
};

interface RouteProviderProps {
  history: History<HistoryLocationState | undefined>;
  url: IUrl;
  children: React.ReactNode;
}

export const RouterProvider = ({ history, url, children }: RouteProviderProps) => {
  const { Provider } = routerContext;
  return <Provider value={{ history, url }}>{children}</Provider>;
};

export const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  const { Provider } = locationContext;
  const history = useHistory();

  const [location, setLocation] = React.useState(history.location);

  React.useEffect(() => {
    const historyListenerUnregister = history.listen(newLocation => {
      setLocation(newLocation);
    });
    return () => {
      historyListenerUnregister();
    };
  }, []);

  return <Provider value={{ location }}>{children}</Provider>;
};

export const useLocationContext = () => {
  const locationCtx = React.useContext(locationContext);

  if (!locationCtx) {
    throw new Error("Component beyond LocationContext!");
  }

  return locationCtx;
};

export const useLocation = () => {
  const locationCtx = useLocationContext();
  return locationCtx.location;
};

export const useRouter = () => {
  const history = createBrowserHistory<HistoryLocationState | undefined>();
  const url = Url;

  const routerProvider = ({ children }: { children: React.ReactNode }) => {
    return (
      <RouterProvider history={history} url={url}>
        <LocationProvider>{children}</LocationProvider>
      </RouterProvider>
    );
  };

  return { history, RouterProvider: routerProvider };
};
