/* eslint-disable @typescript-eslint/no-unused-vars */
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { ImageCropDto } from "../../../types/shared/dto/ImageCropDto";
import { ImageHotspotDto } from "../../../types/shared/dto/ImageHotspotDto";
import { Motif } from "../../contexts/MotifContext";

const builder = imageUrlBuilder({
  projectId: window.sanityProjectId,
  dataset: "production",
});

const urlFor = (source: SanityImageSource) => {
  return builder.image(source);
};

export const buildUrl = (
  url: string,
  hotspot: ImageHotspotDto,
  crop: ImageCropDto,
  width?: number,
  height?: number
): string => {
  if (crop && hotspot && width && height) {
    const { left, top, bottom, right } = crop;
    const cropLeft = Math.round(left * width);
    const cropTop = Math.round(top * height);
    const cropValues = {
      left: cropLeft,
      top: cropTop,
      width: Math.round(width - right * width - cropLeft),
      height: Math.round(height - bottom * height - cropTop),
    };

    return urlFor(url)
      .dpr(1)
      .fit("crop")
      .focalPoint(hotspot.x, hotspot.y)
      .rect(cropValues.left, cropValues.top, cropValues.width, cropValues.height)
      .url();
  }

  return urlFor(url).dpr(1).fit("crop").url();
};
