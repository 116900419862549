import flatMap from "lodash/flatMap";
import random from "lodash/random";
import values from "lodash/values";
import { autorun, computed, observable, onBecomeObserved } from "mobx";
import { Command } from "react-mvvm";
import { ITrainingGroundService } from "../../../../shared/api/BackendApi";
import { mapToTask } from "../../../../shared/api/StepService";
import { TaskFactory } from "../../../../shared/components/tasks/TaskFactory";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../shared/stores/BookmarkStore";
import { assertUnreachable } from "../../../../shared/typeUtils";
import { TrainingGroundInfoDto } from "../../../../types/courses/dto/TrainingGroundInfoDto";
import { TrainingGroundTheme } from "../themesSelector/ThemesSelector";
import { TrainingTask } from "./testTask/TrainingTask";

const maxRandomNumber = 100000;
const firstPageNumber = 1;

type Page = number;
export type LoadedTrainingSetState = { _type: "loaded"; trainingSet: TrainingSet };
type TrainingSetState = { _type: "loading" } | { _type: "empty" } | LoadedTrainingSetState;
type TrainingSets = { [page: Page]: TrainingSetState };

class TrainingSet {
  @observable currentTaskIndex = 0;

  @computed get currentTask() {
    return this.tasks[this.currentTaskIndex];
  }

  @computed get currentTaskTheme() {
    return this.currentTask.theme;
  }

  @computed get tasksCount() {
    return this.tasks.length;
  }

  @computed get isFirstTask() {
    return this.currentTaskIndex === 0;
  }

  @computed get isLastTask() {
    return this.currentTaskIndex === this.tasksCount - 1;
  }

  @computed get isFirstTaskInTrainingSet() {
    return this.trainingViewModel.isFirstTrainingSet && this.isFirstTask;
  }

  @computed get isLastTaskInTrainingSet() {
    return this.trainingViewModel.isNextTrainingSetLast && this.isLastTask;
  }

  @computed get visitedTasks() {
    return this.tasks.filter(task => task.visited);
  }

  gotoPreviousTask = new Command(
    () => {
      if (this.currentTaskIndex === 0) {
        this.trainingViewModel.gotoPreviousTrainingSet.execute();
        return;
      }

      this.currentTaskIndex -= 1;
    },
    () => !this.isFirstTaskInTrainingSet
  );

  gotoNextTask = new Command(
    () => {
      if (this.currentTaskIndex === this.tasksCount - 1) {
        this.trainingViewModel.gotoNextTrainingSet.execute();
        return;
      }

      this.currentTaskIndex += 1;
    },
    () => !this.isLastTaskInTrainingSet
  );

  constructor(private trainingViewModel: TrainingViewModel, private tasks: TrainingTask[]) {}
}

export class TrainingViewModel {
  readonly seed = random(maxRandomNumber);

  readonly taskFactory = new TaskFactory();

  @observable page = firstPageNumber;

  @observable private trainingSets: TrainingSets = { 1: { _type: "loading" } };

  @computed get currentTrainingSetState() {
    const current = this.trainingSets[this.page];

    if (current._type === "empty") {
      throw new Error(`Current training cannot be type - ${current._type}`);
    }

    return current;
  }

  @computed get nextTrainingSetState(): TrainingSetState | undefined {
    return this.trainingSets[this.page + 1];
  }

  @computed get isFirstTrainingSet() {
    return this.page === firstPageNumber;
  }

  @computed get isNextTrainingSetLast() {
    if (this.nextTrainingSetState === undefined) {
      return true;
    }

    return this.nextTrainingSetState._type === "empty";
  }

  @computed get trainingGroundTitle() {
    return this.trainingGroundInfo.title;
  }

  @computed get selectedThemesCount() {
    return this.themes.length;
  }

  getVisitedTasks() {
    const visitedTasks = flatMap(values(this.trainingSets), trainingSet => {
      if (trainingSet._type !== "loaded") {
        return [];
      }

      return trainingSet.trainingSet.visitedTasks;
    });

    return visitedTasks;
  }

  gotoPreviousTrainingSet = new Command(
    () => {
      this.page -= 1;
    },
    () => !this.isFirstTrainingSet
  );

  gotoNextTrainingSet = new Command(
    () => {
      this.page += 1;
    },
    () => !this.isNextTrainingSetLast
  );

  endTrainingWithConfirmation = new Command(
    async () => {
      await this.endTraining.execute(true);
    },
    () => this.endTraining.isEnabled
  );

  endTrainingWithoutConfirmation = new Command(
    async () => {
      await this.endTraining.execute(false);
    },
    () => this.endTraining.isEnabled
  );

  constructor(
    private themes: TrainingGroundTheme[],
    private courseStructure: CourseStructure,
    private trainingGroundService: ITrainingGroundService,
    private endTraining: Command<boolean>,
    private trainingGroundInfo: TrainingGroundInfoDto,
    private bookmarkStore: IBookmarkStore,
    private activeCourseParams: ActiveCourseParams
  ) {
    onBecomeObserved(this, "trainingSets", async () => {
      await this.loadTrainingSet(this.page);
    });

    autorun(async () => {
      await this.loadNextPage();
    });
  }

  private async loadNextPage() {
    if (this.currentTrainingSetState._type === "loading") {
      return;
    }

    // we will wait with loading the next page to reach the penultimate task of the current training set
    const penultimateTaskIndex = this.currentTrainingSetState.trainingSet.tasksCount - 2;

    if (this.currentTrainingSetState.trainingSet.currentTaskIndex < penultimateTaskIndex) {
      return;
    }

    const nextPage = this.page + 1;
    const nextTrainingSet = this.trainingSets[nextPage];

    if (nextTrainingSet !== undefined) {
      return;
    }

    await this.loadTrainingSet(nextPage);
  }

  private async loadTrainingSet(page: Page) {
    this.trainingSets[page] = { _type: "loading" };

    const { items } = await this.trainingGroundService.getTrainingGroundTasks({
      page,
      seed: this.seed,
      themeIds: this.themes.map(t => t.id),
      courseId: this.courseStructure.id,
      trainingGroundId: this.trainingGroundInfo.id,
    });

    if (items.every(themesWithTaskValue => themesWithTaskValue.task === null)) {
      this.trainingSets[page] = { _type: "empty" };
      return;
    }

    const tasks = items
      .filter(item => !!item.task)
      .map(item => {
        if (!item.task) {
          return assertUnreachable(item.task);
        }
        return new TrainingTask(
          this.activeCourseParams,
          this.taskFactory,
          mapToTask(item.task),
          item.isLast,
          item.theme,
          this.bookmarkStore
        );
      });

    this.trainingSets[page] = { _type: "loaded", trainingSet: new TrainingSet(this, tasks) };
  }
}
