import * as React from "react";
import { Command } from "react-mvvm";
import { useAppUser } from "../../../contexts/UserProvider";
import { assertUnreachable } from "../../../typeUtils";
import { BookmarkLayer } from "./bookmarkLayer/BookmarkLayer";
import { FreeInfoLayer } from "./freeInfoLayer/FreeInfoLayer";
import { LockedLayer } from "./lockedLayer/LockedLayer";
import { StatusLayer } from "./statusLayer/StatusLayer";

export type ThumbnailOverlayStates =
  | {
      overlayState: "bookmark";
      isBookmarked: boolean;
      bookmarkCommand: Command;
      isVideo: boolean;
    }
  | { overlayState: "locked"; onClick: () => void }
  | {
      overlayState: "finished";
      isBookmarked?: boolean;
      bookmarkCommand?: Command;
      isVideo: boolean;
    }
  | {
      overlayState: "freeNotFinished";
      isBookmarked?: boolean;
      bookmarkCommand?: Command;
      isVideo: boolean;
    };

interface ThumbnailOverlayProps {
  state: ThumbnailOverlayStates;
  variant?: "small" | "default";
}

export const ThumbnailOverlay: React.FC<ThumbnailOverlayProps> = ({ state, variant = "default" }) => {
  const user = useAppUser();
  const isAuthenticated = user._type === "loggedIn";

  switch (state.overlayState) {
    case "finished":
      return (
        <>
          <StatusLayer variant={variant} status="success" />
          {state.isBookmarked !== undefined && state.bookmarkCommand && isAuthenticated && (
            <BookmarkLayer
              isBookmarked={state.isBookmarked}
              bookmarkCommand={state.bookmarkCommand}
              isVideo={state.isVideo}
              variant={variant}
            />
          )}
        </>
      );
    case "locked":
      return <LockedLayer onClick={state.onClick} variant={variant} />;
    case "bookmark":
      return (
        <>
          {isAuthenticated && (
            <BookmarkLayer
              isBookmarked={state.isBookmarked}
              bookmarkCommand={state.bookmarkCommand}
              isVideo={state.isVideo}
              variant={variant}
            />
          )}
        </>
      );
    case "freeNotFinished":
      return (
        <>
          <FreeInfoLayer size={variant} />
          {state.isBookmarked !== undefined && state.bookmarkCommand && isAuthenticated && (
            <BookmarkLayer
              isBookmarked={state.isBookmarked}
              bookmarkCommand={state.bookmarkCommand}
              isVideo={state.isVideo}
              variant={variant}
            />
          )}
        </>
      );
    default:
      return assertUnreachable(state);
  }
};
