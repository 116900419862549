import React from "react";
import { TrainingGroundInfo } from "../../../../../pages/dashboard/components/dashboardSections/sections/trainingGroundSection/TrainingGroundSection";
import { useTranslations } from "../../../../contexts/StaticTranslationContext";
import { assertUnreachable } from "../../../../typeUtils";
import { HorizontalCard } from "../../../card/horizontalCard/HorizontalCard";
import { Link } from "../../../link/Link";
import { Level } from "../../../title/Title";
import { HighlightedThumbnail } from "../../highlightedThumbnail/HighlightedThumbnail";
import { VerticalThumbnail } from "../../verticalThumbnail/VerticalThumbnail";

interface TrainingGroundDomainThumbnailProps {
  model: TrainingGroundInfo;
  href: string;
  thumbnailTitleLevel?: Level;
  thumbnailType?: "highlighted" | "vertical" | "horizontalCard";
}

export const TrainingGroundDomainThumbnail = (props: TrainingGroundDomainThumbnailProps) => {
  const { model, href, thumbnailTitleLevel = 3, thumbnailType = "highlighted" } = props;
  const { title, description, image, hasAccess } = model;
  const translations = useTranslations();

  const bottomContent = hasAccess && (
    <Link to={href} type="button" className="TrainingGroundDomainThumbnail__link">
      {translations.startNow}
    </Link>
  );

  // TODO: AFTER REMOVING AREA WE CAN CHANGE THIS TO THE course.areaColor
  const imageBackgroundColor = "#212121";
  const backgroundColor = "#f2edea";

  if (thumbnailType === "highlighted") {
    return (
      <HighlightedThumbnail
        title={{ text: title, level: thumbnailTitleLevel }}
        description={description}
        type="TrainingGround"
        image={image}
        href={href}
        bottomContent={bottomContent}
        imageBackgroundColor={imageBackgroundColor}
        backgroundColor={backgroundColor}
      />
    );
  }

  if (thumbnailType === "vertical") {
    return (
      <VerticalThumbnail
        title={{ text: title, level: thumbnailTitleLevel }}
        description={description}
        type="TrainingGround"
        image={image}
        href={href}
        bottomContent={bottomContent}
        imageBackgroundColor={imageBackgroundColor}
        backgroundColor={backgroundColor}
      />
    );
  }

  if (thumbnailType === "horizontalCard") {
    return (
      <HorizontalCard
        title={{ text: title, level: thumbnailTitleLevel }}
        category={translations.trainingGround}
        type="TrainingGround"
        image={image}
        imageBackgroundColor={imageBackgroundColor}
        href={href}
      />
    );
  }

  return assertUnreachable(thumbnailType);
};
