import { computed } from "mobx";
import { IVideoService } from "../../api/BackendApi";
import { ThemeStructure } from "../../contentStructure/ThemeStructure";
import { IProgressStore } from "../../stores/ProgressStore";
import { CardThemeBasicViewModel } from "../card/cardThemeBasic/CardThemeBasicViewModel";
import { createStepThumbnailViewModel } from "../thumbnails/stepThumbnail/createStepThumbnailViewModel";

export class ThemeItem extends CardThemeBasicViewModel {
  @computed get continueStepThumbnail() {
    if (this.status !== "inProgress") {
      return;
    }

    if (!this.firstUnfinishedStep) {
      return;
    }

    return createStepThumbnailViewModel(this.firstUnfinishedStep.thumbnail, this.progressStore, this.videoService);
  }

  constructor(
    public themeStructure: ThemeStructure,
    progressStore: IProgressStore,
    private videoService: IVideoService
  ) {
    super(themeStructure, progressStore);
    this.id = themeStructure.id;
  }
}
