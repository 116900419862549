import { action, observable } from "mobx";

export const Bokmal = "nb";
export const Nynorsk = "nn";

export interface ILanguageService {
  lang: string;
  changeLanguage(): void;
}

export class LanguageService implements ILanguageService {
  @observable lang: string;

  private readonly localStorageLanguageKey = "lang";

  constructor() {
    const storageLang = localStorage.getItem(this.localStorageLanguageKey);
    this.lang = storageLang || Bokmal;
  }

  @action.bound
  changeLanguage(): void {
    if (this.lang === Nynorsk) {
      this.lang = Bokmal;
    } else {
      this.lang = Nynorsk;
    }
    localStorage.setItem(this.localStorageLanguageKey, this.lang);
  }
}
