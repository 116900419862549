import React from "react";
import { PortableTextContent } from "../../../../../../shared/components/portableTextContent/PortableTextContent";
import { HintRelatedStep } from "../../../../../../shared/components/taskHint/hintRelatedStep/HintRelatedStep";
import { HintRelatedStepViewModel } from "../../../../../../shared/components/taskHint/hintRelatedStep/HintRelatedStepViewModel";
import { PortableTextDto } from "../../../../../../types/shared/dto/PortableTextDto";

interface HintPanelContentProps {
  content?: PortableTextDto;
  relatedStep?: HintRelatedStepViewModel;
}

export const HintPanelContent: React.FC<HintPanelContentProps> = props => {
  const savedProps = React.useRef<HintPanelContentProps>(props);
  const { content, relatedStep } = savedProps.current;

  if (content === null && relatedStep === null) {
    return null;
  }

  return (
    <div className="HintPanelContent">
      {content && <PortableTextContent content={content} className="HintPanelContent__content" />}
      {relatedStep && (
        <div className="HintPanelContent__relatedStep">
          <HintRelatedStep model={relatedStep} />
        </div>
      )}
    </div>
  );
};
