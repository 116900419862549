import { Command, IRoute } from "react-mvvm";
import { ProductParams } from "../product/addProductRouting";
import { ArticleParams } from "./addArticlesRoutes";
import { ArticleViewModel } from "./article/ArticleViewModel";
import { ArticleLoginSectionViewModel } from "./article/articleLoginSection/ArticleLoginSectionViewModel";
import { ArticleListViewModel } from "./articleList/ArticleListViewModel";

export const addProductArticleRouting = (
  productArticleListRoute: IRoute<ArticleListViewModel, unknown, ProductParams>
) => {
  const productArticleRouting = productArticleListRoute.addRoute<ArticleViewModel, ArticleParams>(
    `/:articleSlug`,
    ArticleViewModel,
    (articleListViewModel, params) => {
      const {
        handleLogin,
        languageService,
        errorService,
        backendApi,
        navigationService,
        gtm,
        userStore: { isAuthenticated },
        product: { defaultCourseInfo },
      } = articleListViewModel;

      const paramToRidirectFrom = "nasjonal-deleksamen-i-matematikk-2021-og-2022-tidligere-eksamensoppgaver";

      if (params.articleSlug === paramToRidirectFrom) {
        window.location.replace("/glu/algebraisk-/eksamener");
      }

      /* This code is responsible for redirecting from old article to new, simillar article.
       It has been request by Kine and it should be first and last time we are doing this
       because it's very bad practice to have this kind of code in the codebase. */
      const articlesSlugs = {
        oldSlug: "eksamensoppsummering-afb",
        newSlug: "gratis-webinar-oppsummering-av-pensum-i-anatomi-fysiologi-og-biokjemi",
      };

      if (params.articleSlug === articlesSlugs.oldSlug) {
        window.location.assign(`/sykepleie/artikler/${articlesSlugs.newSlug}`);
      }

      const articleHandleLogin = new Command(async () => {
        await handleLogin(defaultCourseInfo?.slug);
      });

      const actionSection = !isAuthenticated ? new ArticleLoginSectionViewModel(articleHandleLogin) : undefined;

      const article = new ArticleViewModel(
        params.articleSlug,
        languageService,
        errorService,
        backendApi,
        navigationService,
        gtm,
        actionSection
      );

      return articleListViewModel.showChildPage(article);
    },
    page => ({ articleSlug: page.articleSlug })
  );

  return productArticleRouting;
};
