import { action, computed, observable } from "mobx";
import { OptionStatus } from "../shared/OptionStatus";

type BaseOptionType = {
  id: string;
};

export class OptionWithStatus<TOption extends BaseOptionType> {
  @observable id: string;

  @observable state: OptionStatus = "default";

  @computed get isDisabled() {
    const optionState = this.state;

    return optionState === "incorrect" || optionState === "correct" || optionState === "shouldBeChecked";
  }

  constructor(public option: TOption) {
    this.id = option.id;
  }

  @action.bound
  markAsCorrect() {
    this.state = "correct";
  }

  @action.bound
  markAsIncorrect() {
    this.state = "incorrect";
  }

  @action.bound
  markAsShouldBeChecked() {
    this.state = "shouldBeChecked";
  }

  @action.bound
  reset() {
    this.state = "default";
  }
}
