import React from "react";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { PortableTextContent } from "../../../portableTextContent/PortableTextContent";
import { ChoiceTestOptionStatus } from "../../shared/choiceTestOptionStatus/ChoiceTestOptionStatus";
import { Answers, MatchTaskValue, Questions } from "../MatchTaskViewModel";
import { MatchTaskList } from "../list/MatchTaskList";
import { MatchTaskTable } from "../table/MatchTaskTable";

export interface MatchTaskProps {
  onChange: (value: MatchTaskValue) => void;
  value: MatchTaskValue;
  questions: Questions;
  answers: Answers;
  content: PortableTextDto;
  isMultipleChoice: boolean;
}

export const MatchTask = (props: MatchTaskProps) => {
  const { value, onChange, questions, answers, content, isMultipleChoice } = props;

  return (
    <div className="MatchTask">
      <div className="MatchTask__content">
        <PortableTextContent content={content} />
      </div>
      <div className="tablet">
        <div className="MatchTask__tableWrapper">
          <MatchTaskTable
            questions={questions}
            answers={answers}
            isMultipleChoice={isMultipleChoice}
            renderAnswer={(answer, _) => (
              <ChoiceTestOptionStatus
                id={answer.id}
                content={
                  <div className="MatchTask__answerWrapper">
                    <PortableTextContent content={answer.content} />
                  </div>
                }
                status="default"
                type={isMultipleChoice ? "checkbox" : "radio"}
              />
            )}
            value={value}
            disabled={false}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="mobileOnly">
        <div className="MatchTask__listWrapper">
          <MatchTaskList
            questions={questions}
            answers={answers}
            isMultipleChoice={isMultipleChoice}
            renderAnswer={(answer, _) => (
              <ChoiceTestOptionStatus
                id={answer.id}
                content={<PortableTextContent content={answer.content} />}
                status="default"
                type={isMultipleChoice ? "checkbox" : "radio"}
              />
            )}
            value={value}
            disabled={false}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
