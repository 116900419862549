import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { App } from "../App";
import { ArticleViewModel } from "./article/ArticleViewModel";

export type ArticleParams = { articleSlug: string };

export const addArticlesRoutes = (route: IRoute<App, unknown, unknown>) => {
  const articlesRoutes = route.addRoute<ArticleViewModel, ArticleParams>(
    `${RouteConfiguration.Articles}/:articleSlug`,
    ArticleViewModel,
    (app, p) => {
      const article = new ArticleViewModel(
        p.articleSlug,
        app.languageService,
        app,
        app.backendApi,
        app.navigation,
        app.gtm
      );

      return app.showChildPage(article);
    },
    page => ({ articleSlug: page.articleSlug })
  );
  return articlesRoutes;
};
