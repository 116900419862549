import * as React from "react";
import { MotifImage } from "../../shared/components/image/Image";
import { HrefType, Link } from "../../shared/components/link/Link";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { assertUnreachable } from "../../shared/typeUtils";

import { useUrl } from "../../useRouter";
import { errorMessagesMap, getUserErrorMessage } from "./ErrorMessages";

interface ErrorViewProps {
  error: unknown;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ error }) => {
  const translations = useTranslations();
  const url = useUrl();
  const errorMessage = getUserErrorMessage(error);
  const errorMessageTranslation = translations[errorMessagesMap[errorMessage.type]];

  const getErrorPageData = () => {
    switch (errorMessage.type) {
      case "UNEXPECTED_ERROR":
        return {
          pageTitle: errorMessageTranslation,
          imageUrl: "iconsColor/error.svg",
          title: errorMessageTranslation,
        };
      case "FORBIDDEN":
        return {
          pageTitle: `${403} ${errorMessageTranslation}`,
          imageUrl: "iconsColor/error-403.svg",
          title: 403,
          description: errorMessageTranslation,
        };
      case "NOT_FOUND":
        return {
          pageTitle: `${404} ${errorMessageTranslation}`,
          imageUrl: "iconsColor/error.svg",
          title: 404,
          description: errorMessageTranslation,
        };
      case "SYSTEM_FAILURE":
        return {
          pageTitle: `${500} ${errorMessageTranslation}`,
          imageUrl: "iconsColor/error.svg",
          title: 500,
          description: errorMessageTranslation,
        };
      case "PAYMENT_FAILURE": {
        return {
          pageTitle: errorMessageTranslation,
          imageUrl: "iconsColor/error-payment.svg",
          title: errorMessageTranslation,
        };
      }
      default:
        return assertUnreachable(errorMessage.type);
    }
  };

  const pageData = getErrorPageData();

  return (
    <PageTitle noIndex={errorMessage.type === "NOT_FOUND"} title={pageData.pageTitle}>
      <article className="Error">
        <div className="Error__content">
          <div className="Error__imageWrapper">
            <MotifImage
              image={{ fileName: pageData.imageUrl as `${string}.${string}`, alt: errorMessageTranslation }}
            />
          </div>
          <div className="Error__info">
            <h1 className="Error__title">{pageData.title}</h1>
            {pageData.description && <p className="Error__description">{pageData.description}</p>}
            <Link to={url.toProductNestPage()} hrefType={HrefType.External} className="Error__button" type="button">
              {translations.goToProductOverview}
            </Link>
          </div>
        </div>
      </article>
    </PageTitle>
  );
};
