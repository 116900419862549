import classNames from "classnames";
import React from "react";
import { Button } from "../../../../shared/components/button/Button";

export type ButtonType = "hint" | "solution";

interface ScreenSizeAwareButtonProps {
  toggleValue: () => void;
  toggleMobileValue: () => void;
  displayText?: string;
  ariaExpanded?: boolean;
  ariaControls?: string;
  disabled?: boolean;
  className?: string;
}

export const ScreenSizeAwareButton: React.FC<ScreenSizeAwareButtonProps> = props => {
  const {
    toggleValue,
    toggleMobileValue,
    displayText,
    className,
    ariaExpanded,
    ariaControls,
    disabled = false,
  } = props;

  return (
    <div className={classNames("ScreenSizeAwareButton", className)}>
      <Button
        className="ScreenSizeAwareButton__button"
        type="outline"
        variant="secondary"
        onClick={toggleValue}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        disabled={disabled}
      >
        {displayText}
      </Button>
      <Button
        className={classNames("ScreenSizeAwareButton__button", "ScreenSizeAwareButton__button--mobile")}
        type="outline"
        variant="secondary"
        onClick={toggleMobileValue}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        disabled={disabled}
      >
        {displayText}
      </Button>
    </div>
  );
};
