import classNames from "classnames";
import React from "react";
import { animated, useSpring } from "react-spring";
import { Collapse } from "../../../../../shared/components/collapse/Collapse";
import { TaskHint } from "../../../../../shared/components/taskHint/TaskHint";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { useDelayedDisplay } from "../../../../../shared/hooks/useDelayedDisplay";

interface LessonTaskHintProps {
  id: string;
  hint: TaskHintViewModel;
  isHintOpen: boolean;
  onAnimationResolve?: () => void;
  className?: string;
}

export const LessonTaskHint: React.FC<LessonTaskHintProps> = props => {
  const { id, hint, isHintOpen, onAnimationResolve, className } = props;
  const [isDisplayed, hide] = useDelayedDisplay(isHintOpen);

  const taskHintPanelStyles = useSpring({
    from: { marginLeft: 100, opacity: 0 },
    marginLeft: isHintOpen ? 0 : 100,
    opacity: isHintOpen ? 1 : 0,
    onResolve: () => {
      hide();

      if (isHintOpen && onAnimationResolve) {
        onAnimationResolve();
      }
    },
    config: { precision: 10 },
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <Collapse id={id} isOpen={isHintOpen}>
      <animated.div style={taskHintPanelStyles} className={classNames("LessonTaskHint", className)}>
        <TaskHint hint={hint} />
      </animated.div>
    </Collapse>
  );
};
