import { computed, observable } from "mobx";
import { PaymentSidePanelViewModel } from "../../../pages/shared/paymentDialog/PaymentSidePanelViewModel";
import { Popup } from "../../../shared/components/popup/Popup";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { IUrl } from "../../../shared/models/Url";
import { Product } from "../../../shared/stores/Product";
import { ConfigDto } from "../../../types/config/dto/ConfigDto";
import { SourceLink, StaticLink } from "../HeaderViewModel";
import { HamburgerMenuLinks } from "../hamburgerMenu/HamburgerMenuLinks";

type HeaderContext = "productNest" | "product" | "course";

export class HeaderHamburgerViewModel {
  @observable public mobileMenu = new Popup();

  @observable public desktopMenu = new Popup();

  @observable mobileHamburgerMenuLinks = new HamburgerMenuLinks(this, this.url);

  @computed get currentContext(): HeaderContext {
    if (!this.activeProduct) {
      return "productNest";
    }

    if (!this.activeCourse) {
      return "product";
    }

    return "course";
  }

  @computed private get activeCourseParams() {
    if (!this.activeCourse) {
      return;
    }

    return this.activeCourse.urlParams;
  }

  @computed private get productAuthorsHero() {
    return this.activeProduct?.productAuthorsHero;
  }

  @computed get studiesType() {
    return this.activeProduct?.studiesType;
  }

  @computed get desktopHamburgerPrimaryLinks(): SourceLink[] {
    const links: SourceLink[] = [];

    if (!this.activeProduct) {
      return links;
    }

    links.push({
      source: "static",
      id: "products",
      href: this.url.toProductNestPage(),
      translation: "products",
    });

    if (this.activeProduct.shouldShowProductArticleList) {
      links.push({
        source: "static",
        id: "articles",
        href: this.url.toProductArticleListPage(this.activeProduct.productParams),
        translation: "articles",
      });
    }

    if (this.productAuthorsHero) {
      links.push({
        source: "dynamic",
        id: "meetTheAuthors",
        title: this.productAuthorsHero.title,
        href: this.url.toAuthorsForProductPage(this.activeProduct.productParams),
      });
    }

    return links;
  }

  @computed get desktopHamburgerSecondaryLinks(): SourceLink[] {
    const links: SourceLink[] = [];

    if (!this.config.hideAboutUsInMenu) {
      links.push({
        source: "dynamic",
        id: "aboutUs",
        title: "Om oss",
        href: this.url.toAboutUsPage(),
      });
    }

    return links;
  }

  constructor(
    private url: IUrl,
    public baseHeaderLinks: SourceLink[],
    public goToSearchPage: (searchPhrase: string) => void,
    public config: ConfigDto,
    public searchLink?: StaticLink,
    public activeProduct?: Product,
    public activeCourse?: CourseStructure,
    public paymentSidePanelViewModel?: PaymentSidePanelViewModel | undefined
  ) {}
}
