import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { BuyRedirectViewModel } from "./BuyRedirectViewModel";
import "./BuyRedirect.css";

interface BuyRedirectProps {
  model: BuyRedirectViewModel;
}

export const BuyRedirect: React.FC<BuyRedirectProps> = ({ model }) => {
  const queryParams = new URLSearchParams(location.search);

  const courseId = queryParams.get("courseId");
  const paymentOptionId = queryParams.get("paymentOptionId");
  const successUrl = queryParams.get("successUrl");
  const cancelUrl = queryParams.get("cancelUrl");
  const failUrl = queryParams.get("failUrl");

  if (courseId && paymentOptionId && successUrl && cancelUrl && failUrl) {
    model.setAndInitiateBuying(courseId, paymentOptionId, successUrl, cancelUrl, failUrl);
  }

  return (
    <div className="BuyRedirect">
      <ClipLoader color="var(--primaryBase)" />
      {model.isPurchasing ? <p>Tar deg til kjøpssiden</p> : <p>Vennligst vent</p>}
    </div>
  );
};
