//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum StepThumbnailTypeDto { 
	Video = "Video", 
	TextBlock = "TextBlock", 
	TaskGroup = "TaskGroup", 
	TextTask = "TextTask", 
	ChoiceListTask = "ChoiceListTask", 
	ChoiceTask = "ChoiceTask", 
	MaskTask = "MaskTask", 
	InputTask = "InputTask", 
	GapTask = "GapTask", 
	MatchTask = "MatchTask", 
	Sound = "Sound"
}
