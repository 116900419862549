import classNames from "classnames";
import React from "react";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { Link } from "../../link/Link";
import { SvgIcon } from "../../svgIcon/SvgIcon";
import { Level, Title } from "../../title/Title";
import { ThumbnailImage } from "../shared/thumbnailImage/ThumbnailImage";
import { ThumbnailTypes } from "../types/ThumbnailTypes";

export interface HorizontalThumbnailProps {
  title: { text: string; level: Level };
  type: ThumbnailTypes;
  subtitle?: string;
  timeStamp?: string;
  image?: ImageDto;
  overlay?: React.ReactNode;
  locked?: boolean;
  color?: string;
  href?: string;
}

export const HorizontalThumbnail: React.FC<HorizontalThumbnailProps> = props => {
  const { title, type, subtitle, timeStamp, image, overlay, locked, color, href } = props;

  const renderAdditionalInfo = () => {
    if (type === "Video" && timeStamp) {
      return (
        <>
          <SvgIcon iconName="clock" className="HorizontalThumbnail__timeStampIcon" />
          <span>{timeStamp}</span>
        </>
      );
    }

    if (subtitle) {
      return subtitle;
    }

    return null;
  };

  return (
    <div className={classNames("HorizontalThumbnail", { "HorizontalThumbnail--locked": locked })}>
      {href && !locked && (
        <Link to={href} type="clear" className="HorizontalThumbnail__linkOverlay">
          <span className="visuallyHidden">{title.text}</span>
        </Link>
      )}
      <div className="HorizontalThumbnail__imageWrapper" style={{ backgroundColor: color }}>
        {overlay}
        {href && type === "Video" && !locked && (
          <div className="HorizontalThumbnail__linkWrapper">
            <Link
              type="button"
              buttonType="circle"
              buttonVariant="tertiary"
              buttonSize="xlarge"
              className="HorizontalThumbnail__link"
              to={href}
              tabIndex={-1}
            >
              <span className="visuallyHidden">{title.text}</span>
              <SvgIcon iconName="play" />
            </Link>
          </div>
        )}
        <ThumbnailImage type={type} image={image} />
      </div>
      <div className="HorizontalThumbnail__info">
        <div className="HorizontalThumbnail__additionalInfo">{renderAdditionalInfo()}</div>
        <Title className="HorizontalThumbnail__title" level={title.level}>
          {title.text}
        </Title>
      </div>
    </div>
  );
};
