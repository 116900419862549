import last from "lodash/last";
import { computed } from "mobx";
import { ImageDto } from "../../../types/shared/dto/ImageDto";

interface CourseLevelProgress {
  courseProgress: {
    progressPercent: number;
  };
}

export interface Level {
  order: number;
  image: ImageDto;
}

export class CourseLevel {
  levels: Level[];

  constructor(private progress: CourseLevelProgress, private _levels: ImageDto[]) {
    this.levels = _levels.map((level, index) => ({ order: index, image: level }));
  }

  @computed get progressPercent() {
    return this.progress.courseProgress.progressPercent;
  }

  @computed get level() {
    if (this.levels.length === 0) {
      return;
    }

    if (this.progressPercent === 100) {
      return last(this.levels);
    }

    if (this.levelsCount === 0) {
      return this.levels[0];
    }

    return this.levels[this.currentIndex(this.levelsCount)];
  }

  @computed get previousLevel() {
    if (this.levelsCount === 1) {
      return;
    }

    const prevIndex = this.currentIndex(this.levelsCount) - 1;

    return prevIndex > 0 ? this.levels[prevIndex] : undefined;
  }

  @computed get levelsCount() {
    return this.levels.length - 1; // - 1 because of the one image for 100% progress
  }

  private currentIndex(levelsCount: number) {
    const treshold = 100 / levelsCount;
    return Math.floor(this.progressPercent / treshold);
  }
}
