import * as React from "react";
import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { SanityImage } from "../../../image/Image";
import { SketchfabViewer } from "../../../sketchfabViewer/SketchfabViewer";

interface ChoiceListTestViewerProps {
  image?: ImageDto;
  modelId?: string;
}

export const ChoiceListTestViewer: React.FC<ChoiceListTestViewerProps> = ({ image, modelId }) => {
  return (
    <div className="ChoiceListTestViewer">
      {image && <SanityImage {...image} />}
      {modelId && <SketchfabViewer modelId={modelId} className="ChoiceListTestViewer__model" />}
    </div>
  );
};
