import React from "react";
import { animated, useSpring } from "react-spring";
import { SanityImage } from "../../../../../shared/components/image/Image";
import { Level } from "../../../../shared/courseLevel/CourseLevel";

interface AnimatedLevelProps {
  newLevel: Level;
  oldLevel?: Level;
}

export const AnimatedLevel: React.FC<AnimatedLevelProps> = props => {
  const { newLevel, oldLevel } = props;

  const animationConfig = { mass: 1, tension: 250, friction: 150 };

  const oldLevelStyles = useSpring({
    delay: 1500,
    config: animationConfig,
    from: { opacity: 1 },
    opacity: 0,
  });

  const newLevelStyles = useSpring({
    delay: 750,
    config: animationConfig,
    from: { opacity: 0 },
    opacity: 1,
  });

  return (
    <div className="AnimatedLevel">
      {oldLevel && (
        <animated.div style={oldLevelStyles}>
          <SanityImage {...oldLevel.image} />
        </animated.div>
      )}
      <animated.div style={newLevelStyles}>
        <SanityImage {...newLevel.image} />
      </animated.div>
    </div>
  );
};
