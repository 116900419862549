import uniqueId from "lodash/uniqueId";
import React from "react";
import { ReactSVG } from "react-svg";
import { FooterListItem } from "../../../layout/footer/FooterListItem/FooterListItem";
import { HrefType, Link } from "../../../shared/components/link/Link";
import { List } from "../../../shared/components/list/List";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import gyldendalLogoUrl from "../../../static/images/iconsAnimated/logo-desktop.svg";
import { RouteConfiguration } from "../../../types/platform/infrastructure/RouteConfiguration";
import { LinkTypeDto } from "../../../types/shared/dto/LinkTypeDto";

export const ErrorFooter: React.FC = () => {
  const translations = useTranslations();

  const links = [
    { id: uniqueId(), type: LinkTypeDto.ExternalLink, externalUrl: "mailto:support@gyldendal.no", title: "Support" },
  ];

  return (
    <div className="ErrorFooter">
      <div className="ErrorFooter__inner">
        <Link
          to={RouteConfiguration.GyldendalPage}
          className="ErrorFooter__logoLink"
          hrefType={HrefType.External}
          aria-label={translations.goToGyldendalSite}
        >
          <ReactSVG className="ErrorFooter__logo" src={gyldendalLogoUrl} />
        </Link>

        <List
          items={links}
          singleItemClassName="ErrorFooter__singleLink"
          renderItem={link => <FooterListItem model={link} />}
          className="ErrorFooter__linksList"
        />
      </div>
    </div>
  );
};
