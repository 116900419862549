import React from "react";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { PortableTextContent } from "../../../portableTextContent/PortableTextContent";

interface MaskTaskContentProps {
  content: PortableTextDto;
}

export const MaskTaskContent = (props: MaskTaskContentProps) => {
  const { content } = props;

  // TODO: Change the types on the backend and compare it to the normal null
  if (content.value === "null") {
    return null;
  }

  return (
    <div className="MaskTaskContent">
      <PortableTextContent content={content} />
    </div>
  );
};
