import noop from "lodash/noop";
import React from "react";
import { Command } from "../../../react-mvvm/src";
import { TrainingTask } from "../../trainingGrounds/trainingGround/training/testTask/TrainingTask";
import { TrainingItemContainer } from "../../trainingGrounds/trainingGround/training/testTask/trainingItemContainer/TrainingItemContainer";

interface TrainingGroundPreviewProps {
  task: TrainingTask;
}

export const TrainingGroundPreview = (props: TrainingGroundPreviewProps) => {
  const { task } = props;

  const mockCommand = new Command(
    () => {
      noop();
    },
    () => false
  );

  return (
    <div className="TrainingGroundPreview">
      <div className="TrainingGroundPreview__inner">
        <TrainingItemContainer
          trainingTask={task}
          endTrainingWithoutConfirmation={mockCommand}
          gotoPreviousTask={mockCommand}
          gotoNextTask={mockCommand}
          isNextTrainingSetLast={false}
          isLastTask={false}
        />
      </div>
    </div>
  );
};
