/* eslint-disable class-methods-use-this */
import { action, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { UserBookmarkDto } from "../../types/bookmarks/dto/UserBookmarkDto";
import { IBookmarkService } from "../api/BackendApi";
import { CourseStructure } from "../contentStructure/CourseStructure";
import { Bookmark } from "./Bookmark";
import { IUserStore } from "./UserStore";

export interface IBookmarkStore {
  isBookmarked: (stepId: string) => boolean;
  removeBookmark: (bookmark: Bookmark) => void;
  createBookmark: (stepId: string, id?: string) => Bookmark;
  getBookmark: (stepId: string) => Bookmark | undefined;
  bookmarkService: IBookmarkService;
  bookmarks: Bookmark[];
}

export class BookmarkStore implements IBookmarkStore {
  @observable bookmarks: Bookmark[] = [];

  public constructor(
    public bookmarkService: IBookmarkService,
    private userStore: IUserStore,
    private activeCourseStructure: CourseStructure
  ) {}

  @action.bound
  async init() {
    if (this.userStore.isAuthenticated && this.activeCourseStructure) {
      const fetchedDtos = await this.bookmarkService.getUserBookmarks({
        courseId: this.activeCourseStructure.id,
      });
      fetchedDtos.forEach(dto => this.updateBookmarkFromServer(dto));
    }
  }

  // Does not create bookmark on the server. See bookmark.Save
  @action.bound
  createBookmark(stepId: string, id: string = uuid()): Bookmark {
    const bookmark = new Bookmark(this, stepId, this.activeCourseStructure.id, id);
    this.bookmarks.push(bookmark);
    return bookmark;
  }

  // Does not delete bookmark from the server. See bookmark.Delete
  @action.bound
  removeBookmark(bookmark: Bookmark) {
    this.bookmarks = this.bookmarks.filter(b => b.id !== bookmark.id);
    bookmark.dispose();
  }

  private updateBookmarkFromServer(dto: UserBookmarkDto) {
    let bookmark = this.bookmarks.find(b => b.id === dto.id);
    if (!bookmark) {
      bookmark = new Bookmark(this, dto.thumbnail.id, dto.courseId, dto.id);
      this.bookmarks.push(bookmark);
    }
    bookmark.updateFromDto(dto);
  }

  getBookmark(stepId: string) {
    return this.bookmarks.find(b => b.stepId === stepId);
  }

  isBookmarked(stepId: string) {
    return !!this.bookmarks.some(x => x.stepId === stepId);
  }
}
