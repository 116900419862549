import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../shared/components/button/Button";
import { MotifImage } from "../../shared/components/image/Image";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import { LoginViewModel } from "./LoginViewModel";

export const LoginView = observer(({ model }: { model: LoginViewModel }) => {
  const translations = useTranslations();
  const { loginConfig, gtmService, navigation, returnUrl } = model;

  return (
    <div className="Login">
      <div className="Login__inner">
        <div className="Login__info">
          <h1 className="Login__title">{loginConfig.title ? loginConfig.title : translations.logIn}</h1>
          <p className="Login__description">
            {loginConfig.description ? loginConfig.description : translations.dashboardDescription}
          </p>
          <Button
            onClick={() => {
              gtmService.pushLoginStarted("Started Login And Buy Process");
              navigation.goToTibetLogin(returnUrl);
            }}
            variant="primary"
            className="Login__logInButton"
          >
            {translations.logIn}
          </Button>
        </div>
        <div className="Login__imageContainer">
          <MotifImage image={{ fileName: "login.svg" }} className="Login__image" />
        </div>
      </div>
    </div>
  );
});
