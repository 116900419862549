import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { CourseInfo, Product } from "../../stores/Product";
import { Button } from "../button/Button";
import { List } from "../list/List";
import { Radio } from "../radio/Radio";
import { RadioGroup } from "../radio/RadioGroup";
import { useRadioGroupContext } from "../radio/RadioGroupContext";
import { SvgIcon } from "../svgIcon/SvgIcon";
import { Title } from "../title/Title";

interface CoursesPickerProps {
  handleLogin: (slug: string) => void;
  activeProduct?: Product;
}

export const CoursesPicker: React.FC<CoursesPickerProps> = observer(props => {
  const { activeProduct, handleLogin } = props;

  if (!activeProduct) {
    return null;
  }

  const { availableCourses, studiesType } = activeProduct;
  const [selectedCourse, setSelectedCourse] = React.useState<CourseInfo>(availableCourses[0]);
  const translations = useTranslations();

  return (
    <div className="CoursesPicker">
      <div className="CoursesPicker__text">
        <Title level={2} className="CoursesPicker__title">
          {translations.selectCourse}
        </Title>
        <p className="CoursesPicker__description">{translations.selectCourseDescription}</p>
      </div>
      <RadioGroup
        name="courseSelector"
        onChange={value => setSelectedCourse(availableCourses.filter(course => course.id === value)[0])}
        value={selectedCourse.id}
      >
        <List
          items={availableCourses}
          className="CoursesPicker__list"
          renderItem={item => {
            const { onChange, disabled } = useRadioGroupContext();

            const onContainerClick = () => {
              if (disabled) {
                return;
              }

              onChange(item.id);
            };

            return (
              <div
                className={classNames("CoursesPicker__singleItem", {
                  "CoursesPicker__singleItem--active": item.id === selectedCourse?.id,
                })}
                role="presentation"
                onClick={onContainerClick}
              >
                <Radio id={item.id}>
                  <p className="CoursesPicker__studiesType">{studiesType}</p>
                  <p className="CoursesPicker__courseTitle">{item.title}</p>
                </Radio>
              </div>
            );
          }}
        />
      </RadioGroup>
      <Button
        onClick={() => handleLogin(selectedCourse.slug)}
        disabled={!selectedCourse}
        className="CoursesPicker__loginButton"
      >
        <span>{translations.logIn}</span>
        <SvgIcon iconName="right-arrow-line" />
      </Button>
    </div>
  );
});
