import { observer } from "mobx-react";
import React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";

const CURRENT_YEAR = new Date().getFullYear();

export const ProductCopyright: React.FC = observer(() => {
  const translations = useTranslations();

  return (
    <div className="ProductCopyright">
      <span className="Footer__copyrightText">
        &copy; {`${CURRENT_YEAR} ${translations.siteName} ${translations.allRightsReserved}`}
      </span>
    </div>
  );
});
