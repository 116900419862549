import shuffle from "lodash/shuffle";

type GapTaskOption = { content: { nb: string } };

export const shuffledOptions = (choices: GapTaskOption[], additionalChoices: GapTaskOption[] = []) => {
  const gapTestChoices = additionalChoices
    .concat(choices)
    .map(option => ({ value: option.content.nb, label: option.content.nb }));
  return shuffle(gapTestChoices);
};

export const isAnswerCorrect = (choosenValue: string, correctChoices: string[]) => {
  return correctChoices.map(correctChoice => choosenValue === correctChoice).includes(true);
};
