import uniqueId from "lodash/uniqueId";
import React from "react";
import { useSelectAllCheckbox } from "../../hooks/useSelectAllCheckbox";
import { CheckboxButton } from "../checkbox/CheckboxButton";
import { CheckboxGroup } from "../checkbox/CheckboxGroup";

interface SingleFilterProps<TFilterValue extends string> {
  allFilterText: string;
  filterByWithText: { [k in TFilterValue]: { text: string } };
  value?: TFilterValue[];
  onChange: (value: TFilterValue[]) => void;
}

export const SingleFilter = <TFilterValue extends string>(props: SingleFilterProps<TFilterValue>) => {
  const { filterByWithText, allFilterText, value, onChange } = props;
  const checkboxGroupName = uniqueId("singleFilter__");
  const availableFilterValues = Object.keys(filterByWithText) as TFilterValue[];
  const { isSelectAllChecked } = useSelectAllCheckbox(availableFilterValues, onChange, value, "filter");

  return (
    <div className="SingleFilter">
      <CheckboxGroup
        name={checkboxGroupName}
        value={isSelectAllChecked ? [] : value}
        onChange={values => onChange(values as TFilterValue[])}
      >
        <ul className="SingleFilter__filterValueList">
          <li key="all" className="SingleFilter__selectAll">
            <CheckboxButton
              className="SingleFilter__filterValue"
              checked={isSelectAllChecked}
              onChange={() => {
                onChange(availableFilterValues);
              }}
              id="all"
            >
              {allFilterText}
            </CheckboxButton>
          </li>
          {availableFilterValues.map(availableFilterValue => (
            <li key={availableFilterValue} className="SingleFilter__item">
              <CheckboxButton className="SingleFilter__filterValue" id={availableFilterValue}>
                {filterByWithText[availableFilterValue].text}
              </CheckboxButton>
            </li>
          ))}
        </ul>
      </CheckboxGroup>
    </div>
  );
};
