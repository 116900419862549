import classnames from "classnames";
import * as React from "react";
import { ImageCropDto } from "../../../types/shared/dto/ImageCropDto";
import { ImageHotspotDto } from "../../../types/shared/dto/ImageHotspotDto";
import { useMotif } from "../../contexts/MotifContext";
import { Button } from "../button/Button";
import { SvgIcon } from "../svgIcon/SvgIcon";
import { Tooltip } from "../tooltip/Tooltip";
import { imageParamsMock } from "./Image.mock";
import { buildUrl } from "./ImageBuilder";

export type Placement = "top-start" | "top-end" | "bottom-start" | "bottom-end";

interface ImageCreditProps {
  text: string;
  placement?: Placement;
}

const placementToClassNameMap: { [placement in Placement | "default"]: string } = {
  default: "ImageCredit--bottomEnd",
  "top-start": "ImageCredit--topStart",
  "top-end": "ImageCredit--topEnd",
  "bottom-start": "ImageCredit--bottomStart",
  "bottom-end": "ImageCredit--bottomEnd",
};

const ImageCredit: React.FC<ImageCreditProps> = props => {
  const { text, placement } = props;

  return (
    <div className={classnames("ImageCredit", placementToClassNameMap[placement ?? "default"])}>
      <Tooltip placement="top" label={text}>
        <Button type="circle" variant="secondary" size="small">
          <SvgIcon iconName="info" />
          <figcaption className="visuallyHidden">{text}</figcaption>
        </Button>
      </Tooltip>
    </div>
  );
};

type AspectRatio = "3/2" | "4/3" | "3/4" | "1/1" | "9/16" | "16/9";

const aspectRatioToClassNameMap: { [aspectRatio in AspectRatio]: string } = {
  "3/2": "ImageContainer--3x2",
  "4/3": "ImageContainer--4x3",
  "3/4": "ImageContainer--3x4",
  "1/1": "ImageContainer--1x1",
  "9/16": "ImageContainer--9x16",
  "16/9": "ImageContainer--16x9",
};

interface ImageContainerProps {
  children: React.ReactNode;
  aspectRatio: AspectRatio;
  className?: string;
  style?: React.CSSProperties;
}

const ImageContainer: React.FC<ImageContainerProps> = props => {
  const { children, style, className, aspectRatio } = props;

  return (
    <figure className={classnames("ImageContainer", className, aspectRatioToClassNameMap[aspectRatio])} style={style}>
      {children}
    </figure>
  );
};

interface ImageProps {
  url: string;
  alt?: string;
  className?: string;
}

interface SanityImageProps {
  url: string;
  alt?: string;
  className?: string;
  hotspot: ImageHotspotDto;
  crop: ImageCropDto;
  width: number;
  height: number;
}

interface ImageComposition extends React.ForwardRefExoticComponent<ImageProps & React.RefAttributes<HTMLImageElement>> {
  Container: typeof ImageContainer;
  Credit: typeof ImageCredit;
}

// Use this component for internal images (our svg's or pngs that are present in the project directory)
export const Image = React.forwardRef<HTMLImageElement | null, ImageProps>(({ url, alt = "", className }, ref) => {
  return <img ref={ref} src={url} alt={alt} className={className} />;
}) as ImageComposition;

// Use this component for images that come from Sanity. When using for internal images the app will breake cause of `buildUrl` from Sanity
export const SanityImage = React.forwardRef<HTMLImageElement | null, SanityImageProps>(
  ({ url, alt = "", className, crop, hotspot, height, width }, ref) => {
    if (!url) {
      return <Image ref={ref} url={url} alt={alt} className={className} />;
    }

    return <Image ref={ref} url={buildUrl(url, hotspot, crop, width, height)} alt={alt} className={className} />;
  }
) as ImageComposition;

export interface MotifImageProps {
  fileName: `${string}.${string}`;
  alt?: string;
}

export const MotifImage: React.FC<{
  image: MotifImageProps;
  className?: string;
  maxWidth?: string;
}> = ({ image: { fileName, ...restImageProps }, ...restProps }) => {
  const importedIconUrlRef = React.useRef("");
  const [loading, setLoading] = React.useState(true);
  const motif = useMotif();

  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        importedIconUrlRef.current = (await import(`../../../static/images/${motif}/${fileName}`)).default;
      } finally {
        setLoading(false);
      }
    })();
  }, [fileName]);

  if (loading || !importedIconUrlRef) {
    return null;
  }

  const { current } = importedIconUrlRef;
  return <Image url={current} {...imageParamsMock} alt={restImageProps.alt} {...restProps} />;
};

Image.Container = ImageContainer;
Image.Credit = ImageCredit;
