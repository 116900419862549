import { observer } from "mobx-react";
import * as React from "react";
import { Video } from "../../shared/video/Video";
import { VideoStep } from "./VideoStep";

interface VideoStepViewProps {
  model: VideoStep;
}

export const VideoStepView = observer((props: VideoStepViewProps) => {
  const {
    model: {
      title,
      description,
      videoDetails,
      videoUrl,
      startPositionSeconds,
      author,
      endVideo,
      updateVideoProgress,
      authorInfo,
      authorInfoPopup,
    },
  } = props;

  return (
    <Video
      title={title}
      description={description}
      duration={videoDetails?.duration}
      videoUrl={videoUrl}
      startPositionSeconds={startPositionSeconds}
      author={author}
      endVideo={endVideo}
      updateVideoProgress={updateVideoProgress}
      authorInfo={authorInfo}
      authorInfoPopup={authorInfoPopup}
    />
  );
});
