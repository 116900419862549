import React from "react";
import { SvgIcon } from "../../svgIcon/SvgIcon";

interface QuoteProps {
  children: React.ReactNode;
}

export const Quote: React.FC<QuoteProps> = props => {
  const { children } = props;

  if (!children) {
    return null;
  }

  return (
    <div className="Quote">
      <SvgIcon iconName="apostrophe" className="Quote__icon" />
      <blockquote className="Quote__text">{children}</blockquote>
    </div>
  );
};
