import React from "react";
import { MotifImage } from "../image/Image";
import { Link } from "../link/Link";

interface EmptySearchProps {
  emptySearchMessage: string;
  linkUrl: string;
  linkText: string;
}

export const EmptySearch = (props: EmptySearchProps) => {
  const { emptySearchMessage, linkUrl, linkText } = props;

  return (
    <div className="EmptySearch">
      <div className="EmptySearch__imageWrapper">
        <MotifImage className="EmptySearch__image" image={{ fileName: "empty-search-image.png" }} />
      </div>
      <p className="EmptySearch__text">{emptySearchMessage}</p>
      <div className="EmptySearch__linkWrapper">
        <Link type="button" buttonVariant="primary" to={linkUrl}>
          {linkText}
        </Link>
      </div>
    </div>
  );
};
