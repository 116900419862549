import { action, computed, observable } from "mobx";
import { AuthorInfo } from "../../../pages/authorsForProduct/authorInfo/AuthorInfo";
import { AuthorDto } from "../../../types/shared/dto/AuthorDto";
import { BackendApi } from "../../api/BackendApi";
import { Popup } from "../popup/Popup";

export class AuthorsSliderViewModel {
  @observable authors?: AuthorDto[] = [];

  @observable authorInfoPopup = new Popup();

  @observable private author?: AuthorDto;

  @computed get authorInfo() {
    if (!this.author) {
      return;
    }

    return new AuthorInfo(this.backendApi, this.author);
  }

  constructor(private backendApi: BackendApi, authors?: AuthorDto[]) {
    this.authors = authors;
  }

  @action.bound
  setAuthor(slug: string) {
    if (this.authors) {
      this.author = this.authors.find(author => author.slug === slug);
    }
  }
}
