import { observable } from "mobx";
import { PaginatedList } from "react-mvvm";

export class Search<TResult extends { id: number | string }, TFilters> {
  @observable public filters: TFilters;

  @observable public paginatedList: PaginatedList<TResult>;

  private readonly PAGE_SIZE = 6;

  constructor(
    load: (options: { skip: number; take: number; filters: TFilters }) => Promise<ReadonlyArray<TResult>>,
    defaultFilters: TFilters,
    pageSize?: number
  ) {
    this.filters = defaultFilters;

    this.paginatedList = new PaginatedList(
      options => load({ ...options, filters: this.filters }),
      pageSize || this.PAGE_SIZE
    );
  }
}
