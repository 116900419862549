import { observer } from "mobx-react";
import React from "react";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { TaskViewModel, TaskView } from "../../../../../shared/components/tasks/TaskView";
import { assertUnreachable } from "../../../../../shared/typeUtils";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";
import { TaskActions } from "../../shared/taskActions/TaskActions";
import { TaskStatusImage } from "../../shared/taskStatusImage/TaskStatusImage";
import { StateButton } from "./stateButton/StateButton";

export interface TaskStepViewProps {
  task: TaskViewModel;
  hint?: TaskHintViewModel;
  solution?: PortableTextDto;
}

const Actions = observer(({ task, hint, solution }: TaskStepViewProps) => {
  const { _type, model } = task;

  switch (_type) {
    case "TextTask":
      return <TaskActions taskId={model.id} solution={solution} hint={hint} />;
    case "ChoiceListTask":
    case "ChoiceTask":
    case "GapTask":
    case "InputTask":
    case "MaskTask":
    case "MatchTask":
      return (
        <>
          <TaskActions
            taskId={model.id}
            solution={model.isSubmitted ? solution : undefined}
            hint={!model.isSubmitted ? hint : undefined}
            additionalContent={
              <StateButton
                answersState={model.answersState}
                onReset={model.reset}
                onSubmit={model.submit}
                disabled={!model.isAnswered}
              />
            }
          />
          <TaskStatusImage state={task.model.answersState} />
        </>
      );
    default:
      throw assertUnreachable(_type);
  }
});

export const TaskStepView = observer(({ task, hint, solution }: TaskStepViewProps) => {
  return (
    <div data-testid="TaskStep" className="TaskStep">
      <TaskView task={task} />
      <div className="TaskStep__actions">
        <Actions task={task} hint={hint} solution={solution} />
      </div>
    </div>
  );
});
