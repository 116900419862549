import React from "react";

export type Motif = "riktig" | "degrees37";

const DEFAULT_MOTIF: Motif = "riktig";

interface MotifContext {
  motif: Motif;
}

const getMotif = (): Motif => {
  const { motif = DEFAULT_MOTIF } = window;

  if (motif !== "riktig" && motif !== "degrees37") {
    throw new Error(`${motif} is not a correct motif`);
  }

  return motif;
};

export const motifContext = React.createContext<MotifContext>({
  motif: DEFAULT_MOTIF,
});

export const useMotif = () => React.useContext<MotifContext>(motifContext).motif;

export const MotifProvider = ({ children }: { children: React.ReactNode }) => {
  const { Provider } = motifContext;
  return <Provider value={{ motif: getMotif() }}>{children}</Provider>;
};
