import uniqueId from "lodash/uniqueId";
import { Task } from "../../../../../shared/api/StepService";
import { TaskHintViewModel } from "../../../../../shared/components/taskHint/TaskHintViewModel";
import { TaskFactory } from "../../../../../shared/components/tasks/TaskFactory";
import { TaskViewModel } from "../../../../../shared/components/tasks/TaskView";
import { ActiveCourseParams } from "../../../../../shared/models/Url";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { TaskHintDto } from "../../../../../types/dto/tasks/TaskHintDto";
import { PortableTextDto } from "../../../../../types/shared/dto/PortableTextDto";

export type TaskGroupItem = {
  id: string;
  title: string;
  description?: string;
  task: TaskViewModel;
  hint?: TaskHintViewModel;
  solution?: PortableTextDto;
};

export class TaskGroupFactory {
  private taskFactory = new TaskFactory();

  constructor(public bookmarkStore: IBookmarkStore, public activeCourseParams: ActiveCourseParams) {}

  private createTaskHintViewModel(hint?: TaskHintDto) {
    return (
      hint && new TaskHintViewModel(this.activeCourseParams, this.bookmarkStore, hint.content, hint.relatedStepInfo)
    );
  }

  createTask(param: Task): TaskGroupItem {
    const task = this.taskFactory.createTask(param);

    return {
      id: uniqueId(),
      task,
      title: param.title,
      description: param.description,
      hint: this.createTaskHintViewModel(param.hint),
      solution: param.solution,
    };
  }
}
