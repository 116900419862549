import React from "react";

export const useDelayedDisplay = (shouldBeDisplay: boolean) => {
  const [isDisplayed, setIsDisplayed] = React.useState(shouldBeDisplay);
  React.useEffect(() => {
    shouldBeDisplay && setIsDisplayed(true);
  }, [shouldBeDisplay]);

  const hide = () => {
    !shouldBeDisplay && setIsDisplayed(false);
  };

  return [isDisplayed, hide] as const;
};
