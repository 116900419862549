import groupBy from "lodash/groupBy";
import { CourseStructure } from "../../../../shared/contentStructure/CourseStructure";
import { TrainingTask } from "../training/testTask/TrainingTask";
import { ProgressPerTheme, ProgressPerThemeWithAvailability } from "./TrainingResultViewModel";

export function getResultPerTheme(visitedTasks: TrainingTask[]) {
  const visitedTasksPerTheme = groupBy(visitedTasks, task => task.theme.id);

  return Object.entries(visitedTasksPerTheme).map(([_, value]) => {
    const themeInfo = value[0].theme;
    return {
      id: themeInfo.id,
      totalStepsCount: value.length,
      completedStepsCount: value.filter(visitedTask => visitedTask.task.model.answersState === "correct").length,
      themeInfo,
    };
  });
}

export function getThemesAvailabilityForResults(
  progressPerThemes: ProgressPerTheme[],
  courseStructure: CourseStructure
): ProgressPerThemeWithAvailability[] {
  return progressPerThemes.map(themeProgress => {
    const theme = courseStructure.tryGetThemeBySlug(themeProgress.themeInfo.slug);

    return {
      ...themeProgress,
      isThemeAvailable: theme ? !theme.isUnderDevelopment : false,
    };
  });
}
