import { observer } from "mobx-react";
import * as React from "react";
import { ClipLoader } from "react-spinners";
import { CommandButton } from "../../../shared/components/button/Button";
import { List } from "../../../shared/components/list/List";
import { PortableTextContent } from "../../../shared/components/portableTextContent/PortableTextContent";
import { RadioGroup } from "../../../shared/components/radio/RadioGroup";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";
import { useTranslations } from "../../../shared/contexts/StaticTranslationContext";
import { PriceOptionDto } from "../../../types/courses/dto/PriceOptionDto";
import { BuyCourseViewModel } from "./BuyCourseViewModel";
import { BuyCourseItem } from "./buyCourseItem/BuyCourseItem";

export const BuyCourse = observer(({ model }: { model: BuyCourseViewModel }) => {
  const { course, buyCourseCommand, setSelectedOption, selectedPriceOptionId, groupName } = model;
  const { buyCourseSection, priceOptions } = course;
  const { title, description } = buyCourseSection;
  const translations = useTranslations();

  return (
    <div className="BuyCourse">
      <h2 className="BuyCourse__title">{title}</h2>
      <div className="BuyCourse__description">
        <PortableTextContent content={description} />
      </div>
      {priceOptions && priceOptions.length > 1 && (
        <>
          <h3 className="BuyCourse__pricingMessage">{translations.choosePricing}</h3>
          <RadioGroup name={`${groupName}_priceOption`} value={selectedPriceOptionId} onChange={setSelectedOption}>
            <List
              items={priceOptions}
              className="BuyCourse__priceOptions"
              singleItemClassName="BuyCourse__priceOptionItem"
              renderItem={(option: PriceOptionDto) => {
                return <BuyCourseItem option={option} />;
              }}
            />
          </RadioGroup>
        </>
      )}
      <div className="BuyCourse__buyCourseButtonWrapper">
        <CommandButton className="BuyCourse__buyCourseButton" command={buyCourseCommand}>
          <span>{translations.buyCourseButton}</span>
          <SvgIcon iconName="right-arrow-line" />
        </CommandButton>
        {buyCourseCommand.isRunning && (
          <div className="BuyCourse__paymentInProgress">
            <div className="BuyCourse__paymentSpinner">
              <ClipLoader color="#e50043" loading />
            </div>
            <span>{translations.pleaseWaitItCanTakeFewSeconds}</span>
          </div>
        )}
      </div>
    </div>
  );
});
