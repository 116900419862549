import { IRoute } from "react-mvvm";
import { App } from "../App";
import { StepPreviewViewModel } from "./StepPreviewViewModel";

export type PreviewParams = {
  slug: string;
};

export type PreviewQuery = {
  isDraft: boolean;
  revision: string;
};

export const addStepPreviewRouting = (appRoute: IRoute<App, unknown, unknown>) => {
  const productRoute = appRoute.addRoute<StepPreviewViewModel, PreviewParams, PreviewQuery>(
    "/preview/step/:slug",
    StepPreviewViewModel,
    (app, params, query) => {
      const product = new StepPreviewViewModel(
        params.slug,
        query,
        app.backendApi,
        app.languageService,
        app,
        app.navigation
      );
      return app.showChildPage(product);
    },
    page => ({ slug: page.stepSlug })
  );
  return productRoute;
};
