import { observer } from "mobx-react";
import React from "react";
import { MotifImage } from "../image/Image";
import { PortableTextContent } from "../portableTextContent/PortableTextContent";
import { TaskHintViewModel } from "./TaskHintViewModel";
import { HintRelatedStep } from "./hintRelatedStep/HintRelatedStep";

export interface TaskHintProps {
  hint: TaskHintViewModel;
}

export const TaskHint = observer((props: TaskHintProps) => {
  const { hint } = props;
  const { content, relatedStep } = hint;

  return (
    <div className="TaskHint">
      <div className="TaskHint__bubble">
        {content && <PortableTextContent content={content} className="TaskHint__content" />}
        {relatedStep && (
          <div className="TaskHint__relatedStep">
            <HintRelatedStep model={relatedStep} />
          </div>
        )}
      </div>
      <MotifImage className="TaskHint__image" image={{ fileName: "hint-image.svg" }} />
    </div>
  );
});
