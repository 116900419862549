import classNames from "classnames";
import React from "react";
import { Slide, toast, ToastContainer, ToastOptions } from "react-toastify";
import { Button } from "../../components/button/Button";
import { SvgIcon } from "../../components/svgIcon/SvgIcon";
import { useTranslations } from "../StaticTranslationContext";

export type NotificationVariant = "info" | "error";

type ToastParams = Omit<ToastOptions, "className" | "bodyClassName">;

export interface NotificationService {
  showNotification: (children: React.ReactNode, variant: NotificationVariant, params?: ToastParams) => void;
}

const notificationContext = React.createContext<NotificationService | undefined>(undefined);

export const useNotificationService = () => {
  const context = React.useContext<NotificationService | undefined>(notificationContext);

  if (!context) {
    throw new Error("Notification context shouldn't be used outside of the NotificationProvider");
  }

  return context;
};

export const NotificationProvider = ({
  children,
  maxNumberOfNotifications = 4,
}: {
  children: React.ReactNode;
  maxNumberOfNotifications?: number;
}) => {
  const translations = useTranslations();

  const notificationService = {
    showNotification: (toastChildren: React.ReactNode, variant: NotificationVariant, params?: ToastParams) => {
      toast(toastChildren, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        transition: Slide,
        className: classNames("NotificationContainer__notification", {
          "NotificationContainer__notification--info": variant === "info",
          "NotificationContainer__notification--error": variant === "error",
        }),
        bodyClassName: "NotificationContainer__content",
        closeButton: ({ closeToast }) => (
          <div className="NotificationContainer__buttonContainer">
            <Button onClick={closeToast} type="clear" className="NotificationContainer__button">
              <SvgIcon iconName="close" />
              <span className="visuallyHidden">{translations.close}</span>
            </Button>
          </div>
        ),
        ...params,
      });
    },
  };

  const { Provider } = notificationContext;

  return (
    <Provider value={notificationService}>
      {children}
      <ToastContainer newestOnTop limit={maxNumberOfNotifications} className="NotificationContainer" />
    </Provider>
  );
};
