import { observer } from "mobx-react";
import React from "react";
import { ContentView } from "../../react-mvvm/src";
import { Image, SanityImage } from "../../shared/components/image/Image";
import { List } from "../../shared/components/list/List";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { Title } from "../../shared/components/title/Title";
import SuperHeroDog from "../../static/images/superhero-dog.png";
import { AuthorsForProductViewModel } from "./AuthorsForProductViewModel";
import { AuthorDetailsSidePanel } from "./authorDetailsSidePanel/AuthorDetailsSidePanel";
import { AuthorThumbnail } from "./authorThumbnail/AuthorThumbnail";

interface AuthorsForProductViewProps {
  model: AuthorsForProductViewModel;
}
export const AuthorsForProductView = observer((props: AuthorsForProductViewProps) => {
  const { model } = props;
  const { productAuthorsHero, authors, setAuthor, authorInfo, authorInfoPopup } = model;

  if (!productAuthorsHero) {
    return null;
  }

  const { description, image, title } = productAuthorsHero;

  return (
    <ContentView content={model.childPage}>
      <PageTitle title={title}>
        <article className="AuthorsForProductPage">
          <div className="AuthorsForProductPage__header">
            <div className="AuthorsForProductPage__headerText">
              <Title level={1} className="AuthorsForProductPage__title">
                {title}
              </Title>
              <p className="AuthorsForProductPage__description">{description}</p>
            </div>
            <Image.Container aspectRatio="3/2" className="AuthorsForProductPage__headerImageWrapper">
              {image ? <SanityImage {...image} /> : <Image url={SuperHeroDog} />}
            </Image.Container>
          </div>
          <div className="AuthorsForProductPage__content">
            {authors && (
              <List
                items={authors}
                renderItem={item => (
                  <AuthorThumbnail
                    {...item}
                    buttonOnClickAction={() => {
                      setAuthor(item.slug);
                      authorInfoPopup.show();
                    }}
                  />
                )}
                className="AuthorsForProductPage__authorsList"
              />
            )}
          </div>
        </article>
        <AuthorDetailsSidePanel authorInfo={authorInfo} popup={authorInfoPopup} />
      </PageTitle>
    </ContentView>
  );
});
