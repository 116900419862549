import { GetStepPreviewRequest } from "../../types/api/lessons/GetStepPreviewRequest";
import { GetStepRequest } from "../../types/api/lessons/GetStepRequest";
import { GetTrainingGroundTaskPreviewRequest } from "../../types/api/trainingground/GetTrainingGroundTaskPreviewRequest";
import { LessonSoundDto } from "../../types/dto/lessonstepdtos/LessonSoundDto";
import { LessonStepDto } from "../../types/dto/lessonstepdtos/LessonStepDto";
import { LessonTaskGroupDto } from "../../types/dto/lessonstepdtos/LessonTaskGroupDto";
import { LessonTextBlockDto } from "../../types/dto/lessonstepdtos/LessonTextBlockDto";
import { LessonVideoDto } from "../../types/dto/lessonstepdtos/LessonVideoDto";
import { ChoiceListTaskDto } from "../../types/dto/tasks/ChoiceListTaskDto";
import { ChoiceTaskDto } from "../../types/dto/tasks/ChoiceTaskDto";
import { GapTaskDto } from "../../types/dto/tasks/GapTaskDto";
import { InputTaskDto } from "../../types/dto/tasks/InputTaskDto";
import { MaskTaskDto } from "../../types/dto/tasks/MaskTaskDto";
import { MatchTaskDto } from "../../types/dto/tasks/MatchTaskDto";
import { TaskDto } from "../../types/dto/tasks/TaskDto";
import { TaskTypeDto } from "../../types/dto/tasks/TaskTypeDto";
import { TextTaskDto } from "../../types/dto/tasks/TextTaskDto";
import { StepTypeDto } from "../../types/shared/dto/StepTypeDto";
import { StepType } from "../stepTypeUtils";
import { assertUnreachable } from "../typeUtils";

export type TaskType = `${TaskTypeDto}`;

export type Step =
  | (
      | ({ _type: Extract<StepType, "TextBlock"> } & LessonTextBlockDto)
      | ({ _type: Extract<StepType, "Video"> } & LessonVideoDto)
      | ({ _type: Extract<StepType, "TaskGroup"> } & Omit<LessonTaskGroupDto, "tasks"> & { tasks: Task[] })
      | { _type: Extract<StepType, "Task">; task: Task }
      | ({ _type: Extract<StepType, "Sound"> } & LessonSoundDto)
    ) & {
      id: string;
      title: string;
      slug: string;

      relatedContentIds?: string[];
    };

export type Task =
  | ({ _type: Extract<TaskType, "TextTask"> } & TextTaskDto)
  | ({ _type: Extract<TaskType, "ChoiceListTask"> } & ChoiceListTaskDto)
  | ({ _type: Extract<TaskType, "ChoiceTask"> } & ChoiceTaskDto)
  | ({ _type: Extract<TaskType, "MaskTask"> } & MaskTaskDto & { solution: undefined })
  | ({ _type: Extract<TaskType, "InputTask"> } & InputTaskDto)
  | ({ _type: Extract<TaskType, "GapTask"> } & GapTaskDto)
  | ({ _type: Extract<TaskType, "MatchTask"> } & MatchTaskDto);

export interface IStepService {
  getStep: (req: GetStepRequest) => Promise<Step>;
  getStepPreview: (req: GetStepPreviewRequest) => Promise<Step>;
  getTrainingGroundTaskPreview: (req: GetTrainingGroundTaskPreviewRequest) => Promise<Task>;
}

function throwCreationError<TType>(type: TType): never {
  throw new Error(`Unable to create object with type: ${type}`);
}

export const mapToTask = (task: TaskDto): Task => {
  switch (task.type) {
    case TaskTypeDto.ChoiceListTask: {
      if (!task.choiceListTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "ChoiceListTask", ...task.choiceListTask };
    }
    case TaskTypeDto.ChoiceTask: {
      if (!task.choiceTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "ChoiceTask", ...task.choiceTask };
    }
    case TaskTypeDto.GapTask: {
      if (!task.gapTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "GapTask", ...task.gapTask };
    }
    case TaskTypeDto.InputTask: {
      if (!task.inputTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "InputTask", ...task.inputTask };
    }
    case TaskTypeDto.MaskTask: {
      if (!task.maskTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "MaskTask", ...task.maskTask, solution: undefined };
    }
    case TaskTypeDto.MatchTask: {
      if (!task.matchTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "MatchTask", ...task.matchTask };
    }
    case TaskTypeDto.TextTask: {
      if (!task.textTask) {
        throwCreationError<TaskTypeDto>(task.type);
      }
      return { _type: "TextTask", ...task.textTask };
    }
    default:
      return assertUnreachable(task.type);
  }
};

export const mapToStep = (step: LessonStepDto): Step => {
  switch (step.type) {
    case StepTypeDto.TextBlock: {
      if (!step.textBlock) {
        throwCreationError<StepTypeDto>(step.type);
      }
      return { _type: step.type, ...step, ...step.textBlock };
    }
    case StepTypeDto.Video: {
      if (!step.video) {
        throwCreationError<StepTypeDto>(step.type);
      }
      return { _type: step.type, ...step, ...step.video };
    }
    case StepTypeDto.TaskGroup: {
      if (!step.taskGroup) {
        throwCreationError<StepTypeDto>(step.type);
      }

      return { _type: step.type, ...step, ...step.taskGroup, tasks: step.taskGroup.tasks.map(mapToTask) };
    }
    case StepTypeDto.Task: {
      if (!step.task) {
        throwCreationError<StepTypeDto>(step.type);
      }
      return { _type: step.type, ...step, task: mapToTask(step.task) };
    }
    case StepTypeDto.Sound: {
      if (!step.sound) {
        throwCreationError<StepTypeDto>(step.type);
      }
      return { _type: step.type, ...step, ...step.sound };
    }
    default:
      return assertUnreachable(step.type);
  }
};
