import React from "react";
import { Helmet } from "react-helmet-async";
import { ImageDto } from "../../../types/shared/dto/ImageDto";
import { useTranslations } from "../../contexts/StaticTranslationContext";

interface PageTitleProps {
  title?: string;
  description?: string;
  image?: ImageDto;
  type?: "website" | "article";
  children: React.ReactNode;
  noIndex?: boolean;
}

export const PageTitle: React.FC<PageTitleProps> = props => {
  const { title, description, image, type, children, noIndex = false } = props;
  const translations = useTranslations();

  const { siteName } = translations;
  const fullTitle = !title ? siteName : `${title} | ${siteName}`;

  return (
    <>
      <Helmet defer={false}>
        <title>{fullTitle}</title>
        {title && <meta content={title} property="og:title" />}
        {noIndex && <meta name="robots" content="noindex" />}
        {description && <meta name="description" content={description} />}
        {description && <meta name="og:description" content={description} />}
        {image && <meta name="og:image" content={image.url} />}
        {type && <meta property="og:type" content={type} />}
      </Helmet>
      {children}
    </>
  );
};
