import Sketchfab from "@sketchfab/viewer-api";
import classNames from "classnames";
import * as React from "react";

interface SketchfabViewerProps {
  modelId: string;
  className?: string;
}

export const SketchfabViewer: React.FC<SketchfabViewerProps> = ({ modelId, className }) => {
  const viewerIframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    const client = new Sketchfab(viewerIframeRef.current);
    client.init(modelId, {
      error: () => {
        throw new Error("Sketchfab init error");
      },
      annotation_tooltip_visible: 0,
      ui_stop: 0,
      ui_theme: "dark",
      ui_animations: 0, // Premium
      ui_controls: 0, // Premium
      ui_hint: 0, // Premium
      ui_infos: 0, // Premium
      ui_settings: 0, // Premium
      ui_sound: 0, // Premium
      ui_vr: 0, // Premium
      ui_ar: 0, // Enterprise
      ui_ar_help: 0, // Enterprise
      ui_ar_qrcode: 0, // Enterprise
      ui_watermark: 0, // Enterprise
    });
  }, []);

  return (
    <div className={classNames("SketchfabViewer", className)}>
      <iframe ref={viewerIframeRef} title="sketchfab-viewer" />
    </div>
  );
};
