import { observer } from "mobx-react";
import React from "react";
import { LessonViewModel } from "../../LessonViewModel";
import { StepsListItems, StepsListNavigation, StepsListProgress, StepsListProvider } from "../StepsListProvider";

interface StepsListProps {
  lesson: LessonViewModel;
}

export const StepsList: React.FC<StepsListProps> = observer(props => {
  const { lesson } = props;
  return (
    <div className="StepsList">
      <StepsListProvider model={lesson}>
        <div className="StepsList__content">
          <div className="StepsList__progress">
            <StepsListProgress />
          </div>
          <div className="StepsList__itemsWrapper">
            <StepsListItems className="StepsList__items" />
          </div>
          <div className="StepsList__navigation">
            <StepsListNavigation />
          </div>
        </div>
      </StepsListProvider>
    </div>
  );
});
