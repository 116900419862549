import * as React from "react";
import { assertUnreachable } from "../../typeUtils";
import { VimeoPlayer } from "./vimeoPlayer/VimeoPlayer";
import { YoutubePlayer } from "./youtubePlayer/YoutubePlayer";

export interface VideoPlayerProps {
  videoUrl: string;
}

const isYoutubeUrl = (url: string) => url.indexOf("youtu") > 0;
const isVimeoUrl = (url: string) => url.indexOf("vimeo") > 0;

export const VideoPlayer = ({ videoUrl }: VideoPlayerProps) => {
  if (isVimeoUrl(videoUrl)) {
    return <VimeoPlayer videoUrl={videoUrl} />;
  }

  if (isYoutubeUrl(videoUrl)) {
    return <YoutubePlayer videoUrl={videoUrl} />;
  }

  return assertUnreachable(videoUrl as never);
};
