import classNames from "classnames";
import React from "react";
import { List } from "../../list/List";

export interface Tile {
  _key: string;
  content: unknown[];
  shouldCenterContent?: boolean;
}

export interface TileCollectionProps {
  tileSize?: "small" | "normal" | "large";
  tiles: Tile[];
  renderBlocks: (blocks: unknown) => React.ReactNode;
}

export const TileCollection: React.FC<TileCollectionProps> = props => {
  const { tileSize = "normal", tiles, renderBlocks } = props;

  return (
    <List
      className={classNames("TileCollection", {
        "TileCollection--small": tileSize === "small",
        "TileCollection--large": tileSize === "large",
      })}
      items={tiles.map(c => {
        return { ...c, id: c._key };
      })}
      renderItem={cell => {
        const { content, shouldCenterContent = true } = cell;
        return (
          <div
            className={classNames("TileCollection__tile", {
              "TileCollection__tile--centred": shouldCenterContent,
            })}
          >
            {renderBlocks(content)}
          </div>
        );
      }}
    />
  );
};
