import { IStepThumbnail } from "../../../../shared/components/thumbnails/stepThumbnail/createStepThumbnailViewModel";

export class StepListItem {
  readonly id: string;

  readonly title: string;

  readonly slug: string;

  readonly color?: string;

  constructor(
    step: { id: string; title: string; slug: string; color?: string },
    public readonly thumbnail: IStepThumbnail
  ) {
    this.id = step.id;
    this.title = step.title;
    this.slug = step.slug;
    this.color = step.color;
  }
}
