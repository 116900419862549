import * as React from "react";
import { List } from "../../../shared/components/list/List";
import { CourseStructure } from "../../../shared/contentStructure/CourseStructure";
import { PaymentSidePanelViewModel } from "../paymentDialog/PaymentSidePanelViewModel";
import { LessonListItemViewModel } from "./LessonListItemViewModel";
import { LessonSection, LessonSectionTitle } from "./lessonSection/LessonSection";

interface LessonListProps {
  lessonList: LessonListItemViewModel[];
  paymentSidePanelViewModel: PaymentSidePanelViewModel;
  courseStructure: CourseStructure;
  lessonSectionTitleVariant?: Omit<LessonSectionTitle, "text">;
}

export const LessonList: React.FC<LessonListProps> = ({
  lessonList,
  paymentSidePanelViewModel,
  courseStructure,
  lessonSectionTitleVariant,
}) => {
  return (
    <List
      items={lessonList}
      className="LessonList"
      renderItem={lesson => (
        <LessonSection
          key={lesson.id}
          isHighlighted={lesson.lesson.isHighlighted}
          title={{ text: lesson.lesson.title, ...lessonSectionTitleVariant }}
          steps={lesson.stepThumbnails}
          courseStructure={courseStructure}
          paymentSidePanelViewModel={paymentSidePanelViewModel}
        />
      )}
    />
  );
};
