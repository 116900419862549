import { action, computed } from "mobx";
import { IGtmService } from "../../../../../shared/services/GtmService";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";
import { IProgressStore } from "../../../../../shared/stores/ProgressStore";
import { Step } from "../Step";
import { SoundStepProperties } from "./CreateSoundStepProperties";

export class SoundStep extends Step {
  readonly _type = "Sound";

  readonly soundCloudUrl: string;

  @computed get trackProgress() {
    return this.progressStore.getVideoProgress(this.id);
  }

  @computed get startPositionSeconds() {
    return this.positionSeconds;
  }

  @computed private get positionSeconds() {
    return this.trackProgress?.lastPosition.totalSeconds ?? 0;
  }

  constructor(
    step: SoundStepProperties,
    protected progressStore: IProgressStore,
    private gtm: IGtmService,
    bookmarkStore: IBookmarkStore
  ) {
    super(step, progressStore, bookmarkStore);
    this.soundCloudUrl = step.soundCloudUrl;
  }

  @action.bound
  async updateSoundStepProgress(secondsSinceStart: number) {
    await this.progressStore.updateStepWithTimeProgress(this.id, secondsSinceStart, this._type);
  }

  @action.bound
  async endSoundStep(secondsSinceStart: number) {
    await this.progressStore.endStepWithTimeProgressContent(this.id, secondsSinceStart, this._type);
    this.gtm.pushSoundCloudEnded(this.id, this.title, this.soundCloudUrl);
  }
}
