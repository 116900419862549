import { observer } from "mobx-react";
import * as React from "react";
import Slider, { Settings, CustomArrowProps } from "react-slick";
import { SectionQuoteBlockDto } from "../../../../types/sections/dto/SectionQuoteBlockDto";
import { useTranslations } from "../../../contexts/StaticTranslationContext";
import { Button } from "../../button/Button";
import { SanityImage } from "../../image/Image";
import { SvgIcon } from "../../svgIcon/SvgIcon";

export interface SectionMultiQuoteBlockProps {
  id: string;
  quotes: SectionQuoteBlockDto[];
}

const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button className="SectionMultiQuoteBlock__arrow" type="clear" onClick={onClick}>
      <span className="visuallyHidden">{translations.previous}</span>
      <SvgIcon iconName="left-arrow-line" />
    </Button>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  const translations = useTranslations();

  return (
    <Button
      className="SectionMultiQuoteBlock__arrow SectionMultiQuoteBlock__arrow--next"
      type="clear"
      onClick={onClick}
    >
      <span className="visuallyHidden">{translations.next}</span>
      <SvgIcon iconName="right-arrow-line" />
    </Button>
  );
};

export const SectionMultiQuoteBlock = observer(({ quotes }: SectionMultiQuoteBlockProps) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    pauseOnDotsHover: true,
    touchThreshold: 10,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  } as Settings;

  return (
    <div className="SectionMultiQuoteBlock SectionQuoteBlockBase">
      <Slider {...sliderSettings}>
        {quotes.map((quote, index) => {
          return (
            <div className="SectionMultiQuoteBlock__wrapper" key={index}>
              <div className="SectionQuoteBlockBase__inner">
                <div className="SectionQuoteBlockBase__imageContainer">
                  <div className="SectionQuoteBlockBase__authorContainer">
                    <SanityImage {...quote.primaryImage} />
                    <div className="SectionQuoteBlockBase__apostropheContainer">
                      <SvgIcon iconName="apostrophe" />
                    </div>
                  </div>
                </div>
                <div className="SectionQuoteBlockBase__quote">
                  <blockquote>{quote.quote}</blockquote>
                  <span className="SectionQuoteBlockBase__author">{quote.author}</span>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
});
