import classNames from "classnames";
import { ButtonType, ButtonSize, ButtonVariant } from "../../button/Button";
import { LinkType } from "../Link";

export const getLinkClassNames = (
  type: LinkType,
  buttonType: Exclude<ButtonType, "clear">,
  buttonVariant: ButtonVariant,
  size: ButtonSize,
  className?: string
) => {
  if (type === "button") {
    return classNames(
      "Link",
      "Link--clear",
      `Link--${type}`,
      "Button",
      `Button--${buttonType}`,
      `Button--${buttonVariant}`,
      `Button--${size}`,
      className
    );
  }

  return classNames("Link", `Link--${type}`, `Link--${size}`, className);
};
