import { action, observable } from "mobx";
import { asyncCommand, Command, command } from "react-mvvm";
import { IBookmarkStore } from "../../../../../shared/stores/BookmarkStore";

export class AddBookmarkDialogViewModel {
  @observable message = "";

  done: Command;

  cancel: Command;

  @observable isVisible = false;

  @action.bound
  public show() {
    this.message = "";
    this.isVisible = true;
  }

  @action.bound
  public hide() {
    this.isVisible = false;
  }

  constructor(private stepId: string, private bookmarkStore: IBookmarkStore) {
    this.done = asyncCommand(async () => {
      const bookmark = this.bookmarkStore.createBookmark(this.stepId);
      bookmark.message = this.message;
      await bookmark.save();
      this.hide();
    });
    this.cancel = command(
      () => {
        this.hide();
      },
      () => !this.done.isRunning
    );
  }
}
