import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Textarea } from "../../../../shared/components/textarea/Textarea";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";

interface BookmarkMessageEditorProps {
  message?: string;
  isInEditMode: boolean;
  onSave: (value?: string) => void;
  onCancel: () => void;
}

export const BookmarkMessageEditor = observer((props: BookmarkMessageEditorProps) => {
  const { message: defaultMessage, isInEditMode, onSave, onCancel } = props;
  const translations = useTranslations();

  const [message, setMessage] = React.useState(defaultMessage);
  const messageRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (isInEditMode) {
      messageRef.current?.focus();

      const messageLength = message?.length ?? 0;
      messageRef.current?.setSelectionRange(messageLength, messageLength);
    }
  }, [isInEditMode]);

  if (isInEditMode) {
    return (
      <div className="BookmarkMessageEditor">
        <Textarea ref={messageRef} value={message} onChange={setMessage} className="BookmarkMessageEditor__textarea" />
        <div className="BookmarkMessageEditor_action">
          <Button onClick={() => onCancel()} type="outline" variant="secondary" size="medium">
            {translations.cancel}
          </Button>
          <Button
            onClick={() => onSave(message)}
            variant="primary"
            size="medium"
            className="BookmarkMessageEditor__saveChanges"
          >
            {translations.save}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="BookmarkMessageEditor">
      <p className="BookmarkMessageEditor__message">{message}</p>
    </div>
  );
});
