import { computed } from "mobx";
import { PreviousExamsStructureDto } from "../../types/courses/dto/PreviousExamsStructureDto";
import { CourseStructure } from "./CourseStructure";
import { ThemeStructure } from "./ThemeStructure";

export class PreviousExamsStructure {
  @computed get examThemes() {
    return this.dto.examThemes.map(examTheme => ({
      ...examTheme,
      id: examTheme.theme.id,
      theme: new ThemeStructure(this.courseStructure, examTheme.theme, "previousExams"),
    }));
  }

  get previousExamsSection() {
    return this.dto.previousExamsSection;
  }

  get title() {
    return this.dto.title;
  }

  get description() {
    return this.dto.description;
  }

  get image() {
    return this.dto.image;
  }

  get articles() {
    return this.dto.articles;
  }

  constructor(public courseStructure: CourseStructure, private dto: PreviousExamsStructureDto) {}
}
