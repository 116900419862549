import React from "react";
import { FieldError } from "react-hook-form";
import { TextContentWithPlaceholder } from "../textContentWithPlaceholder/TextContentWithPlaceholder";

interface FormErrorMessageProps {
  id: string;
  error?: FieldError;
}

export const FormErrorMessage: React.FC<FormErrorMessageProps> = props => {
  const { id, error } = props;

  return (
    <div aria-live={!error?.message ? "off" : "polite"} id={id} className="FormErrorMessage">
      <TextContentWithPlaceholder isVisible={!!error?.message}>{error?.message}</TextContentWithPlaceholder>
    </div>
  );
};
