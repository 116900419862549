import { ImageDto } from "../../../../../types/shared/dto/ImageDto";
import { ContentReference } from "../../../../models/Url";

export interface ITestThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  description?: string;
  color?: string;
  image?: ImageDto;
  hasAccess: boolean;
  type: "Test";
}

export class TestThumbnailViewModel implements ITestThumbnail {
  id: string;

  contentRef: ContentReference;

  title: string;

  description?: string;

  image?: ImageDto;

  color?: string;

  hasAccess: boolean;

  readonly type = "Test";

  constructor(stepThumbnail: {
    id: string;
    title: string;
    image?: ImageDto;
    color?: string;
    description?: string;
    hasAccess: boolean;
    slug: string;
  }) {
    this.id = stepThumbnail.id;
    this.contentRef = stepThumbnail;
    this.title = stepThumbnail.title;
    this.description = stepThumbnail.description;
    this.color = stepThumbnail.color;
    this.hasAccess = true;
    this.image = stepThumbnail.image;
  }
}
