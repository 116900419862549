import { observer } from "mobx-react";
import React from "react";
import { command } from "../../../react-mvvm/src";
import { DialogView } from "../../../shared/components/dialog/DialogView";
import { Popup } from "../../../shared/components/popup/Popup";
import { useDelayedDisplay } from "../../../shared/hooks/useDelayedDisplay";
import { useLocation } from "../../../useRouter";
import { UserLink } from "../HeaderViewModel";
import { UserLinks } from "../userLinks/UserLinks";

interface UserLinksDialogProps {
  popup: Popup;
  links: UserLink[];
  referenceElement: HTMLElement | null;
}

export const UserLinksDialog: React.FC<UserLinksDialogProps> = observer(props => {
  const {
    popup: { isOpen, close },
    links,
    referenceElement,
  } = props;
  const [isDisplayed] = useDelayedDisplay(isOpen);
  const location = useLocation();

  React.useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [location]);

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="UserLinksDialog">
      <DialogView
        placement="bottom"
        isVisible={isOpen}
        referenceElement={referenceElement}
        cancelCommand={command(() => close())}
        offset={[0, 4]}
        className="UserLinksDialog__dialog"
      >
        <UserLinks links={links} />
      </DialogView>
    </div>
  );
});
