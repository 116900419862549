import React from "react";
import { SvgIcon } from "../../../shared/components/svgIcon/SvgIcon";

interface InfoElementProps {
  value: string;
}

export const InfoElement: React.FC<InfoElementProps> = props => {
  const { value } = props;
  return (
    <div className="InfoElement">
      <SvgIcon iconName="check" className="InfoElement__icon" />
      <p className="InfoElement__value">{value}</p>
    </div>
  );
};
