import * as React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { formatProgress } from "../../formatProgress";
import { HighLevelStepType } from "../../stepTypeUtils";
import { ProgressBar } from "../progressBar/ProgressBar";

type StepTypeWithProgress = { [key in HighLevelStepType]: number };

export interface ProgressByTypeProps {
  stepsProgress: StepTypeWithProgress;
  isAnyOfType: (type: HighLevelStepType) => boolean;
}

export const ProgressByType = ({ stepsProgress, isAnyOfType }: ProgressByTypeProps) => {
  const translations = useTranslations();

  const stepTypesTranslations: { [key in HighLevelStepType]: string } = {
    Video: translations.video,
    Task: translations.tasks,
    TextBlock: translations.text,
    Sound: translations.sound,
  };

  const getProgressItem = (type: HighLevelStepType) => {
    const { progressValue, unitInterval } = formatProgress(stepsProgress[type]);
    return isAnyOfType(type) ? (
      <li className="ProgressByType__item" key={type}>
        <div className="ProgressByType__taskType">{stepTypesTranslations[type]}</div>
        <div className="ProgressByType__progressBar">
          <ProgressBar progress={unitInterval} variant="dark" />
        </div>
        <div className="ProgressByType__taskProgress">{`${progressValue} %`}</div>
      </li>
    ) : null;
  };

  const progressItems: { [key in HighLevelStepType]: React.ReactNode } = {
    Video: getProgressItem("Video"),
    Task: getProgressItem("Task"),
    TextBlock: getProgressItem("TextBlock"),
    Sound: getProgressItem("Sound"),
  };

  return (
    <div className="ProgressByType">
      <ul className="ProgressByType__list">{Object.values(progressItems).map(item => item)}</ul>
    </div>
  );
};
