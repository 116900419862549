import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../../shared/components/button/Button";
import { Popover } from "../../../../shared/components/popover/Popover";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { DomainThumbnail } from "../../../../shared/components/thumbnails/domainThumbnail/DomainThumbnail";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { BookmarkMessageEditor } from "../bookmarkMessageEditor/BookmarkMessageEditor";
import { BookmarkViewModel } from "./BookmarkViewModel";

export const BookmarkView = observer(({ model }: { model: BookmarkViewModel }) => {
  const translations = useTranslations();

  const {
    bookmark,
    isInEditMode,
    activeCourseStructure,
    paymentSidePanelViewModel,
    thumbnail,
    popover,
    setIsInEditMode,
    updateMessage,
    deleteBookmark,
  } = model;
  const { subTitle, title, message } = bookmark;
  const { urlParams } = activeCourseStructure;

  return (
    <div className="Bookmark">
      <div className="Bookmark__thumbnail">
        <DomainThumbnail
          stepThumbnail={thumbnail}
          courseStructure={activeCourseStructure}
          activeCourseParams={urlParams}
          paymentSidePanelViewModel={paymentSidePanelViewModel}
          thumbnailType="rectangle"
        />
      </div>
      <div className="Bookmark__info">
        {subTitle && <span className="Bookmark__subtitle">{subTitle}</span>}
        <Title level={2} className="Bookmark__title">
          {title}
        </Title>
        <div className="Bookmark__popoverwrapper">
          <Popover isOpen={popover.isOpen} toggle={popover.toggle}>
            <div className="Bookmark__popover">
              <Button
                className="Bookmark__popoverElement"
                onClick={() => {
                  popover.close();
                  setIsInEditMode(!isInEditMode);
                }}
                type="clear"
              >
                <SvgIcon iconName="pencil" className="Bookmark__popoverIcon" />
                <span>{translations.edit}</span>
              </Button>
              <Button className="Bookmark__popoverElement" onClick={deleteBookmark} type="clear">
                <SvgIcon iconName="close" className="Bookmark__popoverIcon" />
                <span>{translations.remove}</span>
              </Button>
            </div>
          </Popover>
        </div>
        <div className="Bookmark__message">
          <BookmarkMessageEditor
            message={message}
            isInEditMode={isInEditMode}
            onCancel={() => setIsInEditMode(false)}
            onSave={value => {
              setIsInEditMode(false);
              updateMessage(value);
            }}
          />
        </div>
      </div>
    </div>
  );
});
