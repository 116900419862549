import { autorun, computed, observable } from "mobx";
import { ImageDto } from "../../../../types/shared/dto/ImageDto";
import { StepThumbnailDto } from "../../../../types/shared/dto/StepThumbnailDto";
import { VideoDetailsDto } from "../../../../types/video/dto/VideoDetailsDto";
import { IVideoService } from "../../../api/BackendApi";
import { ContentReference } from "../../../models/Url";
import { IProgressStore } from "../../../stores/ProgressStore";
import { imageCropMock, imageHotspotMock } from "../../image/Image.mock";
import { BookmarkToggleViewModel } from "../shared/BookmarkToggleViewModel";

export interface IVideoThumbnail {
  id: string;
  contentRef: ContentReference;
  title: string;
  description?: string;
  color?: string;
  hasAccess: boolean;
  isHighlighted?: boolean;
  isCompleted: boolean;
  progress?: number;
  image: ImageDto;
  videoDetails?: VideoDetailsDto;
  type: "Video";
  slug: string;
  bookmarkToggleViewModel?: BookmarkToggleViewModel;
}

export class VideoThumbnailViewModel implements IVideoThumbnail {
  id: string;

  slug: string;

  title: string;

  contentRef: ContentReference;

  description?: string;

  color?: string;

  hasAccess: boolean;

  isHighlighted?: boolean;

  readonly type = "Video";

  @computed get image() {
    // Adding mocks here since the thumbnail comes from vimeo, not sanity
    if (!this.videoDetails) {
      return { url: "", alt: this.title, width: 400, height: 264, crop: imageCropMock, hotspot: imageHotspotMock };
    }

    return {
      url: this.videoDetails.thumbnailUrl,
      alt: this.title,
      width: 400,
      height: 264,
      crop: imageCropMock,
      hotspot: imageHotspotMock,
    };
  }

  @observable videoDetails?: VideoDetailsDto;

  @computed get videoProgress() {
    return this.progressStore.getVideoProgress(this.id);
  }

  @computed get progress() {
    if (!this.videoProgress || !this.videoDetails) {
      return undefined;
    }

    return this.videoProgress.lastPosition.totalSeconds / this.videoDetails.duration.totalSeconds;
  }

  @computed get isCompleted() {
    return this.progressStore.isCompleted(this.id);
  }

  constructor(
    stepThumbnail: StepThumbnailDto,
    protected progressStore: IProgressStore,
    private videoService: IVideoService,
    public bookmarkToggleViewModel?: BookmarkToggleViewModel,
    videoDetailsDto?: VideoDetailsDto,
    isHighlighted?: boolean
  ) {
    this.id = stepThumbnail.id;
    this.contentRef = stepThumbnail;
    this.title = stepThumbnail.title;
    this.color = stepThumbnail.color;
    this.hasAccess = stepThumbnail.hasAccess;
    this.description = stepThumbnail.description;
    this.isHighlighted = isHighlighted;
    this.slug = stepThumbnail.slug;

    autorun(async () => {
      if (videoDetailsDto) {
        this.videoDetails = videoDetailsDto;
      } else {
        await this.loadVideoDetails(this.id);
      }
    });
  }

  private async loadVideoDetails(stepId: string) {
    this.videoDetails = await this.videoService.getVideoDetails({ stepId });
  }
}
