import React from "react";
import { Command } from "../../../../react-mvvm/src";
import { CommandButton } from "../../../../shared/components/button/Button";
import { Image, SanityImage } from "../../../../shared/components/image/Image";
import { Link } from "../../../../shared/components/link/Link";
import { List } from "../../../../shared/components/list/List";
import { ProgressRing } from "../../../../shared/components/progressRing/ProgressRing";
import { SvgIcon } from "../../../../shared/components/svgIcon/SvgIcon";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { ActiveCourseParams } from "../../../../shared/models/Url";
import { useUrl } from "../../../../useRouter";
import { TrainingGroundResult } from "./TrainingResultViewModel";
import { ThemeResultItem } from "./themeResultItem/ThemeResultItem";

interface TrainingResultViewProps {
  result: TrainingGroundResult;
  title: string;
  activeCourseParams: ActiveCourseParams;
  resetTraining: Command;
}

export const TrainingResultView: React.FC<TrainingResultViewProps> = props => {
  const { result, title: trainingTitle, activeCourseParams, resetTraining } = props;
  const {
    resultInfo: { title, description, image },
    hasPassed,
    score,
    progressPerTheme,
  } = result;

  const translations = useTranslations();
  const url = useUrl();

  return (
    <div className="TrainingResult">
      <Title level={1} className="visuallyHidden">
        {trainingTitle}
      </Title>
      <div className="TrainingResult__topContent">
        <div className="TrainingResult__close">
          <Link
            to={url.toDashboardPage(activeCourseParams)}
            type="button"
            buttonSize="medium"
            buttonType="outline"
            buttonVariant="secondary"
            className="TrainingResult__closeLink"
          >
            <SvgIcon iconName="close" className="TrainingResult__closeLinkIcon" />
            <span className="tablet">{translations.goToDashboard}</span>
          </Link>
        </div>
        <div className="TrainingResult__progress">
          <div className="TrainingResult__ringWrapper">
            <ProgressRing progress={score} type={hasPassed ? "success" : "default"} />
          </div>
        </div>
        <div className="TrainingResult__info">
          <Title level={2} className="TrainingResult__title">
            {title}
          </Title>
          <p className="TrainingResult__description">{description}</p>
        </div>
        {image && (
          <Image.Container aspectRatio="3/2" className="TrainingResult__imageWrapper">
            <SanityImage {...image} className="TrainingResult__image" />
            {image.credit && <Image.Credit text={image.credit} />}
          </Image.Container>
        )}
        <div className="TrainingResult__action">
          <CommandButton className="TrainingResult__actionButton" command={resetTraining}>
            {translations.tryAgain}
            <SvgIcon iconName="repeat" className="TrainingResult__actionButtonIcon" />
          </CommandButton>
        </div>
      </div>
      <div className="TrainingResult__bottomContent">
        <Title level={2} className="TrainingResult__summaryTitle">
          {translations.summary}
        </Title>
        <List
          items={progressPerTheme}
          renderItem={themeProgress => <ThemeResultItem {...themeProgress} activeCourseParams={activeCourseParams} />}
          className="TrainingResult__summaryList"
          singleItemClassName="TrainingResult__summaryItemWrapper"
        />
      </div>
    </div>
  );
};
