import React from "react";

export interface TextareaProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement | null, TextareaProps>(
  ({ value, onChange, placeholder, className }, ref) => {
    return (
      <textarea
        className={className}
        value={value}
        placeholder={placeholder}
        onChange={e => {
          onChange(e.target.value);
        }}
        ref={ref}
      />
    );
  }
);
