import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react";
import React from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { ActivityIndicator } from "../../../../shared/components/activityIndicator/ActivityIndicator";
import { Button } from "../../../../shared/components/button/Button";
import { Checkbox } from "../../../../shared/components/checkbox/Checkbox";
import { FormErrorMessage } from "../../../../shared/components/formErrorMessage/FormErrorMessage";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import { Modal } from "../../../../shared/components/modal/Modal";
import { Select, SelectListItem } from "../../../../shared/components/select/Select";
import { Title } from "../../../../shared/components/title/Title";
import { useTranslations } from "../../../../shared/contexts/StaticTranslationContext";
import { BetaNewsletterPopupViewModel } from "./BetaNewsletterPopupViewModel";

interface BetaNewsletterPopupProps {
  model: BetaNewsletterPopupViewModel;
}

interface FormInputs {
  fullName?: string;
  email: string;
  profession: string;
  processingAgreement: boolean;
}

const defaultFormValues = { email: "", fullName: "", profession: "" };

export const BetaNewsletterPopup: React.FC<BetaNewsletterPopupProps> = observer(props => {
  const { model } = props;
  const { isOpen, close, handleSubmit } = model;
  const translations = useTranslations();

  const professionOptions: SelectListItem<string>[] = [
    { value: "student", label: translations.student },
    { value: "lecturer", label: translations.lecturer },
    { value: "other", label: translations.other },
  ];

  const createContactUsSchema = () => {
    const contactUsSchema: yup.SchemaOf<FormInputs> = yup
      .object({
        fullName: yup.string().optional(),
        email: yup.string().required(translations.formEmailRequired).email(translations.formEmailNotValid),
        profession: yup.string().required(translations.formFieldRequired),
        processingAgreement: yup
          .boolean()
          .required(translations.formConsentRequired)
          .oneOf([true], translations.formConsentRequired),
      })
      .defined();

    return contactUsSchema;
  };

  const form = useForm<FormInputs>({
    resolver: yupResolver(createContactUsSchema()),
    defaultValues: defaultFormValues,
  });

  const onFormSubmit: SubmitHandler<FormInputs> = async fieldValues => {
    const profession = professionOptions.find(option => option.value === fieldValues.profession);

    if (!profession) {
      throw new Error("Selected option needs to be specified");
    }

    await handleSubmit({
      fullName: fieldValues.fullName,
      email: fieldValues.email,
      profession: profession.label,
    });
    form.reset();
  };

  const onClose = () => {
    form.reset();
    close();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" className="BetaNewsletterPopup">
      <div className="BetaNewsletterPopup__container">
        <Title level={2} className="BetaNewsletterPopup__title">
          {translations.signUpForNewsletter}
        </Title>
        <p className="BetaNewsletterPopup__description">{translations.newsletterDescription}</p>
        <div className="BetaNewsletterPopup__content">
          <FormProvider {...form}>
            <form className="BetaNewsletterPopup__action" onSubmit={form.handleSubmit(onFormSubmit)} noValidate>
              <Controller
                name="fullName"
                control={form.control}
                render={({ field, fieldState }) => {
                  const fieldErrorId = `${field.name}__error`;
                  return (
                    <>
                      <Label>{translations.fullName}</Label>
                      <Input
                        {...field}
                        value={field.value ?? ""}
                        label={translations.fullName}
                        describedBy={fieldState.error && fieldErrorId}
                        className="BetaNewsletterPopup__fieldContainer"
                      />
                      <FormErrorMessage id={fieldErrorId} error={fieldState.error} />
                    </>
                  );
                }}
              />
              <Controller
                name="email"
                control={form.control}
                render={({ field, fieldState }) => {
                  const fieldErrorId = `${field.name}__error`;
                  return (
                    <>
                      <Label required>{translations.email}</Label>
                      <Input
                        {...field}
                        label={translations.email}
                        describedBy={fieldState.error && fieldErrorId}
                        htmlType="email"
                        required
                        className="BetaNewsletterPopup__fieldContainer BetaNewsletterPopup__formEmail"
                      />
                      <FormErrorMessage id={fieldErrorId} error={fieldState.error} />
                    </>
                  );
                }}
              />
              <Controller
                control={form.control}
                name="profession"
                render={({ field, fieldState }) => {
                  const fieldErrorId = `${field.name}__error`;

                  return (
                    <>
                      <Label required>{translations.whoAreYou}</Label>
                      <Select<string>
                        className="BetaNewsletterPopup__fieldContainer"
                        // ref={field.ref}  // TODO: add ref to the Select component (to focus when first field with error)
                        value={field.value}
                        options={professionOptions}
                        onChange={field.onChange}
                        label={translations.whoAreYou}
                        describedBy={fieldState.error && fieldErrorId}
                        placeholder=""
                      />
                      <FormErrorMessage id={fieldErrorId} error={fieldState.error} />
                    </>
                  );
                }}
              />
              <Controller
                control={form.control}
                name="processingAgreement"
                render={({ field: { onChange, value, name, ref }, fieldState }) => {
                  const fieldErrorId = `${name}__error`;
                  return (
                    <div className="BetaNewsletterPopup__formConsent">
                      <Checkbox
                        ref={ref}
                        checked={value}
                        onChange={(_id, checked) => onChange(checked)}
                        id="processingAgreement"
                        required
                        aria-describedby={fieldState.error && fieldErrorId}
                      >
                        <span className="BetaNewsletterPopup__processingAgreementLabel">
                          {translations.newsletterConsent}
                        </span>
                      </Checkbox>
                      <FormErrorMessage id={fieldErrorId} error={fieldState.error} />
                    </div>
                  );
                }}
              />
              <Button
                disabled={form.formState.isSubmitting}
                className="BetaNewsletterPopup__submitButton"
                htmltype="submit"
                type="solid"
              >
                {translations.send}
              </Button>
              <ActivityIndicator isLoading={form.formState.isSubmitting} />
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
});
