export const getInitials = (name: string) => {
  const nameSplitted = name.split(" ");

  if (nameSplitted.length === 2) {
    return `${nameSplitted[0].charAt(0)}${nameSplitted[1].charAt(0)}`.toUpperCase();
  }

  return name.slice(0, 2).toUpperCase();
};

/**
 * Apply variables to string with {} placeholders
 * ex. format(`{0} is {1}`, ["price", 30])
 */
const format = (template: string, values: (string | number)[]) => {
  return template.replace(/{(\d+)}/g, (match, index) => {
    return typeof values[index] !== "undefined" ? values[index].toString() : match;
  });
};

export const FormattedText = (text?: string, values?: (string | number)[]) =>
  text && values && values.length > 0 ? format(text, values) : text;

export const alphabet = "abcdefghijklmnopqrstuvwxyz";

export const splitWords = (words: string[], conjunction: string) => {
  if (words.length === 1) {
    return words[0];
  }

  const lastWord = words.pop();

  if (!lastWord) {
    throw new Error("Last element of array should be specified");
  }

  const formattedText = FormattedText(conjunction, [words.join(", "), lastWord]);

  if (!formattedText) {
    throw new Error("Formatted text should be specified");
  }

  return formattedText;
};

export const useSingularOrPluralForm = (count: number, forms: [singular: string, plural: string]) => {
  const [singular, plural] = forms;
  switch (count) {
    case 0:
      return undefined;
    case 1:
      return FormattedText(singular, [count]);
    default:
      return FormattedText(plural, [count]);
  }
};
