import classNames from "classnames";
import React from "react";
import { animated, useSpring } from "react-spring";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { useDelayedDisplay } from "../../hooks/useDelayedDisplay";
import { useVerticalSwipeThreshold } from "../../hooks/useVerticalSwipeThreshold";
import { Button } from "../button/Button";
import { SvgIcon } from "../svgIcon/SvgIcon";

interface BottomSheetProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onResolve?: () => void;
  variant?: "white" | "blue";
}

export const BottomSheet: React.FC<BottomSheetProps> = props => {
  const { children, isOpen, setIsOpen, onResolve, variant = "white" } = props;
  const translations = useTranslations();
  const [isDisplayed, hide] = useDelayedDisplay(isOpen);
  const { touchStart, touchEnd } = useVerticalSwipeThreshold(40);

  const sheetStyles = useSpring({
    from: { transform: `translateY(100%)` },
    transform: isOpen ? `translateY(0%)` : `translateY(100%)`,
    onResolve: () => {
      if (!isOpen) {
        hide();

        if (onResolve) {
          onResolve();
        }
      }
    },
  });

  const fadeInStyles = useSpring({
    from: { opacity: 0 },
    opacity: isOpen ? 1 : 0,
  });

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="BottomSheet">
      <animated.div style={fadeInStyles}>
        <Button
          onClick={() => setIsOpen(false)}
          disabled={!isOpen}
          tabIndex={-1}
          type="clear"
          className="BottomSheet__backgroundButton"
        >
          <span className="visuallyHidden">{translations.close}</span>
        </Button>
      </animated.div>
      <animated.div
        className={classNames("BottomSheet__sheet", { "BottomSheet__sheet--blue": variant === "blue" })}
        style={sheetStyles}
      >
        <div
          className="BottomSheet__sheetTop"
          role="presentation"
          onTouchStart={touchStart}
          onTouchEnd={e =>
            touchEnd(e, () => {
              if (!isOpen) {
                return;
              }

              setIsOpen(false);
            })
          }
        >
          <div className="BottomSheet__bar" />
        </div>
        {children}
        <animated.div className="BottomSheet__buttonWrapper" style={fadeInStyles}>
          <Button onClick={() => setIsOpen(false)} type="circle" variant="secondary" disabled={!isOpen}>
            <span className="visuallyHidden">{translations.close}</span>
            <SvgIcon iconName="close" />
          </Button>
        </animated.div>
      </animated.div>
    </div>
  );
};
