import { IRoute } from "react-mvvm";
import { RouteConfiguration } from "../../types/platform/infrastructure/RouteConfiguration";
import { CourseViewModel } from "../course/CourseViewModel";
import { CourseParams } from "../course/addCourseRouting";
import { ProductParams } from "../product/addProductRouting";
import { ThemeListViewModel } from "./themeList/ThemeListViewModel";

export const addThemeListRouting = (courseRoute: IRoute<CourseViewModel, ProductParams, CourseParams>) => {
  const themeListRouting = courseRoute.addRoute<ThemeListViewModel>(
    RouteConfiguration.Themes,
    ThemeListViewModel,
    coursePage => {
      const theme = new ThemeListViewModel(
        coursePage.courseStructure,
        coursePage.languageService,
        coursePage.errorService,
        coursePage.progressStore,
        coursePage.userStore,
        coursePage.backendApi,
        coursePage.paymentSidePanelViewModel,
        coursePage.bookmarkStore,
        coursePage.navigation,
        coursePage.gtm,
        coursePage.backendApi,
        coursePage.courseNavigator
      );
      return coursePage.showChildPage(theme);
    }
  );

  return themeListRouting;
};
