import { TaskId, AnswerQuestions } from "./taskUtils";

export type UserAnswer = { taskId: TaskId; value: AnswerQuestions };

type CorrectAnswers = {
  [taskId: TaskId]: AnswerQuestions;
};

interface TaskResult {
  taskId: TaskId;
  correctAnswers: AnswerQuestions;
  isCorrect: boolean;
}

export interface CheckAnswersResult {
  numberOfCorrectAnswers: number;
  numberOfQuestions: number;
  taskResults: TaskResult[];
}

export interface TaskService {
  checkAnswers(userAnswers: UserAnswer[]): CheckAnswersResult;
  getCorrectAnswers(id: TaskId): Promise<AnswerQuestions>;
}

export class LocalTaskService implements TaskService {
  private correctAnswers: CorrectAnswers = {};

  registerTask(taskId: TaskId, correctAnswer: AnswerQuestions) {
    this.correctAnswers[taskId] = correctAnswer;
  }

  checkAnswers(userAnswers: UserAnswer[]): CheckAnswersResult {
    const taskResults = userAnswers.map(userAnswer => {
      const correctAnswers = this.correctAnswers[userAnswer.taskId];
      if (!correctAnswers) {
        throw new Error(`Unregistered task with id: ${userAnswer.taskId}`);
      }

      const isCorrect = correctAnswers.every(correctAnswer => {
        const userAnswerToQuestion = userAnswer.value.find(v => v.questionId === correctAnswer.questionId);
        if (userAnswerToQuestion === undefined) {
          return false;
        }
        if (userAnswerToQuestion._type !== correctAnswer._type) {
          throw new Error(
            `User answer(${userAnswerToQuestion._type}) and correct answer(${correctAnswer._type}) type do not match`
          );
        }

        if (userAnswerToQuestion._type === "inputAnswerQuestion" && correctAnswer._type === "inputAnswerQuestion") {
          return userAnswerToQuestion.value === correctAnswer.value;
        }

        if (userAnswerToQuestion._type === "singleAnswerQuestion" && correctAnswer._type === "singleAnswerQuestion") {
          return userAnswerToQuestion.value === correctAnswer.value;
        }

        if (
          userAnswerToQuestion._type === "multipleAnswerQuestion" &&
          correctAnswer._type === "multipleAnswerQuestion"
        ) {
          return correctAnswer.value.every(
            userAnswerToQuestionValue =>
              userAnswerToQuestion.value.find(
                correctAnswerValue => correctAnswerValue === userAnswerToQuestionValue
              ) !== undefined
          );
        }

        throw new Error(`Didn't expect to get here`);
      });

      return {
        taskId: userAnswer.taskId,
        correctAnswers: correctAnswers,
        isCorrect: isCorrect,
      };
    });

    return {
      taskResults,
      numberOfQuestions: userAnswers.length,
      numberOfCorrectAnswers: taskResults.filter(ac => ac.isCorrect).length,
    };
  }

  async getCorrectAnswers(id: TaskId): Promise<AnswerQuestions> {
    return this.correctAnswers[id];
  }
}
