import classNames from "classnames";
import * as React from "react";
import { useTranslations } from "../../contexts/StaticTranslationContext";
import { formatProgress } from "../../formatProgress";
import { ProgressBar, ProgressBarProps } from "../progressBar/ProgressBar";
import { SvgIcon } from "../svgIcon/SvgIcon";

type DetailedProgressBarSize = "small" | "medium" | "large";

export type DetailedProgressBarProps = {
  totalStepsCount: number;
  completedStepsCount: number;
  progressInfo: string;
  className?: string;
  size?: DetailedProgressBarSize;
} & Pick<ProgressBarProps, "type" | "variant">;

export const DetailedProgressBar: React.FC<DetailedProgressBarProps> = ({
  totalStepsCount,
  completedStepsCount,
  progressInfo,
  className,
  type = "success",
  variant = "light",
  size = "large",
}) => {
  const translations = useTranslations();
  const { isCompleted, unitInterval } = formatProgress(completedStepsCount / totalStepsCount);

  return (
    <div className={classNames("DetailedProgressBar", `DetailedProgressBar--${size}`, className)}>
      <div className="DetailedProgressBar__heading">
        {isCompleted ? (
          <div className="DetailedProgressBar__progressValueCompleted">
            <SvgIcon iconName="completed" />
            <span>{translations.completed}</span>
          </div>
        ) : (
          <div>
            <strong className="DetailedProgressBar__progressSteps">{`${completedStepsCount} / ${totalStepsCount} `}</strong>
            <span className="DetailedProgressBar__progressValueText">{progressInfo}</span>
          </div>
        )}
      </div>
      <div className="DetailedProgressBar__progressBar">
        <ProgressBar
          progress={unitInterval}
          variant={variant}
          type={type}
          size={size === "large" ? "large" : "medium"}
        />
      </div>
    </div>
  );
};
